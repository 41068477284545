import { isEmpty, filter, pickBy } from 'lodash-es';
import queryString from 'query-string';
import React from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes as RouterRoutes,
  useLocation,
} from 'react-router-dom';

import AbsencesPage from 'components/pages/absences/AbsencesPage';
import AgreementsPage from 'components/pages/agreements/AgreementsPage';
import AnnouncementDetailPage from 'components/pages/announcements/AnnouncementDetailPage';
import AnnouncementsIndexPage from 'components/pages/announcements/AnnouncementsIndexPage';
import ErrorPage from 'components/pages/errors/common/ErrorPage';
import ForgotPage from 'components/pages/forgot/ForgotPage';
import LoginPage from 'components/pages/login/LoginPage';
import ContactPage from 'components/pages/mypage/ContactPage';
import EmailChangeCompletePage from 'components/pages/mypage/EmailChangeCompletePage';
import OrganizationMembership from 'components/pages/mypage/OrganizationMembership';
import ParentProfileEditPage from 'components/pages/mypage/ParentProfileEditPage';
import ParentProfilePage from 'components/pages/mypage/ParentProfilePage';
import NoticeListPage from 'components/pages/notice/NoticeListPage';
import NoticeShowPage from 'components/pages/notice/NoticeShowPage';
import PasswordChangeEmailConfirmationPage from 'components/pages/password/PasswordChangeEmailConfirmationPage';
import ResetPassword from 'components/pages/password/ResetPassword';
import EmailRegistration from 'components/pages/registration/EmailRegistration';
import ProfileRegistration from 'components/pages/registration/ProfileRegistration';
import WithdrawalPage from 'components/pages/withdrawal/WithdrawalPage';
import useAuthentication from 'hooks/common/useAuthentication';
import useHomePath from 'hooks/common/useHomePath';
import { AppContext } from 'providers/ContextProvider';

const Authenticated: React.FC = () => {
  const { isLoggedIn } = useAuthentication();
  const location = useLocation();

  if (isLoggedIn) {
    return <Outlet />;
  } else {
    // utm_*のクエリパラメータを保持する
    const queryParams = queryString.parse(location.search);
    const utmParams = pickBy(queryParams, (v, k) => k.startsWith('utm_'));

    return (
      <Navigate
        replace
        to={{
          pathname: '/login',
          search: queryString.stringify(utmParams),
        }}
        state={{
          redirectTo: {
            pathname: location.pathname,
            search: location.search,
            hash: location.hash,
          },
        }}
      />
    );
  }
};
const Unauthenticated: React.FC<{ fallback?: React.ReactNode }> = ({
  fallback = <ErrorPage type="not_found" />,
}) => {
  const { isLoggedIn } = useAuthentication();

  if (isLoggedIn) {
    return fallback;
  } else {
    return <Outlet />;
  }
};

const RequireAgreement: React.FC = () => {
  const { agreements } = React.useContext(AppContext);
  const location = useLocation();

  const isAgreed = React.useMemo(() => {
    if (isEmpty(agreements)) return null;
    return isEmpty(filter(agreements, ['agreement', false]));
  }, [agreements]);

  if (isAgreed === null) {
    return null;
  } else if (isAgreed) {
    return <Outlet />;
  } else {
    return (
      <Navigate
        replace
        to="/agreements"
        state={{
          redirectTo: {
            pathname: location.pathname,
            search: location.search,
            hash: location.hash,
          },
        }}
      />
    );
  }
};

const Routes: React.FC = () => {
  const homePath = useHomePath();

  return (
    <RouterRoutes>
      <Route path="/" element={<Navigate replace to={homePath} />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/notices/:noticeId" element={<NoticeShowPage />} />
      <Route path="/notices" element={<NoticeListPage />} />
      <Route
        path="/email_change_tokens/:token"
        element={<EmailChangeCompletePage />}
      />

      <Route element={<Authenticated />}>
        <Route path="/agreements" element={<AgreementsPage />} />
        <Route element={<RequireAgreement />}>
          <Route path="/announcements" element={<AnnouncementsIndexPage />} />
          <Route
            path="/announcements/:announcementId"
            element={<AnnouncementDetailPage />}
          />
          <Route path="/absences/*" element={<AbsencesPage />} />
        </Route>
        <Route path="/mypage/profile" element={<ParentProfilePage />} />
        <Route
          path="/mypage/profile/edit/*"
          element={<ParentProfileEditPage />}
        />
        <Route
          path="/mypage/organization_memberships/*"
          element={<OrganizationMembership />}
        />
        <Route path="/withdrawal" element={<WithdrawalPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Route>
      <Route
        element={
          <Unauthenticated
            fallback={<Navigate replace to="/mypage/profile" />}
          />
        }
      >
        <Route
          path="/registration/profile/*"
          element={<ProfileRegistration />}
        />
        <Route path="/registration/*" element={<EmailRegistration />} />
      </Route>
      <Route element={<Unauthenticated />}>
        <Route
          path="/forgot/password/confirmation"
          element={<PasswordChangeEmailConfirmationPage />}
        />
        <Route path="/forgot/*" element={<ForgotPage />} />
        <Route path="/password/reset/*" element={<ResetPassword />} />
      </Route>

      <Route path="*" element={<ErrorPage type="not_found" />} />
    </RouterRoutes>
  );
};

export default Routes;
