import React from 'react';
import { FormattedMessage } from 'react-intl';

import BackToPageButton from 'components/atoms/buttons/BackToPageButton';
import Button from 'components/modules/button/Button';

import CSSModule from './WithdrawalForm.module.scss';

interface Props {
  onClick: () => void;
}

const WithdrawalForm: React.FC<Props> = ({ onClick }) => (
  <div className={CSSModule.WithdrawalForm}>
    <div className={CSSModule.WithdrawalForm__Inner}>
      <p className={CSSModule.WithdrawalForm__Description}>
        <FormattedMessage id="withdrawal.withdrawalPage.description" />
      </p>
      <ul className={CSSModule.WithdrawalForm__List}>
        <li className={CSSModule.WithdrawalForm__ListItem}>
          <FormattedMessage id="withdrawal.withdrawalPage.loginToForParent" />
        </li>
        <li className={CSSModule.WithdrawalForm__ListItem}>
          <FormattedMessage id="withdrawal.withdrawalPage.viewDeliveredAnnouncements" />
        </li>
      </ul>
      <p className={CSSModule.WithdrawalForm__Note}>
        <FormattedMessage id="withdrawal.withdrawalPage.note" />
      </p>
      <Button
        color="danger"
        onClick={onClick}
        className={CSSModule.WithdrawalForm__Button}
      >
        <FormattedMessage id="withdrawal.withdrawalPage.goToWithdrawal" />
      </Button>
      <div className={CSSModule.WithdrawalForm__BackToPage}>
        <BackToPageButton to="/contact">
          <FormattedMessage id="withdrawal.withdrawalPage.back" />
        </BackToPageButton>
      </div>
    </div>
  </div>
);

export default WithdrawalForm;
