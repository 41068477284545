import { useEffect } from 'react';

import useFetch from 'hooks/common/useFetch';
import useModal from 'hooks/common/useModal';
import { Student } from 'interfaces/student';

interface Props {
  code: string;
}

export interface FetchStudentResult {
  student: Student;
}

const useStudentModalWithParentAssociation = ({ code }: Props) => {
  const { showModal } = useModal();
  const {
    fetch: fetchStudent,
    isFetching: isFetchingStudent,
    status: fetchStudentStatus,
    error: fetchStudentError,
    data: studentData,
  } = useFetch<FetchStudentResult>(
    'GET',
    `/api/school/parents/organization_memberships/${code}`,
  );

  useEffect(() => {
    if (studentData && fetchStudentStatus === 200) {
      showModal('StudentModalWithParentAssociation', {
        student: studentData.student,
      });
    }
  }, [fetchStudentStatus, studentData, showModal]);

  return {
    fetchStudent: () => fetchStudent(),
    isFetchingStudent,
    fetchStudentErrors: !!fetchStudentError && [fetchStudentError.message],
    fetchStudentStatus,
  };
};

export default useStudentModalWithParentAssociation;
