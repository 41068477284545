import React from 'react';
import { styled, css } from 'styled-components';

import AbsencesDetailContents from 'components/pages/absences/detail/AbsencesDetailContents';

interface OwnProps {
  studentName?: string | null;
  date?: Date | null;
  contactType?: string | null;
  reason?: string | null;
  goToSchoolAt?: string | null;
  leaveSchoolAt?: string | null;
  diseaseName?: string | null;
  symptom?: string | null;
  bodyTemperature?: string | null;
  doctorsInstruction?: string | null;
  relationship?: string | null;
  transportation?: string | null;
  detailComment?: string | null;
}

const AbsencesDetailPanelContentStyled = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing.xxl};

    ${theme.media.sp} {
      padding: ${theme.spacing.xl} ${theme.spacing.l};
    }
  `,
);

const AbsencesDetailPanelContent: React.FC<OwnProps> = (props) => (
  <AbsencesDetailPanelContentStyled>
    <AbsencesDetailContents {...props} />
  </AbsencesDetailPanelContentStyled>
);

export default AbsencesDetailPanelContent;
