import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Image from 'assets/errors/500.png';
import Button from 'components/modules/button/Button';
import Browser from 'utils/Browser';

import CSSModule from './InternalServerError.module.scss';

interface Props {
  isLoggedIn: boolean;
  signOut?: () => void;
}

const InternalServerError: React.FC<Props> = ({ isLoggedIn, signOut }) => {
  const intl = useIntl();

  return (
    <div className={CSSModule.InternalServerError}>
      <div className={CSSModule.InternalServerError__Container}>
        <div className={CSSModule.InternalServerError__ImageContainer}>
          <img
            src={Image}
            alt={intl.formatMessage({
              id: 'errors.internalServerError.title',
            })}
            className={CSSModule.InternalServerError__Image}
          />
        </div>
        <h1 className={CSSModule.InternalServerError__Title}>
          <FormattedMessage id="errors.internalServerError.title" />
        </h1>
        <h2 className={CSSModule.InternalServerError__SubTitle}>
          <FormattedMessage id="errors.internalServerError.subTitle" />
        </h2>
        <div className={CSSModule.InternalServerError__Description}>
          <FormattedMessage id="errors.internalServerError.description" />
        </div>
        <p className={CSSModule.InternalServerError__ButtonContainer}>
          <Button color="primary" onClick={() => Browser.reload()}>
            <FormattedMessage id="errors.common.refresh" />
          </Button>
          {isLoggedIn && (
            <Button color="outline" onClick={signOut}>
              <FormattedMessage id="errors.common.logout" />
            </Button>
          )}
        </p>
      </div>
    </div>
  );
};

export default InternalServerError;
