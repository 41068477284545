import React from 'react';

import Button from 'components/modules/button/Button';
import ErrorContent from 'components/pages/errors/common/ErrorContent';
import useError from 'hooks/common/useError';
import Browser from 'utils/Browser';

interface Props {
  description?: string;
}

const SomethingWentWrongError: React.FC<Props> = ({ description }) => {
  const { normalLogout } = useError();

  const logout = () => {
    normalLogout();
  };

  return (
    <ErrorContent
      description={
        description ||
        '予期しないエラーが発生しました。ページを再読み込みするか、ログアウトしてください。'
      }
      buttons={[
        <Button key="refresh" color="primary" onClick={() => Browser.reload()}>
          ページを再度読み込む
        </Button>,
        <Button key="logout" color="outline" onClick={logout}>
          ログアウト
        </Button>,
      ]}
    />
  );
};

export default SomethingWentWrongError;
