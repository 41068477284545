import React from 'react';

export const convertText = (body: string) =>
  body
    .replace(/&/gim, '&amp;')
    .replace(/>/gim, '&gt;')
    .replace(/</gim, '&lt;')
    .replace(/(?:\r\n|\r|\n)/gim, '<br />')
    .replace(
      /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim,
      '<a href="$1" target="_blank">$1</a>',
    );

export interface DetailBodyProps {
  body: string;
}

const DetailBody: React.FC<DetailBodyProps> = ({ body = '' }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: convertText(body),
    }}
  />
);

export default DetailBody;
