import React from 'react';
import { FormattedMessage } from 'react-intl';

import CSSModule from './Contact.module.scss';

interface Props {
  className?: string;
}

const Contact: React.FC<Props> = ({ className }) => (
  <div className={className}>
    <p className={CSSModule.Contact__Description}>
      <FormattedMessage id="contact.description" />
    </p>
    <p>
      <a
        href="https://studysapuri.jp/info/inquiry/parent/"
        rel="noopener noreferrer"
        target="_blank"
        className={CSSModule.Contact__FormTitle}
      >
        <FormattedMessage id="contact.formTitle" />
      </a>
    </p>
    <div className={CSSModule.Contact__ContactDetail}>
      <p className={CSSModule.Contact__ContactDetail__Title}>
        <FormattedMessage id="contact.contactDetail.title" />
      </p>
      <p className={CSSModule.Contact__ContactDetail__Name}>
        <FormattedMessage id="contact.contactDetail.name" />
      </p>
      <p className={CSSModule.Contact__ContactDetail__PhoneNumber}>
        <FormattedMessage id="contact.contactDetail.phoneNumber" />
      </p>
      <p className={CSSModule.Contact__ContactDetail__ReceptionTime}>
        <FormattedMessage id="contact.contactDetail.receptionTime" />
        <br />
        <FormattedMessage id="contact.contactDetail.receptionDate" />
      </p>
    </div>
  </div>
);

export default Contact;
