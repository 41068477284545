import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { ReactComponent as RecruitLogo } from 'assets/logo/recruit.svg';
import ConsentTrailParameterFactory from 'utils/ConsentTrailParameterFactory';

import CSSModule from './Footer.module.scss';

interface Props {
  className?: string;
}

const Footer: React.FC<Props> = ({ className }) => {
  const classes = classNames(CSSModule.Footer, className);
  return (
    <footer className={classes}>
      <div className={CSSModule.Footer__Inner}>
        <div className={CSSModule.Footer__Links}>
          <ul className={CSSModule.Footer__LinkList}>
            <li className={CSSModule.Footer__LinkItem}>
              <Link to="/notices" className={CSSModule.Footer__Link}>
                <FormattedMessage id="footer.notice" />
              </Link>
            </li>
            <li className={CSSModule.Footer__LinkItem}>
              <a
                href="https://studysapuri.jp/info/help/"
                rel="noopener noreferrer"
                target="_blank"
                className={CSSModule.Footer__Link}
              >
                <FormattedMessage id="footer.contact" />
              </a>
            </li>
            <li className={CSSModule.Footer__LinkItem}>
              <a
                href={ConsentTrailParameterFactory.termsUrl}
                className={CSSModule.Footer__Link}
                rel="noopener noreferrer"
                target="blank"
              >
                <FormattedMessage id="footer.terms" />
              </a>
            </li>
            <li className={CSSModule.Footer__LinkItem}>
              <a
                href={ConsentTrailParameterFactory.privacyPolicyUrl}
                className={CSSModule.Footer__Link}
                rel="noopener noreferrer"
                target="blank"
              >
                <FormattedMessage id="footer.privacy" />
              </a>
            </li>
          </ul>
        </div>
        <div className={CSSModule.Footer__Copyright}>
          <a
            href="https://www.recruit.co.jp/"
            rel="noopener noreferrer"
            target="_blank"
            className={CSSModule.Footer__LinkToRecruitSite}
          >
            <RecruitLogo className={CSSModule.Footer__RecruitLogo} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
