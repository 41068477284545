import React from 'react';

import Jumbotron from 'components/modules/box/Jumbotron';
import JumbotronPanel from 'components/modules/box/JumbotronPanel';

const AbsencesRestricted: React.FC = () => (
  <Jumbotron>
    <JumbotronPanel
      title="アクセス権限がありません"
      description={
        <>
          契約手続き未完了もしくは学校で機能制限中のため閲覧できません。
          <br />
          急用の場合は直接学校に問い合わせてください。
        </>
      }
    />
  </Jumbotron>
);

export default AbsencesRestricted;
