import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Loading from 'components/atoms/loading/Loading';
import Button from 'components/modules/button/Button';

import CSSModule from './TokenVerificationResult.module.scss';

export interface Props {
  isFetching: boolean;
  errorType: 'invalid' | 'expired';
  linkUrl: string;
}

const TokenVerificationResult: React.FC<Props> = ({
  isFetching,
  errorType,
  linkUrl,
}) => (
  <div className={CSSModule.TokenVerificationResult}>
    {isFetching ? (
      <Loading className={CSSModule.TokenVerificationResult__Loading} />
    ) : (
      <>
        <h3 className={CSSModule.TokenVerificationResult__Title}>
          <FormattedMessage
            id={`registration.tokenVerificationResult.errors.${errorType}.title`}
          />
        </h3>
        <div className={CSSModule.TokenVerificationResult__Description}>
          <FormattedMessage
            id={`registration.tokenVerificationResult.errors.${errorType}.description`}
          />
        </div>
        <Button
          tag={Link}
          to={linkUrl}
          className={CSSModule.TokenVerificationResult__Button}
        >
          <FormattedMessage id="registration.tokenVerificationResult.goBack" />
        </Button>
      </>
    )}
  </div>
);

export default TokenVerificationResult;
