/* eslint-disable @typescript-eslint/no-misused-promises */
import { camelCase, uniq } from 'lodash-es';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { styled, css } from 'styled-components';
import * as Yup from 'yup';

import Panel from 'components/modules/box/Panel';
import Paper from 'components/modules/box/Paper';
import Button from 'components/modules/button/Button';
import ErrorList from 'components/modules/forms/ErrorList';
import Field from 'components/modules/forms/Field';
import FormGroup from 'components/modules/forms/FormGroup';
import Input from 'components/modules/forms/Input';
import Label from 'components/modules/forms/Label';
import PasswordInput from 'components/modules/forms/PasswordInput';
import useReactHookForm from 'hooks/common/useReactHookForm';
import {
  ParentProfileFormValues,
  HIRAGANA_REGEXP,
  NAME_KANA_MAX_LENGTH,
  NAME_MAX_LENGTH,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_REGEXP,
  ZENKAKU_REGEXP,
} from 'interfaces/parent';

import CSSModule from './ParentProfileForm.module.scss';

interface Props {
  email: string;
  formValues: ParentProfileFormValues;
  onClickEditEmailButton: () => void;
  onSubmit: (formValues: ParentProfileFormValues) => void;
  serverError?: string;
}

const FieldStyled = styled(Field)<{ $width?: 'max' | 'normal' }>(
  ({ theme, $width = 'normal' }) => css`
    max-width: ${{ max: '100%', normal: theme.form.input.width.normal }[
      $width
    ]};
  `,
);

const ParentProfileForm: React.FC<Props> = ({
  email,
  formValues,
  onClickEditEmailButton,
  onSubmit,
  serverError,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const generateErrorMessage = (
    errorType: 'required' | 'pattern' | 'minLength' | 'maxLength',
    label: keyof ParentProfileFormValues,
    value: string | number = '',
  ) =>
    intl.formatMessage(
      {
        id: `forms.errors.${errorType}`,
      },
      {
        label: intl.formatMessage({
          id: `registration.profileRegistration.labels.${camelCase(label)}`,
        }),
        value,
      },
    );

  const generateNameFormatErrorMessage = (
    errorType: 'hiraganaFormat' | 'zenkakuFormat',
    label: keyof ParentProfileFormValues,
  ) =>
    intl.formatMessage(
      {
        id: `registration.profileRegistration.errors.${errorType}`,
      },
      {
        label: intl.formatMessage({
          id: `registration.profileRegistration.labels.${camelCase(label)}`,
        }),
      },
    );

  const onCancel = () => navigate('/mypage/profile');

  const validationSchema = Yup.object().shape({
    last_name: Yup.string()
      .trim()
      .required(generateErrorMessage('required', 'last_name'))
      .matches(ZENKAKU_REGEXP, {
        message: generateNameFormatErrorMessage('zenkakuFormat', 'last_name'),
        excludeEmptyString: true,
      })
      .max(
        NAME_MAX_LENGTH,
        generateErrorMessage('maxLength', 'last_name', NAME_MAX_LENGTH),
      ),
    first_name: Yup.string()
      .trim()
      .required(generateErrorMessage('required', 'first_name'))
      .matches(ZENKAKU_REGEXP, {
        message: generateNameFormatErrorMessage('zenkakuFormat', 'first_name'),
        excludeEmptyString: true,
      })
      .max(
        NAME_MAX_LENGTH,
        generateErrorMessage('maxLength', 'first_name', NAME_MAX_LENGTH),
      ),
    last_name_kana: Yup.string()
      .required(generateErrorMessage('required', 'last_name_kana'))
      .matches(HIRAGANA_REGEXP, {
        message: generateNameFormatErrorMessage(
          'hiraganaFormat',
          'last_name_kana',
        ),
        excludeEmptyString: true,
      })
      .max(
        NAME_KANA_MAX_LENGTH,
        generateErrorMessage(
          'maxLength',
          'last_name_kana',
          NAME_KANA_MAX_LENGTH,
        ),
      ),
    first_name_kana: Yup.string()
      .required(generateErrorMessage('required', 'first_name_kana'))
      .matches(HIRAGANA_REGEXP, {
        message: generateNameFormatErrorMessage(
          'hiraganaFormat',
          'first_name_kana',
        ),
        excludeEmptyString: true,
      })
      .max(
        NAME_KANA_MAX_LENGTH,
        generateErrorMessage(
          'maxLength',
          'first_name_kana',
          NAME_KANA_MAX_LENGTH,
        ),
      ),
    password: Yup.string()
      .test(
        'min',
        generateErrorMessage('minLength', 'password', PASSWORD_MIN_LENGTH),
        (value) =>
          value
            ? value.length <= 0 || value.length >= PASSWORD_MIN_LENGTH
            : true,
      )
      .max(
        PASSWORD_MAX_LENGTH,
        generateErrorMessage('maxLength', 'password', PASSWORD_MAX_LENGTH),
      )
      .matches(PASSWORD_REGEXP, {
        message: intl.formatMessage({
          id: 'registration.profileRegistration.errors.invalidPassword',
        }),
        excludeEmptyString: true,
      })
      .notOneOf(
        [email],
        intl.formatMessage({
          id: 'registration.profileRegistration.errors.passwordMatchesEmail',
        }),
      ),
    password_confirmation: Yup.string()
      .test(
        'min',
        generateErrorMessage(
          'minLength',
          'password_confirmation',
          PASSWORD_MIN_LENGTH,
        ),
        (value) =>
          value
            ? value.length <= 0 || value.length >= PASSWORD_MIN_LENGTH
            : true,
      )
      .max(
        PASSWORD_MAX_LENGTH,
        generateErrorMessage(
          'maxLength',
          'password_confirmation',
          PASSWORD_MAX_LENGTH,
        ),
      )
      .matches(PASSWORD_REGEXP, {
        message: intl.formatMessage({
          id: 'registration.profileRegistration.errors.invalidPassword',
        }),
        excludeEmptyString: true,
      })
      .oneOf(
        [Yup.ref('password')],
        intl.formatMessage({
          id: 'registration.profileRegistration.errors.invalidPasswordConfirmation',
        }),
      ),
  });

  const {
    registerWithInnerRef,
    handleSubmit,
    formState: { isSubmitting, isValid, touchedFields, errorMessages },
    trigger,
  } = useReactHookForm<ParentProfileFormValues>({
    validationSchema,
    defaultValues: formValues,
  });

  const inputDisabled = isSubmitting;
  // 入力なしでもenabledになりうるのでisDirtyはチェックしない
  const buttonDisabled = !isValid || isSubmitting;

  const passwordErrors = uniq([
    ...(errorMessages.password || []),
    ...(errorMessages.password_confirmation || []),
  ]);

  const nameErrors = [
    ...(errorMessages.last_name || []),
    ...(errorMessages.first_name || []),
  ];

  const nameKanaErrors = [
    ...(errorMessages.last_name_kana || []),
    ...(errorMessages.first_name_kana || []),
  ];

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={CSSModule.ParentProfileForm}
    >
      <Paper>
        <Panel title={intl.formatMessage({ id: 'mypage.profilePage.title' })}>
          <FormGroup>
            <Label>
              {intl.formatMessage({
                id: 'mypage.profilePage.labels.name',
              })}
            </Label>
            <FieldStyled>
              <div className={CSSModule.ParentProfileForm__HorizontalInput}>
                <div
                  className={CSSModule.ParentProfileForm__HorizontalInputItem}
                >
                  <Input
                    {...registerWithInnerRef('last_name')}
                    disabled={inputDisabled}
                    placeholder={intl.formatMessage({
                      id: 'registration.profileRegistration.placeholders.lastName',
                    })}
                  />
                </div>
                <div
                  className={CSSModule.ParentProfileForm__HorizontalInputItem}
                >
                  <Input
                    {...registerWithInnerRef('first_name')}
                    disabled={inputDisabled}
                    placeholder={intl.formatMessage({
                      id: 'registration.profileRegistration.placeholders.firstName',
                    })}
                  />
                </div>
              </div>
              <ErrorList errors={nameErrors} />
            </FieldStyled>
          </FormGroup>
          <FormGroup>
            <Label>
              {intl.formatMessage({
                id: 'mypage.profilePage.labels.nameKana',
              })}
            </Label>
            <FieldStyled>
              <div className={CSSModule.ParentProfileForm__HorizontalInput}>
                <div
                  className={CSSModule.ParentProfileForm__HorizontalInputItem}
                >
                  <Input
                    {...registerWithInnerRef('last_name_kana')}
                    disabled={inputDisabled}
                    placeholder={intl.formatMessage({
                      id: 'registration.profileRegistration.placeholders.lastNameKana',
                    })}
                  />
                </div>
                <div
                  className={CSSModule.ParentProfileForm__HorizontalInputItem}
                >
                  <Input
                    {...registerWithInnerRef('first_name_kana')}
                    disabled={inputDisabled}
                    placeholder={intl.formatMessage({
                      id: 'registration.profileRegistration.placeholders.firstNameKana',
                    })}
                  />
                </div>
              </div>
              <ErrorList errors={nameKanaErrors} />
            </FieldStyled>
          </FormGroup>
          <FormGroup>
            <Label>
              {intl.formatMessage({
                id: 'mypage.profilePage.labels.email',
              })}
            </Label>
            <FieldStyled>
              <Input isStatic value={email} disabled />

              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onClickEditEmailButton();
                }}
                className={CSSModule.ParentProfileForm__EditEmail}
              >
                <FormattedMessage id="mypage.profileEditPage.editEmail" />
              </a>
            </FieldStyled>
          </FormGroup>
          <FormGroup>
            <Label>
              {intl.formatMessage({
                id: 'mypage.profileEditPage.labels.newPassword',
              })}
            </Label>
            <FieldStyled $width="max">
              <div className={CSSModule.ParentProfileForm__PasswordInput}>
                <PasswordInput
                  {...registerWithInnerRef('password', {
                    onChange: () => {
                      touchedFields.password_confirmation &&
                        void trigger('password_confirmation');
                    },
                  })}
                  disabled={inputDisabled}
                  autoComplete="new-password"
                  placeholder={intl.formatMessage({
                    id: 'mypage.profileEditPage.placeholders.newPassword',
                  })}
                />
              </div>
              <p className={CSSModule.ParentProfileForm__Note}>
                <FormattedMessage
                  id="mypage.profileEditPage.noteForPasswordValidation"
                  values={{ length: PASSWORD_MIN_LENGTH }}
                />
                <br />
                <FormattedMessage id="mypage.profileEditPage.noteForPasswordFormat" />
              </p>
              <div className={CSSModule.ParentProfileForm__PasswordInput}>
                <PasswordInput
                  {...registerWithInnerRef('password_confirmation')}
                  disabled={inputDisabled}
                  autoComplete="new-password"
                  placeholder={intl.formatMessage({
                    id: 'mypage.profileEditPage.placeholders.newPasswordConfirmation',
                  })}
                />

                {passwordErrors && passwordErrors.length > 0 && (
                  <div className={CSSModule.ParentProfileForm__FormGroup}>
                    <ErrorList errors={passwordErrors} />
                  </div>
                )}
              </div>
            </FieldStyled>
          </FormGroup>
          {serverError && (
            <div className={CSSModule.ParentProfileForm__FormGroup}>
              <ErrorList errors={serverError.split(/(?:\r\n|\r|\n)/gim)} />
            </div>
          )}
        </Panel>
      </Paper>
      <div className={CSSModule.ParentProfileForm__ButtonArea}>
        <Button
          color="primary"
          disabled={buttonDisabled}
          type="submit"
          className={CSSModule.ParentProfileForm__Button}
        >
          <FormattedMessage id="forms.button.save" />
        </Button>
        <Button
          color="link"
          className={CSSModule.ParentProfileForm__Button}
          type="button"
          onClick={onCancel}
        >
          <FormattedMessage id="forms.button.cancel" />
        </Button>
      </div>
    </form>
  );
};

export default ParentProfileForm;
