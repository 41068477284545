import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';

import AbsencesCreatePage from 'components/pages/absences/AbsencesCreatePage';
import AbsencesDetailPage from 'components/pages/absences/AbsencesDetailPage';
import AbsencesEditPage from 'components/pages/absences/AbsencesEditPage';
import AbsencesIndexPage from 'components/pages/absences/AbsencesIndexPage';
import ErrorPage from 'components/pages/errors/common/ErrorPage';
import { pageTitles } from 'constants/pageTitles';

const AbsencesPage: React.FC = () => (
  <>
    <Helmet>
      <title>{pageTitles.absences}</title>
    </Helmet>
    <Routes>
      <Route path="" element={<AbsencesIndexPage />} />
      <Route path="member" element={<AbsencesIndexPage />} />
      <Route
        path="member/:organizationMembershipId"
        element={<AbsencesIndexPage />}
      />
      <Route
        path="member/:organizationMembershipId/create"
        element={<AbsencesCreatePage />}
      />
      <Route
        path=":absenceId/type/:contactType/edit"
        element={<AbsencesEditPage />}
      />
      <Route
        path=":absenceId/type/:contactType"
        element={<AbsencesDetailPage />}
      />
      <Route path="*" element={<ErrorPage type="not_found" />} />
    </Routes>
  </>
);

export default AbsencesPage;
