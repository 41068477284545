import React from 'react';

import SingleColumnTemplate from 'components/templates/SingleColumnTemplate';

import CSSModule from './TwoColumnTemplate.module.scss';

interface Props {
  sidebar: JSX.Element;
  pageTitle: JSX.Element;
  children?: React.ReactNode;
}

const TwoColumnTemplate: React.FC<Props> = ({
  sidebar,
  pageTitle,
  children,
}) => (
  <SingleColumnTemplate>
    <div className={CSSModule.TwoColumnTemplate}>
      <div className={CSSModule.TwoColumnTemplate__PageTitle}>{pageTitle}</div>
      <div className={CSSModule.TwoColumnTemplate__Body}>
        <div className={CSSModule.TwoColumnTemplate__Sidebar}>{sidebar}</div>
        <div className={CSSModule.TwoColumnTemplate__Content}>{children}</div>
      </div>
    </div>
  </SingleColumnTemplate>
);

export default TwoColumnTemplate;
