import * as ReactQuery from '@tanstack/react-query';
import { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';

import useError, { buildErrorResponse } from 'hooks/common/useError';
import { accessTokenStorage } from 'utils/AccessTokenStorage';

import { useDarklaunchV2VariationWithoutIdentifier } from '../useDarklaunchV2';

import useAuthentication from './useAuthentication';

type HttpMethod =
  | 'GET'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'PATCH'
  | 'CONNECT'
  | 'HEAD'
  | 'OPTIONS'
  | 'TRACE';

interface Request {
  url: string;
  method?: HttpMethod;
  headers?: HeadersInit;
  body?: BodyInit;
}

type ErrorJsonResponse = NormalErrorJsonResponse | OldErrorJsonResponse;

interface NormalErrorJsonResponse {
  error_code: string | null;
  message: string | null;
}

interface OldErrorJsonResponse {
  error: {
    message: string | null;
  };
}

interface ErrorResponse extends Error {
  status: number;
  error_code: string;
  json: NormalErrorJsonResponse;
}

const isOldErrorJsonResponse = (
  json: ErrorJsonResponse,
): json is OldErrorJsonResponse =>
  Boolean(
    !(json as NormalErrorJsonResponse).error_code &&
      (json as OldErrorJsonResponse)?.error?.message,
  );

const useReactQuery = () => {
  const { refreshAccessToken } = useAuthentication();
  const { unauthLogout } = useError();
  const { variation: isEnableSchoolCommParentsAuthV2 } =
    useDarklaunchV2VariationWithoutIdentifier(
      'enable-school-comm-parents-auth-v2',
    );

  const buildRequestHeaders = (): Record<string, string> => {
    const accessToken = accessTokenStorage.get();
    if (!accessToken) {
      return {};
    }

    return {
      'X-Token-Type': 'access_token',
      Authorization: `Bearer ${accessToken}`,
    };
  };

  const fetcher = async <T = unknown>({
    url,
    method,
    headers: optionalHeaders,
    body,
  }: Request): Promise<T> => {
    const headers: HeadersInit = {
      'Content-Type': 'application/json;charset=UTF-8',
      ...(buildRequestHeaders() as HeadersInit),
      ...optionalHeaders,
    };

    const res = await fetch(url, {
      method,
      headers,
      body,
    }).catch(() => {
      throw buildErrorResponse({ status: 500 });
    });

    if (!res.ok) {
      if (res.status >= 500 && res.status <= 599) {
        throw buildErrorResponse({
          status: res.status,
          error_code: 'network_error',
          message:
            '申し訳ありませんがサーバーへの接続中にエラーが発生しました。時間をおいて再度お試しください。',
        });
      }

      if (res.status === 401) {
        if (isEnableSchoolCommParentsAuthV2) {
          try {
            await refreshAccessToken();
            return fetcher({ url, method, headers: optionalHeaders, body });
          } catch (e) {
            if ((e as ErrorResponse).status) {
              if ((e as ErrorResponse).status === 401) {
                unauthLogout();
                throw buildErrorResponse({
                  status: 401,
                  error_code: 'unauthorized',
                  message: '認証に失敗しました。再度ログインをしてください。',
                });
              } else {
                throw e;
              }
            } else {
              throw buildErrorResponse({
                status: 500,
              });
            }
          }
        } else {
          unauthLogout();
          throw buildErrorResponse({
            status: 401,
            error_code: 'unauthorized',
            message: '認証に失敗しました。再度ログインをしてください。',
          });
        }
      }

      let err;
      try {
        const json = (await res.json()) as ErrorJsonResponse;
        if (isOldErrorJsonResponse(json)) {
          err = buildErrorResponse({
            status: res.status,
            error_code: 'error',
            message: json.error.message || 'エラーが発生しました。',
          });
        } else {
          err = buildErrorResponse({
            status: res.status,
            error_code: json.error_code || 'error',
            message: json.message || 'エラーが発生しました。',
          });
        }
      } catch {
        err = buildErrorResponse({
          status: res.status,
          error_code: 'error',
          message: 'エラーが発生しました。',
        });
      }

      throw err;
    }

    if (res.status === 204) {
      return new Promise((resolve) => resolve({} as Promise<T>));
    }

    return res.json() as Promise<T>;
  };

  const useQuery = <D = unknown>({
    queryKey,
    method = 'GET',
    headers,
    body,
    url,
    ...options
  }: Omit<UseQueryOptions<D, ErrorResponse>, 'queryFn'> & Request) =>
    ReactQuery.useQuery<D, ErrorResponse>({
      queryKey,
      queryFn: () => fetcher<D>({ url, method, headers, body }),
      ...options,
    });

  const useMutation = <T>({
    method = 'POST',
    headers,
    url,
    ...options
  }: Omit<
    UseMutationOptions<T, ErrorResponse, BodyInit | undefined>,
    'mutationFn'
  > &
    Omit<Request, 'body'>) =>
    ReactQuery.useMutation<T, ErrorResponse, BodyInit | undefined>({
      mutationFn: (body) => fetcher<T>({ url, method, headers, body }),
      ...options,
    });

  // ToDo (You can add those hooks for react query, if you wanted) like...
  // const useQueries;
  // const useInfiniteQuery;

  return {
    useQuery,
    useMutation,
    useQueryClient: ReactQuery.useQueryClient,
    useIsFetching: ReactQuery.useIsFetching,
    useIsMutating: ReactQuery.useIsMutating,
  };
};

export default useReactQuery;
