import React from 'react';
import { styled, css } from 'styled-components';

import Button from 'components/modules/button/Button';
import ErrorList from 'components/modules/forms/ErrorList';
import AbsencesFooter from 'components/pages/absences/parts/AbsencesFooter';
import AbsencesFooterContent from 'components/pages/absences/parts/AbsencesFooterContent';

interface Props {
  onSubmit: () => void;
  onBack: () => void;
  buttonDisabled?: boolean;
  errors?: string[] | null;
}

const ErrorListStyled = styled(ErrorList)(
  ({ theme }) => css`
    ${theme.media.pc} {
      margin-bottom: ${theme.spacing.xxxl};
    }

    ${theme.media.sp} {
      margin-bottom: ${theme.spacing.xl};
    }
  `,
);

const ButtonStyled = styled(Button)`
  display: block;
  margin: 0 auto;
`;

const ButtonsStyled = styled.div(
  ({ theme }) => css`
    ${ButtonStyled}:not(:last-of-type) {
      ${theme.media.pc} {
        margin-bottom: ${theme.spacing.m};
      }

      ${theme.media.sp} {
        margin-bottom: ${theme.spacing.l};
      }
    }
  `,
);

const AbsencesConfirmFooter: React.FC<Props> = ({
  onSubmit,
  onBack,
  buttonDisabled = false,
  errors = [],
}) => (
  <AbsencesFooter>
    <AbsencesFooterContent
      note="この連絡は学校内のすべての教員が閲覧できます。"
      description="※生徒の出欠日数は、この連絡では確定しません。出欠日数のカウントは実際の在校状況が優先されるのでご了承ください。"
    />
    {errors && <ErrorListStyled errors={errors} />}
    <ButtonsStyled>
      <ButtonStyled
        color="primary"
        size="l"
        onClick={onSubmit}
        disabled={buttonDisabled}
      >
        送信
      </ButtonStyled>
      <ButtonStyled color="link" size="l" onClick={onBack}>
        &lt; 戻る
      </ButtonStyled>
    </ButtonsStyled>
  </AbsencesFooter>
);

export default AbsencesConfirmFooter;
