import React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled, css } from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface InformationBarProps {
  title: string;
  onClick?: () => void;
  onClose?: () => void;
}

const WrapperStyled = styled.div(
  ({ theme }) => css`
    display: flex;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    padding-right: env(safe-area-inset-right);
    padding-left: env(safe-area-inset-left);
    background-color: ${theme.globalColor.purple800};

    ${theme.media.pc} {
      height: 56px;
    }

    ${theme.media.sp} {
      height: 72px;
    }
  `,
);

const InnerStyled = styled.div(
  ({ theme }) => css`
    ${theme.media.pc} {
      display: flex;
      align-items: center;
    }

    ${theme.media.sp} {
      text-align: center;
    }
  `,
);

const LinkStyled = styled.a(
  ({ theme }) => css`
    color: ${theme.globalColor.white};
    font-size: ${theme.fontSize.default};
    font-weight: ${theme.fontWeight.bold};
    cursor: pointer;

    &:hover {
      color: ${theme.globalColor.white};
      text-decoration: underline;
    }
  `,
);

const ButtonStyled = styled.button(
  ({ theme }) => css`
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 24px;
    padding: 0;
    border: 1px solid ${theme.globalColor.white};
    border-radius: ${theme.borderRadius.default};
    background: transparent;
    color: ${theme.globalColor.white};
    font-size: ${theme.fontSize.s};
    font-weight: ${theme.fontWeight.bold};
    cursor: pointer;
    appearance: none;

    ${theme.media.pc} {
      margin-left: ${theme.spacing.m};
    }

    ${theme.media.sp} {
      margin-top: ${theme.spacing.m};
    }
  `,
);

const CloseButtonStyled = styled.button(
  ({ theme }) => css`
    display: inline-flex;
    position: absolute;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: ${theme.icon.size.s};
    height: ${theme.icon.size.s};
    padding: 0;
    border: none;
    background: transparent;
    line-height: 1;
    appearance: none;

    span,
    svg {
      display: block;
    }

    ${theme.media.pc} {
      right: ${theme.spacing.l};
    }

    ${theme.media.sp} {
      top: ${theme.spacing.m};
      right: ${theme.spacing.m};
    }
  `,
);

const InformationBar: React.FC<InformationBarProps> = ({
  title,
  onClick,
  onClose,
}) => (
  <WrapperStyled>
    <InnerStyled>
      <LinkStyled onClick={onClick}>{title}</LinkStyled>
      <ButtonStyled onClick={onClick}>
        <FormattedMessage id="informationBar.button" />
      </ButtonStyled>
    </InnerStyled>
    <CloseButtonStyled onClick={onClose}>
      <span aria-hidden>
        <CloseIcon />
      </span>
    </CloseButtonStyled>
  </WrapperStyled>
);

export default InformationBar;
