import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

import Button from 'components/modules/button/Button';

export interface LocationState {
  from?: 'list';
}

const ButtonStyled = styled(Button)`
  display: block;
  margin: 0 auto;
`;

const WrapperStyled = styled.div(({ theme }) => ({
  [theme.media.pc]: {
    margin: `${theme.spacing.xxxl} 0`,
  },
  [theme.media.sp]: {
    margin: `${theme.spacing.xl} ${theme.spacing.l}`,
  },
}));

const InnerStyled = styled.div({
  textAlign: 'center',
});

const AnnouncementDetailFooter: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 639px)' });
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;

  const handleOnclickBack = (e: React.MouseEvent) => {
    e.preventDefault();

    state?.from === 'list' ? navigate(-1) : navigate('/announcements');
  };

  return (
    <WrapperStyled>
      <InnerStyled>
        <ButtonStyled
          color="link"
          size={isMobile ? 'm' : 'l'}
          onClick={handleOnclickBack}
        >
          &lt; 戻る
        </ButtonStyled>
      </InnerStyled>
    </WrapperStyled>
  );
};

export default AnnouncementDetailFooter;
