import classNames from 'classnames';
import React from 'react';
import { Link, To } from 'react-router-dom';

import CSSModule from './BackToPageButton.module.scss';

interface Props {
  to?: To;
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

const BackToPageButton: React.FC<Props> = ({
  to,
  className,
  onClick,
  children,
}) => {
  const classes = classNames(CSSModule.BackToPageButton, className);
  const onClickAnchor = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (onClick) {
      onClick();
    }
  };

  return to ? (
    <Link className={classes} to={to}>
      {children}
    </Link>
  ) : (
    <a href="" className={classes} onClick={onClickAnchor}>
      {children}
    </a>
  );
};

export default BackToPageButton;
