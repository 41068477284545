import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as SapuriLogo } from 'assets/logo/for-parents.svg';
import MainHeaderNav from 'components/organisms/mainHeader/MainHeaderNav';
import useHomePath from 'hooks/common/useHomePath';

import CSSModule from './Header.module.scss';

interface Props {
  hideSubmenu?: boolean;
  showNumberBadge?: boolean;
}

const Header: React.FC<Props> = ({ hideSubmenu, showNumberBadge = true }) => {
  const homePath = useHomePath();

  return (
    <header className={CSSModule.Header}>
      <div className={CSSModule.Header__Inner}>
        <Link to={homePath} className={CSSModule.Header__Logo}>
          <SapuriLogo className={CSSModule.Header__Logo__Svg} />
        </Link>
        <MainHeaderNav
          isHidden={hideSubmenu}
          showNumberBadge={showNumberBadge}
        />
      </div>
    </header>
  );
};

export default Header;
