import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { styled, css } from 'styled-components';

type Size = 's' | 'm' | 'l';
type Color = 'primary' | 'danger' | 'outline' | 'link';
type Tag = 'button' | typeof Link;

interface ButtonProps {
  size?: Size;
  color?: Color;
  children?: React.ReactNode;
  tag?: Tag;
}

type Props = ButtonProps &
  (
    | (React.ButtonHTMLAttributes<HTMLButtonElement> & { to?: never })
    | (LinkProps & { type?: never })
  );

interface StyledProps {
  $size: Size;
  $color: Color;
}

export const ButtonStyled = styled.button<StyledProps>(
  ({ theme, $size, $color }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: ${theme.button.width.s};
    max-width: ${theme.button.width.l};
    height: ${theme.button.height[$size]};
    padding: 0px 16px;
    border: ${theme.button.border[$color]};
    border-radius: ${theme.borderRadius.default};
    background-color: ${theme.button.backgroundColor[$color]};
    box-shadow: ${theme.button.boxShadow[$color]};
    color: ${theme.button.color[$color]};
    font-size: ${theme.fontSize.default};
    font-style: normal;
    font-weight: 600;
    line-height: ${theme.lineHeight.default};
    text-align: center;
    cursor: pointer;
    appearance: none;

    ${theme.media.sp} {
      height: ${theme.button.heightSp[$size]};
    }

    &:hover,
    &:focus,
    &:active {
      background-color: ${theme.button.backgroundColor.active[$color]};
      box-shadow: ${$color === 'outline'
        ? '0px 8px 9px rgba(0, 0, 0, 0.04)'
        : theme.button.boxShadow[$color]};
      color: ${theme.button.color[$color]};
      text-decoration: none;
    }

    &:disabled {
      border: ${theme.button.border.disabled};
      background-color: ${theme.button.backgroundColor.disabled};
      box-shadow: none;
      color: ${theme.button.color.disabled};
      cursor: default;
    }
  `,
);

const Button: React.FC<Props> = ({
  size = 'm',
  color = 'primary',
  children,
  type = 'button',
  tag = 'button',
  ...props
}) => (
  <ButtonStyled as={tag} $size={size} $color={color} type={type} {...props}>
    {children}
  </ButtonStyled>
);
export default Button;
