import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ErrorPage from 'components/pages/errors/common/ErrorPage';
import OrganizationMembershipErrorPage from 'components/pages/mypage/OrganizationMembershipErrorPage';
import OrganizationMembershipPage from 'components/pages/mypage/OrganizationMembershipPage';

const OrganizationMembership: React.FC = () => (
  <Routes>
    <Route path="new" element={<OrganizationMembershipPage />} />
    <Route path="new/error" element={<OrganizationMembershipErrorPage />} />
    <Route path="*" element={<ErrorPage type="not_found" />} />
  </Routes>
);

export default OrganizationMembership;
