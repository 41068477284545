import React, { useCallback, useContext } from 'react';

import { DropdownContext } from 'components/modules/dropdown/Dropdown';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const DropdownToggle: React.FC<Props> = ({ className, children }) => {
  const { toggle } = useContext(DropdownContext);

  const handleClick = useCallback(
    (ev: React.UIEvent) => {
      ev.preventDefault();

      toggle();
    },
    [toggle],
  );

  return (
    <div className={className} onClick={handleClick}>
      {children}
    </div>
  );
};

export default DropdownToggle;
