import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ImportantIcon from 'assets/icons/important-information.png';
import SapuriIcon from 'assets/icons/studysapuri-information.png';
import {
  DetailPanelHeader,
  DetailPanelBaseHeaderProps,
} from 'components/modules/detailpage/DetailPanelHeader';
import { Kind } from 'interfaces/notice';

import CSSModule from './DetailPanelNoticeHeader.module.scss';

export interface DetailPanelNoticeHeaderProps
  extends DetailPanelBaseHeaderProps {
  kind: (typeof Kind)[keyof typeof Kind];
}

const DetailPanelNoticeHeader: React.FC<DetailPanelNoticeHeaderProps> = ({
  kind,
  sendAt,
}) => {
  const intl = useIntl();
  const avatar =
    kind === Kind.IMPORTANT ? (
      <img src={ImportantIcon} alt={ImportantIcon} />
    ) : (
      <img src={SapuriIcon} alt={SapuriIcon} />
    );

  return (
    <DetailPanelHeader
      avatar={avatar}
      contents={
        <>
          <div className={CSSModule.DetailPanel__sender}>
            <span>
              {intl.formatMessage({
                id: `notice.kind.${kind}`,
              })}
            </span>
          </div>
          <div>
            <FormattedMessage
              id="detailPanel.sendAt"
              values={{
                sendAt: dayjs.unix(sendAt).format(
                  intl.formatMessage({
                    id: 'detailPanel.sendAtFormat',
                  }),
                ),
              }}
            />
          </div>
        </>
      }
    />
  );
};

export default DetailPanelNoticeHeader;
