import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from 'App';
import * as serviceWorker from 'serviceWorker';
import { datadogRumInit } from 'utils/Datadog';
import Sentry from 'utils/Sentry';

import 'normalize.css';
import 'styles/global.scss';
import './bootstrap.scss';

datadogRumInit();
Sentry.setup();

const container = document.getElementById('root');
if (!container) throw new Error("Fail: Couldn't find root element");
const root = createRoot(container);

const { NODE_ENV } = process.env;
root.render(
  NODE_ENV === 'development' ? (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ) : (
    <App />
  ),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
