/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Darklaunch V2 API
 * すべて一人のユーザに対するkey(s)の値をbooleanのJSON Objectなどで取得する。 複数のユーザに対してまとめてクエリする方法はない。  なお、ここでいうユーザとは与えられたidentifierの文字列の完全一致のみで判断するものである。 identifierには名前空間があり、現在 User / Organization / Aya::Pg::Account / Parentの4種類くらいをサポ〜トする。 ※ 2023-11-14現在、Parentは未対応 AccessToken や IP や session id(?) あたりもユースケースが多いなら追加するけどいまのところそんなになさそうなので少なくともいまはない。ユースケースに依存 
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  VariationGet200Response,
  VariationWithoutIdentifierGet200Response,
  VariationsByKeysGet200ResponseInner,
} from '../models';
import {
    VariationGet200ResponseFromJSON,
    VariationGet200ResponseToJSON,
    VariationWithoutIdentifierGet200ResponseFromJSON,
    VariationWithoutIdentifierGet200ResponseToJSON,
    VariationsByKeysGet200ResponseInnerFromJSON,
    VariationsByKeysGet200ResponseInnerToJSON,
} from '../models';

export interface VariationGetRequest {
    key: string;
    identifierNamespace: VariationGetIdentifierNamespaceEnum;
    identifier: string;
}

export interface VariationWithoutIdentifierGetRequest {
    key: string;
}

export interface VariationsByKeysGetRequest {
    keys: Array<string>;
    identifierNamespace: VariationsByKeysGetIdentifierNamespaceEnum;
    identifier: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * 一人のユーザの1つのkeyの情報をbooleanで取得。もっとも頻繁に呼ばれる想定。 keyが存在しないときはfalseを返す。  identifierはちょうど1つだけ指定できる。Darklaunch v1であったような、User.idとOrganization.idの組み合わせ、などといった指定方法はできない。 
     */
    async variationGetRaw(requestParameters: VariationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VariationGet200Response>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling variationGet.');
        }

        if (requestParameters.identifierNamespace === null || requestParameters.identifierNamespace === undefined) {
            throw new runtime.RequiredError('identifierNamespace','Required parameter requestParameters.identifierNamespace was null or undefined when calling variationGet.');
        }

        if (requestParameters.identifier === null || requestParameters.identifier === undefined) {
            throw new runtime.RequiredError('identifier','Required parameter requestParameters.identifier was null or undefined when calling variationGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        if (requestParameters.identifierNamespace !== undefined) {
            queryParameters['identifier_namespace'] = requestParameters.identifierNamespace;
        }

        if (requestParameters.identifier !== undefined) {
            queryParameters['identifier'] = requestParameters.identifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/variation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VariationGet200ResponseFromJSON(jsonValue));
    }

    /**
     * 一人のユーザの1つのkeyの情報をbooleanで取得。もっとも頻繁に呼ばれる想定。 keyが存在しないときはfalseを返す。  identifierはちょうど1つだけ指定できる。Darklaunch v1であったような、User.idとOrganization.idの組み合わせ、などといった指定方法はできない。 
     */
    async variationGet(requestParameters: VariationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VariationGet200Response> {
        const response = await this.variationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザにかかわらず、1つのkeyの情報をbooleanで取得。全ユーザを同一視してよい特殊なケースで使う。 keyが存在しないときはfalseを返す。 
     */
    async variationWithoutIdentifierGetRaw(requestParameters: VariationWithoutIdentifierGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VariationWithoutIdentifierGet200Response>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling variationWithoutIdentifierGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/variation-without-identifier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VariationWithoutIdentifierGet200ResponseFromJSON(jsonValue));
    }

    /**
     * ユーザにかかわらず、1つのkeyの情報をbooleanで取得。全ユーザを同一視してよい特殊なケースで使う。 keyが存在しないときはfalseを返す。 
     */
    async variationWithoutIdentifierGet(requestParameters: VariationWithoutIdentifierGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VariationWithoutIdentifierGet200Response> {
        const response = await this.variationWithoutIdentifierGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 一人のユーザの複数のkeyの情報をまとめてbooleanで取得。 keyが存在しないときはfalseを返す。 
     */
    async variationsByKeysGetRaw(requestParameters: VariationsByKeysGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VariationsByKeysGet200ResponseInner>>> {
        if (requestParameters.keys === null || requestParameters.keys === undefined) {
            throw new runtime.RequiredError('keys','Required parameter requestParameters.keys was null or undefined when calling variationsByKeysGet.');
        }

        if (requestParameters.identifierNamespace === null || requestParameters.identifierNamespace === undefined) {
            throw new runtime.RequiredError('identifierNamespace','Required parameter requestParameters.identifierNamespace was null or undefined when calling variationsByKeysGet.');
        }

        if (requestParameters.identifier === null || requestParameters.identifier === undefined) {
            throw new runtime.RequiredError('identifier','Required parameter requestParameters.identifier was null or undefined when calling variationsByKeysGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.keys) {
            queryParameters['keys[]'] = requestParameters.keys;
        }

        if (requestParameters.identifierNamespace !== undefined) {
            queryParameters['identifier_namespace'] = requestParameters.identifierNamespace;
        }

        if (requestParameters.identifier !== undefined) {
            queryParameters['identifier'] = requestParameters.identifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/variations-by-keys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VariationsByKeysGet200ResponseInnerFromJSON));
    }

    /**
     * 一人のユーザの複数のkeyの情報をまとめてbooleanで取得。 keyが存在しないときはfalseを返す。 
     */
    async variationsByKeysGet(requestParameters: VariationsByKeysGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VariationsByKeysGet200ResponseInner>> {
        const response = await this.variationsByKeysGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const VariationGetIdentifierNamespaceEnum = {
    UserId: 'User.id',
    OrganizationId: 'Organization.id',
    AyaPgAccountId: 'Aya::Pg::Account.id'
} as const;
export type VariationGetIdentifierNamespaceEnum = typeof VariationGetIdentifierNamespaceEnum[keyof typeof VariationGetIdentifierNamespaceEnum];
/**
 * @export
 */
export const VariationsByKeysGetIdentifierNamespaceEnum = {
    UserId: 'User.id',
    OrganizationId: 'Organization.id',
    AyaPgAccountId: 'Aya::Pg::Account.id'
} as const;
export type VariationsByKeysGetIdentifierNamespaceEnum = typeof VariationsByKeysGetIdentifierNamespaceEnum[keyof typeof VariationsByKeysGetIdentifierNamespaceEnum];
