import { omitBy, isNil, sortBy, reverse } from 'lodash-es';
import React, { useContext, useEffect, useState } from 'react';

import LoadingScreen from 'components/molecules/LoadingScreen';
import useAuthentication from 'hooks/common/useAuthentication';
import useError, {
  buildErrorResponse,
  ErrorResponse,
} from 'hooks/common/useError';
import useReactQuery from 'hooks/common/useReactQuery';
import { useDarklaunchV2VariationWithoutIdentifier } from 'hooks/useDarklaunchV2';
import { Agreements } from 'interfaces/agreement';
import { ParentWithStudents } from 'interfaces/parent';
import { AppContext } from 'providers/ContextProvider';
import CookieStorage from 'utils/CookieStorage';

import InternalServerError from '../errors/InternalServerError';

interface OwnProps {
  children: React.ReactNode;
}

const AutoLogin: React.FC<OwnProps> = ({ children }) => {
  const { isLoggedIn, refreshAccessToken, loginV2 } = useAuthentication();
  const { unauthLogout } = useError();
  const { useQuery } = useReactQuery();
  const { currentUser, setCurrentUser, setAgreements } = useContext(AppContext);

  const {
    variation: isEnableAuthV2,
    isError: isErrorDlAuthV2,
    isLoading: isLoadingDlAuthV2,
    isSuccess: isSuccessDlAuthV2,
  } = useDarklaunchV2VariationWithoutIdentifier(
    'enable-school-comm-parents-auth-v2',
  );

  const [isLoadingAccessToken, setIsLoadingAccessToken] =
    useState<boolean>(true);
  const [isErrorAccessToken, setIsErrorAccessToken] = useState<boolean>(false);
  useEffect(() => {
    if (!isSuccessDlAuthV2) {
      return;
    }

    setIsLoadingAccessToken(true);
    if (isEnableAuthV2) {
      void refreshAccessToken()
        .catch((e) => {
          if ((e as ErrorResponse).status === 401) {
            return;
          }
          setIsErrorAccessToken(true);
        })
        .finally(() => {
          setIsLoadingAccessToken(false);
        });
    } else {
      const cookieAccessToken = CookieStorage.getAccessToken();
      if (cookieAccessToken) {
        // 旧ログイン方式の処理内だが、Cookieの更新をしないためloginV2を利用する
        loginV2(cookieAccessToken);
      }
      setIsLoadingAccessToken(false);
    }
  }, [isEnableAuthV2, isSuccessDlAuthV2, loginV2, refreshAccessToken]);

  const {
    data: meData,
    error: meError,
    isSuccess: isSuccessFetchMe,
  } = useQuery<ParentWithStudents>({
    queryKey: ['me'],
    url: '/api/school/parents/me',
    enabled:
      isSuccessDlAuthV2 && !isLoadingAccessToken && isLoggedIn && !currentUser,
  });
  useEffect(() => {
    if (isSuccessFetchMe && meData) {
      setCurrentUser(meData);
    }
  }, [isSuccessFetchMe, meData, setCurrentUser]);

  const queryParams = new URLSearchParams({
    display_code: 'for_parents',
  });

  const {
    data: agreementsData,
    isFetching: isFetchingAgreements,
    error: agreementsError,
    isSuccess: isSuccessFetchAgreements,
  } = useQuery<Agreements>({
    queryKey: ['agreements'],
    url: `/api/school_communication/agreements?${queryParams.toString()}`,
    enabled: !isLoadingAccessToken && isLoggedIn,
  });
  useEffect(() => {
    if (isSuccessFetchAgreements) {
      setAgreements(agreementsData.agreements || null);
    }
  }, [agreementsData?.agreements, isSuccessFetchAgreements, setAgreements]);

  const isLoading =
    isLoadingDlAuthV2 ||
    isLoadingAccessToken ||
    (!isLoadingAccessToken && isLoggedIn && !currentUser) ||
    isFetchingAgreements;
  const isError = !!meError || !!agreementsError;

  // show error page when API responds error except 401
  useEffect(() => {
    if (isError) {
      const statuses = omitBy(
        {
          meError,
          agreementsError,
        },
        isNil,
      );

      reverse(sortBy(Object.values(statuses), ['status'])).map((value) => {
        if (value?.status !== 401) {
          throw buildErrorResponse(value || {});
        }
      });
    }
  }, [agreementsError, unauthLogout, isError, meError]);

  if (isErrorDlAuthV2 || isErrorAccessToken) {
    return <InternalServerError isLoggedIn={false} />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default AutoLogin;
