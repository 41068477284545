/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Modal from 'components/atoms/modals/Modal';
import Button from 'components/modules/button/Button';
import ErrorList from 'components/modules/forms/ErrorList';
import Field from 'components/modules/forms/Field';
import FormGroup from 'components/modules/forms/FormGroup';
import PasswordInput from 'components/modules/forms/PasswordInput';
import useFetch from 'hooks/common/useFetch';
import { ModalInnerProps } from 'hooks/common/useModal';
import useReactHookForm from 'hooks/common/useReactHookForm';
import useCurrentUser from 'hooks/store/useCurrentUser';
import useToast from 'hooks/store/useToast';
import {
  ParentProfileFormValues,
  PasswordConfirmFormValues,
} from 'interfaces/parent';

import CSSModule from './PasswordConfirmationModal.module.scss';

interface Props extends ModalInnerProps {
  profileValues: ParentProfileFormValues;
  onError: (errorMessage: string) => void;
}

interface ErrorResponse {
  message: string;
  code?: string;
}

const PasswordConfirmationModal: React.FC<Props> = ({
  isOpen,
  toggle,
  profileValues,
  onError,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [, setCurrentUser] = useCurrentUser();
  const [serverError, setServerError] = useState<string>();
  const initialValues = {
    current_password: '',
  };
  const [formValues] = useState(initialValues);
  const { fetch, isFetching, status, error } = useFetch<null, ErrorResponse>(
    'PATCH',
    '/api/school/parents/me',
  );
  const { showToast } = useToast();

  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required(
      intl.formatMessage(
        {
          id: 'forms.errors.required',
        },
        {
          label: intl.formatMessage({
            id: 'mypage.profileEditPage.labels.currentPassword',
          }),
        },
      ),
    ),
  });

  const {
    registerWithInnerRef,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid, errorMessages },
  } = useReactHookForm<PasswordConfirmFormValues>({
    validationSchema,
    defaultValues: formValues,
  });

  const onSubmit = (formValues: PasswordConfirmFormValues) => {
    void fetch({ body: { parent: { ...profileValues, ...formValues } } });
  };

  const formErrors = [
    ...(errorMessages.current_password || []),
    ...(serverError ? [serverError] : []),
  ];

  useEffect(() => {
    if (status === 200) {
      setCurrentUser(
        (currentUser) =>
          currentUser && {
            ...currentUser,
            ...profileValues,
          },
      );
      toggle();
      showToast(intl.formatMessage({ id: 'mypage.profileEditPage.finished' }));
      navigate('/mypage/profile');
    }
  }, [
    status,
    setCurrentUser,
    profileValues,
    toggle,
    showToast,
    intl,
    navigate,
  ]);

  useEffect(() => {
    if (error && error.code === 'incorrect-password') {
      setServerError(error.message);
    }
  }, [error, setServerError]);

  useEffect(() => {
    if (error && !error.code) {
      onError(error.message);
      toggle();
    }
  }, [error, onError, toggle]);

  const inputDisabled = isSubmitting || isFetching;
  const buttonDisabled = !isDirty || !isValid || isSubmitting || isFetching;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title={intl.formatMessage({
        id: 'mypage.profileEditPage.passwordConfirmationModal.title',
      })}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={CSSModule.PasswordConfirmationModal}
      >
        <p>
          <FormattedMessage id="mypage.profileEditPage.passwordConfirmationModal.description" />
        </p>
        <FormGroup>
          <Field>
            <PasswordInput
              {...registerWithInnerRef('current_password')}
              disabled={inputDisabled}
              autoComplete="new-password"
              placeholder={intl.formatMessage({
                id: 'mypage.profileEditPage.passwordConfirmationModal.placeholders.password',
              })}
            />
            <ErrorList errors={formErrors} />
          </Field>
        </FormGroup>

        <div className={CSSModule.PasswordConfirmationModal__Buttons}>
          <Button
            color="outline"
            className={CSSModule.PasswordConfirmationModal__Button}
            onClick={toggle}
          >
            <FormattedMessage id="forms.button.cancel" />
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={buttonDisabled}
            className={CSSModule.PasswordConfirmationModal__Button}
          >
            <FormattedMessage id="forms.button.next" />
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default PasswordConfirmationModal;
