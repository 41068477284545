import React, { useCallback, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';

import CSSModule from './Collapse.module.scss';

interface Props {
  isOpen: boolean;
  children?: React.ReactNode;
}

const Collapse: React.FC<Props> = ({ isOpen, children }) => {
  const [dimension, setDimension] = useState<number | null>(null);
  const nodeRef = useRef<HTMLDivElement>(null);

  const getDimension = useCallback(
    () => (nodeRef.current ? nodeRef.current.scrollHeight : null),
    [],
  );

  const onEntering = useCallback(() => {
    setDimension(getDimension());
  }, [getDimension]);

  const onEntered = useCallback(() => {
    setDimension(null);
  }, []);

  const onExit = useCallback(() => {
    setDimension(getDimension());
  }, [getDimension]);

  const onExiting = useCallback(() => {
    // getting this variable triggers a reflow
    const _unused = getDimension();
    setDimension(0);
  }, [getDimension]);

  const onExited = useCallback(() => {
    setDimension(null);
  }, []);

  return (
    <Transition
      in={isOpen}
      timeout={350}
      onEntering={onEntering}
      onEntered={onEntered}
      onExit={onExit}
      onExiting={onExiting}
      onExited={onExited}
      nodeRef={nodeRef}
    >
      {(status) => {
        const className: string = CSSModule[`Collapse__${status}`];
        const style = dimension === null ? undefined : { height: dimension };
        return (
          <div className={className} style={style} ref={nodeRef}>
            {children}
          </div>
        );
      }}
    </Transition>
  );
};

export default Collapse;
