import { useCallback } from 'react';

type LinkProperties = Partial<Omit<HTMLAnchorElement, 'href'>>;

const useDownloadBlob = () => {
  const download = useCallback(
    (blob: Blob, linkProperties?: LinkProperties) => {
      const _blob = blob instanceof Blob ? blob : new Blob([blob]);
      const url = window.URL.createObjectURL(_blob);
      const link = Object.assign(
        document.createElement('a'),
        linkProperties || {},
        {
          href: url,
          onclick: () => {
            if (linkProperties?.type === 'application/pdf') {
              window.open(url);
            }
          },
        },
      );
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 40000);
    },
    [],
  );

  return download;
};

export default useDownloadBlob;
