// import classNames from 'classnames';
import React from 'react';
import { styled, css } from 'styled-components';

type Color = 'primary' | 'success' | 'warning' | 'danger';
export interface Props {
  color?: Color;
  className?: string;
  'aria-label'?: string;
  children?: React.ReactNode;
}

const WrapperStyled = styled.div<{ $color?: Color }>(
  ({ theme, $color = 'success' }) => css`
    &&& {
      padding: ${theme.spacing.l};
      border: 1px solid ${theme.globalColor.pinkRed800};
      border-radius: ${theme.borderRadius.default};
      border-color: ${theme.alert.color[$color]};
      background-color: ${theme.alert.color[
        `${$color}Minus` as keyof typeof theme.alert.color
      ]};
      color: ${theme.alert.color[$color]};
      font-size: ${theme.fontSize.default};
      font-weight: ${theme.fontWeight.bold};
      letter-spacing: -0.2px;
      line-height: ${theme.lineHeight.default};
      text-align: left;

      a {
        color: inherit;
      }
    }
  `,
);

const Alert: React.FC<Props> = ({
  children,
  className,
  color,
  'aria-label': ariaLabel,
}) => (
  <WrapperStyled
    role="alert"
    aria-label={ariaLabel}
    className={className}
    $color={color}
  >
    {children}
  </WrapperStyled>
);

export default Alert;
