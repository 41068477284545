import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, css } from 'styled-components';

import Jumbotron from 'components/modules/box/Jumbotron';
import PageTitle from 'components/modules/headers/PageTitle';
import AbsencesConfirmBody from 'components/pages/absences/confirm/AbsencesConfirmBody';
import AbsencesConfirmFooter from 'components/pages/absences/confirm/AbsencesConfirmFooter';
import { SubmitValues } from 'components/pages/absences/types';
import SingleColumnTemplate from 'components/templates/SingleColumnTemplate';
import UiStackTemplate from 'components/templates/UiStackTemplate';
import { ErrorResponse } from 'hooks/common/useError';
import useModal from 'hooks/common/useModal';
import useReactQuery from 'hooks/common/useReactQuery';
interface Props {
  organizationMembershipId: string;
  values: SubmitValues;
  onBack: () => void;
  onCancel: () => void;
  submitStatus: 'created' | 'updated';
}

const ContentsStyled = styled.div(
  ({ theme }) => css`
    ${theme.media.pc} {
      margin-top: ${theme.spacing.xxxl};
    }
  `,
);

/* istanbul ignore next */
const filterSubmitValues = ({
  absence,
  contact_type,
  reason,
  go_to_school_at,
  leave_school_at,
  disease_name,
  symptom,
  body_temperature,
  doctors_instruction,
  relationship,
  transportation,
  detail_comment,
}: SubmitValues): Partial<SubmitValues> => {
  let filteredByContactType = {};
  switch (contact_type) {
    case 'absence':
      filteredByContactType = {};
      break;
    case 'tardy':
      filteredByContactType = { go_to_school_at };
      break;
    case 'early_leaving':
      filteredByContactType = { leave_school_at };
      break;
  }

  let filteredByReason = {};
  switch (reason) {
    case 'poor_health':
    case 'injury':
    case 'visit_hospital':
      filteredByReason = {
        disease_name,
        symptom,
        body_temperature,
        doctors_instruction,
      };
      break;
    case 'traffic_delay':
      filteredByReason = { transportation };
      break;
    case 'mourning':
      filteredByReason = { relationship };
      break;
    case 'family':
    case 'others':
      filteredByReason = {};
      break;
  }

  return {
    absence,
    contact_type,
    reason,
    detail_comment,
    ...filteredByContactType,
    ...filteredByReason,
  };
};

const AbsencesConfirmPage: React.FC<Props> = ({
  organizationMembershipId,
  values,
  onBack,
  onCancel,
  submitStatus,
}) => {
  const navigate = useNavigate();
  const { showModal } = useModal();
  // NOTE: 作成も編集も同APIでパラメータの`status`が違う
  const { useMutation } = useReactQuery();
  const { mutate, isPending, isSuccess, isError, error } = useMutation({
    method: 'POST',
    url: '/api/school_communication/absences',
  });

  React.useEffect(() => {
    if (isSuccess) {
      navigate(`/absences/member/${organizationMembershipId}`);
      if (submitStatus === 'updated') {
        showModal('AbsencesEditedModal', {});
      } else if (submitStatus === 'created') {
        showModal('AbsencesCreatedModal', {});
      }
    }
  }, [showModal, navigate, organizationMembershipId, isSuccess, submitStatus]);

  const onSubmit = React.useCallback(() => {
    void mutate(
      JSON.stringify({ ...filterSubmitValues(values), status: submitStatus }),
    );
  }, [mutate, submitStatus, values]);

  const switchErrorMessage: (e: ErrorResponse) => string[] | null = (e) => {
    let message: string | null;
    if (e?.error_code) {
      switch (e.error_code) {
        case 'duplicate_absence_log':
          message = '指定した日付に欠席連絡が既に登録されています。';
          break;
        case 'absence_time_limit_exceeded':
          message = e.json.message;
          break;
        default:
          message =
            '予期しないエラーが発生しました。しばらく後に再度お試しください。';
          break;
      }
    } else {
      message =
        '予期しないエラーが発生しました。しばらく後に再度お試しください。';
    }

    return message ? [message] : null;
  };

  return (
    <SingleColumnTemplate>
      <UiStackTemplate isLoading={false} isError={false}>
        <PageTitle
          title="入力内容の確認"
          backLinkLabel="欠席連絡の入力"
          onBackLinkClick={onBack}
          rightLinkLabel="キャンセル"
          onRightLinkClick={onCancel}
          rightLinkColor="gray"
        />
        <ContentsStyled>
          <form>
            <Jumbotron flexHeight>
              <AbsencesConfirmBody values={filterSubmitValues(values)} />
              <AbsencesConfirmFooter
                onSubmit={onSubmit}
                onBack={onBack}
                buttonDisabled={isPending}
                errors={isError ? switchErrorMessage(error) : null}
              />
            </Jumbotron>
          </form>
        </ContentsStyled>
      </UiStackTemplate>
    </SingleColumnTemplate>
  );
};

export default AbsencesConfirmPage;
