import React from 'react';
import { styled, css } from 'styled-components';

import AbsenceLabel from 'components/modules/labels/AbsenceLabel';

interface OwnProps {
  title: string;
  label: string;
  isCreatedTeacher?: boolean;
}

const AbsencesDetailPanelHeaderStyled = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing.xxl};
    border-bottom: 1px solid ${theme.globalColor.gray100};
    color: ${theme.globalColor.gray700};
    font-size: ${theme.fontSize.default};
    word-break: 'break-all';

    ${theme.media.sp} {
      padding: ${theme.spacing.l};
    }
  `,
);

const PanelHeaderStyled = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    ${theme.media.sp} {
      font-size: ${theme.fontSize.l};
    }
  `,
);

const DateStyled = styled.h2(
  ({ theme }) => css`
    margin-bottom: 0;
    color: ${theme.globalColor.gray700};
    font-size: ${theme.fontSize.l};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.default};
    word-break: break-all;

    ${theme.media.sp} {
      font-size: ${theme.fontSize.l};
    }
  `,
);

const LabelStyled = styled.span(
  ({ theme }) => css`
    color: ${theme.globalColor.gray500};
    font-size: ${theme.fontSize.default};
    font-weight: ${theme.fontWeight.light};
    line-height: ${theme.lineHeight.default};
  `,
);

const AbsencesDetailPanelHeader: React.FC<OwnProps> = ({
  title = '日付なし',
  label,
  isCreatedTeacher,
}) => (
  <AbsencesDetailPanelHeaderStyled>
    <PanelHeaderStyled>
      <DateStyled>{title}</DateStyled>
      {isCreatedTeacher && <LabelStyled>学校登録</LabelStyled>}
    </PanelHeaderStyled>
    <AbsenceLabel label={label} />
  </AbsencesDetailPanelHeaderStyled>
);

export default AbsencesDetailPanelHeader;
