import classNames from 'classnames';
import React from 'react';

import CSSModule from './FileInfoList.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const FileInfoList: React.FC<Props> = ({ children, className }) => (
  <div className={classNames(CSSModule.FileInfoList, className)}>
    {children}
  </div>
);

export default FileInfoList;
