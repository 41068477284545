/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Darklaunch V2 API
 * すべて一人のユーザに対するkey(s)の値をbooleanのJSON Objectなどで取得する。 複数のユーザに対してまとめてクエリする方法はない。  なお、ここでいうユーザとは与えられたidentifierの文字列の完全一致のみで判断するものである。 identifierには名前空間があり、現在 User / Organization / Aya::Pg::Account / Parentの4種類くらいをサポ〜トする。 ※ 2023-11-14現在、Parentは未対応 AccessToken や IP や session id(?) あたりもユースケースが多いなら追加するけどいまのところそんなになさそうなので少なくともいまはない。ユースケースに依存 
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VariationWithoutIdentifierGet200Response
 */
export interface VariationWithoutIdentifierGet200Response {
    /**
     * 引数で与えたkeyがそのままくる
     * @type {string}
     * @memberof VariationWithoutIdentifierGet200Response
     */
    key?: string;
    /**
     * ユーザにかかわらずそのkeyが有効ならtrue。keyが無効だったり、存在しなかったりするとfalse
     * @type {boolean}
     * @memberof VariationWithoutIdentifierGet200Response
     */
    variation?: boolean;
}

/**
 * Check if a given object implements the VariationWithoutIdentifierGet200Response interface.
 */
export function instanceOfVariationWithoutIdentifierGet200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VariationWithoutIdentifierGet200ResponseFromJSON(json: any): VariationWithoutIdentifierGet200Response {
    return VariationWithoutIdentifierGet200ResponseFromJSONTyped(json, false);
}

export function VariationWithoutIdentifierGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariationWithoutIdentifierGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'variation': !exists(json, 'variation') ? undefined : json['variation'],
    };
}

export function VariationWithoutIdentifierGet200ResponseToJSON(value?: VariationWithoutIdentifierGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'variation': value.variation,
    };
}

