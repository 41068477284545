import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import OrganizationMembershipRegistrationForm from 'components/organisms/mypage/OrganizationMembershipRegistrationForm';
import SingleCardTemplate from 'components/templates/SingleCardTemplate';
import { pageTitles } from 'constants/pageTitles';

const OrganizationMembershipPage: React.FC = () => {
  const intl = useIntl();
  const [formValues] = useState({ code: '' });

  return (
    <SingleCardTemplate
      title={intl.formatMessage({
        id: 'mypage.organizationMembershipRegistration.title',
      })}
    >
      <Helmet>
        <title>{pageTitles.mypageOrganizationMembershipsNew}</title>
      </Helmet>
      <OrganizationMembershipRegistrationForm formValues={formValues} />
    </SingleCardTemplate>
  );
};

export default OrganizationMembershipPage;
