import { captureException, captureMessage } from '@sentry/browser';
import dayjs from 'dayjs';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';

import ImportantIcon from 'assets/icons/important-information.png';
import SapuriIcon from 'assets/icons/studysapuri-information.png';
import Jumbotron from 'components/modules/box/Jumbotron';
import JumbotronPanel from 'components/modules/box/JumbotronPanel';
import Paper from 'components/modules/box/Paper';
import PageTitle from 'components/modules/headers/PageTitle';
import List from 'components/modules/lists/List';
import ListItem from 'components/modules/lists/ListItem';
import Pagination from 'components/modules/lists/Pagination';
import { UnreadNoticesCount } from 'components/organisms/mainHeader/MainHeaderNav';
import ErrorPage from 'components/pages/errors/common/ErrorPage';
import SingleColumnTemplate from 'components/templates/SingleColumnTemplate';
import UiStackTemplate from 'components/templates/UiStackTemplate';
import { pageTitles } from 'constants/pageTitles';
import useAuthentication from 'hooks/common/useAuthentication';
import useFetch from 'hooks/common/useFetch';
import useReactQuery from 'hooks/common/useReactQuery';
import { NoticeFromManagement } from 'interfaces/notice';

import CSSModule from './NoticeListPage.module.scss';

export interface Notices {
  notice_from_managements: NoticeFromManagement[];
  pagination: {
    current_page: number;
    limit_value: number;
    total_count: number;
  };
}

const NoticeListPage: React.FC = () => {
  const { useMutation, useQueryClient, useQuery } = useReactQuery();
  const queryClient = useQueryClient();
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn } = useAuthentication();
  const { page = '1' } = { ...queryString.parse(location.search) };
  const {
    fetch: fetchNotices,
    isFetching,
    status,
    data,
  } = useFetch('GET', `/api/school/notice_from_managements?page=${page}`);
  const notices = (data as Notices) || {
    notice_from_managements: {} as NoticeFromManagement[],
  };

  const isNetworkError = status && status >= 500;
  const isLoading = !status || isFetching;

  const handleOnPageChanged = (page: number) => {
    navigate(`/notices?page=${page}`);
  };

  const {
    data: unreadCountData,
    error,
    errorUpdatedAt,
  } = useQuery<UnreadNoticesCount>({
    queryKey: ['unread_count'],
    url: '/api/school/notice_from_managements/unread_count',
    enabled: isLoggedIn,
    staleTime: 0,
  });

  useEffect(() => {
    if (error && errorUpdatedAt) {
      captureMessage(`Failed to fetch unreadNoticesCount: ${error.error_code}`);
      captureException(error || 'unreadNoticesCount: something wrong');
    }
  }, [error, errorUpdatedAt]);

  const unreadCount = unreadCountData?.unread_count;

  const { mutate, isSuccess, isError } = useMutation({
    method: 'POST',
    url: '/api/school/notice_from_managements/read_all',
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['unread_count'] });
    },
    onError: async () => {
      await queryClient.refetchQueries({ queryKey: ['unread_count'] });
    },
  });

  const unreadCountFlag = unreadCount === undefined ? false : unreadCount > 0;

  const showNumberBadge = isSuccess || isError;

  useEffect(() => {
    if (isLoggedIn && unreadCountFlag) {
      mutate(undefined);
    }
  }, [mutate, isLoggedIn, unreadCountFlag]);

  useEffect(() => {
    void fetchNotices();
  }, [fetchNotices]);

  return isNetworkError ? (
    <ErrorPage type="internal_server_error" />
  ) : (
    <SingleColumnTemplate showNumberBadge={showNumberBadge}>
      <Helmet>
        <title>{pageTitles.notices}</title>
      </Helmet>
      <UiStackTemplate isLoading={isLoading}>
        <PageTitle
          title={intl.formatMessage({
            id: 'notice.list.title',
          })}
        />
        <div className={CSSModule.NoticeListPage}>
          <Jumbotron>
            {notices.notice_from_managements.length > 0 ? (
              <div>
                <div className={CSSModule.NoticeListPage__List}>
                  <Paper>
                    <List>
                      {notices.notice_from_managements.map((notice) => (
                        <ListItem
                          key={notice.id}
                          title={notice.title}
                          icon={
                            {
                              important: <img src={ImportantIcon} />,
                              normal: <img src={SapuriIcon} />,
                            }[notice.kind] as React.ReactNode
                          }
                          description={intl.formatMessage({
                            id: `notice.kind.${notice.kind}`,
                          })}
                          time={dayjs(notice.publish_start_at).format(
                            intl.formatMessage({
                              id: 'notice.list.publish_start_at_format',
                            }),
                          )}
                          url={`/notices/${notice.id}`}
                        />
                      ))}
                    </List>
                  </Paper>
                </div>
                <div className={CSSModule.NoticeListPage__Pagination}>
                  <Pagination
                    totalPage={Math.ceil(
                      notices.pagination.total_count /
                        notices.pagination.limit_value,
                    )}
                    initialPage={notices.pagination.current_page}
                    onPageChanged={(page) => handleOnPageChanged(page)}
                  />
                </div>
              </div>
            ) : (
              <JumbotronPanel
                title={intl.formatMessage({
                  id: 'notice.list.empty.title',
                })}
                description={intl.formatMessage({
                  id: 'notice.list.empty.description',
                })}
              />
            )}
          </Jumbotron>
        </div>
      </UiStackTemplate>
    </SingleColumnTemplate>
  );
};

export default NoticeListPage;
