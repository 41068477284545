import React from 'react';
import { useIntl } from 'react-intl';

import EmailRegistrationForm from 'components/organisms/registration/EmailRegistrationForm';
import { FormValues } from 'components/pages/registration/EmailRegistration';
import SingleCardTemplate from 'components/templates/SingleCardTemplate';

interface Props {
  formValues: FormValues;
  isFetching: boolean;
  errorCode?: string;
  serverError: string | null;
  onSubmit: (formValues: FormValues) => void;
}

const EmailRegistrationPage: React.FC<Props> = ({
  formValues,
  isFetching,
  errorCode,
  serverError,
  onSubmit,
}) => {
  const intl = useIntl();

  return (
    <SingleCardTemplate
      title={intl.formatMessage({ id: 'registration.emailRegistration.title' })}
    >
      <EmailRegistrationForm
        formValues={formValues}
        isFetching={isFetching}
        errorCode={errorCode}
        serverError={serverError}
        onSubmit={onSubmit}
      />
    </SingleCardTemplate>
  );
};

export default EmailRegistrationPage;
