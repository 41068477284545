import * as Yup from 'yup';

import { TIME_FORMAT } from 'components/modules/forms/TimeInputs';
import {
  contactTypeOptions,
  contactTypeReasonsV2,
} from 'components/pages/absences/constants';
import {
  ContactType,
  FormValues,
  SubmitValues,
} from 'components/pages/absences/types';

export const validationSchema = Yup.object().shape({
  absence: Yup.object().shape({
    date: Yup.string().required('日付は必須です'),
  }),
  contact_type: Yup.string()
    .required('内容は必須です')
    .oneOf(
      contactTypeOptions.map((opt) => opt.value).concat(['']),
      '正しい値で入力してください',
    ),
  reason: Yup.string()
    .required('理由は必須です')
    .when('contact_type', ([val]: string[], schema: Yup.StringSchema) =>
      val
        ? schema.oneOf(
            contactTypeReasonsV2[val as ContactType].concat(['']),
            '正しい値で入力してください',
          )
        : schema,
    ),
  go_to_school_at: Yup.string().when(
    'contact_type',
    ([val]: string[], schema: Yup.StringSchema) =>
      val === 'tardy'
        ? schema.required('登校時刻は必須です').matches(TIME_FORMAT, {
            excludeEmptyString: true,
            message: '正しい値で入力してください',
          })
        : schema,
  ),
  leave_school_at: Yup.string().when(
    'contact_type',
    ([val]: string[], schema: Yup.StringSchema) =>
      val === 'early_leaving'
        ? schema.required('下校時刻は必須です').matches(TIME_FORMAT, {
            excludeEmptyString: true,
            message: '正しい値で入力してください',
          })
        : schema,
  ),
  disease_name: Yup.string().when(
    'reason',
    ([val]: string[], schema: Yup.StringSchema) =>
      ['poor_health', 'injury', 'visit_hospital'].includes(val)
        ? schema.trim().max(400, '病名は400文字以内で入力してください')
        : schema,
  ),
  symptom: Yup.string().when(
    'reason',
    ([val]: string[], schema: Yup.StringSchema) =>
      ['poor_health', 'injury', 'visit_hospital'].includes(val)
        ? schema
            .trim()
            .required('症状は必須です')
            .max(400, '症状は400文字以内で入力してください')
        : schema,
  ),
  body_temperature: Yup.string().when(
    'reason',
    ([val]: string[], schema: Yup.StringSchema) =>
      ['poor_health', 'injury', 'visit_hospital'].includes(val)
        ? schema.trim().matches(/^[3-4]\d(\.\d{1,2})?$/, {
            excludeEmptyString: true,
            message: '半角数字で30.0~49.9の範囲内で入力してください',
          })
        : schema,
  ),
  doctors_instruction: Yup.string().when(
    'reason',
    ([val]: string[], schema: Yup.StringSchema) =>
      ['poor_health', 'injury', 'visit_hospital'].includes(val)
        ? schema.oneOf(['1', '0', ''], '正しい値で入力してください')
        : schema,
  ),
  relationship: Yup.string().when(
    'reason',
    ([val]: string[], schema: Yup.StringSchema) =>
      val === 'mourning'
        ? schema
            .trim()
            .required('亡くなった方と生徒の関係は必須です')
            .max(400, '亡くなった方と生徒の関係は400文字以内で入力してください')
        : schema,
  ),
  transportation: Yup.string().when(
    'reason',
    ([val]: string[], schema: Yup.StringSchema) =>
      val === 'traffic_delay'
        ? schema
            .trim()
            .required('交通機関は必須です')
            .max(400, '交通機関は400文字以内で入力してください')
        : schema,
  ),
  detail_comment: Yup.string()
    .trim()
    .required('詳細コメントは必須です')
    .max(400, '詳細コメントは400文字以内で入力してください'),
});

// 'absence.date'がRHF内で整形されvaluesとしては{absence: {date: string}}で返されるのでtypeを無理やり変更
export const convertToSubmitValuesWithOmId = (
  values: FormValues,
  organizationMembershipId: string,
): SubmitValues => {
  const { 'absence.date': _, ...submitValues } = values;
  (submitValues as SubmitValues).absence.organization_membership_id =
    organizationMembershipId;

  return submitValues as SubmitValues;
};
