import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes, useNavigate } from 'react-router-dom';

import MyPageProfileEditPageTitle from 'components/organisms/mypage/MyPageProfileEditPageTitle';
import ParentProfileForm from 'components/organisms/mypage/ParentProfileForm';
import ErrorPage from 'components/pages/errors/common/ErrorPage';
import EmailConfirmationPage from 'components/pages/mypage/EmailConfirmationPage';
import MyPageTemplate from 'components/templates/mypage/MyPageTemplate';
import { pageTitles } from 'constants/pageTitles';
import usePasswordConfirmationModal from 'hooks/mypage/usePasswordConfirmationModal';
import useEditEmailModal from 'hooks/registration/useEditEmailModal';
import useCurrentUser from 'hooks/store/useCurrentUser';
import { ParentProfileFormValues } from 'interfaces/parent';

const ParentProfileEditPage: React.FC = () => {
  const navigate = useNavigate();
  const [currentParent] = useCurrentUser();
  const [serverError, setServerError] = useState('');
  const initialValues: ParentProfileFormValues = {
    first_name: currentParent?.first_name || '',
    last_name: currentParent?.last_name || '',
    first_name_kana: currentParent?.first_name_kana || '',
    last_name_kana: currentParent?.last_name_kana || '',
    password: '',
    password_confirmation: '',
  };
  const [formValues] = useState(initialValues);
  const { showEditEmailModal, editEmailvalues } = useEditEmailModal();
  const { showPasswordConfirmationModal } = usePasswordConfirmationModal();

  const onError = useCallback(
    (errorMessage: string) => {
      setServerError(errorMessage);
    },
    [setServerError],
  );

  const onSubmit = (formValues: ParentProfileFormValues) => {
    showPasswordConfirmationModal({ profileValues: formValues, onError });
  };

  const onCancelEmailEdit = () => {
    navigate(-1);
    showEditEmailModal();
  };

  return (
    <>
      <Helmet>
        <title>{pageTitles.mypage}</title>
      </Helmet>
      <Routes>
        <Route
          path=""
          element={
            <MyPageTemplate pageTitle={<MyPageProfileEditPageTitle />}>
              {currentParent && (
                <ParentProfileForm
                  email={currentParent.email}
                  formValues={formValues}
                  onClickEditEmailButton={showEditEmailModal}
                  onSubmit={onSubmit}
                  serverError={serverError}
                />
              )}
            </MyPageTemplate>
          }
        />
        <Route
          path="email_confirmation"
          element={
            <EmailConfirmationPage
              email={editEmailvalues.email}
              password={editEmailvalues.current_password}
              onCancel={onCancelEmailEdit}
            />
          }
        />
        <Route path="*" element={<ErrorPage type="not_found" />} />
      </Routes>
    </>
  );
};

export default ParentProfileEditPage;
