import React from 'react';

import Input from 'components/modules/forms/Input';
import Label from 'components/modules/forms/Label';
import {
  FieldStyled,
  FormGroupStyled,
} from 'components/pages/absences/form/styled';

interface Props {
  label: string;
  value?: string;
}

const AbsencesFormStaticInput: React.FC<Props> = ({ label, value }) => (
  <FormGroupStyled>
    <Label>{label}</Label>
    <FieldStyled>
      <Input isStatic value={value} disabled />
    </FieldStyled>
  </FormGroupStyled>
);

export default AbsencesFormStaticInput;
