/* eslint-disable @typescript-eslint/no-misused-promises */
import classNames from 'classnames';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import Button from 'components/modules/button/Button';
import Checkbox from 'components/modules/forms/Checkbox';
import ErrorList from 'components/modules/forms/ErrorList';
import Input from 'components/modules/forms/Input';
import PasswordInput from 'components/modules/forms/PasswordInput';
import ButtonWithAgreement from 'components/molecules/forms/ButtonWithAgreement';
import useReactHookForm from 'hooks/common/useReactHookForm';

import CSSModule from './LoginForm.module.scss';

interface FormValues {
  email_or_code: string;
  password: string;
  remember_me: boolean;
}

interface Props {
  isFetching: boolean;
  serverError: string | null;
  onSubmit: (values: FormValues) => void;
}

const LoginForm: React.FC<Props> = ({ isFetching, serverError, onSubmit }) => {
  const intl = useIntl();
  const [formValues] = useState<FormValues>({
    email_or_code: '',
    password: '',
    remember_me: false,
  });

  const validationSchema = Yup.object().shape({
    email_or_code: Yup.string().required(
      intl.formatMessage(
        { id: 'forms.errors.required' },
        {
          label: intl.formatMessage({ id: 'loginPage.labels.emailOrCode' }),
        },
      ),
    ),
    password: Yup.string().required(
      intl.formatMessage(
        { id: 'forms.errors.required' },
        { label: intl.formatMessage({ id: 'loginPage.labels.password' }) },
      ),
    ),
  });

  const {
    registerWithInnerRef,
    handleSubmit,
    formState: { isSubmitting, errorMessages: formErrorMessages },
  } = useReactHookForm<FormValues>({
    validationSchema,
    defaultValues: formValues,
  });

  const inputDisabled = isSubmitting || isFetching;
  const buttonDisabled = isSubmitting || isFetching;

  const errorMessages = [
    ...(formErrorMessages.email_or_code || []),
    ...(formErrorMessages.password || []),
    ...(serverError ? [serverError] : []),
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={CSSModule.LoginForm}>
      <h2 className={CSSModule.LoginForm__Title}>
        <FormattedMessage id="loginPage.title" />
      </h2>
      <div
        className={classNames(
          CSSModule.LoginForm__ForgotPageLinkWrapper,
          CSSModule.forPC,
        )}
      >
        <Link to="/forgot" className={CSSModule.LoginForm__ForgotPageLink}>
          <FormattedMessage id="loginPage.forgotPageLink" />
        </Link>
      </div>
      <div className={CSSModule.LoginForm__InputWrapper}>
        <Input
          {...registerWithInnerRef('email_or_code')}
          disabled={inputDisabled}
          placeholder={intl.formatMessage({
            id: 'loginPage.placeholders.emailOrCode',
          })}
        />
      </div>
      <div className={CSSModule.LoginForm__InputWrapper}>
        <PasswordInput
          {...registerWithInnerRef('password')}
          disabled={inputDisabled}
          placeholder={intl.formatMessage({
            id: 'loginPage.placeholders.password',
          })}
        />
      </div>
      <div
        className={classNames(
          CSSModule.LoginForm__ForgotPageLinkWrapper,
          CSSModule.forSP,
        )}
      >
        <Link to="/forgot" className={CSSModule.LoginForm__ForgotPageLink}>
          <FormattedMessage id="loginPage.forgotPageLink" />
        </Link>
      </div>
      {errorMessages.length > 0 && <ErrorList errors={errorMessages} />}
      <div className={CSSModule.LoginForm__RememberMe}>
        <Checkbox
          {...registerWithInnerRef('remember_me')}
          disabled={inputDisabled}
        >
          <FormattedMessage id="loginPage.labels.rememberMe" />
        </Checkbox>
      </div>
      <p className={CSSModule.LoginForm__NoteForSharedDevice}>
        <FormattedMessage id="loginPage.noteForSharedDevice" />
      </p>
      <ButtonWithAgreement disabled={buttonDisabled}>
        <FormattedMessage id="loginPage.submitWithAgreement" />
      </ButtonWithAgreement>
      <Button
        color="outline"
        tag={Link}
        to="/registration"
        className={CSSModule.LoginForm__LinkToRegistration}
      >
        <FormattedMessage id="loginPage.registrationPageLink" />
      </Button>
    </form>
  );
};

export default LoginForm;
