import { OptionObject } from 'components/modules/forms/Select';
import { ContactType, Reason } from 'components/pages/absences/types';

export const contactTypeNames: { [key in ContactType]: string } = {
  absence: '欠席',
  tardy: '遅刻',
  early_leaving: '早退',
};

export const contactTypeOptions: OptionObject[] = Object.entries(
  contactTypeNames,
).map(([value, name]) => ({
  name,
  value,
}));

export const reasonNames: { [key in Reason]: string } = {
  poor_health: '体調不良',
  injury: '怪我',
  visit_hospital: '通院',
  traffic_delay: '交通遅延',
  mourning: '忌引き',
  family: '家庭の都合',
  others: 'その他',
};

export const reasonOptions: OptionObject[] = Object.entries(reasonNames).map(
  ([value, name]) => ({
    name,
    value,
  }),
);

export const contactTypeReasonsV2: Record<ContactType, string[]> = {
  absence: [
    'poor_health',
    'injury',
    'visit_hospital',
    'mourning',
    'family',
    'others',
  ],
  tardy: [
    'poor_health',
    'injury',
    'visit_hospital',
    'traffic_delay',
    'mourning',
    'family',
    'others',
  ],
  early_leaving: [
    'poor_health',
    'injury',
    'visit_hospital',
    'mourning',
    'family',
    'others',
  ],
};
