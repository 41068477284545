import React from 'react';

interface Props {
  children: React.ReactNode;
}

const AbsencesDetailPanel: React.FC<Props> = ({ children }) => (
  <div>{children}</div>
);

export default AbsencesDetailPanel;
