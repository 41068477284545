import React from 'react';
import { styled, css } from 'styled-components';

interface Props {
  note?: string;
  description?: string;
}

const WrapperStyled = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.xl};
  `,
);

const NoteStyled = styled.div(({ theme }) => ({
  fontWeight: theme.fontWeight.bold,
  lineHeight: theme.lineHeight.default,
  color: theme.globalColor.gray700,
  marginBottom: theme.spacing.m,
  fontSize: theme.fontSize.default,
}));

const DescriptionStyled = styled.div(({ theme }) => ({
  fontWeight: theme.fontWeight.light,
  color: theme.globalColor.gray500,
  fontSize: theme.fontSize.s,
}));

const AbsencesFooterContent: React.FC<Props> = ({ note, description }) => (
  <WrapperStyled data-testid="footerContent">
    {note && <NoteStyled>{note}</NoteStyled>}
    {description && <DescriptionStyled>{description}</DescriptionStyled>}
  </WrapperStyled>
);

export default AbsencesFooterContent;
