/* eslint-disable @typescript-eslint/no-misused-promises */
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import ErrorList from 'components/modules/forms/ErrorList';
import Field from 'components/modules/forms/Field';
import FormGroup from 'components/modules/forms/FormGroup';
import Input from 'components/modules/forms/Input';
import LabelWithStatus from 'components/modules/forms/LabelWithStatus';
import ButtonWithAgreement from 'components/molecules/forms/ButtonWithAgreement';
import { FormValues } from 'components/pages/registration/EmailRegistration';
import useReactHookForm from 'hooks/common/useReactHookForm';
import { SAPURI_EMAIL_REGEXP, HANKAKU_REGEXP } from 'store/constants';

import CSSModule from './EmailRegistrationForm.module.scss';

interface Props {
  formValues: FormValues;
  isFetching: boolean;
  errorCode?: string;
  serverError: string | null;
  onSubmit: (formValues: FormValues) => void;
}

const EmailRegistrationForm: React.FC<Props> = ({
  formValues,
  isFetching,
  serverError,
  errorCode,
  onSubmit,
}) => {
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(
        intl.formatMessage(
          { id: 'forms.errors.required' },
          {
            label: intl.formatMessage({
              id: 'registration.emailRegistration.labels.email',
            }),
          },
        ),
      )
      .matches(SAPURI_EMAIL_REGEXP, {
        message: intl.formatMessage(
          { id: 'forms.errors.pattern' },
          {
            label: intl.formatMessage({
              id: 'mypage.profileEditPage.editEmailModal.labels.email',
            }),
          },
        ),
        excludeEmptyString: true,
      })
      .test(
        'should-not-end-with-a-dot',
        intl.formatMessage(
          { id: 'forms.errors.pattern' },
          {
            label: intl.formatMessage({
              id: 'mypage.profileEditPage.editEmailModal.labels.email',
            }),
          },
        ),
        (value) => !/\.$/.test(value),
      )
      .matches(HANKAKU_REGEXP, {
        message: intl.formatMessage(
          {
            id: 'forms.errors.hankaku',
          },
          {
            label: intl.formatMessage({
              id: 'mypage.profileEditPage.editEmailModal.labels.email',
            }),
          },
        ),
        excludeEmptyString: true,
      }),
  });

  const {
    registerWithInnerRef,
    handleSubmit,
    formState: { isSubmitting, isValid, validFields, errorMessages },
  } = useReactHookForm<FormValues>({
    validationSchema,
    defaultValues: formValues,
  });

  const inputDisabled = isSubmitting || isFetching;
  // 「戻る」時valueセットされてるのでisDirtyチェック不要
  const buttonDisabled = !isValid || isSubmitting || isFetching;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={CSSModule.EmailRegistrationForm}
    >
      <div className={CSSModule.EmailRegistrationForm__Description}>
        <FormattedMessage id="registration.emailRegistration.description1" />
        <br />
        <FormattedMessage id="registration.emailRegistration.description2" />
      </div>
      <FormGroup>
        <LabelWithStatus required valid={validFields.email}>
          {intl.formatMessage({
            id: 'registration.emailRegistration.labels.email',
          })}
        </LabelWithStatus>
        <Field>
          <Input
            {...registerWithInnerRef('email')}
            disabled={inputDisabled}
            placeholder={intl.formatMessage({
              id: 'registration.emailRegistration.placeholders.email',
            })}
          />
          <ErrorList errors={errorMessages.email} />
        </Field>
      </FormGroup>

      {serverError && (
        <ErrorList
          aria-label="server-error"
          errors={
            errorCode !== 'email-taken'
              ? [serverError]
              : [
                  <>
                    <FormattedMessage id="registration.emailRegistration.errorMessages.emailTaken.message1" />
                    <br />
                    <Link to="/login">
                      <FormattedMessage id="registration.emailRegistration.errorMessages.emailTaken.here" />
                    </Link>
                    <FormattedMessage id="registration.emailRegistration.errorMessages.emailTaken.message2" />
                  </>,
                ]
          }
        />
      )}
      <div className={CSSModule.EmailRegistrationForm__Notice}>
        <FormattedMessage id="registration.emailRegistration.notices.notice1" />
        <br />
        <span>
          <FormattedMessage id="registration.emailRegistration.notices.notice2" />
        </span>
        <FormattedMessage id="registration.emailRegistration.notices.notice3" />
      </div>
      <div className={CSSModule.EmailRegistrationForm__RaisedButton}>
        <ButtonWithAgreement disabled={buttonDisabled}>
          <FormattedMessage id="registration.emailRegistration.submitWithAgreement" />
        </ButtonWithAgreement>
      </div>
    </form>
  );
};

export default EmailRegistrationForm;
