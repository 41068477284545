import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';

import EmailConfirmation from 'components/organisms/mypage/EmailConfirmation';
import SingleCardTemplate from 'components/templates/SingleCardTemplate';
import useFetch from 'hooks/common/useFetch';
import useToast from 'hooks/store/useToast';

interface Props {
  email: string | null;
  password: string | null;
  onCancel: () => void;
}

const EmailConfirmationPage: React.FC<Props> = ({
  email,
  password,
  onCancel,
}) => {
  const intl = useIntl();
  const { showToast } = useToast();

  const { fetch, isFetching, status, error } = useFetch(
    'POST',
    '/api/school/parents/email_change_tokens',
  );

  const onResend = () => {
    void fetch({ body: { email, current_password: password } });
  };

  useEffect(() => {
    if (status === 201) {
      showToast(
        intl.formatMessage(
          {
            id: 'mypage.profileEditPage.emailConfirmation.completed',
          },
          { email },
        ),
      );
    }
  }, [intl, showToast, status, email]);

  useEffect(() => {
    if (error) {
      showToast(error.message);
    }
  }, [showToast, error]);

  if (!email || !password) {
    return <Navigate replace to="/mypage/profile/edit" />;
  }

  return (
    <SingleCardTemplate
      title={intl.formatMessage({
        id: 'mypage.profileEditPage.emailConfirmation.title',
      })}
    >
      <EmailConfirmation
        email={email}
        isFetching={isFetching}
        onResend={onResend}
        onCancel={onCancel}
      />
    </SingleCardTemplate>
  );
};

export default EmailConfirmationPage;
