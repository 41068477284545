import React from 'react';

import MyPageSidebar from 'components/organisms/mypage/MyPageSidebar';
import TwoColumnTemplate from 'components/templates/TwoColumnTemplate';

import CSSModule from './MyPageTemplate.module.scss';

interface Props {
  pageTitle: JSX.Element;
  children?: React.ReactNode;
}

const MyPageTemplate: React.FC<Props> = ({ pageTitle, children }) => (
  <TwoColumnTemplate pageTitle={pageTitle} sidebar={<MyPageSidebar />}>
    <div className={CSSModule.MyPageTemplate}>{children}</div>
  </TwoColumnTemplate>
);

export default MyPageTemplate;
