import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import DocumentTitle from 'components/atoms/documentTitle/DocumentTitle';
import Toast from 'components/atoms/toasts/Toast';
import ModalRoot from 'components/organisms/common/ModalRoot';
import TrackPageView from 'components/organisms/common/TrackPageView';
import AutoLogin from 'components/organisms/login/AutoLogin';
import ErrorBoundary from 'components/pages/errors/ErrorBoundary';
import Providers from 'providers/Providers';
import Routes from 'routes/Routes';
import ScrollToTop from 'utils/ScrollToTop';

const App: React.FC = () => (
  <Providers>
    <BrowserRouter>
      <DocumentTitle />
      <ErrorBoundary>
        <AutoLogin>
          <Routes />
        </AutoLogin>
        <ScrollToTop />
        <ModalRoot />
        <Toast />
        <TrackPageView />
      </ErrorBoundary>
    </BrowserRouter>
  </Providers>
);

export default App;
