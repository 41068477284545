import { yupResolver } from '@hookform/resolvers/yup';
import { get } from 'lodash-es';
import { useEffect } from 'react';
import {
  useForm,
  DefaultValues,
  FieldValues,
  FieldValue,
  FieldError,
  ValidateResult,
  UseFormRegister,
  UseFormReturn,
} from 'react-hook-form';
import { AnyObject, Lazy, ObjectSchema } from 'yup';

interface Props<T extends FieldValues> {
  validationSchema: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | ObjectSchema<any, AnyObject, any, ''>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | Lazy<any, AnyObject, any>;
  defaultValues: DefaultValues<T>;
}

type ValidFields<T> = Record<keyof T, boolean>;
type ErrorMessages<T> = Record<keyof T, ValidateResult[] | undefined>;

type RegisterWithInnerRefType<T extends FieldValues> = (
  ...args: Parameters<UseFormRegister<T>>
) => Omit<ReturnType<UseFormRegister<T>>, 'ref'> & {
  innerRef: ReturnType<UseFormRegister<T>>['ref'];
};

export interface UseReactHookFormReturn<T extends FieldValues>
  extends UseFormReturn<T> {
  registerWithInnerRef: RegisterWithInnerRefType<T>;
  formState: UseFormReturn<T>['formState'] & {
    validFields: ValidFields<T>;
    errorMessages: ErrorMessages<T>;
  };
}

const useReactHookForm = <T extends FieldValues>({
  validationSchema,
  defaultValues,
}: Props<T>): UseReactHookFormReturn<T> => {
  const { formState, setValue, register, ...returns } = useForm<T>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    criteriaMode: 'all',
    defaultValues: defaultValues,
  });
  const {
    isDirty,
    dirtyFields,
    isSubmitted,
    isSubmitSuccessful,
    submitCount,
    touchedFields,
    isSubmitting,
    isValidating,
    isValid,
    errors,
  } = formState;

  // defaultValuesをvalidateさせる
  useEffect(() => {
    Object.keys(defaultValues).forEach((key) => {
      const val = get(defaultValues, key);
      if (val) {
        setValue(key as FieldValue<T>, val, {
          shouldValidate: true,
          shouldTouch: true,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultValues)]);

  const validFields = {} as ValidFields<T>;
  const errorMessages = {} as ErrorMessages<T>;

  Object.keys(defaultValues).forEach((key) => {
    validFields[key as keyof T] = !!(
      (get(touchedFields, key) || get(defaultValues, key)) &&
      !get(errors, key)
    );
    errorMessages[key as keyof T] = get(errors, key)
      ? Object.values((get(errors, key) as FieldError)?.types || {})
      : undefined;
  });

  const registerWithInnerRef: RegisterWithInnerRefType<T> = (...args) => {
    const { ref, ...rest } = register(...args);
    return { innerRef: ref, ...rest };
  };

  return {
    ...returns,
    register,
    registerWithInnerRef,
    setValue,
    formState: {
      ...formState,
      isDirty,
      dirtyFields,
      isSubmitted,
      isSubmitSuccessful,
      submitCount,
      touchedFields,
      isSubmitting,
      isValidating,
      isValid,
      errors,
      errorMessages,
      validFields,
    },
  };
};

export default useReactHookForm;
