import React from 'react';
import { styled } from 'styled-components';

interface Props {
  sticky?: boolean;
  children: React.ReactNode;
}

interface StyledProps {
  $sticky: boolean;
}

const WrapperStyled = styled.div<StyledProps>(({ theme, $sticky }) => {
  if ($sticky) {
    return {
      position: 'sticky',
      bottom: 0,
      background: theme.globalColor.white,
      border: `1px solid ${theme.globalColor.gray100}`,

      [theme.media.pc]: {
        padding: `${theme.spacing.l} 0`,
      },
      [theme.media.sp]: {
        padding: theme.spacing.l,
      },
    };
  } else {
    return {
      [theme.media.pc]: {
        margin: `${theme.spacing.xxxl} 0`,
      },
      [theme.media.sp]: {
        margin: `${theme.spacing.xl} ${theme.spacing.l}`,
      },
    };
  }
});

const InnerStyled = styled.div({
  textAlign: 'center',
});

const AbsencesFooter: React.FC<Props> = ({ children, sticky = false }) => (
  <WrapperStyled $sticky={sticky}>
    <InnerStyled>{children}</InnerStyled>
  </WrapperStyled>
);

export default AbsencesFooter;
