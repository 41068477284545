/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Modal from 'components/atoms/modals/Modal';
import Button from 'components/modules/button/Button';
import ErrorList from 'components/modules/forms/ErrorList';
import Field from 'components/modules/forms/Field';
import FormGroup from 'components/modules/forms/FormGroup';
import Input from 'components/modules/forms/Input';
import Label from 'components/modules/forms/Label';
import PasswordInput from 'components/modules/forms/PasswordInput';
import useFetch from 'hooks/common/useFetch';
import { ModalInnerProps } from 'hooks/common/useModal';
import useReactHookForm from 'hooks/common/useReactHookForm';
import { SAPURI_EMAIL_REGEXP, HANKAKU_REGEXP } from 'store/constants';

import CSSModule from './EditEmailModal.module.scss';

type FormValues = {
  email: string;
  current_password: string;
};

interface Props extends ModalInnerProps {
  initialValues: FormValues;
  onClose: (values: FormValues) => void;
}

const EditEmailModal: React.FC<Props> = ({
  isOpen,
  toggle,
  initialValues,
  onClose,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [formValues] = useState<FormValues>(initialValues);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(
        intl.formatMessage(
          {
            id: 'forms.errors.required',
          },
          {
            label: intl.formatMessage({
              id: 'mypage.profileEditPage.editEmailModal.labels.email',
            }),
          },
        ),
      )
      .matches(SAPURI_EMAIL_REGEXP, {
        message: intl.formatMessage(
          { id: 'forms.errors.pattern' },
          {
            label: intl.formatMessage({
              id: 'mypage.profileEditPage.editEmailModal.labels.email',
            }),
          },
        ),
        excludeEmptyString: true,
      })
      .test(
        'should-not-end-with-a-dot',
        intl.formatMessage(
          { id: 'forms.errors.pattern' },
          {
            label: intl.formatMessage({
              id: 'mypage.profileEditPage.editEmailModal.labels.email',
            }),
          },
        ),
        (value) => !/\.$/.test(value),
      )
      .matches(HANKAKU_REGEXP, {
        messsage: intl.formatMessage(
          {
            id: 'forms.errors.hankaku',
          },
          {
            label: intl.formatMessage({
              id: 'mypage.profileEditPage.editEmailModal.labels.email',
            }),
          },
        ),
        excludeEmptyString: true,
      })
      .when('current_password', {
        is: (val: string) =>
          val && typeof val == 'string' ? val.length > 0 : false,
        then: (schema) =>
          schema.notOneOf(
            [Yup.ref('current_password')],
            intl.formatMessage({
              id: 'mypage.profileEditPage.editEmailModal.equalToPasswordError',
            }),
          ),
      }),
    current_password: Yup.string().required(
      intl.formatMessage(
        {
          id: 'forms.errors.required',
        },
        {
          label: intl.formatMessage({
            id: 'mypage.profileEditPage.editEmailModal.labels.password',
          }),
        },
      ),
    ),
  });

  const {
    registerWithInnerRef,
    handleSubmit,
    formState: { isSubmitting, isValid, touchedFields, errorMessages },
    trigger,
    getValues,
  } = useReactHookForm<FormValues>({
    validationSchema,
    defaultValues: formValues,
  });

  const { fetch, isFetching, error, status } = useFetch(
    'POST',
    '/api/school/parents/email_change_tokens',
  );

  useEffect(() => {
    if (status === 201) {
      onClose(getValues());
      navigate('/mypage/profile/edit/email_confirmation');
      toggle();
    }
    // navigateを入れると2回発火するため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, toggle, getValues, status]);

  const onSubmit = (body: FormValues) => {
    void fetch({ body });
  };

  const inputDisabled = isSubmitting || isFetching;
  // 「戻る」でdefault値が入っている場合があるのでisDirtyチェックしない
  const buttonDisabled = !isValid || isSubmitting || isFetching;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title={intl.formatMessage({
        id: 'mypage.profileEditPage.editEmailModal.title',
      })}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={CSSModule.EditEmailModal}
      >
        <FormGroup>
          <Label>
            {intl.formatMessage({
              id: 'mypage.profileEditPage.editEmailModal.labels.email',
            })}
          </Label>
          <Field>
            <Input
              {...registerWithInnerRef('email')}
              disabled={inputDisabled}
              autoComplete="off"
              placeholder={intl.formatMessage({
                id: 'mypage.profileEditPage.editEmailModal.placeholders.email',
              })}
            />
            <ErrorList errors={errorMessages.email} />
          </Field>
        </FormGroup>
        <FormGroup>
          <Label>
            {intl.formatMessage({
              id: 'mypage.profileEditPage.editEmailModal.labels.password',
            })}
          </Label>
          <Field>
            <PasswordInput
              {...registerWithInnerRef('current_password', {
                onChange: () => {
                  touchedFields.email && void trigger('email');
                },
              })}
              disabled={inputDisabled}
              autoComplete="new-password"
              placeholder={intl.formatMessage({
                id: 'mypage.profileEditPage.editEmailModal.placeholders.password',
              })}
            />
            <ErrorList errors={errorMessages.current_password} />
          </Field>
        </FormGroup>

        {error && <ErrorList errors={[error.message]} />}

        <div className={CSSModule.EditEmailModal__Buttons}>
          <Button
            color="outline"
            className={CSSModule.EditEmailModal__Button}
            onClick={toggle}
          >
            <FormattedMessage id="forms.button.cancel" />
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={buttonDisabled}
            className={CSSModule.EditEmailModal__Button}
          >
            <FormattedMessage id="forms.button.save" />
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default EditEmailModal;
