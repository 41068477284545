import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { MessageDescriptor, useIntl } from 'react-intl';
import { Route, Routes, useLocation } from 'react-router-dom';

import ParentRegistrationForm from 'components/organisms/registration/ParentRegistrationForm';
import TokenVerificationResult, {
  Props as TokenVerificationResultProps,
} from 'components/organisms/registration/TokenVerificationResult';
import ErrorPage from 'components/pages/errors/common/ErrorPage';
import ProfileRegistrationErrorPage from 'components/pages/registration/ProfileRegistrationErrorPage';
import SingleCardTemplate from 'components/templates/SingleCardTemplate';
import { pageTitles } from 'constants/pageTitles';
import useReactQuery from 'hooks/common/useReactQuery';
import { ParentRegistrationFormValues } from 'interfaces/parent';

export interface TokenResponse {
  email: string;
}

export const initialParentRegistrationFormValues: ParentRegistrationFormValues =
  {
    password: '',
    password_confirmation: '',
    first_name: '',
    last_name: '',
    first_name_kana: '',
    last_name_kana: '',
    code: '',
  };

const ProfileRegistration: React.FC = () => {
  const intl = useIntl();
  const location = useLocation();
  const { useMutation } = useReactQuery();
  const { token } = queryString.parse(location.search);
  const [formValues, setFormValues] = useState(
    initialParentRegistrationFormValues,
  );

  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [errorType, setErrorType] =
    useState<TokenVerificationResultProps['errorType']>('invalid');

  const { mutate, data, isPending } = useMutation<TokenResponse>({
    url: '/api/school/parents/email_registration_tokens/verify',
    onSuccess: () => {
      setIsVerified(true);
    },
    onError: (error) => {
      setIsVerified(false);
      setErrorType(error.status === 403 ? 'expired' : 'invalid');
    },
  });

  useEffect(() => {
    if (token) {
      mutate(JSON.stringify({ token }));
    }
  }, [mutate, token]);

  const titleId = useMemo<MessageDescriptor['id']>(
    () =>
      isVerified
        ? 'registration.profileRegistration.title'
        : 'registration.tokenVerificationResult.title',
    [isVerified],
  );

  return (
    <Routes>
      <Route
        path=""
        element={
          <SingleCardTemplate title={intl.formatMessage({ id: titleId })}>
            <Helmet>
              <title>{pageTitles.registrationProfile}</title>
            </Helmet>
            {isVerified ? (
              data && (
                <ParentRegistrationForm
                  email={data.email}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              )
            ) : (
              <TokenVerificationResult
                isFetching={isPending}
                errorType={errorType}
                linkUrl="/registration"
              />
            )}
          </SingleCardTemplate>
        }
      />
      <Route path="error" element={<ProfileRegistrationErrorPage />} />
      <Route path="*" element={<ErrorPage type="not_found" />} />
    </Routes>
  );
};

export default ProfileRegistration;
