import { captureException, captureMessage } from '@sentry/browser';
import {
  QueryKey,
  useQueries,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

import { darklaunchV2Client, DarklaunchV2Key } from 'clients/darklaunch-v2';
import {
  ResponseError,
  VariationGet200Response,
  VariationGetIdentifierNamespaceEnum,
  VariationWithoutIdentifierGet200Response,
} from 'clients/darklaunch-v2/generated';

export type DarklaunchV2KeyWithAdditionalQueryKeys = {
  key: DarklaunchV2Key;
  additionalQueryKeys?: QueryKey;
};

/**
 * Darklaunch v2のキーを指定し、true / falseを返却します。
 * React Queryをラップしており、キャッシュ機構を備えています。
 *
 * @example
 * const { variation, ...result } = useDarklaunchV2VariationByOrganizationIds(
 *   'dark-launch-key',
 *   ['organization_id_A', 'organization_id_B']
 * );
 */
export const useDarklaunchV2VariationByOrganizationIds = (
  key: DarklaunchV2Key | DarklaunchV2KeyWithAdditionalQueryKeys,
  organizationIds: string[],
  options?: Omit<
    UseQueryOptions<VariationGet200Response>,
    'queryKey' | 'queryFn'
  >,
) => {
  const keyWithOptions = useMemo(
    (): DarklaunchV2KeyWithAdditionalQueryKeys =>
      typeof key === 'string' ? { key } : key,
    [key],
  );

  const queries = organizationIds.map((organizationId) => {
    const queryKey: QueryKey = [
      'darklaunch-v2',
      'variation',
      VariationGetIdentifierNamespaceEnum.OrganizationId,
      organizationId,
      keyWithOptions.key,
      ...(keyWithOptions.additionalQueryKeys ?? []),
    ];

    return {
      queryKey,
      queryFn: () =>
        darklaunchV2Client.variationGet({
          key: keyWithOptions.key,
          identifierNamespace:
            VariationGetIdentifierNamespaceEnum.OrganizationId,
          identifier: organizationId,
        }),
      staleTime: Infinity,
      ...options,
    };
  });

  const results = useQueries({ queries });

  useEffect(() => {
    results.forEach((result) => {
      if (result.status === 'error' && result.error) {
        if (result.error instanceof ResponseError) {
          result.error.response
            .json()
            .then((resJson) => {
              captureMessage(
                `DarklaunchV2Client: client error: ${
                  (result.error as ResponseError).response.status
                } ${JSON.stringify(resJson)}`,
              );
            })
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .catch(() => {});
          return;
        }
        captureException(result.error || 'DarklaunchV2Client: something wrong');
      }
    });
  }, [results]);

  const isError = results.some((r) => r.isError);
  const firstError = results.find(
    (r) => r.isError && r.error !== undefined && r.error !== null,
  )?.error;
  const isLoading = results.some((r) => r.isLoading);
  const isSuccess = results.some((r) => r.isSuccess);
  const variation = (() => {
    const v: { [organizationId: string]: boolean | undefined } = {};

    organizationIds.forEach((id, index) => {
      v[id] = results[index].data?.variation;
    });

    return v;
  })();

  return { isError, error: firstError, isLoading, isSuccess, variation };
};

export const useDarklaunchV2VariationWithoutIdentifier = (
  key: DarklaunchV2Key | DarklaunchV2KeyWithAdditionalQueryKeys,
  options?: Omit<
    UseQueryOptions<VariationWithoutIdentifierGet200Response>,
    'queryKey' | 'queryFn'
  >,
) => {
  const keyWithOptions = useMemo(
    (): DarklaunchV2KeyWithAdditionalQueryKeys =>
      typeof key === 'string' ? { key } : key,
    [key],
  );

  const result = useQuery({
    queryKey: [
      'darklaunch-v2',
      'variation',
      keyWithOptions.key,
      ...(keyWithOptions.additionalQueryKeys ?? []),
    ],
    queryFn: () =>
      darklaunchV2Client.variationWithoutIdentifierGet({
        key: keyWithOptions.key,
      }),
    staleTime: Infinity,
    ...options,
  });

  useEffect(() => {
    if (result.status === 'error' && result.error) {
      if (result.error instanceof ResponseError) {
        result.error.response
          .json()
          .then((resJson) => {
            captureMessage(
              `DarklaunchV2Client: client error: ${
                (result.error as ResponseError).response.status
              } ${JSON.stringify(resJson)}`,
            );
          })
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          .catch(() => {});
        return;
      }
      captureException(result.error || 'DarklaunchV2Client: something wrong');
    }
  }, [result.error, result.status]);

  return {
    ...result,
    variation: result.data?.variation,
  };
};
