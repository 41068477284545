import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as AvatarPlaceholder } from 'assets/icons/avatar.svg';
import Modal from 'components/atoms/modals/Modal';
import Button from 'components/modules/button/Button';
import GradeClassNumberLabel from 'components/modules/formats/GradeClassNumberLabel';
import ErrorList from 'components/modules/forms/ErrorList';
import CSSModule from 'components/organisms/common/StudentModal.module.scss';
import { ModalInnerProps } from 'hooks/common/useModal';
import { Student } from 'interfaces/student';

interface FooterProps {
  isFetching: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

interface Props extends ModalInnerProps {
  student: Student;
  isFetching: boolean;
  serverErrors: string[] | null;
  onSubmit: () => void;
  onCancel: () => void;
}

const Footer: React.FC<FooterProps> = ({ isFetching, onSubmit, onCancel }) => (
  <div className={CSSModule.StudentModal__Footer}>
    <div className={CSSModule.StudentModal__FooterButtons}>
      <Button
        color="outline"
        size="s"
        aria-label="Cancel"
        onClick={onCancel}
        className={CSSModule.StudentModal__Button}
      >
        <FormattedMessage id="forms.button.no" />
      </Button>
    </div>
    <div className={CSSModule.StudentModal__FooterButtons}>
      <Button
        color="primary"
        size="s"
        aria-label="OK"
        disabled={isFetching}
        onClick={onSubmit}
        className={CSSModule.StudentModal__Button}
      >
        <FormattedMessage id="forms.button.yes" />
      </Button>
    </div>
  </div>
);

const StudentModal: React.FC<Props> = ({
  student,
  isOpen,
  toggle,
  isFetching,
  serverErrors,
  onSubmit,
  onCancel,
}) => {
  const intl = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title={intl.formatMessage({
        id: 'common.studentModal.title',
      })}
      footer={
        <Footer
          isFetching={isFetching}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      }
      className={CSSModule.StudentModal}
    >
      <div className={CSSModule.StudentModal__Body}>
        <div className={CSSModule.StudentModal__Profile}>
          {student.profile_image_url ? (
            <img
              src={student.profile_image_url}
              alt={student.name}
              className={CSSModule.StudentModal__ProfileImage}
            />
          ) : (
            <AvatarPlaceholder
              className={CSSModule.StudentModal__ProfileImage}
            />
          )}
          <div className={CSSModule.StudentModal__ProfileText}>
            {student.organization_name}
            &emsp;
            <GradeClassNumberLabel
              gradeLabel={student.grade}
              classNameLabel={student.class_name}
              attendanceNumberLabel={student.attendance_number}
            />
            <br />
            {student.name}
          </div>
        </div>
        {serverErrors && (
          <div className={CSSModule.StudentModal__Error}>
            <ErrorList errors={serverErrors} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default StudentModal;
