import React, { useState } from 'react';
import { styled, css } from 'styled-components';

import { ReactComponent as InvisibleIcon } from 'assets/icons/invisible.svg';
import { ReactComponent as VisibleIcon } from 'assets/icons/visible.svg';
import Input from 'components/modules/forms/Input';

type Props = Omit<React.ComponentProps<typeof Input>, 'static'>;

const passwordIconRight = '12px';

const WrapperStyled = styled.div`
  position: relative;
`;

const InputStyled = styled(Input)(
  ({ theme }) => css`
    padding-right: calc(
      ${theme.icon.size.s} + ${passwordIconRight} + ${theme.spacing.m}
    );
  `,
);

const IconStyled = styled.i(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    right: ${passwordIconRight};
    bottom: 0;
    height: ${theme.icon.size.s};
    margin: auto;
    cursor: pointer;

    svg {
      background-color: transparent;
      fill: ${theme.globalColor.gray400};
    }
  `,
);

const PasswordInput: React.FC<Props> = (attrs) => {
  const [isVisible, setIsVisible] = useState(false);
  const type = isVisible ? 'text' : 'password';

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <WrapperStyled>
      <InputStyled type={type} {...attrs} />
      <IconStyled onClick={toggleVisibility}>
        {isVisible ? <VisibleIcon /> : <InvisibleIcon />}
      </IconStyled>
    </WrapperStyled>
  );
};

export default PasswordInput;
