import React from 'react';
import { styled, css } from 'styled-components';

import ErrorList from 'components/modules/forms/ErrorList';
import Input from 'components/modules/forms/Input';
import LabelWithStatus from 'components/modules/forms/LabelWithStatus';
import Select from 'components/modules/forms/Select';
import TextArea from 'components/modules/forms/TextArea';
import TimeInputs from 'components/modules/forms/TimeInputs';
import {
  contactTypeReasonsV2,
  reasonOptions,
} from 'components/pages/absences/constants';
import {
  FieldStyled,
  FormGroupStyled,
} from 'components/pages/absences/form/styled';
import {
  ContactType,
  FormValues,
  Value,
} from 'components/pages/absences/types';
import { UseReactHookFormReturn } from 'hooks/common/useReactHookForm';

interface Props {
  registerWithInnerRef: UseReactHookFormReturn<FormValues>['registerWithInnerRef'];
  validFields: UseReactHookFormReturn<FormValues>['formState']['validFields'];
  contactType: Value;
  errorMessages: UseReactHookFormReturn<FormValues>['formState']['errorMessages'];
}

const TextAreaStyled = styled(TextArea)(
  ({ theme }) => css`
    ${theme.media.pc} {
      min-height: 96px;
    }
    ${theme.media.sp} {
      min-height: 198px;
    }
  `,
);

const NoticeStyled = styled.p(({ theme }) => ({
  marginTop: theme.spacing.m,
  marginBottom: '0px',
  fontSize: theme.fontSize.default,
  color: theme.globalColor.gray700,

  [theme.media.sp]: {
    marginBottom: theme.spacing.m,
  },
}));

const AbsencesFormInputsOnOrAfterReason: React.FC<Props> = ({
  registerWithInnerRef,
  validFields,
  contactType,
  errorMessages,
}) => {
  const [reason, setReason] = React.useState<Value>(null);

  return (
    <>
      <FormGroupStyled>
        <LabelWithStatus required valid={validFields.reason}>
          理由
        </LabelWithStatus>
        <FieldStyled>
          <Select
            data-testid="reason"
            inputProps={registerWithInnerRef('reason')}
            placeholder="選択してください"
            options={
              contactType
                ? reasonOptions.filter((opt) =>
                    contactTypeReasonsV2[contactType as ContactType].includes(
                      opt.value,
                    ),
                  )
                : []
            }
            onChange={(val) => setReason(val)}
          />
          <ErrorList errors={errorMessages.reason} />
        </FieldStyled>
      </FormGroupStyled>

      <FormGroupStyled $visible={contactType === 'tardy'}>
        <LabelWithStatus required valid={validFields.go_to_school_at}>
          登校時刻
        </LabelWithStatus>
        <FieldStyled>
          <TimeInputs inputProps={registerWithInnerRef('go_to_school_at')} />
          <ErrorList errors={errorMessages.go_to_school_at} />
        </FieldStyled>
      </FormGroupStyled>

      <FormGroupStyled $visible={contactType === 'early_leaving'}>
        <LabelWithStatus required valid={validFields.leave_school_at}>
          下校時刻
        </LabelWithStatus>
        <FieldStyled>
          <TimeInputs inputProps={registerWithInnerRef('leave_school_at')} />
          <ErrorList errors={errorMessages.leave_school_at} />
        </FieldStyled>
      </FormGroupStyled>

      <FormGroupStyled
        $visible={['poor_health', 'injury', 'visit_hospital'].includes(
          reason || '',
        )}
      >
        <LabelWithStatus valid={validFields.disease_name}>病名</LabelWithStatus>
        <FieldStyled>
          <Input
            {...registerWithInnerRef('disease_name')}
            placeholder="例）インフルエンザ、骨折など"
          />
          <ErrorList errors={errorMessages.disease_name} />
        </FieldStyled>
      </FormGroupStyled>

      <FormGroupStyled
        $visible={['poor_health', 'injury', 'visit_hospital'].includes(
          reason || '',
        )}
      >
        <LabelWithStatus required valid={validFields.symptom}>
          症状
        </LabelWithStatus>
        <FieldStyled>
          <Input
            {...registerWithInnerRef('symptom')}
            placeholder="例）頭痛、発熱、咳、腹痛、発疹など"
          />
          <ErrorList errors={errorMessages.symptom} />
        </FieldStyled>
      </FormGroupStyled>

      <FormGroupStyled
        $visible={['poor_health', 'injury', 'visit_hospital'].includes(
          reason || '',
        )}
      >
        <LabelWithStatus valid={validFields.body_temperature}>
          体温（°C）
        </LabelWithStatus>
        <FieldStyled>
          <Input
            {...registerWithInnerRef('body_temperature')}
            placeholder="例）36.5"
          />
          <ErrorList errors={errorMessages.body_temperature} />
        </FieldStyled>
      </FormGroupStyled>

      <FormGroupStyled
        $visible={['poor_health', 'injury', 'visit_hospital'].includes(
          reason || '',
        )}
      >
        <LabelWithStatus valid={validFields.doctors_instruction}>
          医師からの出席停止指示
        </LabelWithStatus>
        <FieldStyled>
          <Select
            inputProps={registerWithInnerRef('doctors_instruction')}
            placeholder="選択してください"
            options={[
              { name: 'あり', value: '1' },
              { name: 'なし', value: '0' },
            ]}
          />
          <ErrorList errors={errorMessages.doctors_instruction} />
        </FieldStyled>
      </FormGroupStyled>

      <FormGroupStyled $visible={reason === 'mourning'}>
        <LabelWithStatus required valid={validFields.relationship}>
          亡くなった方と生徒の関係
        </LabelWithStatus>
        <FieldStyled>
          <Input
            {...registerWithInnerRef('relationship')}
            placeholder="例）祖父"
          />
          <ErrorList errors={errorMessages.relationship} />
        </FieldStyled>
      </FormGroupStyled>

      <FormGroupStyled $visible={reason === 'traffic_delay'}>
        <LabelWithStatus required valid={validFields.transportation}>
          交通機関
        </LabelWithStatus>
        <FieldStyled>
          <Input
            {...registerWithInnerRef('transportation')}
            placeholder="例）電車、バスなど"
          />
          <ErrorList errors={errorMessages.transportation} />
        </FieldStyled>
      </FormGroupStyled>

      <FormGroupStyled>
        <LabelWithStatus required valid={validFields.detail_comment}>
          詳細コメント
        </LabelWithStatus>
        <FieldStyled $width="max">
          <TextAreaStyled
            {...registerWithInnerRef('detail_comment')}
            rows={3}
            placeholder="詳細を入力してください"
          />
          <ErrorList errors={errorMessages.detail_comment} />
          <NoticeStyled>
            ご質問など返答が必要な内容につきましては「詳細コメント」には記入せず、別途学校に問い合わせてください。
          </NoticeStyled>
        </FieldStyled>
      </FormGroupStyled>
    </>
  );
};

export default AbsencesFormInputsOnOrAfterReason;
