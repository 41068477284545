/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import Button from 'components/modules/button/Button';
import ErrorList from 'components/modules/forms/ErrorList';
import Field from 'components/modules/forms/Field';
import FormGroup from 'components/modules/forms/FormGroup';
import Label from 'components/modules/forms/Label';
import PasswordInput from 'components/modules/forms/PasswordInput';
import useFetch from 'hooks/common/useFetch';
import useReactHookForm from 'hooks/common/useReactHookForm';
import {
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_REGEXP,
} from 'interfaces/parent';

import CSSModule from './ResetPasswordForm.module.scss';

type FormValues = {
  password: string;
  password_confirmation: string;
};

interface Props {
  token?: string | (string | null)[] | null;
  email?: string;
  setCompleted: (completed: boolean) => void;
  setIsVerificationError: (isVerificationError: boolean) => void;
}

const ResetPasswordForm: React.FC<Props> = ({
  token,
  email,
  setCompleted,
  setIsVerificationError,
}) => {
  const intl = useIntl();
  const [formValues] = useState<FormValues>({
    password: '',
    password_confirmation: '',
  });
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(
        intl.formatMessage(
          { id: 'forms.errors.required' },
          {
            label: intl.formatMessage({
              id: 'resetPasswordPage.resetPasswordForm.labels.password',
            }),
          },
        ),
      )
      .min(
        PASSWORD_MIN_LENGTH,
        intl.formatMessage(
          { id: 'forms.errors.minLength' },
          {
            label: intl.formatMessage({
              id: 'resetPasswordPage.resetPasswordForm.labels.password',
            }),
            value: PASSWORD_MIN_LENGTH,
          },
        ),
      )
      .max(
        PASSWORD_MAX_LENGTH,
        intl.formatMessage(
          { id: 'forms.errors.maxLength' },
          {
            label: intl.formatMessage({
              id: 'resetPasswordPage.resetPasswordForm.labels.password',
            }),
            value: PASSWORD_MAX_LENGTH,
          },
        ),
      )
      .matches(
        PASSWORD_REGEXP,
        intl.formatMessage({
          id: 'resetPasswordPage.resetPasswordForm.errors.invalidPassword',
        }),
      )
      .notOneOf(
        [email],
        intl.formatMessage({
          id: 'resetPasswordPage.resetPasswordForm.errors.equalsEmail',
        }),
      ),
    password_confirmation: Yup.string()
      .required(
        intl.formatMessage(
          { id: 'forms.errors.required' },
          {
            label: intl.formatMessage({
              id: 'resetPasswordPage.resetPasswordForm.labels.password_confirmation',
            }),
          },
        ),
      )
      .oneOf(
        [Yup.ref('password')],
        intl.formatMessage({
          id: 'resetPasswordPage.resetPasswordForm.errors.invalidPasswordConfirmation',
        }),
      ),
  });

  const {
    fetch: resetPassword,
    isFetching,
    status,
  } = useFetch('PATCH', '/api/school/parents/password/reset');

  const {
    registerWithInnerRef,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid, touchedFields, errorMessages },
    trigger,
  } = useReactHookForm<FormValues>({
    validationSchema,
    defaultValues: formValues,
  });

  const onSubmit = async (values: FormValues) => {
    await resetPassword({
      headers: {
        'X-Token-Type': 'password_change_token',
        Authorization: `Bearer ${token as string}`,
      },
      body: values,
    });
  };

  useEffect(() => {
    setCompleted(status === 200);
  }, [status, setCompleted]);

  useEffect(() => {
    setIsVerificationError(!!(status && 400 <= status && status <= 499));
  }, [setIsVerificationError, status]);

  const inputDisabled = isSubmitting || isFetching;
  const buttonDisabled = !isDirty || !isValid || isSubmitting || isFetching;

  return (
    <form
      className={CSSModule.ResetPasswordForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormGroup>
        <Label>
          {intl.formatMessage({
            id: 'resetPasswordPage.resetPasswordForm.labels.new_password',
          })}
        </Label>
        <Field>
          <PasswordInput
            {...registerWithInnerRef('password', {
              onChange: () => {
                touchedFields.password_confirmation &&
                  void trigger('password_confirmation');
              },
            })}
            placeholder={intl.formatMessage({
              id: 'resetPasswordPage.resetPasswordForm.placeholders.password',
            })}
            disabled={inputDisabled}
          />
          <ErrorList errors={errorMessages.password} />
        </Field>
        <Field>
          <PasswordInput
            {...registerWithInnerRef('password_confirmation')}
            placeholder={intl.formatMessage({
              id: 'resetPasswordPage.resetPasswordForm.placeholders.password_confirmation',
            })}
            disabled={inputDisabled}
          />
          <ErrorList errors={errorMessages.password_confirmation} />
        </Field>
      </FormGroup>

      <ul className={CSSModule.ResetPasswordForm__Notes}>
        <li>
          <strong>
            <FormattedMessage id="resetPasswordPage.resetPasswordForm.noteForPasswordLength" />
          </strong>
        </li>
        <li>
          <strong>
            <FormattedMessage id="resetPasswordPage.resetPasswordForm.noteForPasswordFormat" />
          </strong>
        </li>
        <li>
          <FormattedMessage id="resetPasswordPage.resetPasswordForm.noteForPasswordRecommendationFormat" />
        </li>
      </ul>

      <div className={CSSModule.ResetPasswordForm__Buttons}>
        <Button
          color="primary"
          disabled={buttonDisabled}
          type="submit"
          className={CSSModule.ResetPasswordForm__SubmitButton}
        >
          <FormattedMessage id="resetPasswordPage.resetPasswordForm.submit" />
        </Button>
        <Button
          tag={Link}
          to="/login"
          color="outline"
          className={CSSModule.ResetPasswordForm__BackToLoginButton}
        >
          <FormattedMessage id="resetPasswordPage.resetPasswordForm.backToLoginPage" />
        </Button>
      </div>
    </form>
  );
};

export default ResetPasswordForm;
