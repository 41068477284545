import React from 'react';
import { styled, css } from 'styled-components';

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  innerRef?: React.Ref<HTMLTextAreaElement>;
  isStatic?: boolean;
}

const TextAreaStyled = styled.textarea<{ $static?: boolean }>(
  ({ theme, $static }) => css`
    ${theme.form.input.styles.default}

    &::placeholder {
      ${theme.form.input.styles.placeholder}
    }

    &:not(:placeholder-shown) {
      ${theme.form.input.styles.fill}
    }

    &:hover {
      ${theme.form.input.styles.hover}
    }

    &:focus {
      ${theme.form.input.styles.focus}
    }

    &:disabled {
      ${theme.form.input.styles.disabled}
    }

    &&& {
      ${$static ? theme.form.input.styles.static : ''}
    }
  `,
);

const TextArea: React.FC<Props> = ({ innerRef, isStatic, ...attrs }) => (
  <TextAreaStyled ref={innerRef} $static={isStatic} {...attrs} />
);

export default TextArea;
