import React from 'react';
import { styled, css } from 'styled-components';

import Modal from 'components/atoms/modals/Modal';
import Button from 'components/modules/button/Button';
import { ModalInnerProps } from 'hooks/common/useModal';

interface Props extends ModalInnerProps {}

const DescriptionStyled = styled.div(
  ({ theme }) => css`
    font-size: ${theme.fontSize.default};
    ${theme.media.pc} {
      margin-top: ${theme.spacing.l};
      text-align: center;
    }

    ${theme.media.sp} {
      margin-top: ${theme.spacing.m};
    }
  `,
);
const ButtonsStyled = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing.xxl};
    font-size: ${theme.fontSize.default};
  `,
);
const ButtonStyled = styled(Button)(
  ({ theme }) => css`
    ${theme.media.pc} {
      width: ${theme.button.width.s};
    }
    ${theme.media.sp} {
      width: 160px;
    }
  `,
);

const AbsencesCanceledModal: React.FC<Props> = ({ isOpen, toggle }) => (
  <Modal
    isOpen={isOpen}
    toggle={toggle}
    title={'欠席連絡の取り消しが完了しました'}
  >
    <DescriptionStyled>
      欠席連絡の取り消しが正常に送信完了しました。
      <br />
      別途、お電話等での確認は不要です。
    </DescriptionStyled>
    <ButtonsStyled>
      <ButtonStyled size="s" color="outline" onClick={toggle}>
        閉じる
      </ButtonStyled>
    </ButtonsStyled>
  </Modal>
);

export default AbsencesCanceledModal;
