import React from 'react';
import { Helmet } from 'react-helmet-async';

import EmailConfirmation from 'components/organisms/forgot/EmailConfirmation';
import SingleCardTemplate from 'components/templates/SingleCardTemplate';
import { pageTitles } from 'constants/pageTitles';

const PasswordChangeEmailConfirmationPage: React.FC = () => (
  <SingleCardTemplate title={pageTitles.forgot}>
    <Helmet>
      <title>{pageTitles.forgotPasswordConfirmation}</title>
    </Helmet>
    <EmailConfirmation />
  </SingleCardTemplate>
);

export default PasswordChangeEmailConfirmationPage;
