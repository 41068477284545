import React from 'react';
import { styled } from 'styled-components';

interface OwnProps {
  children?: React.ReactNode;
  flexHeight?: boolean;
}

const WrapperStyled = styled.div<{
  $flexHeight: boolean;
}>(({ theme, $flexHeight }) => ({
  '&&&': {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: theme.jumbotron.width,
    minHeight: $flexHeight ? 'inherit' : theme.jumbotron.height,
    margin: 'auto',

    [theme.media.sp]: {
      maxWidth: 'auto',
      minHeight: $flexHeight ? 'inherit' : theme.jumbotron.heightSp,
      margin: 0,
      borderRadius: 'none',
    },
  },
}));

const Jumbotron: React.FC<OwnProps> = ({ children, flexHeight = false }) => (
  <WrapperStyled $flexHeight={flexHeight}>{children}</WrapperStyled>
);

export default Jumbotron;
