import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, css } from 'styled-components';

import Button from 'components/modules/button/Button';

interface Props {
  image?: string;
  title?: string;
  subtitle?: string;
  description: string;
  buttons?: React.ReactElement[];
}

const WrapperStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ContainerStyled = styled.div`
  width: 100%;
`;

const ImgContainerStyled = styled.div`
  text-align: center;
`;

const ImgStyled = styled.img(
  ({ theme }) => css`
    padding-top: 52px;
    ${theme.media.pc} {
      max-width: 300px;
    }
    ${theme.media.sp} {
      max-width: 200px;
    }
  `,
);

const H2Styled = styled.h2(
  ({ theme }) => css`
    color: ${theme.globalColor.darkBlue700};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.default};
    text-align: center;

    ${theme.media.pc} {
      margin-bottom: ${theme.spacing.xxxl};
      font-size: ${theme.fontSize.errorTitlePc};
    }
    ${theme.media.sp} {
      font-size: ${theme.fontSize.errorTitleSp};
    }
  `,
);

const H3Styled = styled.h3(
  ({ theme }) => css`
    color: ${theme.globalColor.gray700};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.default};
    text-align: center;

    ${theme.media.pc} {
      margin: 20px 0;
      font-size: ${theme.fontSize.errorSubtitlePc};
    }
    ${theme.media.sp} {
      margin: ${theme.spacing.l} 0 ${theme.spacing.m};
      font-size: ${theme.fontSize.errorSubtitleSp};
    }
  `,
);

const DescriptionStyled = styled.p(
  ({ theme }) => css`
    width: 85%;
    margin: 0 auto;
    color: ${theme.globalColor.gray700};
    font-style: normal;
    line-height: ${theme.lineHeight.default};
    text-align: center;

    ${theme.media.pc} {
      max-width: 400px;
      font-size: ${theme.fontSize.l};
    }

    ${theme.media.sp} {
      max-width: 320px;
      font-size: ${theme.fontSize.default};
    }
  `,
);

const ButtonContainerStyled = styled.div(
  ({ theme }) => css`
    width: 85%;

    ${theme.media.pc} {
      max-width: ${theme.button.width.l};
      margin: ${theme.spacing.xxxl} auto;
    }

    ${theme.media.sp} {
      max-width: 320px;
      margin: 20px auto;
    }

    & > button:last-child {
      margin-top: ${theme.spacing.l};
    }
  `,
);

const ErrorContent: React.FC<Props> = ({
  image,
  title,
  subtitle,
  description,
  buttons,
}) => {
  const navigate = useNavigate();

  return (
    <WrapperStyled>
      <ContainerStyled>
        {image && (
          <ImgContainerStyled>
            <ImgStyled src={image} alt={title} />
          </ImgContainerStyled>
        )}
        {title && <H2Styled>{title}</H2Styled>}
        {subtitle && <H3Styled>{subtitle}</H3Styled>}
        <DescriptionStyled>{description}</DescriptionStyled>
        <ButtonContainerStyled>
          {buttons ? (
            buttons.map((button) => button)
          ) : (
            <Button
              onClick={() => {
                navigate('/login');
              }}
            >
              ホームに戻る
            </Button>
          )}
        </ButtonContainerStyled>
      </ContainerStyled>
    </WrapperStyled>
  );
};

export default ErrorContent;
