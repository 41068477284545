/* eslint-disable @typescript-eslint/no-misused-promises */
import { camelCase, uniq } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import ErrorList from 'components/modules/forms/ErrorList';
import Field from 'components/modules/forms/Field';
import FormGroup from 'components/modules/forms/FormGroup';
import Input from 'components/modules/forms/Input';
import Label from 'components/modules/forms/Label';
import LabelWithStatus from 'components/modules/forms/LabelWithStatus';
import PasswordInput from 'components/modules/forms/PasswordInput';
import ButtonWithAgreement from 'components/molecules/forms/ButtonWithAgreement';
import useReactHookForm from 'hooks/common/useReactHookForm';
import useStudentConfirmationModal from 'hooks/registration/useStudentConfirmationModal';
import { SetState } from 'interfaces/common';
import {
  HIRAGANA_REGEXP,
  NAME_KANA_MAX_LENGTH,
  NAME_MAX_LENGTH,
  ORGANIZATION_MEMBERSHIP_CODE_REGEXP,
  ParentRegistrationFormValues,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_REGEXP,
  ZENKAKU_REGEXP,
} from 'interfaces/parent';

import CSSModule from './ParentRegistrationForm.module.scss';

interface ServerErrorProps {
  errors: string[];
  code?: 'token-expired';
}

const ServerErrorMessages: React.FC<ServerErrorProps> = ({ errors, code }) => {
  if (code === 'token-expired') {
    return (
      <ErrorList
        errors={[
          <>
            <FormattedMessage id="registration.profileRegistration.errors.tokenExpired" />
            <Link to="/registration">
              <FormattedMessage id="registration.profileRegistration.errors.emailRegistration" />
            </Link>
            <FormattedMessage id="registration.profileRegistration.errors.retryRegistration" />
          </>,
        ]}
      />
    );
  }

  if (errors.length === 0) {
    return null;
  }

  return <ErrorList errors={errors} />;
};

interface Props {
  email: string;
  formValues: ParentRegistrationFormValues;
  setFormValues: SetState<ParentRegistrationFormValues>;
}

const ParentRegistrationForm: React.FC<Props> = ({
  email,
  formValues,
  setFormValues,
}) => {
  const intl = useIntl();

  const [code, setCode] = useState('');

  const generateErrorMessage = (
    errorType: 'required' | 'pattern' | 'minLength' | 'maxLength',
    label: keyof ParentRegistrationFormValues,
    value: string | number = '',
  ) =>
    intl.formatMessage(
      {
        id: `forms.errors.${errorType}`,
      },
      {
        label: intl.formatMessage({
          id: `registration.profileRegistration.labels.${camelCase(label)}`,
        }),
        value,
      },
    );

  const generateNameFormatErrorMessage = (
    errorType: 'hiraganaFormat' | 'zenkakuFormat',
    label: keyof ParentRegistrationFormValues,
  ) =>
    intl.formatMessage(
      {
        id: `registration.profileRegistration.errors.${errorType}`,
      },
      {
        label: intl.formatMessage({
          id: `registration.profileRegistration.labels.${camelCase(label)}`,
        }),
      },
    );

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(generateErrorMessage('required', 'password'))
      .test(
        'min',
        generateErrorMessage('minLength', 'password', PASSWORD_MIN_LENGTH),
        (value) =>
          value
            ? value.length <= 0 || value.length >= PASSWORD_MIN_LENGTH
            : true,
      )
      .max(
        PASSWORD_MAX_LENGTH,
        generateErrorMessage('maxLength', 'password', PASSWORD_MAX_LENGTH),
      )
      .matches(PASSWORD_REGEXP, {
        message: intl.formatMessage({
          id: 'registration.profileRegistration.errors.invalidPassword',
        }),
        excludeEmptyString: true,
      })
      .notOneOf(
        [email],
        intl.formatMessage({
          id: 'registration.profileRegistration.errors.passwordMatchesEmail',
        }),
      ),
    password_confirmation: Yup.string()
      .required(generateErrorMessage('required', 'password_confirmation'))
      .oneOf(
        [Yup.ref('password')],
        intl.formatMessage({
          id: 'registration.profileRegistration.errors.invalidPasswordConfirmation',
        }),
      ),
    last_name: Yup.string()
      .trim()
      .required(generateErrorMessage('required', 'last_name'))
      .matches(ZENKAKU_REGEXP, {
        message: generateNameFormatErrorMessage('zenkakuFormat', 'last_name'),
        excludeEmptyString: true,
      })
      .max(
        NAME_MAX_LENGTH,
        generateErrorMessage('maxLength', 'last_name', NAME_MAX_LENGTH),
      ),
    first_name: Yup.string()
      .trim()
      .required(generateErrorMessage('required', 'first_name'))
      .matches(ZENKAKU_REGEXP, {
        message: generateNameFormatErrorMessage('zenkakuFormat', 'first_name'),
        excludeEmptyString: true,
      })
      .max(
        NAME_MAX_LENGTH,
        generateErrorMessage('maxLength', 'first_name', NAME_MAX_LENGTH),
      ),
    last_name_kana: Yup.string()
      .required(generateErrorMessage('required', 'last_name_kana'))
      .matches(HIRAGANA_REGEXP, {
        message: generateNameFormatErrorMessage(
          'hiraganaFormat',
          'last_name_kana',
        ),
        excludeEmptyString: true,
      })
      .max(
        NAME_KANA_MAX_LENGTH,
        generateErrorMessage(
          'maxLength',
          'last_name_kana',
          NAME_KANA_MAX_LENGTH,
        ),
      ),
    first_name_kana: Yup.string()
      .required(generateErrorMessage('required', 'first_name_kana'))
      .matches(HIRAGANA_REGEXP, {
        message: generateNameFormatErrorMessage(
          'hiraganaFormat',
          'first_name_kana',
        ),
        excludeEmptyString: true,
      })
      .max(
        NAME_KANA_MAX_LENGTH,
        generateErrorMessage(
          'maxLength',
          'first_name_kana',
          NAME_KANA_MAX_LENGTH,
        ),
      ),
    code: Yup.string()
      .required(generateErrorMessage('required', 'code'))
      .matches(ORGANIZATION_MEMBERSHIP_CODE_REGEXP, {
        message: intl.formatMessage({
          id: 'registration.profileRegistration.errors.invalidCode',
        }),
        excludeEmptyString: true,
      }),
  });

  const {
    registerWithInnerRef,
    handleSubmit,
    formState: {
      isSubmitting,
      isValid,
      touchedFields,
      validFields,
      errorMessages,
    },
    trigger,
    getValues,
  } = useReactHookForm<ParentRegistrationFormValues>({
    validationSchema,
    defaultValues: formValues,
  });

  const {
    fetchStudent,
    isFetchingStudent,
    fetchStudentErrors,
    fetchStudentErrorCode,
  } = useStudentConfirmationModal({
    code: code,
  });

  const inputDisabled = isSubmitting || isFetchingStudent;
  const buttonDisabled = !isValid || isSubmitting || isFetchingStudent;

  const onSubmit = (values: ParentRegistrationFormValues) => {
    setCode(values.code);
    setFormValues(values);
  };

  useEffect(() => {
    if (code) {
      void fetchStudent(getValues());
      // onSubmitのたびにこのeffectを発火させたいので
      setCode('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const passwordErrors = uniq([
    ...(errorMessages.password || []),
    ...(errorMessages.password_confirmation || []),
  ]);

  const nameErrors = [
    ...(errorMessages.last_name || []),
    ...(errorMessages.first_name || []),
  ];

  const nameKanaErrors = [
    ...(errorMessages.last_name_kana || []),
    ...(errorMessages.first_name_kana || []),
  ];

  const submitErrors = [...(fetchStudentErrors || [])];

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={CSSModule.ParentRegistrationForm}
    >
      <div className={CSSModule.ParentRegistrationForm__Description}>
        <FormattedMessage id="registration.profileRegistration.description" />
      </div>
      <FormGroup>
        <Label>
          {intl.formatMessage({
            id: 'registration.profileRegistration.labels.email',
          })}
        </Label>
        <Field>
          <div className={CSSModule.ParentRegistrationForm__EmailValue}>
            {email}
          </div>
        </Field>
      </FormGroup>
      <FormGroup>
        <LabelWithStatus
          required
          valid={validFields.password && validFields.password_confirmation}
        >
          {intl.formatMessage({
            id: 'registration.profileRegistration.labels.password',
          })}
        </LabelWithStatus>
        <Field>
          <PasswordInput
            {...registerWithInnerRef('password', {
              onChange: () => {
                (touchedFields.password_confirmation ||
                  formValues.password_confirmation) &&
                  void trigger('password_confirmation');
              },
            })}
            disabled={inputDisabled}
            autoComplete="new-password"
            placeholder={intl.formatMessage({
              id: 'registration.profileRegistration.placeholders.password',
            })}
          />
        </Field>
        <Field>
          <PasswordInput
            {...registerWithInnerRef('password_confirmation')}
            disabled={inputDisabled}
            autoComplete="new-password"
            placeholder={intl.formatMessage({
              id: 'registration.profileRegistration.placeholders.passwordConfirmation',
            })}
          />
        </Field>
        <ErrorList errors={passwordErrors} />
      </FormGroup>
      <p className={CSSModule.ParentRegistrationForm__Note}>
        <strong>
          <FormattedMessage
            id="registration.profileRegistration.noteForPasswordValidation"
            values={{ length: PASSWORD_MIN_LENGTH }}
          />
        </strong>
        <br />
        <FormattedMessage id="registration.profileRegistration.noteForPasswordFormat" />
      </p>

      <FormGroup>
        <LabelWithStatus
          required
          valid={validFields.first_name && validFields.last_name}
        >
          {intl.formatMessage({
            id: 'registration.profileRegistration.labels.name',
          })}
        </LabelWithStatus>
        <Field>
          <div className={CSSModule.ParentRegistrationForm__HorizontalInput}>
            <div
              className={CSSModule.ParentRegistrationForm__HorizontalInputItem}
            >
              <Input
                {...registerWithInnerRef('last_name')}
                disabled={inputDisabled}
                placeholder={intl.formatMessage({
                  id: 'registration.profileRegistration.placeholders.lastName',
                })}
              />
            </div>
            <div
              className={CSSModule.ParentRegistrationForm__HorizontalInputItem}
            >
              <Input
                {...registerWithInnerRef('first_name')}
                disabled={inputDisabled}
                placeholder={intl.formatMessage({
                  id: 'registration.profileRegistration.placeholders.firstName',
                })}
              />
            </div>
          </div>
        </Field>
        <ErrorList errors={nameErrors} />
      </FormGroup>

      <FormGroup>
        <LabelWithStatus
          required
          valid={validFields.first_name_kana && validFields.last_name_kana}
        >
          {intl.formatMessage({
            id: 'registration.profileRegistration.labels.nameKana',
          })}
        </LabelWithStatus>
        <Field>
          <div className={CSSModule.ParentRegistrationForm__HorizontalInput}>
            <div
              className={CSSModule.ParentRegistrationForm__HorizontalInputItem}
            >
              <Input
                {...registerWithInnerRef('last_name_kana')}
                disabled={inputDisabled}
                placeholder={intl.formatMessage({
                  id: 'registration.profileRegistration.placeholders.lastNameKana',
                })}
              />
            </div>
            <div
              className={CSSModule.ParentRegistrationForm__HorizontalInputItem}
            >
              <Input
                {...registerWithInnerRef('first_name_kana')}
                disabled={inputDisabled}
                placeholder={intl.formatMessage({
                  id: 'registration.profileRegistration.placeholders.firstNameKana',
                })}
              />
            </div>
          </div>
          <ErrorList errors={nameKanaErrors} />
        </Field>
      </FormGroup>

      <FormGroup>
        <LabelWithStatus required valid={validFields.code}>
          {intl.formatMessage({
            id: 'registration.profileRegistration.labels.code',
          })}
        </LabelWithStatus>
        <Field>
          <Input
            {...registerWithInnerRef('code')}
            disabled={inputDisabled}
            placeholder={intl.formatMessage({
              id: 'registration.profileRegistration.placeholders.code',
            })}
          />
          <ErrorList errors={errorMessages.code} />
        </Field>
      </FormGroup>

      <p className={CSSModule.ParentRegistrationForm__Note}>
        <FormattedMessage id="registration.profileRegistration.noteForOrganizationMembershipCode" />
      </p>

      <ServerErrorMessages errors={submitErrors} code={fetchStudentErrorCode} />

      <div className={CSSModule.ParentRegistrationForm__Button}>
        <ButtonWithAgreement disabled={buttonDisabled}>
          <FormattedMessage id="registration.profileRegistration.submitWithAgreement" />
        </ButtonWithAgreement>
      </div>
    </form>
  );
};

export default ParentRegistrationForm;
