import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from 'components/modules/button/Button';

import CSSModule from './EmailConfirmation.module.scss';

const EmailConfirmation: React.FC = () => (
  <div className={CSSModule.EmailConfirmation}>
    <div>
      <h3 className={CSSModule.EmailConfirmation__Title}>
        <FormattedMessage id="forgotPage.forgotPassword.emailConfirmation.title" />
      </h3>
      <div className={CSSModule.EmailConfirmation__Description}>
        <FormattedMessage id="forgotPage.forgotPassword.emailConfirmation.description" />
      </div>
      <Button
        tag={Link}
        to="/login"
        color="outline"
        className={CSSModule.EmailConfirmation__BackToLogin}
      >
        <FormattedMessage id="forgotPage.forgotPassword.emailConfirmation.backToLogin" />
      </Button>
    </div>
  </div>
);

export default EmailConfirmation;
