import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { APP_TITLE } from 'constants/pageTitles';

const DocumentTitle: React.FC = () => (
  <Helmet defaultTitle={APP_TITLE} titleTemplate={`%s | ${APP_TITLE}`} />
);

export default DocumentTitle;
