import { math } from 'polished';
import React from 'react';
import { styled, css } from 'styled-components';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import useInputChangeValue from 'hooks/common/useInputChangeValue';
import usePrevious from 'hooks/common/usePrevious';

type Value = string | null;

export interface OptionObject {
  name: string;
  value: NonNullable<Value>;
}

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  innerRef?: React.Ref<HTMLInputElement>;
}

interface Props {
  placeholder?: string;
  options: OptionObject[];
  onChange?: (val: Value) => void;
  onBlur?: () => void;
  value?: Value;
  inputProps?: InputProps;
  [key: `data-${string}`]: string;
}

const WrapperStyled = styled.div(
  ({ theme }) => css`
    position: relative;
    font-size: ${theme.fontSize.l};
    line-height: 1;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  `,
);

// input hiddenでは各種eventが発火しないため
const HiddenInputStyled = styled.input`
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  appearance: none;
  border: none;
  background: transparent;
  color: transparent;
`;

const HandleStyled = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    height: ${theme.form.input.height.default};
    padding: 0 ${theme.spacing.l};
    border: 1px solid ${theme.globalColor.gray400};
    border-radius: ${theme.borderRadius.default};
    background: ${theme.globalColor.white};

    &[data-is-placeholder='true'] {
      background: ${theme.globalColor.gray50};
      color: ${theme.globalColor.gray400};
    }

    > div:first-child {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ${WrapperStyled}:hover & {
      border: 1px solid ${theme.globalColor.skyBlue600};
    }

    ${WrapperStyled}:focus &,
  &[data-is-open="true"] {
      border: 1px solid ${theme.globalColor.skyBlue600};
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      box-shadow: 0 0 0 1px ${theme.globalColor.skyBlue600};
    }
  `,
);

const ArrowStyled = styled(ArrowDownIcon)(
  ({ theme }) => css`
    width: 10px;
    height: 5px;
    margin-left: ${theme.spacing.l};
  `,
);

const OptionsStyled = styled.div<{ $isOpen?: boolean }>(
  ({ theme, $isOpen = false }) => css`
    display: ${$isOpen ? 'block' : 'none'};
    position: absolute;
    z-index: 1;
    top: ${theme.form.input.height.default};
    left: 0;
    width: calc(100% + 2px);
    max-height: 300px;
    margin-top: -1px;
    margin-left: -1px;
    overflow: hidden scroll;
    border: 2px solid ${theme.globalColor.skyBlue600};
    border-top: none;
    border-radius: ${theme.borderRadius.default};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: ${theme.globalColor.white};
  `,
);

const OptionStyled = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    height: ${math(`${theme.form.input.height.default} - 2px`)};
    padding: 0 ${theme.spacing.l};

    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover {
      background: ${theme.globalColor.skyBlue100};
    }

    &[data-selected='true'] {
      background: ${theme.globalColor.gray100};
    }

    &[data-is-placeholder='true'] {
      background: ${theme.globalColor.gray50};
      color: ${theme.globalColor.gray400};
    }
  `,
);

const Select: React.FC<Props> = ({
  placeholder,
  options,
  onChange,
  onBlur,
  value = null,
  inputProps: { innerRef, ...inputProps } = { innerRef: undefined },
  ...dataAttributes
}) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>();

  const { changeValue } = useInputChangeValue();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const prevIsOpen = usePrevious<boolean>(isOpen);

  const [selectedValue, _setSelectedValue] = React.useState<Value>(value);
  const setSelectedValue = React.useCallback(
    (val: Value) => {
      if (val !== selectedValue) {
        _setSelectedValue(val);

        if (inputRef.current) {
          changeValue(inputRef.current, val);
        }

        onChange && onChange(val);
      }
    },
    [onChange, selectedValue, changeValue],
  );

  const toggle = React.useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleDocumentClick = React.useCallback((ev: Event) => {
    const wrapper = wrapperRef.current;
    const isInWrapper =
      wrapper?.contains(ev.target as Node) && wrapper !== ev.target;
    if (isInWrapper) return;

    setIsOpen(false);
  }, []);

  const controlDocumentHandleClick = React.useCallback(
    (attach: boolean) => {
      ['click', 'touchstart'].forEach((type) =>
        document[attach ? 'addEventListener' : 'removeEventListener'](
          type,
          handleDocumentClick,
          true,
        ),
      );
    },
    [handleDocumentClick],
  );

  React.useEffect(() => {
    if (isOpen) {
      controlDocumentHandleClick(true);
    } else {
      controlDocumentHandleClick(false);
    }

    return () => {
      controlDocumentHandleClick(false);
    };
  }, [isOpen, controlDocumentHandleClick]);

  // 閉じたらinputからblur (blurをトリガーとするRHF等への対応)
  React.useEffect(() => {
    if (prevIsOpen && !isOpen) {
      inputRef.current?.focus();
      inputRef.current?.blur();

      onBlur && onBlur();

      // style上の整合性のため
      wrapperRef.current?.focus();
      wrapperRef.current?.blur();
    }
  }, [isOpen, prevIsOpen, onBlur]);

  // optionsが変化した場合にselectedValueが適正でなければ空にする
  React.useEffect(() => {
    if (selectedValue) {
      if (!options.map((opt) => opt.value).includes(selectedValue)) {
        setSelectedValue(null);
      }
    }
    // optionsはarrayのため同値性比較でfalseになるのでstringifyで同一性比較
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(options), selectedValue]);

  // componentのvalue変化でもselectedValueをset
  React.useEffect(() => {
    setSelectedValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // inputのvalueに値がある場合(componentの切り替えやback時)にSelectの表示側にも値をsetする
  React.useEffect(() => {
    if (inputRef.current?.value) setSelectedValue(inputRef.current.value);
  }, [inputRef.current?.value, setSelectedValue]);

  const handleOnClickOptions = React.useCallback(
    (ev: React.MouseEvent) => {
      const target = (ev.target as HTMLElement).closest('[data-value]');
      if (target) {
        const value = target.getAttribute('data-value') || null;
        setSelectedValue(value);
      }

      setIsOpen(false);
    },
    [setSelectedValue],
  );

  return (
    <WrapperStyled
      ref={wrapperRef}
      tabIndex={0}
      role="listbox"
      {...dataAttributes}
    >
      <HiddenInputStyled
        defaultValue={selectedValue || value || ''}
        {...inputProps}
        readOnly
        type="text"
        ref={(node) => {
          inputRef.current = node || undefined;
          if (typeof innerRef === 'function') {
            innerRef(node);
          } else if (innerRef) {
            (
              innerRef as React.MutableRefObject<HTMLInputElement | null>
            ).current = node;
          }
        }}
      />
      <HandleStyled
        onClick={toggle}
        data-is-placeholder={!selectedValue}
        data-is-open={isOpen}
        aria-label="select-handle"
      >
        <div aria-label="select-selected-option">
          {options.find(({ value }) => value === selectedValue)?.name ||
            placeholder}
        </div>
        <ArrowStyled />
      </HandleStyled>
      <OptionsStyled
        $isOpen={isOpen}
        onClick={handleOnClickOptions}
        aria-label="select-options"
      >
        {selectedValue && placeholder && (
          <OptionStyled data-value="" data-is-placeholder="true">
            {placeholder}
          </OptionStyled>
        )}
        {options.map(({ name, value }) => (
          <OptionStyled
            key={value}
            data-value={value}
            data-selected={value === selectedValue}
          >
            <div>{name}</div>
          </OptionStyled>
        ))}
      </OptionsStyled>
    </WrapperStyled>
  );
};

export default Select;
