import { range } from 'lodash-es';
import React from 'react';
import { styled, css } from 'styled-components';

import Select from 'components/modules/forms/Select';
import useInputChangeValue from 'hooks/common/useInputChangeValue';

type Value = string | null;

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  innerRef?: React.Ref<HTMLInputElement>;
}

interface Props {
  minuteStep?: number;
  inputProps?: InputProps;
}

export const TIME_FORMAT = /^([0-1]\d|2[0-3]):[0-5]\d$/;

const WrapperStyled = styled.div(
  ({ theme }) => css`
    display: flex;
    position: relative;

    > div {
      width: 100%;

      &:not(:first-child) {
        margin-left: ${theme.spacing.l};
      }
    }
  `,
);

// input hiddenでは各種eventが発火しないため
const HiddenInputStyled = styled.input`
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  appearance: none;
  border: none;
  background: transparent;
  color: transparent;
`;

const TimeInputs: React.FC<Props> = ({
  minuteStep = 5,
  inputProps: { innerRef, ...inputProps } = { innerRef: undefined },
}) => {
  const inputRef = React.useRef<HTMLInputElement>();

  const { changeValue } = useInputChangeValue();

  const [hour, setHour] = React.useState<Value>(null);
  const [minute, setMinute] = React.useState<Value>(null);

  const [time, _setTime] = React.useState<Value>(null);
  const setTime = React.useCallback(
    (val: Value) => {
      _setTime(val);
      inputRef.current && changeValue(inputRef.current, val);
    },
    [changeValue],
  );

  const [hourIsDirty, setHourIsDirty] = React.useState<boolean>(false);
  const [minuteIsDirty, setMinuteIsDirty] = React.useState<boolean>(false);

  React.useEffect(() => {
    // 両inputがblurされたらblurする
    if (hourIsDirty && minuteIsDirty) {
      inputRef.current?.focus();
      inputRef.current?.blur();
    }
  }, [hourIsDirty, minuteIsDirty]);

  React.useEffect(() => {
    if (hourIsDirty && minuteIsDirty) {
      setTime(hour || minute ? `${hour || ''}:${minute || ''}` : null);
    }
  }, [hourIsDirty, minuteIsDirty, hour, minute, setTime]);

  // inputのvalueに値がある場合(componentの切り替えやback時)にTimeInputsの表示側にも値をsetする
  React.useEffect(() => {
    if (inputRef.current?.value && !(hourIsDirty && minuteIsDirty)) {
      const [hour, minute] = inputRef.current.value.split(':');
      setHour(hour);
      setMinute(minute);

      setHourIsDirty(true);
      setMinuteIsDirty(true);
    }
  }, [inputRef.current?.value, setTime, hourIsDirty, minuteIsDirty]);

  return (
    <WrapperStyled>
      <Select
        placeholder="例）12時"
        options={range(0, 24).map((i) => ({
          name: `${i}時`,
          value: `${i}`.padStart(2, '0'),
        }))}
        onChange={(val) => {
          setHour(val);
        }}
        onBlur={() => {
          setHourIsDirty(true);
        }}
        value={hour}
      />
      <Select
        placeholder="例）10分"
        options={range(0, 60, minuteStep).map((i) => ({
          name: `${i}分`,
          value: `${i}`.padStart(2, '0'),
        }))}
        onChange={(val) => {
          setMinute(val);
        }}
        onBlur={() => {
          setMinuteIsDirty(true);
        }}
        value={minute}
      />
      <HiddenInputStyled
        defaultValue={time || ''}
        {...inputProps}
        readOnly
        type="text"
        ref={(node) => {
          inputRef.current = node || undefined;
          if (typeof innerRef === 'function') {
            innerRef(node);
          } else if (innerRef) {
            (
              innerRef as React.MutableRefObject<HTMLInputElement | null>
            ).current = node;
          }
        }}
      />
    </WrapperStyled>
  );
};

export default TimeInputs;
