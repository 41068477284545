import React, { useCallback, useEffect, useRef, useState } from 'react';

import CSSModule from './Dropdown.module.scss';

interface DropDownState {
  isOpen: boolean;
  toggle: () => void;
}

interface OwnProps {
  children?: React.ReactNode;
}

export const DropdownContext = React.createContext<DropDownState>(
  {} as DropDownState,
);

const Dropdown: React.FC<OwnProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const toggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, [setIsOpen]);

  const handleDocumentClick = useCallback(
    (ev: Event) => {
      const container = containerRef.current;
      const isInContainer =
        container?.contains(ev.target as Node) && container !== ev.target;
      if (isInContainer) return;

      toggle();
    },
    [toggle],
  );

  useEffect(() => {
    if (isOpen) {
      ['click', 'touchstart'].forEach((type) =>
        document.addEventListener(type, handleDocumentClick, true),
      );
    } else {
      ['click', 'touchstart'].forEach((type) =>
        document.removeEventListener(type, handleDocumentClick, true),
      );
    }

    return () => {
      ['click', 'touchstart'].forEach((type) =>
        document.removeEventListener(type, handleDocumentClick, true),
      );
    };
  }, [isOpen, handleDocumentClick]);

  return (
    <DropdownContext.Provider value={{ isOpen, toggle }}>
      <div className={CSSModule.Dropdown} ref={containerRef}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
};

export default Dropdown;
