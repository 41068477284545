import React, { useEffect, useState } from 'react';

import FileThumbnail from 'components/molecules/common/FileThumbnail';
import useDownloadBlob from 'hooks/common/useDownloadBlob';
import useFetch from 'hooks/common/useFetch';
import { AnnouncementFile } from 'interfaces/announcement';

/* istanbul ignore next */
const blobToDataUrl = (blob: Blob) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });

interface Props {
  className?: string;
  announcement_file: AnnouncementFile;
}

const AnnouncementFileThumbnail: React.FC<Props> = ({
  className,
  announcement_file: { id, original_file_name },
}) => {
  const [src, setSrc] = useState<string | null>(null);

  const { fetch: fetchAnnouncementFileThumbnail, data: thumbnail } =
    useFetch<Blob>(
      'GET',
      `/api/school_communication/announcement_files/${id}/thumbnail`,
      { responseType: 'blob' },
    );

  const { fetch: fetchAnnouncementFileOriginal } = useFetch<Blob>(
    'GET',
    `/api/school_communication/announcement_files/${id}/original`,
    { responseType: 'blob' },
  );

  useEffect(() => {
    void fetchAnnouncementFileThumbnail();
  }, [fetchAnnouncementFileThumbnail]);

  useEffect(() => {
    /* istanbul ignore next */
    if (thumbnail instanceof Blob && thumbnail.size > 0) {
      void (async () => {
        const blobSrc = await blobToDataUrl(thumbnail);
        setSrc(blobSrc as string);
      })();
    }
  }, [thumbnail]);

  const download = useDownloadBlob();

  /* istanbul ignore next */
  const onClick = () => {
    void (async () => {
      const { data } = await fetchAnnouncementFileOriginal();
      if (data.size > 0) {
        download(data, { target: '_blank', rel: 'noreferrer' });
      }
    })();
  };

  return (
    <FileThumbnail
      src={src}
      alt={original_file_name}
      className={className}
      onClick={onClick}
    />
  );
};

export default AnnouncementFileThumbnail;
