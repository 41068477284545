import {
  ErrorBoundary as SentryErrorBoundary,
  FallbackRender,
} from '@sentry/react';
import { startsWith } from 'lodash-es';
import React from 'react';

import ErrorPage from 'components/pages/errors/common/ErrorPage';

interface Props {
  children?: React.ReactNode;
}

const RenderError: FallbackRender = ({ error }) => {
  if (error instanceof Error) {
    if (startsWith(error.message, 'HTTP status code: 403'))
      return <ErrorPage type="forbidden" />;
    if (startsWith(error.message, 'HTTP status code: 404'))
      return <ErrorPage type="not_found" />;
    if (startsWith(error.message, 'HTTP status code: 5'))
      return <ErrorPage type="internal_server_error" />;
  }
  return <ErrorPage />;
};

const ErrorBoundary: React.FC<Props> = ({ children }) => (
  <SentryErrorBoundary fallback={(props) => <RenderError {...props} />}>
    {children}
  </SentryErrorBoundary>
);

export default ErrorBoundary;
