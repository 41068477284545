import Cookies from 'cookie_js';

interface Options {
  remember?: boolean;
}

class CookieStorage {
  private static accessTokenKey = 'parents_web_access_token';
  private static rememberedTokenLifetime = 7; // Days
  private static cookieMetaData = {
    path: '/',
    domain: window.location.host,
  };

  static getAccessToken() {
    return Cookies.get(this.accessTokenKey) || null;
  }

  static setAccessToken(token: string, { remember }: Options = {}) {
    const expires = remember ? this.rememberedTokenLifetime : undefined;

    Cookies.set(this.accessTokenKey, token, {
      ...this.cookieMetaData,
      expires,
    });
  }

  static deleteAccessToken() {
    Cookies.removeSpecific(this.accessTokenKey, this.cookieMetaData);
  }
}

export default CookieStorage;
