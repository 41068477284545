import React from 'react';

import Image from 'assets/errors/403.png';
import ErrorContent from 'components/pages/errors/common/ErrorContent';

interface Props {
  description?: string;
}

const ForbiddenError: React.FC<Props> = ({ description }) => (
  <ErrorContent
    image={Image}
    title="Forbidden: Access is denied"
    subtitle="アクセスが禁止されています"
    description={
      description ||
      '申し訳ありませんがお探しのページへのアクセスは禁止されています。URLをお確かめください。'
    }
  />
);

export default ForbiddenError;
