import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes, useNavigate } from 'react-router-dom';

import ResetPasswordCompletedPage from 'components/pages/password/ResetPasswordCompletedPage';
import ResetPasswordPage from 'components/pages/password/ResetPasswordPage';
import { pageTitles } from 'constants/pageTitles';

const ResetPassword: React.FC = () => {
  const [completed, setCompleted] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (completed) {
      navigate('/password/reset/completed');
    }
  }, [completed, navigate]);

  return (
    <>
      <Helmet>
        <title>{pageTitles.passwordReset}</title>
      </Helmet>
      <Routes>
        <Route
          path=""
          element={<ResetPasswordPage setCompleted={setCompleted} />}
        />
        <Route
          path="completed"
          element={<ResetPasswordCompletedPage completed={completed} />}
        />
      </Routes>
    </>
  );
};

export default ResetPassword;
