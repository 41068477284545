import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { styled, css } from 'styled-components';

import Button from 'components/modules/button/Button';
import ErrorPage from 'components/pages/errors/common/ErrorPage';
import SingleCardTemplate from 'components/templates/SingleCardTemplate';
import { pageTitles } from 'constants/pageTitles';
import useAuthentication from 'hooks/common/useAuthentication';

interface Props {
  completed: boolean;
}

const WrapperStyled = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    ${theme.media.pc} {
      min-height: calc(347px - ${theme.spacing.xl} * 2);
    }

    ${theme.media.sp} {
      min-height: calc(370px - ${theme.spacing.l} * 2);
    }
  `,
);

const InnerStyled = styled.div`
  width: 100%;
`;

const TitleStyled = styled.h3(
  ({ theme }) => css`
    margin: 0 0 ${theme.spacing.m};
    color: ${theme.globalColor.gray700};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.default};
    text-align: center;

    ${theme.media.pc} {
      font-size: ${theme.fontSize.xl};
    }

    ${theme.media.sp} {
      font-size: ${theme.fontSize.l};
    }
  `,
);

const DescriptionStyled = styled.div(
  ({ theme }) => css`
    margin: 0 0 ${theme.spacing.l};
    color: ${theme.globalColor.gray700};
    text-align: center;
    white-space: pre-line;
  `,
);

const ButtonStyled = styled(Button)(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    max-width: ${theme.button.width.m};
    margin: 0 auto;
  `,
);

const ResetPasswordCompletedPage: React.FC<Props> = ({ completed }) => {
  const intl = useIntl();
  const { isLoggedIn } = useAuthentication();

  if (!completed) return <ErrorPage type="not_found" />;

  const buttonLabelId = isLoggedIn
    ? 'resetPasswordCompletedPage.goToMyPage'
    : 'resetPasswordCompletedPage.goToLoginPage';
  const buttonUrl = isLoggedIn ? '/mypage/profile' : '/login';

  return (
    <SingleCardTemplate title={pageTitles.passwordReset}>
      <WrapperStyled>
        <InnerStyled>
          <TitleStyled>
            <FormattedMessage id="resetPasswordCompletedPage.title" />
          </TitleStyled>

          <DescriptionStyled>
            <FormattedMessage
              id="resetPasswordCompletedPage.description"
              values={{
                buttonLabel: intl.formatMessage({ id: buttonLabelId }),
              }}
            />
          </DescriptionStyled>

          <ButtonStyled tag={Link} to={buttonUrl} color="primary">
            <FormattedMessage id={buttonLabelId} />
          </ButtonStyled>
        </InnerStyled>
      </WrapperStyled>
    </SingleCardTemplate>
  );
};

export default ResetPasswordCompletedPage;
