import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import Linkify from 'linkify-react';
import React from 'react';

import DescriptionList from 'components/modules/lists/DescriptionList';
import DescriptionDetails from 'components/modules/lists/descriptionList/DescriptionDetails';
import DescriptionListItem from 'components/modules/lists/descriptionList/DescriptionListItem';
import DescriptionTerm from 'components/modules/lists/descriptionList/DescriptionTerm';

interface OwnProps {
  studentName?: string | null;
  date?: Date | null;
  contactType?: string | null;
  reason?: string | null;
  goToSchoolAt?: string | null;
  leaveSchoolAt?: string | null;
  diseaseName?: string | null;
  symptom?: string | null;
  bodyTemperature?: string | null;
  doctorsInstruction?: string | null;
  relationship?: string | null;
  transportation?: string | null;
  detailComment?: string | null;
}

const LinkifyCustom: React.FC<Parameters<typeof Linkify>[0]> = ({
  children,
  options = {},
  ...props
}) => (
  <Linkify
    options={{
      target: '_blank',
      rel: 'noopener noreferrer',
      validate: {
        url: (value) => /^https?:\/\//.test(value),
        email: false,
      },
      ...options,
    }}
    {...props}
  >
    {children}
  </Linkify>
);

const AbsencesDetailContents: React.FC<OwnProps> = ({
  studentName,
  date,
  contactType,
  reason,
  goToSchoolAt,
  leaveSchoolAt,
  diseaseName,
  symptom,
  bodyTemperature,
  doctorsInstruction,
  relationship,
  transportation,
  detailComment,
}) => (
  <DescriptionList>
    <DescriptionListItem>
      <DescriptionTerm>生徒名</DescriptionTerm>
      <DescriptionDetails>{studentName}</DescriptionDetails>
    </DescriptionListItem>
    <DescriptionListItem>
      <DescriptionTerm>日付</DescriptionTerm>
      <DescriptionDetails>
        {date && format(date, 'yyyy年M月d日(E)', { locale: ja })}
      </DescriptionDetails>
    </DescriptionListItem>
    <DescriptionListItem>
      <DescriptionTerm>内容</DescriptionTerm>
      <DescriptionDetails>{contactType}</DescriptionDetails>
    </DescriptionListItem>
    <DescriptionListItem>
      <DescriptionTerm>理由</DescriptionTerm>
      <DescriptionDetails>{reason}</DescriptionDetails>
    </DescriptionListItem>
    {goToSchoolAt && (
      <DescriptionListItem>
        <DescriptionTerm>登校時刻</DescriptionTerm>
        <DescriptionDetails>{goToSchoolAt}</DescriptionDetails>
      </DescriptionListItem>
    )}
    {leaveSchoolAt && (
      <DescriptionListItem>
        <DescriptionTerm>下校時刻</DescriptionTerm>
        <DescriptionDetails>{leaveSchoolAt}</DescriptionDetails>
      </DescriptionListItem>
    )}
    {diseaseName && (
      <DescriptionListItem>
        <DescriptionTerm>病名</DescriptionTerm>
        <DescriptionDetails>
          <LinkifyCustom>{diseaseName}</LinkifyCustom>
        </DescriptionDetails>
      </DescriptionListItem>
    )}
    {symptom && (
      <DescriptionListItem>
        <DescriptionTerm>症状</DescriptionTerm>
        <DescriptionDetails>
          <LinkifyCustom>{symptom}</LinkifyCustom>
        </DescriptionDetails>
      </DescriptionListItem>
    )}
    {bodyTemperature && (
      <DescriptionListItem>
        <DescriptionTerm>体温</DescriptionTerm>
        <DescriptionDetails>{`${bodyTemperature.toString()} ℃`}</DescriptionDetails>
      </DescriptionListItem>
    )}
    {doctorsInstruction && (
      <DescriptionListItem>
        <DescriptionTerm>医師からの出席停止指示</DescriptionTerm>
        <DescriptionDetails>{doctorsInstruction}</DescriptionDetails>
      </DescriptionListItem>
    )}
    {relationship && (
      <DescriptionListItem>
        <DescriptionTerm>亡くなった方と生徒の関係</DescriptionTerm>
        <DescriptionDetails>
          <LinkifyCustom>{relationship}</LinkifyCustom>
        </DescriptionDetails>
      </DescriptionListItem>
    )}
    {transportation && (
      <DescriptionListItem>
        <DescriptionTerm>交通機関</DescriptionTerm>
        <DescriptionDetails>
          <LinkifyCustom>{transportation}</LinkifyCustom>
        </DescriptionDetails>
      </DescriptionListItem>
    )}
    {detailComment && (
      <DescriptionListItem>
        <DescriptionTerm>詳細コメント</DescriptionTerm>
        <DescriptionDetails>
          <LinkifyCustom
            options={{
              nl2br: true,
            }}
          >
            {detailComment}
          </LinkifyCustom>
        </DescriptionDetails>
      </DescriptionListItem>
    )}
  </DescriptionList>
);

export default AbsencesDetailContents;
