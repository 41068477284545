import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import CSSModule from './MyPageSidebar.module.scss';

const linkList = [
  {
    to: '/mypage/profile',
    labelId: 'mypage.sideBar.labels.accountSettings',
  },
];

const MyPageSidebar: React.FC = () => (
  <aside className={CSSModule.MyPageSidebar}>
    <section className={CSSModule.MyPageSidebar__Item}>
      {linkList.map((linkListItem, i) => (
        <NavLink
          key={i}
          to={linkListItem.to}
          className={({ isActive }) =>
            classNames(
              CSSModule.MyPageSidebar__Link,
              isActive && CSSModule.isActive,
            )
          }
        >
          <FormattedMessage id={linkListItem.labelId} />
        </NavLink>
      ))}
    </section>
  </aside>
);

export default MyPageSidebar;
