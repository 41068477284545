import classNames from 'classnames';
import { noop } from 'lodash-es';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import Dropdown from 'components/modules/dropdown/Dropdown';
import DropdownItem from 'components/modules/dropdown/DropdownItem';
import DropdownMenu from 'components/modules/dropdown/DropdownMenu';
import DropdownToggle from 'components/modules/dropdown/DropdownToggle';

import CSSModule from './Filter.module.scss';

interface Props {
  items: string[];
  initialIndex?: number;
  onChange?: ({ index, item }: { index: number; item: string }) => void;
  CurrentLabel?: React.FC<{
    index: number;
    item: string;
  }>;
  buttonActive?: ({ index, item }: { index: number; item: string }) => boolean;
}

const Filter: React.FC<Props> = ({
  items,
  initialIndex = 0,
  onChange = noop,
  CurrentLabel,
  buttonActive = ({ index }) => index !== 0,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(initialIndex);

  const handleClick =
    (index: number) => (ev: React.MouseEvent<HTMLAnchorElement>) => {
      ev.preventDefault();
      setCurrentIndex(index);

      onChange({ index, item: items[index] });
    };

  return (
    <div className={CSSModule.Filter}>
      <Dropdown>
        <DropdownToggle>
          <a
            href="#"
            className={classNames(CSSModule.Filter__Button, {
              [CSSModule.Filter__Button__Active]: buttonActive({
                index: currentIndex,
                item: items[currentIndex],
              }),
            })}
          >
            <FilterIcon className={CSSModule.Filter__Button__Icon} />
            <FormattedMessage id="filter.button.label" />
          </a>
        </DropdownToggle>
        <DropdownMenu>
          {items.map((item, index) => (
            <DropdownItem
              key={index}
              active={index === currentIndex}
              onClick={handleClick(index)}
            >
              <span>{item}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      <div className={CSSModule.Filter__Current}>
        {CurrentLabel ? (
          <CurrentLabel index={currentIndex} item={items[currentIndex]} />
        ) : (
          <>表示中：{items[currentIndex]}</>
        )}
      </div>
    </div>
  );
};

export default Filter;
