import React from 'react';
import { styled } from 'styled-components';

import Jumbotron from 'components/modules/box/Jumbotron';
import Panel from 'components/modules/box/Panel';
import Paper from 'components/modules/box/Paper';
import SingleColumnTemplate from 'components/templates/SingleColumnTemplate';

interface Props {
  title: string;
  children?: React.ReactNode;
}

const WrapperStyled = styled.div(({ theme }) => ({
  [theme.media.pc]: {
    marginTop: '56px',
  },
}));

const SingleCardTemplate: React.FC<Props> = ({ title, children }) => (
  <SingleColumnTemplate>
    <WrapperStyled>
      <Jumbotron>
        <Paper>
          <Panel title={title} $strongHeader>
            {children}
          </Panel>
        </Paper>
      </Jumbotron>
    </WrapperStyled>
  </SingleColumnTemplate>
);

export default SingleCardTemplate;
