import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { css, styled } from 'styled-components';

import Jumbotron from 'components/modules/box/Jumbotron';
import AbsenceLabel from 'components/modules/labels/AbsenceLabel';
import Pagination from 'components/modules/lists/Pagination';
import { AbsencesIndexPayload } from 'components/pages/absences/AbsencesIndexPage';
import {
  contactTypeNames,
  reasonNames,
} from 'components/pages/absences/constants';
import { ContactType, Reason } from 'components/pages/absences/types';
import { useDateShorter } from 'hooks/absences/useDateShorter';

const InnerStyled = styled.div(({ theme }) => ({
  [theme.media.sp]: {
    marginBottom: theme.spacing.l,
  },
}));

const DateStyled = styled.div(({ theme }) => ({
  '&:not(:last-child)': {
    [theme.media.pc]: {
      marginBottom: theme.spacing.xxxl,
    },
    [theme.media.sp]: {
      marginBottom: theme.spacing.xl,
    },
  },

  'a:hover': {
    textDecoration: 'none',
  },
}));

const DateTextStyled = styled.div(({ theme }) => ({
  fontWeight: theme.fontWeight.semiBold,
  lineHeight: theme.lineHeight.default,
  color: theme.globalColor.gray700,

  [theme.media.pc]: {
    marginBottom: theme.spacing.l,
    fontSize: theme.fontSize.xl,
  },

  [theme.media.sp]: {
    marginBottom: theme.spacing.m,
    fontSize: theme.fontSize.l,
  },
}));

const ListStyled = styled.ul({
  width: '100%',
  height: 'auto',
  margin: '0 auto',
  padding: 0,
});

const AbsenceLogListItemStyled = styled.li(({ theme }) => ({
  background: theme.globalColor.white,
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16)',
  borderRadius: 4,
  listStyle: 'none',
  cursor: 'pointer',

  [theme.media.pc]: {
    marginBottom: theme.spacing.l,
  },

  [theme.media.sp]: {
    marginBottom: theme.spacing.m,
  },
}));

const LinkStyled = styled(Link)(({ theme }) => ({
  display: 'block',

  [theme.media.pc]: {
    padding: theme.spacing.xl,
  },

  [theme.media.sp]: {
    padding: theme.spacing.l,
  },
}));

const TitleStyled = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: theme.spacing.m,
}));

const TitleTextStyled = styled.span(({ theme }) => ({
  fontWeight: theme.fontWeight.semiBold,
  fontSize: theme.fontSize.l,
  lineHeight: theme.lineHeight.default,
  color: theme.globalColor.gray700,
  marginLeft: theme.spacing.m,
}));

const TitleLabelStyled = styled.span(({ theme }) => ({
  fontWeight: theme.fontWeight.light,
  fontSize: theme.fontSize.default,
  lineHeight: theme.lineHeight.default,
  color: theme.globalColor.gray500,
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'flex-end',
}));

const TimeStyled = styled.div(({ theme }) => ({
  fontWeight: theme.fontWeight.light,
  fontSize: theme.fontSize.default,
  lineHeight: theme.lineHeight.default,
  color: theme.globalColor.gray500,
  marginBottom: theme.spacing.m,
}));

const DescriptionStyled = styled.div(
  ({ theme }) => css`
    display: -webkit-box; /* stylelint-disable-line */
    overflow: hidden;
    color: ${theme.globalColor.gray700};
    font-size: ${theme.fontSize.default};
    font-weight: ${theme.fontWeight.light};
    line-height: ${theme.lineHeight.default};
    word-break: break-all;
    -webkit-box-orient: vertical;
    ${[theme.media.pc]}: {
      -webkit-line-clamp: 1;
    }
    ${[theme.media.sp]}: {
      -webkit-line-clamp: 2;
    }
  `,
);

const AbsencesIndexList: React.FC<{
  organizationMembershipId: string;
  payload: AbsencesIndexPayload;
}> = ({ organizationMembershipId, payload: { absences, pagination } }) => {
  const navigate = useNavigate();
  const dateShorter = useDateShorter();
  const handleOnPageChanged = (page: number) => {
    navigate(`/absences/member/${organizationMembershipId}/?page=${page}`);
  };

  const { total_count, limit_value, current_page } = pagination;
  const pagesCount = Math.ceil(total_count / limit_value);

  return (
    <Jumbotron>
      <InnerStyled>
        {absences.map(({ id: absenceId, date, latest_logs: latestLogs }) => (
          <DateStyled key={absenceId}>
            <DateTextStyled>{dateShorter(date)}</DateTextStyled>
            <ListStyled>
              {latestLogs.map(
                ({
                  id: latestLogId,
                  contact_type: contactType,
                  reason,
                  detail_comment: detailComment,
                  go_to_school_at: goToSchoolAt,
                  leave_school_at: leaveSchoolAt,
                  is_created_teacher: isCreatedTeacher,
                }) => (
                  <AbsenceLogListItemStyled key={latestLogId}>
                    <LinkStyled
                      to={`/absences/${absenceId}/type/${
                        contactType as string
                      }`}
                    >
                      <TitleStyled>
                        <AbsenceLabel
                          label={contactTypeNames[contactType as ContactType]}
                        />
                        <TitleTextStyled>
                          {reasonNames[reason as Reason]}
                        </TitleTextStyled>
                        {isCreatedTeacher && (
                          <TitleLabelStyled>学校登録</TitleLabelStyled>
                        )}
                      </TitleStyled>
                      {goToSchoolAt && (
                        <TimeStyled>登校時刻 {goToSchoolAt}</TimeStyled>
                      )}
                      {leaveSchoolAt && (
                        <TimeStyled>下校時刻 {leaveSchoolAt}</TimeStyled>
                      )}
                      <DescriptionStyled>{detailComment}</DescriptionStyled>
                    </LinkStyled>
                  </AbsenceLogListItemStyled>
                ),
              )}
            </ListStyled>
          </DateStyled>
        ))}
      </InnerStyled>
      <Pagination
        initialPage={current_page}
        totalPage={pagesCount}
        onPageChanged={(page) => handleOnPageChanged(page)}
      />
    </Jumbotron>
  );
};

export default AbsencesIndexList;
