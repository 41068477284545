import { isToday, parseISO } from 'date-fns';
import React from 'react';
import { styled, css } from 'styled-components';

import Modal from 'components/atoms/modals/Modal';
import Button from 'components/modules/button/Button';
import { ModalInnerProps } from 'hooks/common/useModal';

interface Props extends ModalInnerProps {
  timeLimit?: string | null;
  postDate?: string;
}

const DescriptionStyled = styled.div(
  ({ theme }) => css`
    ${theme.media.pc} {
      margin-top: ${theme.spacing.l};
      text-align: center;
    }

    ${theme.media.sp} {
      margin-top: ${theme.spacing.m};
    }
  `,
);
const ButtonsStyled = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing.xxl};
  `,
);
const ButtonStyled = styled(Button)(
  ({ theme }) => css`
    ${theme.media.pc} {
      width: 120px;
    }

    &:first-child {
      margin-right: ${theme.spacing.m};
    }
  `,
);

const AbsencesTimeExceededModal: React.FC<Props> = ({
  isOpen,
  toggle,
  timeLimit = '08:30',
  postDate,
}) => {
  let isPostedToday = true;
  if (postDate && typeof postDate === 'string') {
    isPostedToday = isToday(parseISO(postDate));
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} title={'欠席連絡を変更できません'}>
      <DescriptionStyled>
        {isPostedToday ? (
          <>
            当日の欠席連絡の変更は{timeLimit}までです。
            <br />
            {timeLimit}以降は直接学校に問い合わせてください。
          </>
        ) : (
          <>欠席連絡は当日以降の連絡のみ可能です。</>
        )}
      </DescriptionStyled>
      <ButtonsStyled>
        <ButtonStyled size="s" color="outline" onClick={toggle}>
          閉じる
        </ButtonStyled>
      </ButtonsStyled>
    </Modal>
  );
};

export default AbsencesTimeExceededModal;
