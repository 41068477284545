import React from 'react';
import { styled, css } from 'styled-components';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const WrapperStyled = styled.div(
  ({ theme }) => css`
    & + & {
      ${theme.media.pc} {
        margin-top: ${theme.spacing.xl};
      }
      ${theme.media.sp} {
        margin-top: ${theme.spacing.l};
      }
    }
  `,
);

const FormGroup: React.FC<Props> = ({ className, children }) => (
  <WrapperStyled className={className}>{children}</WrapperStyled>
);

export default FormGroup;
