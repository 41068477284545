import classNames from 'classnames';
import React, { useContext } from 'react';

import { DropdownContext } from 'components/modules/dropdown/Dropdown';

import CSSModule from './Dropdown.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const DropdownMenu: React.FC<Props> = ({ className, children }) => {
  const { isOpen } = useContext(DropdownContext);

  return (
    <div
      tabIndex={-1}
      className={classNames(
        CSSModule.DropdownMenu,
        { [CSSModule.DropdownMenu__IsOpen]: isOpen },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default DropdownMenu;
