import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, css } from 'styled-components';

import Modal from 'components/atoms/modals/Modal';
import Button from 'components/modules/button/Button';
import { ModalInnerProps } from 'hooks/common/useModal';

interface Props extends ModalInnerProps {
  url: string;
}

const DescriptionStyled = styled.div(
  ({ theme }) => css`
    ${theme.media.pc} {
      margin-top: ${theme.spacing.l};
      text-align: center;
    }

    ${theme.media.sp} {
      margin-top: ${theme.spacing.m};
    }
  `,
);
const ButtonsStyled = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing.xxl};
  `,
);
const ButtonStyled = styled(Button)(
  ({ theme }) => css`
    ${theme.media.pc} {
      width: 120px;
    }

    &:first-child {
      margin-right: ${theme.spacing.m};
    }
  `,
);

const AbsencesCancelFormModal: React.FC<Props> = ({ isOpen, toggle, url }) => {
  const navigate = useNavigate();

  const onClick = React.useCallback(() => {
    navigate(url);
    toggle();
  }, [navigate, toggle, url]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} title={'本当にキャンセルしますか？'}>
      <DescriptionStyled>
        送信せずに戻ります。よろしいでしょうか？
      </DescriptionStyled>
      <ButtonsStyled>
        <ButtonStyled size="s" color="outline" onClick={toggle}>
          いいえ
        </ButtonStyled>
        <ButtonStyled size="s" color="primary" onClick={onClick}>
          はい
        </ButtonStyled>
      </ButtonsStyled>
    </Modal>
  );
};

export default AbsencesCancelFormModal;
