import React from 'react';

import Jumbotron from 'components/modules/box/Jumbotron';
import JumbotronPanel from 'components/modules/box/JumbotronPanel';

const AbsencesEmpty: React.FC = () => (
  <Jumbotron>
    <JumbotronPanel
      title="まだ欠席連絡が登録されていません"
      description="欠席・遅刻・早退の連絡は下の「欠席連絡」からおこなってください。"
    />
  </Jumbotron>
);

export default AbsencesEmpty;
