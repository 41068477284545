import React from 'react';
import { styled, css } from 'styled-components';

import DescriptionListItem from 'components/modules/lists/descriptionList/DescriptionListItem';

type IDescriptionListItem = React.ReactElement<
  React.ComponentProps<typeof DescriptionListItem>,
  typeof DescriptionListItem
>;

interface Props {
  children?: IDescriptionListItem | IDescriptionListItem[] | React.ReactNode;
}

const ListStyled = styled.ul(
  ({ theme }) => css`
    margin: 0;
    padding: 0;
    color: ${theme.globalColor.gray700};
    font-size: ${theme.fontSize.default};
    list-style: none;
  `,
);

const DescriptionList: React.FC<Props> = ({ children }) => (
  <ListStyled>{children}</ListStyled>
);

export default DescriptionList;
