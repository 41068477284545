import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { initialParentRegistrationFormValues } from 'components/pages/registration/ProfileRegistration';
import useFetch from 'hooks/common/useFetch';
import useModal from 'hooks/common/useModal';
import { ParentRegistrationFormValues } from 'interfaces/parent';
import { Student } from 'interfaces/student';

interface Props {
  code: string;
}

interface FetchStudentResult {
  student: Student;
}

interface FetchStudentError {
  code?: 'token-expired';
  message: string;
}

const useStudentConfirmationModal = ({ code }: Props) => {
  const [values, setValues] = useState(initialParentRegistrationFormValues);
  const location = useLocation();
  const { token } = queryString.parse(location.search);
  const { showModal } = useModal();
  const {
    fetch: fetchStudent,
    isFetching: isFetchingStudent,
    status: fetchStudentStatus,
    error: fetchStudentError,
    data: studentData,
  } = useFetch<FetchStudentResult, FetchStudentError>(
    'GET',
    `/api/school/parents/organization_memberships/${code}`,
  );

  useEffect(() => {
    if (studentData && fetchStudentStatus === 200) {
      showModal('StudentConfirmationModal', {
        student: studentData.student,
        values,
      });
    }
  }, [fetchStudentStatus, studentData, values, showModal]);

  return {
    fetchStudent: (values: ParentRegistrationFormValues) => {
      setValues(values);
      void fetchStudent({
        headers: {
          Authorization: `Bearer ${token as string}`,
          'X-Token-Type': 'email_registration_token',
        },
      });
    },
    isFetchingStudent,
    fetchStudentErrors: !!fetchStudentError && [fetchStudentError.message],
    fetchStudentErrorCode: fetchStudentError?.code,
    fetchStudentStatus,
  };
};

export default useStudentConfirmationModal;
