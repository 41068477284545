import { isArray } from 'lodash-es';
import React from 'react';
import { ValidateResult } from 'react-hook-form';
import { styled, css } from 'styled-components';

import Alert from 'components/modules/alerts/Alert';

type Color = 'warning' | 'danger';

interface Props {
  errors: string[] | null | undefined | ValidateResult[] | React.ReactElement[];
  color?: Color;
  'aria-label'?: string;
  className?: string;
}

const WrapperStyled = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing.m};
  `,
);

const ListStyled = styled.ul(
  ({ theme }) => css`
    margin: 0;
    padding: 0;
    padding-left: calc(${theme.fontSize.default} * 1.5);
    font-size: ${theme.fontSize.default};
    line-height: ${theme.lineHeight.default};
    text-align: left;

    a {
      text-decoration: underline;
    }
  `,
);

const ErrorList = ({
  errors,
  color = 'danger',
  'aria-label': ariaLabel,
  className,
}: Props) => {
  if (!(errors && errors.length > 0)) return null;
  return (
    <WrapperStyled className={className}>
      <Alert color={color} aria-label={ariaLabel}>
        <ListStyled>
          {(errors as string[]).map((error) =>
            isArray(error) ? (
              (error as string[]).map((err) => (
                <li key={err} data-testid="errorItem">
                  {err}
                </li>
              ))
            ) : (
              <li key={error} data-testid="errorItem">
                {error}
              </li>
            ),
          )}
        </ListStyled>
      </Alert>
    </WrapperStyled>
  );
};

export default ErrorList;
