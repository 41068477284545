import { useCallback, useState } from 'react';

import useModal from 'hooks/common/useModal';

const initialFormValues = {
  email: '',
  current_password: '',
};

const useEditEmailModal = () => {
  const [formValues, setFormValues] = useState(initialFormValues);
  const { showModal } = useModal();

  const showEditEmailModal = useCallback(() => {
    showModal('EditEmailModal', {
      onClose: setFormValues,
      initialValues: formValues,
    });
  }, [showModal, setFormValues, formValues]);

  return {
    showEditEmailModal,
    editEmailvalues: formValues,
  };
};

export default useEditEmailModal;
