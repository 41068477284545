/* eslint-disable @typescript-eslint/no-misused-promises */
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import BackToPageButton from 'components/atoms/buttons/BackToPageButton';
import Button from 'components/modules/button/Button';
import ErrorList from 'components/modules/forms/ErrorList';
import Field from 'components/modules/forms/Field';
import FormGroup from 'components/modules/forms/FormGroup';
import Input from 'components/modules/forms/Input';
import LabelWithStatus from 'components/modules/forms/LabelWithStatus';
import useReactHookForm from 'hooks/common/useReactHookForm';
import useStudentModalWithParentAssociation from 'hooks/mypage/useStudentModalWithParentAssociation';
import { ORGANIZATION_MEMBERSHIP_CODE_REGEXP } from 'interfaces/parent';

import CSSModule from './OrganizationMembershipRegistrationForm.module.scss';

interface Props {
  formValues: FormValues;
}

interface FormValues {
  code: string;
}

const OrganizationMembershipRegistrationForm: React.FC<Props> = ({
  formValues,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .required(
        intl.formatMessage(
          { id: 'forms.errors.required' },
          {
            label: intl.formatMessage({
              id: 'mypage.organizationMembershipRegistration.labels.code',
            }),
          },
        ),
      )
      .matches(ORGANIZATION_MEMBERSHIP_CODE_REGEXP, {
        message: intl.formatMessage({
          id: 'mypage.organizationMembershipRegistration.errors.invalidCode',
        }),
        excludeEmptyString: true,
      }),
  });

  const {
    registerWithInnerRef,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid, validFields, errorMessages },
    getValues,
  } = useReactHookForm<FormValues>({
    validationSchema,
    defaultValues: formValues,
  });

  const { fetchStudent, isFetchingStudent, fetchStudentErrors } =
    useStudentModalWithParentAssociation({
      code: getValues().code,
    });

  const inputDisabled = isSubmitting || isFetchingStudent;
  const buttonDisabled =
    !isDirty || !isValid || isSubmitting || isFetchingStudent;

  const onGoBack = () => navigate(-1);
  const submitErrors = fetchStudentErrors || [];

  return (
    <form
      onSubmit={handleSubmit(() => void fetchStudent())}
      className={CSSModule.OrganizationMembershipRegistrationForm}
    >
      <div
        className={
          CSSModule.OrganizationMembershipRegistrationForm__Description
        }
      >
        <FormattedMessage id="mypage.organizationMembershipRegistration.description" />
      </div>
      <FormGroup>
        <LabelWithStatus required valid={validFields.code}>
          {intl.formatMessage({
            id: 'mypage.organizationMembershipRegistration.labels.code',
          })}
        </LabelWithStatus>
        <Field>
          <Input
            {...registerWithInnerRef('code')}
            disabled={inputDisabled}
            placeholder={intl.formatMessage({
              id: 'mypage.organizationMembershipRegistration.placeholders.code',
            })}
          />
          <ErrorList errors={errorMessages.code} />
        </Field>
      </FormGroup>
      {submitErrors.length > 0 && <ErrorList errors={submitErrors} />}
      <Button
        type="submit"
        color="primary"
        disabled={buttonDisabled}
        className={CSSModule.OrganizationMembershipRegistrationForm__Button}
      >
        <FormattedMessage id="mypage.organizationMembershipRegistration.nextButton" />
      </Button>
      <BackToPageButton
        onClick={onGoBack}
        className={
          CSSModule.OrganizationMembershipRegistrationForm__BackToPageButton
        }
      >
        <FormattedMessage id="mypage.organizationMembershipRegistration.backToPageButton" />
      </BackToPageButton>
    </form>
  );
};

export default OrganizationMembershipRegistrationForm;
