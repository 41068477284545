import React from 'react';
import { styled } from 'styled-components';

import Panel from 'components/modules/box/Panel';
import Paper from 'components/modules/box/Paper';
import Button from 'components/modules/button/Button';

export interface JumbotronPanelProps {
  header?: string;
  title: string;
  description?: React.ReactNode;
  buttonLabel?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

const TitleStyled = styled.h2(({ theme }) => ({
  '&&&': {
    margin: `0 0 ${theme.spacing.xl}`,
    fontSize: theme.fontSize.xl,
    fontWeight: theme.fontWeight.bold,
    lineHeight: theme.lineHeight.default,

    [theme.media.sp]: {
      fontSize: theme.fontSize.l,
    },
  },
}));

const DescriptionStyled = styled.div(({ theme }) => ({
  '&&&': {
    margin: 0,
    fontSize: theme.fontSize.default,
    lineHeight: theme.lineHeight.default,
    textAlign: 'center',
  },
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  '&&&': {
    marginTop: theme.spacing.l,

    [theme.media.sp]: {
      width: '100%',
    },
  },
}));

const JumbotronPanel: React.FC<JumbotronPanelProps> = ({
  header,
  title,
  description,
  buttonLabel,
  onClick,
}) => (
  <Paper flex flexItemWide>
    <Panel title={header} $strongHeader $centerBody>
      <TitleStyled>{title}</TitleStyled>
      <DescriptionStyled>{description}</DescriptionStyled>
      {buttonLabel && (
        <ButtonStyled onClick={onClick} size="m" color="primary">
          {buttonLabel}
        </ButtonStyled>
      )}
    </Panel>
  </Paper>
);

export default JumbotronPanel;
