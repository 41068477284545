import { rgba } from 'polished';

const globalColor = {
  white: '#fff',
  black: '#000',
  gray50: '#fafafa',
  gray100: '#e9eeef',
  gray200: '#dae2e5',
  gray300: '#c7d2d6',
  gray400: '#adb8be',
  gray500: '#808d96',
  gray600: '#67717a',
  gray700: '#4c5263',
  gray800: '#363a4d',
  gray900: '#24243f',
  darkBlue50: '#f7faff',
  darkBlue100: '#e0edfa',
  darkBlue200: '#c5d5f0',
  darkBlue300: '#8eaee5',
  darkBlue400: '#5a85d1',
  darkBlue500: '#436fba',
  darkBlue600: '#275ab3',
  darkBlue700: '#0b41a0',
  darkBlue800: '#093788',
  darkBlue900: '#052a6b',
  ultraBlue50: '#f7fbff',
  ultraBlue100: '#e5f2ff',
  ultraBlue200: '#cfe5fc',
  ultraBlue300: '#9bcafa',
  ultraBlue400: '#69aff5',
  ultraBlue500: '#429bf5',
  ultraBlue600: '#1c80e7',
  skyBlue50: '#f0f8ff',
  skyBlue100: '#e1f4ff',
  skyBlue200: '#caedfc',
  skyBlue300: '#9bddfa',
  skyBlue400: '#69cbf5',
  skyBlue500: '#30b6f0',
  skyBlue600: '#0ca5e6',
  skyBlue700: '#0c8dd8',
  skyBlue800: '#0c73c3',
  skyBlue900: '#1762a3',
  turquoise50: '#f0fafa',
  turquoise100: '#d8f3f2',
  turquoise200: '#c5ebea',
  turquoise300: '#a4e0df',
  turquoise400: '#85d6d4',
  turquoise500: '#64cdca',
  turquoise600: '#3ec1bd',
  turquoise700: '#33aab2',
  turquoise800: '#2692a3',
  turquoise900: '#1a7d91',
  yellow50: '#fffceb',
  yellow100: '#f8f1cc',
  yellow200: '#f2e5a0',
  yellow300: '#f0dc75',
  yellow400: '#e7cc4c',
  yellow500: '#ddb700',
  yellow600: '#c1a000',
  yellow700: '#ad9702',
  yellow800: '#968503',
  yellow900: '#807305',
  pink50: '#fff7fe',
  pink100: '#fcedfa',
  pink200: '#fadef7',
  pink300: '#f7cbf2',
  pink400: '#f0a3e7',
  pink500: '#eb78dd',
  pink600: '#e352d2',
  pink700: '#de30ca',
  pink800: '#c227c4',
  pink900: '#a52eb3',
  purple50: '#fdfaff',
  purple100: '#faefff',
  purple200: '#eedaf7',
  purple300: '#e5c1f5',
  purple400: '#d9a3f0',
  purple500: '#ce85ed',
  purple600: '#c06ae5',
  purple700: '#b551e0',
  purple800: '#aa30de',
  purple900: '#8933c1',
  pinkRed50: '#fffafc',
  pinkRed100: '#fef2f7',
  pinkRed200: '#fae1eb',
  pinkRed300: '#f7d0e0',
  pinkRed400: '#f5b8d0',
  pinkRed500: '#f598bd',
  pinkRed600: '#f56ca3',
  pinkRed700: '#f0307d',
  pinkRed800: '#e0005a',
  pinkRed900: '#bd004b',
  orange50: '#fffaf5',
  orange100: '#fff1e0',
  orange200: '#fce0c0',
  orange300: '#fac78c',
  orange400: '#fab361',
  orange500: '#f59931',
  orange600: '#f08000',
  orange700: '#d17000',
  orange800: '#b05e00',
  orange900: '#995406',
  green50: '#ebfff5',
  green100: '#d2fce7',
  green200: '#bcf7da',
  green300: '#8ff2c1',
  green400: '#52e39a',
  green500: '#27d980',
  green600: '#04c464',
  green700: '#0ba357',
  green800: '#0d8248',
  green900: '#0f6b3d',
  yellowGreen50: '#fafff0',
  yellowGreen100: '#f5ffe0',
  yellowGreen200: '#e8fcc0',
  yellowGreen300: '#d8fa93',
  yellowGreen400: '#bdf54e',
  yellowGreen500: '#a3eb13',
  yellowGreen600: '#90d604',
  yellowGreen700: '#7ab800',
  yellowGreen800: '#679903',
  yellowGreen900: '#537a05',
  greenYellow50: '#ecffeb',
  greenYellow100: '#d5fcd2',
  greenYellow200: '#c1f7bc',
  greenYellow300: '#97f28f',
  greenYellow400: '#5ee352',
  greenYellow500: '#36d927',
  greenYellow600: '#14c404',
  greenYellow700: '#18a30b',
  greenYellow800: '#17820d',
  greenYellow900: '#176b0f',
  violet50: '#fbfaff',
  violet100: '#f4f2fc',
  violet200: '#e8e4fb',
  violet300: '#d3cbf7',
  violet400: '#b5a6f5',
  violet500: '#8e7aeb',
  violet600: '#7856f0',
  violet700: '#6036e0',
  violet800: '#4f18c7',
  violet900: '#440da8',
  blueGreen50: '#f5fdff',
  blueGreen100: '#e4f6fb',
  blueGreen200: '#ceedf5',
  blueGreen300: '#ade2f0',
  blueGreen400: '#83d6eb',
  blueGreen500: '#5bc0d9',
  blueGreen600: '#44adc7',
  blueGreen700: '#2997b2',
  blueGreen800: '#177d96',
  blueGreen900: '#08708a',
};

const media = {
  sp: '@media all and (max-width: 639px)',
  pc: '@media all and (min-width: 640px)',
  large_pc: '@media all and (min-width: 920px)',
};

const fontSize = {
  xs: '10px',
  s: '12px',
  default: '14px',
  l: '16px',
  xl: '20px',

  errorTitlePc: '32px',
  errorSubtitlePc: '24px',
  errorTitleSp: '20px',
  errorSubtitleSp: '16px',
};

const lineHeight = {
  s: '1.2',
  default: '1.5',
  l: '1.67',
  xl: '1.71',
};

const fontWeight = {
  thin: 100,
  extraLight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
  extraBlack: 950,
};

const size = {
  s: '4px',
  m: '8px',
  l: '16px',
  xl: '24px',
  xxl: '32px',
  xxxl: '40px',
};

const spacing = {
  s: '4px',
  m: '8px',
  l: '16px',
  xl: '24px',
  xxl: '32px',
  xxxl: '40px',
};

const borderRadius = {
  default: '4px',
  medium: '6px',
};

const zIndex = {
  high: 10,
  veryHigh: 20,
};

const icon = {
  size: {
    s: '24px',
    m: '48px',
    l: '72px',
  },
};

const alert = {
  color: {
    primary: globalColor.skyBlue600,
    primaryMinus: globalColor.skyBlue100,
    success: globalColor.turquoise600,
    successMinus: globalColor.turquoise100,
    warning: globalColor.pink700,
    danger: globalColor.pinkRed800,
    dangerMinus: globalColor.pinkRed100,
  },
};

const button = {
  width: {
    s: '128px',
    m: '300px',
    l: '400px',
  },
  widthSp: {
    s: '168px',
  },
  height: {
    s: '40px',
    m: '48px',
    l: '64px',
  },
  heightSp: {
    s: '40px',
    m: '48px',
    l: '48px',
  },
  backgroundColor: {
    primary: globalColor.skyBlue600,
    danger: globalColor.pinkRed800,
    outline: globalColor.white,
    link: 'transparent',
    active: {
      primary: globalColor.skyBlue500,
      danger: globalColor.pinkRed700,
      outline: globalColor.white,
      link: 'transparent',
    },
    disabled: globalColor.gray100,
  },
  border: {
    primary: 'none',
    danger: 'none',
    outline: `1px solid ${globalColor.gray200}`,
    link: 'none',
    disabled: 'none',
  },
  boxShadow: {
    primary: '0px 4px 5px rgba(0, 0, 0, 0.08)',
    danger: '0px 4px 5px rgba(0, 0, 0, 0.08)',
    outline: 'none',
    link: 'none',
  },
  color: {
    primary: globalColor.white,
    danger: globalColor.white,
    outline: globalColor.gray700,
    link: globalColor.gray500,
    disabled: globalColor.gray500,
  },
};

const boxShadow = {
  default: `0 1px 4px 1px ${rgba(globalColor.gray500, 0.15)}`,
};

const paperBoxShadow = {
  default: `0 0 2px 0 ${rgba(globalColor.black, 0.16)}`,
};

const others = {
  header: {
    width: '1200px',
  },
  label: {
    width: '40px',
    height: '24px',
  },
  badge: {
    size: '16px',
  },
  navItem: {
    width: '72px',
    height: '56px',
    iconSize: '22px',
  },
  jumbotron: {
    width: '780px',
    height: '464px',
    heightSp: '368px',
  },
  jumbotronPanel: {
    header: {
      height: '56px',
      heightSp: '36px',
    },
  },
};

const form = {
  input: {
    width: {
      normal: '504px',
    },
    height: {
      default: '48px',
    },

    padding: {
      vertical: '12px',
      horizontal: spacing.l,
    },

    bgColor: {
      default: globalColor.gray50,
      focus: globalColor.white,
      disabled: globalColor.gray100,
      static: globalColor.gray50,
    },

    borderColor: {
      default: globalColor.gray400,
      hover: globalColor.skyBlue600,
      focus: globalColor.skyBlue600,
      disabled: globalColor.gray100,
      static: globalColor.gray50,
    },

    color: {
      placeholder: globalColor.gray400,
      default: globalColor.gray700,
      hover: globalColor.skyBlue600,
      disabled: globalColor.gray400,
      static: globalColor.gray700,
    },

    fontSize: {
      default: fontSize.l,
    },
  },
};

const inputStyles = {
  default: `
    transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
    max-width: 100%;
    padding: ${form.input.padding.vertical} ${form.input.padding.horizontal};
    background: ${form.input.bgColor.default};
    border: 1px solid ${form.input.borderColor.default};
    border-radius: ${borderRadius.default};
    color: ${form.input.color.default};
    font-size: ${form.input.fontSize.default};
    line-height: ${lineHeight.default};

    &::-ms-clear {
      visibility: hidden;
      width: 0;
    }

    &::-ms-reveal {
      visibility: hidden;
      width: 0;
    }

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px ${globalColor.white} inset;
    }
  `,
  placeholder: `
    color: ${form.input.color.placeholder};
  `,
  hover: `
    border: 1px solid ${form.input.color.hover};
  `,
  focus: `
    outline: none;
    border: 1px solid ${form.input.borderColor.focus};
    background-color: ${form.input.bgColor.focus};
    box-shadow: 0 0 0 1px ${form.input.borderColor.focus};

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px ${globalColor.white} inset, 0 0 0 1px ${globalColor.skyBlue600};
      box-shadow: 0 0 0 1px ${globalColor.skyBlue600};
    }
  `,
  disabled: `
    border: 1px solid ${form.input.borderColor.disabled};
    background-color: ${form.input.bgColor.disabled};
    color: ${form.input.color.disabled};

    // for safari
    // SEE: https://qiita.com/gyarasu/items/eb0c241d122753fe1f8d
    -webkit-text-fill-color: ${form.input.color.disabled};
    opacity: 1;

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px ${form.input.bgColor.disabled} inset;
    }

  `,
  static: `
    border-color: ${form.input.borderColor.static};
    background: ${form.input.bgColor.static};

    &, &:disabled {
      color: ${form.input.color.static};

      // for safari
      // SEE: https://qiita.com/gyarasu/items/eb0c241d122753fe1f8d
      -webkit-text-fill-color: ${form.input.color.static};
      opacity: 1;
    }

  `,
  fill: `
    background: ${form.input.bgColor.focus};
  `,
};

const theme = {
  media,
  fontSize,
  globalColor,
  lineHeight,
  fontWeight,
  size,
  spacing,
  borderRadius,
  zIndex,
  icon,
  alert,
  button,
  boxShadow,
  paperBoxShadow,
  form: { ...form, input: { ...form.input, styles: inputStyles } },
  ...others,
} as const;

export default theme;
