import React from 'react';

import Label from 'components/modules/forms/Label';
import StatusBadge, { Status } from 'components/modules/forms/StatusBadge';

interface Props {
  children: React.ReactNode;
  valid?: boolean;
  required?: boolean;
}

const getStatus = (valid: boolean, required: boolean): Status =>
  valid ? 'ok' : required ? 'required' : 'optional';

const LabelWithStatus: React.FC<Props> = ({
  children,
  valid = false,
  required = false,
}) => (
  <Label prefix={<StatusBadge status={getStatus(valid, required)} />}>
    {children}
  </Label>
);

export default LabelWithStatus;
