import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { styled, css } from 'styled-components';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import GradeClassNumberLabel from 'components/modules/formats/GradeClassNumberLabel';
import DescriptionList from 'components/modules/lists/DescriptionList';
import DescriptionDetails from 'components/modules/lists/descriptionList/DescriptionDetails';
import DescriptionListItem from 'components/modules/lists/descriptionList/DescriptionListItem';
import DescriptionTerm from 'components/modules/lists/descriptionList/DescriptionTerm';
import { ParentWithStudents } from 'interfaces/parent';

interface Props {
  parent: ParentWithStudents;
}

const WrapperStyled = styled.div(
  ({ theme }) => css`
    ${theme.media.sp} {
      margin: -${theme.spacing.l};
    }
  `,
);

const DescriptionListItemStyled = styled(DescriptionListItem)(
  ({ theme }) => css`
    ${theme.media.sp} {
      && {
        margin: 0;
        padding: ${theme.spacing.xl} ${theme.spacing.l};
        border-bottom: 1px solid ${theme.globalColor.gray100};
      }
    }
  `,
);

const StudentStyled = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.m};

    &:last-of-type {
      margin-bottom: ${theme.spacing.xl};
    }
  `,
);

const AddCodeLinkStyled = styled(Link)(
  ({ theme }) => css`
    display: flex;
    width: 150px;
    color: ${theme.globalColor.skyBlue600};

    > svg {
      width: ${theme.icon.size.s};
      height: ${theme.icon.size.s};
    }

    > p {
      font-weight: ${theme.fontWeight.bold};
    }
  `,
);

const ParentProfile: React.FC<Props> = ({ parent }) => (
  <WrapperStyled>
    <DescriptionList>
      <DescriptionListItemStyled>
        <DescriptionTerm>
          <FormattedMessage id="mypage.profilePage.labels.name" />
        </DescriptionTerm>
        <DescriptionDetails>{`${parent.last_name} ${parent.first_name}`}</DescriptionDetails>
      </DescriptionListItemStyled>
      <DescriptionListItemStyled>
        <DescriptionTerm>
          <FormattedMessage id="mypage.profilePage.labels.nameKana" />
        </DescriptionTerm>
        <DescriptionDetails>{`${parent.last_name_kana} ${parent.first_name_kana}`}</DescriptionDetails>
      </DescriptionListItemStyled>
      <DescriptionListItemStyled>
        <DescriptionTerm>
          <FormattedMessage id="mypage.profilePage.labels.email" />
        </DescriptionTerm>
        <DescriptionDetails>{parent.email}</DescriptionDetails>
      </DescriptionListItemStyled>
      <DescriptionListItemStyled>
        <DescriptionTerm>
          <FormattedMessage id="mypage.profilePage.labels.password" />
        </DescriptionTerm>
        <DescriptionDetails>
          <FormattedMessage id="mypage.profilePage.values.dummyPassword" />
        </DescriptionDetails>
      </DescriptionListItemStyled>
      <DescriptionListItemStyled>
        <DescriptionTerm>
          <FormattedMessage id="mypage.profilePage.labels.code" />
        </DescriptionTerm>
        <DescriptionDetails>
          {parent.organization_memberships.map((student) => (
            <StudentStyled key={student.id}>
              <div>{student.code}</div>
              <div>
                {student.organization_name}
                &nbsp;
                <GradeClassNumberLabel
                  gradeLabel={student.grade}
                  classNameLabel={student.class_name}
                  attendanceNumberLabel={student.attendance_number}
                />
                &nbsp;
                {student.name}
              </div>
            </StudentStyled>
          ))}
          <AddCodeLinkStyled to="/mypage/organization_memberships/new">
            <PlusIcon />
            <p>
              <FormattedMessage id="mypage.profilePage.addStudent" />
            </p>
          </AddCodeLinkStyled>
        </DescriptionDetails>
      </DescriptionListItemStyled>
    </DescriptionList>
  </WrapperStyled>
);

export default ParentProfile;
