import { toNumber } from 'lodash-es';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from 'styled-components';

import PageTitle from 'components/modules/headers/PageTitle';
import AbsencesDetailBody from 'components/pages/absences/detail/AbsencesDetailBody';
import AbsencesDetailFooter from 'components/pages/absences/detail/AbsencesDetailFooter';
import AbsencesEmpty from 'components/pages/absences/parts/AbsencesEmpty';
import AbsencesRestricted from 'components/pages/absences/parts/AbsencesRestricted';
import { ContactType, Reason } from 'components/pages/absences/types';
import InternalServerError from 'components/pages/errors/InternalServerError';
import ErrorPage from 'components/pages/errors/common/ErrorPage';
import SingleColumnTemplate from 'components/templates/SingleColumnTemplate';
import UiStackTemplate from 'components/templates/UiStackTemplate';
import useEditableChecker from 'hooks/absences/useEditableChecker';
import useTimeExceededChecker from 'hooks/absences/useTimeExceededChecker';
import useFetch from 'hooks/common/useFetch';
import useModal from 'hooks/common/useModal';

interface Absence {
  id: number;
  date: string;
  organization_membership_id: string;
}

type AbsenceLogStatus = 'created' | 'updated' | 'canceled';

interface AbsenceLog {
  id: number;
  status: AbsenceLogStatus;
  contact_type: ContactType;
  reason: Reason;
  detail_comment: string;
  go_to_school_at: string | null;
  leave_school_at: string | null;
  disease_name: string | null;
  symptom: string | null;
  body_temperature: string | null;
  doctors_instruction: boolean | null;
  relationship: string | null;
  transportation: string | null;
  is_created_teacher: boolean;
}

export interface OrganizationManegement {
  allowed_absence: boolean;
  absence_time_limit: string | null;
}

export interface AbsenceDetailPayload {
  absence: Absence;
  absence_log: AbsenceLog;
  organization_management?: OrganizationManegement;
}

type DetailState =
  | 'ok'
  | 'restricted'
  | 'forbidden'
  | 'notFound'
  | 'serverError'
  | null;

const WrapperStyled = styled.div({
  height: '100%',
  display: 'flex',
  marginBottom: 0,
  flexDirection: 'column',
});

const InnerStyled = styled.div(({ theme }) => ({
  width: '100%',
  maxWidth: 780,

  [theme.media.pc]: {
    margin: `${theme.spacing.xxxl} auto 0`,
  },
  [theme.media.sp]: {
    margin: `${theme.spacing.l} auto 0`,
  },
}));

const AbsencesDetailPage: React.FC = () => {
  const [detailState, setDetailState] = React.useState<DetailState>(null);
  const navigate = useNavigate();
  const { absenceId = '', contactType = '' } = useParams();
  const { showModal } = useModal();
  const { isTimeExceededWithLimit } = useTimeExceededChecker();
  const { isEditable } = useEditableChecker();

  const {
    fetch: fetchAbsence,
    isFetching,
    status,
    data: payload,
  } = useFetch<AbsenceDetailPayload>(
    'GET',
    `/api/school_communication/absences/${absenceId}/${contactType}`,
  );

  const handleOnclickBack = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(
      `/absences/member/${payload?.absence.organization_membership_id || ''}`,
    );
  };

  useEffect(() => {
    setDetailState(null);
    void fetchAbsence();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnRightLinkClick = (e: React.MouseEvent) => {
    e.preventDefault();

    isTimeExceededWithLimit(
      payload?.absence.date,
      payload?.organization_management?.absence_time_limit,
    )
      ? showModal('AbsencesTimeExceededModal', {
          timeLimit: payload?.organization_management?.absence_time_limit,
          postDate: payload?.absence.date,
        })
      : navigate(`/absences/${absenceId}/type/${contactType}/edit`);
  };

  React.useEffect(() => {
    setDetailState(null);
    const statusCode = toNumber(status);
    if (!isFetching) {
      if (statusCode === 200) {
        return setDetailState('ok');
      }

      if (statusCode === 423) {
        return setDetailState('restricted');
      }

      if (statusCode === 403) {
        return setDetailState('forbidden');
      }

      if (statusCode >= 400 && statusCode < 500) {
        return setDetailState('notFound');
      }

      if (statusCode >= 500 && statusCode < 600) {
        return setDetailState('serverError');
      }

      return setDetailState(null);
    }
  }, [status, isFetching]);

  const renderSwitch: (
    state: DetailState,
    payload: AbsenceDetailPayload | null,
  ) => React.ReactNode = (state: DetailState) => {
    if (state === 'ok') {
      if (payload) {
        return <AbsencesDetailBody payload={payload} />;
      }

      return <AbsencesEmpty />;
    }

    if (state === 'serverError') {
      return <InternalServerError />;
    }

    if (state === 'restricted') {
      return <AbsencesRestricted />;
    }

    return null;
  };

  return detailState === 'notFound' ? (
    <ErrorPage type="not_found" />
  ) : detailState === 'forbidden' ? (
    <ErrorPage type="forbidden" />
  ) : (
    <SingleColumnTemplate>
      <WrapperStyled>
        {detailState === 'ok' && !isFetching ? (
          <PageTitle
            title="欠席連絡詳細"
            backLinkLabel="欠席連絡一覧"
            onBackLinkClick={handleOnclickBack}
            rightLinkLabel={isEditable(payload?.absence.date) ? '編集' : null}
            onRightLinkClick={handleOnRightLinkClick}
          />
        ) : detailState === 'serverError' ? null : (
          <PageTitle
            title="欠席連絡詳細"
            backLinkLabel="欠席連絡一覧"
            onBackLinkClick={handleOnclickBack}
          />
        )}
        <UiStackTemplate isLoading={isFetching}>
          <InnerStyled>{renderSwitch(detailState, payload)}</InnerStyled>
        </UiStackTemplate>
        {detailState === 'ok' && !isFetching && (
          <AbsencesDetailFooter
            date={payload?.absence.date}
            onBack={handleOnclickBack}
            organizationMembershipId={
              payload?.absence.organization_membership_id
            }
            organizationManagement={payload?.organization_management}
          />
        )}
      </WrapperStyled>
    </SingleColumnTemplate>
  );
};

export default AbsencesDetailPage;
