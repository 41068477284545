/* eslint-disable @typescript-eslint/no-unsafe-argument */
import * as Sentry from '@sentry/browser';
import { isEmpty } from 'lodash-es';
import queryString from 'query-string';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { accessTokenStorage } from '../../utils/AccessTokenStorage';
import { useDarklaunchV2VariationWithoutIdentifier } from '../useDarklaunchV2';

import useAuthentication from './useAuthentication';
import useError, { ErrorResponse as CommonErrorResponse } from './useError';

type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
type RequestHeaders = { [key: string]: string };
type RequestBody = { [key: string]: unknown };
type CustomHeaders = {
  'X-Token-Type'?:
    | 'access_token'
    | 'email_registration_token'
    | 'password_change_token';
};

interface SendRequestOptions {
  body?: RequestBody;
  headers?: RequestHeaders & CustomHeaders;
}

interface SendRequestReturnType<R> {
  data: R;
  status: Response['status'];
}

interface Result<R, E> {
  fetch: (params?: SendRequestOptions) => Promise<SendRequestReturnType<R>>;
  reset: () => void;
  isFetching: boolean;
  error: E | null;
  data: R | null;
  status: number | null;
  headers: Headers | null;
}

const buildRequestHeaders = (
  headers: RequestHeaders,
  accessToken: string | null,
): RequestHeaders & CustomHeaders => {
  const commonHeaders = {
    'Content-Type': 'application/json',
  };

  if (accessToken) {
    return {
      'X-Token-Type': 'access_token',
      Authorization: `Bearer ${accessToken}`,
      ...commonHeaders,
      ...headers,
    };
  } else {
    return {
      ...commonHeaders,
      ...headers,
    };
  }
};

const buildRequestPayload = (
  method: HTTPMethod,
  url: string,
  params: unknown,
  headers?: RequestHeaders,
): Parameters<typeof fetch> => {
  if (method === 'GET' || method === 'DELETE') {
    const requestUrl = isEmpty(params)
      ? url
      : `${url}?${queryString.stringify(
          params as Parameters<typeof queryString.stringify>[0],
        )}`;
    return [
      requestUrl,
      {
        method,
        headers,
      },
    ];
  } else {
    return [
      url,
      {
        method,
        headers,
        body: JSON.stringify(params),
      },
    ];
  }
};

const isJSON = (text: string) => {
  try {
    JSON.parse(text);
    return true;
  } catch {
    return false;
  }
};

const SENTRY_WHITELIST_KEYS = ['terms_set_id', 'consent_id'];

interface ErrorResponse {
  message: string;
}

const errorResponse = {
  text: async () => '',
  status: 500,
  ok: false,
  headers: undefined,
} as Partial<Response>;

type ResponseType = 'text' | 'blob';

interface FetchOptions {
  responseType: ResponseType;
}

const defaultFetchOptions: FetchOptions = {
  responseType: 'text',
};

const useFetch = <R, E extends Response | ErrorResponse = ErrorResponse>(
  method: HTTPMethod,
  url: string,
  optionsArg: Partial<FetchOptions> = {},
): Result<R, E> => {
  const options: FetchOptions = { ...defaultFetchOptions, ...optionsArg };

  const intl = useIntl();
  const { variation: isEnableSchoolCommParentsAuthV2 } =
    useDarklaunchV2VariationWithoutIdentifier(
      'enable-school-comm-parents-auth-v2',
    );

  const { refreshAccessToken } = useAuthentication();
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState<R | null>(null);
  const [error, setError] = useState<E | null>(null);
  const [status, setStatus] = useState<number | null>(null);
  const [responseHeaders, setResponseHeaders] = useState<Headers | null>(null);
  const { unauthLogout } = useError();

  const reset = useCallback(() => {
    setError(null);
    setStatus(null);
    setData(null);
    setResponseHeaders(null);
  }, [setData, setError, setStatus, setResponseHeaders]);

  const sendRequest = useCallback(
    async ({ body, headers }: SendRequestOptions = {}): Promise<
      SendRequestReturnType<R>
    > => {
      setIsFetching(true);
      reset();

      const requestHeaders = buildRequestHeaders(
        headers || {},
        accessTokenStorage.get(),
      );
      const payload = buildRequestPayload(method, url, body, requestHeaders);
      const response = (await fetch(...payload).catch(
        () => errorResponse,
      )) as Response;

      let data: R = {} as R;

      if (response.status === 401) {
        if (isEnableSchoolCommParentsAuthV2) {
          try {
            await refreshAccessToken();
            return sendRequest({ body, headers });
          } catch (e) {
            if ((e as CommonErrorResponse).status === 401) {
              unauthLogout();
            } else {
              const errorResponse: ErrorResponse = {
                message: intl.formatMessage({
                  id: 'forms.errors.unexpected',
                }),
              };
              setError(errorResponse as any); // eslint-disable-line @typescript-eslint/no-explicit-any
            }
          }
        } else {
          unauthLogout();
        }
      } else if (!response.ok) {
        const textBody = await response.text();

        if (isJSON(textBody)) {
          const { error } = JSON.parse(textBody); // eslint-disable-line @typescript-eslint/no-unsafe-assignment
          error ? setError(error) : setError(response as E);
        } else {
          const errorResponse: ErrorResponse = {
            message: intl.formatMessage({
              id: 'forms.errors.unexpected',
            }),
          };
          setError(errorResponse as any); // eslint-disable-line @typescript-eslint/no-explicit-any
        }

        if (response.status >= 400 && response.status <= 499) {
          Sentry.withScope((scope) => {
            if (body != null) {
              const masked_body: RequestBody = {};

              Object.keys(body).forEach((key) => {
                if (SENTRY_WHITELIST_KEYS.includes(key) || isEmpty(body[key])) {
                  masked_body[key] = body[key];
                } else {
                  masked_body[key] = '[FILTERED]';
                }
              });
              scope.setExtra('request body', masked_body);
            }
            Sentry.captureMessage(
              `${method} ${url} respond ${response.status} error`,
            );
          });
        }
      } else {
        // in case of success
        if (options.responseType === 'text') {
          const textBody = await response.text();
          if (isJSON(textBody)) {
            const responseBody = (data = JSON.parse(textBody)); // eslint-disable-line @typescript-eslint/no-unsafe-assignment
            setData(responseBody);
          }
        } else if (options.responseType === 'blob') {
          const blobBody = (data = (await response.blob()) as unknown as R);
          setData(blobBody); // eslint-disable-line @typescript-eslint/no-explicit-any
        }
      }

      setResponseHeaders(response.headers);
      setStatus(response.status);
      setIsFetching(false);

      return {
        data,
        status: response.status,
      };
    },
    [
      reset,
      method,
      url,
      isEnableSchoolCommParentsAuthV2,
      refreshAccessToken,
      unauthLogout,
      intl,
      options.responseType,
    ],
  );

  return {
    fetch: sendRequest,
    reset,
    isFetching,
    error,
    data,
    status,
    headers: responseHeaders,
  };
};

export default useFetch;
