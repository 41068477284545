/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { math } from 'polished';
import React from 'react';
import { styled, css } from 'styled-components';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import Input from 'components/modules/forms/Input';

interface Props extends React.ComponentProps<typeof Input> {
  isOpen?: boolean;
}

const iconRightPosition = '17px';

const WrapperStyled = styled.div`
  position: relative;
`;

const InputStyled = styled(Input)(
  ({ theme }) => css`
    padding-right: ${math(`${iconRightPosition} + ${theme.icon.size.s}`)};
    cursor: pointer;

    /* Icon上にマウスがある際にもhoverに見せるため */
    ${WrapperStyled}:hover & {
      ${theme.form.input.styles.hover}
    }

    /* Icon上でのクリック時にもfocusに見せるため */
    ${WrapperStyled}[data-is-open="true"] & {
      ${theme.form.input.styles.focus}
    }
  `,
);

const IconStyled = styled.i(
  ({ theme }) => css`
    position: absolute;
    top: -2px;
    right: ${iconRightPosition};
    bottom: 0;
    height: ${theme.icon.size.s};
    margin: auto 0;
    cursor: pointer;
  `,
);

const ArrowStyled = styled(ArrowDownIcon)(
  ({ theme }) => css`
    width: 10px;
    height: 5px;
    margin-left: ${theme.spacing.l};
    background-color: transparent;
    fill: ${theme.globalColor.gray400};
  `,
);

const DatePickerInput: React.FC<Props> = ({ isOpen, ...props }) => (
  <WrapperStyled data-is-open={isOpen}>
    <InputStyled type="text" {...props} readOnly />
    <IconStyled onClick={props.onClick}>
      <ArrowStyled />
    </IconStyled>
  </WrapperStyled>
);

export default DatePickerInput;
