import React from 'react';
import { useIntl } from 'react-intl';

import Jumbotron from 'components/modules/box/Jumbotron';
import JumbotronPanel from 'components/modules/box/JumbotronPanel';

import CSSModule from './AnnouncementDetailRestricted.module.scss';

const AnnouncementDetailRestricted: React.FC = () => {
  const intl = useIntl();

  return (
    <div className={CSSModule.AnnouncementDetailRestricted}>
      <Jumbotron>
        <JumbotronPanel
          title={intl.formatMessage({
            id: 'announcements.announcementDetailRestricted.title',
          })}
          description={intl.formatMessage({
            id: 'announcements.announcementDetailRestricted.description',
          })}
        />
      </Jumbotron>
    </div>
  );
};

export default AnnouncementDetailRestricted;
