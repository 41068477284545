import classNames from 'classnames';
import React from 'react';

import CSSModule from './Loading.module.scss';

interface Props {
  className?: string;
  svgClassName?: string;
}

const Loading: React.FC<Props> = ({ className, svgClassName }) => {
  const classes = classNames(CSSModule.Loading, className);
  const svgClasses = classNames(CSSModule.Loading__Svg, svgClassName);

  return (
    <div className={classes} role="alert">
      <svg
        className={svgClasses}
        version="1.1"
        viewBox="0 0 28 28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Loading</title>
        <circle
          cx="14"
          cy="14"
          fill="none"
          r="13"
          strokeDasharray="87.96452"
          strokeDashoffset="17.592904"
          strokeWidth="2"
          stroke="#adb8be"
        />
      </svg>
    </div>
  );
};

export default Loading;
