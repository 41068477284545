import {
  parseISO,
  fromUnixTime,
  format,
  isSameYear,
  getDayOfYear,
} from 'date-fns';
import { useCallback } from 'react';

export const useDateShorter = () => {
  const dateShorter = useCallback((date?: string | number | Date | null) => {
    let passedDate: Date;

    switch (typeof date) {
      case 'number':
        passedDate = fromUnixTime(date / 1000); // for millisec
        break;
      case 'string':
        passedDate = parseISO(date);
        break;
      case 'object':
        if (date instanceof Date) {
          passedDate = date;
        } else if (date === null) {
          passedDate = new Date();
        } else {
          throw Error;
        }
        break;
      default:
        passedDate = new Date();
    }

    const today = new Date();
    if (!isSameYear(passedDate, today)) return format(passedDate, 'y年M月d日');

    const diffDay = getDayOfYear(passedDate) - getDayOfYear(today);

    if (diffDay === 0) return '今日';
    if (diffDay === -1) return '昨日';
    return format(passedDate, 'M月d日');
  }, []);

  return dateShorter;
};
