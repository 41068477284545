import { useContext } from 'react';

import { AppContext } from 'providers/ContextProvider';

const useCurrentUser = () => {
  const { currentUser, setCurrentUser } = useContext(AppContext);
  return [currentUser, setCurrentUser] as const;
};

export default useCurrentUser;
