import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

import Button from 'components/modules/button/Button';
import { SelectedStudent } from 'components/pages/absences/AbsencesIndexPage';
import AbsencesFooter from 'components/pages/absences/parts/AbsencesFooter';
import AbsencesFooterContent from 'components/pages/absences/parts/AbsencesFooterContent';

interface Props {
  selectedStudent: SelectedStudent | null;
  organizationManagement?: {
    absence_time_limit?: string;
  };
}

const ButtonStyled = styled(Button)`
  display: block;
  margin: 0 auto;
`;

const AbsencesIndexFooter: React.FC<Props> = ({
  selectedStudent,
  organizationManagement,
}) => {
  const navigate = useNavigate();

  let timeLimit: string | null = null;
  if (organizationManagement?.absence_time_limit) {
    timeLimit = organizationManagement.absence_time_limit;
  }

  return (
    <AbsencesFooter sticky>
      {timeLimit && (
        <AbsencesFooterContent
          note={`当日の受付時間は${timeLimit}までです。`}
          description={`※当日、${timeLimit}以降は直接学校に問い合わせてください。`}
        />
      )}
      <ButtonStyled
        color="primary"
        size="m"
        onClick={() =>
          navigate(`/absences/member/${selectedStudent?.value || ''}/create`, {
            state: { timeLimit },
          })
        }
      >
        欠席連絡
      </ButtonStyled>
    </AbsencesFooter>
  );
};

export default AbsencesIndexFooter;
