import React from 'react';

import InformationHeader from 'components/organisms/common/InformationHeader';
import Footer from 'components/templates/Footer';
import Header from 'components/templates/Header';

import CSSModule from './SingleColumnTemplate.module.scss';

interface Props {
  hideSubmenu?: boolean;
  children?: React.ReactNode;
  showNumberBadge?: boolean;
}

const SingleColumnTemplate: React.FC<Props> = ({
  hideSubmenu,
  children,
  showNumberBadge = true,
}) => (
  <div className={CSSModule.SingleColumnTemplate}>
    <InformationHeader />
    <Header hideSubmenu={hideSubmenu} showNumberBadge={showNumberBadge} />
    <main className={CSSModule.SingleColumnTemplate__Body}>{children}</main>
    <Footer />
  </div>
);

export default SingleColumnTemplate;
