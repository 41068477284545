import { parseISO } from 'date-fns';
import { isBoolean } from 'lodash-es';
import React from 'react';

import Jumbotron from 'components/modules/box/Jumbotron';
import Paper from 'components/modules/box/Paper';
import { AbsenceDetailPayload } from 'components/pages/absences/AbsencesDetailPage';
import {
  contactTypeNames,
  reasonNames,
} from 'components/pages/absences/constants';
import AbsencesDetailPanel from 'components/pages/absences/detail/AbsencesDetailPanel';
import AbsencesDetailPanelContent from 'components/pages/absences/detail/AbsencesDetailPanelContent';
import AbsencesDetailPanelHeader from 'components/pages/absences/detail/AbsencesDetailPanelHeader';
import { ContactType, Reason } from 'components/pages/absences/types';
import { useDateShorter } from 'hooks/absences/useDateShorter';
import { AppContext } from 'providers/ContextProvider';
interface OwnProps {
  payload: AbsenceDetailPayload | null;
}

const AbsencesDetailBody: React.FC<OwnProps> = ({ payload }) => {
  const dateShorter = useDateShorter();

  const { currentUser } = React.useContext(AppContext);
  const organizationMemberships = currentUser?.organization_memberships || [];
  const selectedStudent = organizationMemberships.find(
    (obj) => obj.id === payload?.absence.organization_membership_id,
  );

  const studentName = selectedStudent?.name;
  const date = payload?.absence.date
    ? parseISO(payload?.absence.date)
    : new Date();
  const contactType =
    contactTypeNames[
      (payload?.absence_log.contact_type || 'absence') as ContactType
    ];
  const reason = reasonNames[payload?.absence_log.reason as Reason] || '';
  const goToSchoolAt = payload?.absence_log.go_to_school_at;
  const leaveSchoolAt = payload?.absence_log.leave_school_at;
  const diseaseName = payload?.absence_log.disease_name;
  const symptom = payload?.absence_log.symptom;
  const bodyTemperature = payload?.absence_log.body_temperature;
  const doctorsInstruction = isBoolean(payload?.absence_log.doctors_instruction)
    ? payload?.absence_log.doctors_instruction
      ? 'あり'
      : 'なし'
    : null;
  const relationship = payload?.absence_log.relationship;
  const transportation = payload?.absence_log.transportation;
  const detailComment = payload?.absence_log.detail_comment;
  const isCreatedTeacher = payload?.absence_log.is_created_teacher;

  return (
    <Jumbotron flexHeight>
      <Paper>
        <AbsencesDetailPanel>
          <AbsencesDetailPanelHeader
            title={dateShorter(payload?.absence.date)}
            label={contactType || ''}
            isCreatedTeacher={isCreatedTeacher}
          />
          <AbsencesDetailPanelContent
            studentName={studentName}
            date={date}
            contactType={contactType}
            reason={reason}
            goToSchoolAt={goToSchoolAt}
            leaveSchoolAt={leaveSchoolAt}
            diseaseName={diseaseName}
            symptom={symptom}
            bodyTemperature={bodyTemperature}
            doctorsInstruction={doctorsInstruction}
            relationship={relationship}
            transportation={transportation}
            detailComment={detailComment}
          />
        </AbsencesDetailPanel>
      </Paper>
    </Jumbotron>
  );
};

export default AbsencesDetailBody;
