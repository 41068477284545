import React from 'react';
import { Link } from 'react-router-dom';
import { styled, css } from 'styled-components';

import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrow-back.svg';
import theme from 'store/theme';

export type RightLinkColor = 'primary' | 'gray';

const rightLinkColors = {
  primary: theme.globalColor.skyBlue600,
  gray: theme.globalColor.gray500,
};

export interface Props {
  title: string | React.ReactNode;
  backLinkLabel?: string | null;
  backLinkUrl?: string;
  onBackLinkClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  rightLinkLabel?: string | null;
  rightLinkUrl?: string;
  onRightLinkClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  rightLinkColor?: RightLinkColor;
}

interface StyledProps {
  $isLabel: boolean;
}

export const WrapperStyled = styled.div(
  ({ theme }) => `
  display: flex;
  background-color: ${theme.globalColor.white};
`,
);

export const InnerStyled = styled.div<StyledProps>(
  ({ theme, $isLabel }) => css`
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: ${theme.header.width};
    min-height: 100px;
    margin: 0 auto;
    padding: ${theme.spacing.l};

    ${theme.media.sp} {
      max-width: 100%;
      min-height: ${$isLabel ? '72px' : '60px'};
    }
  `,
);

export const LeftStyled = styled.div`
  flex-grow: 1;
`;

export const RightStyled = styled.div`
  /* stylelint-disable-line no-empty-source */
`;

export const TitleStyled = styled.h1(
  ({ theme }) => css`
    margin: 0;
    color: ${theme.globalColor.gray700};
    font-size: ${theme.fontSize.xl};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.default};
    word-break: break-all;

    ${theme.media.sp} {
      font-size: ${theme.fontSize.l};
    }
  `,
);

const BackLinkStyled = styled(Link)(
  ({ theme }) => css`
    display: inline-flex;
    align-content: center;
    align-items: center;
    margin-bottom: ${theme.spacing.m};
    font-size: ${theme.fontSize.s};

    &,
    &:hover {
      color: ${theme.globalColor.skyBlue600};
    }

    &:hover {
      > span {
        text-decoration: underline;
      }
    }

    ${theme.media.sp} {
      margin-bottom: ${theme.spacing.s};
    }
  `,
);

const BackIconStyled = styled(ArrowBackIcon)(
  ({ theme }) => css`
    display: block;
    width: auto;
    height: auto;
    padding-right: ${theme.spacing.m};
  `,
);

export const RightLinkStyled = styled(Link)<{
  $rightLinkColor?: RightLinkColor;
}>(
  ({ theme, $rightLinkColor = 'primary' }) => css`
    display: block;
    padding: ${theme.spacing.l};
    font-size: ${theme.fontSize.default};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.default};
    text-decoration: none;

    &,
    &:hover {
      color: ${rightLinkColors[$rightLinkColor]};
    }

    &:hover {
      text-decoration: underline;
    }
  `,
);

const PageTitle: React.FC<Props> = ({
  title,
  backLinkLabel,
  backLinkUrl,
  onBackLinkClick,
  rightLinkLabel,
  rightLinkUrl,
  onRightLinkClick,
  rightLinkColor,
}) => (
  <WrapperStyled>
    <InnerStyled $isLabel={!!backLinkLabel}>
      <LeftStyled>
        {backLinkLabel && (
          <BackLinkStyled to={backLinkUrl || ''} onClick={onBackLinkClick}>
            <BackIconStyled />
            <span>{backLinkLabel}</span>
          </BackLinkStyled>
        )}
        <TitleStyled>{title}</TitleStyled>
      </LeftStyled>
      {rightLinkLabel && (
        <RightStyled>
          <RightLinkStyled
            to={rightLinkUrl || ''}
            onClick={onRightLinkClick}
            $rightLinkColor={rightLinkColor}
          >
            {rightLinkLabel}
          </RightLinkStyled>
        </RightStyled>
      )}
    </InnerStyled>
  </WrapperStyled>
);

export default PageTitle;
