import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import NumberBadge from 'components/modules/badge/NumberBadge';

import CSSModule from './NavItem.module.scss';

interface Props {
  icon: React.ReactNode;
  label: string;
  url?: string;
  onClick?: (ev: React.MouseEvent) => void;
  badgeCount?: number;
  active?: boolean;
  badgeShowEmpty?: boolean;
}

const NavItem: React.FC<Props> = ({
  icon,
  label,
  url,
  onClick,
  badgeCount,
  active = false,
  badgeShowEmpty,
}) => {
  // preventDefault if url & onClick is undefined
  const handleClick =
    onClick ||
    (url
      ? undefined
      : /* istanbul ignore next */
        (ev) => {
          ev.preventDefault();
        });
  return (
    <div
      className={classNames(CSSModule.NavItem, 'NavItem', {
        [CSSModule.NavItem__Active]: active,
      })}
    >
      <Link to={url ?? '#'} onClick={handleClick}>
        <div className={CSSModule.NavItem__Icon}>{icon}</div>
        <div className={CSSModule.NavItem__Label}>{label}</div>
      </Link>
      <NumberBadge count={badgeCount} showEmpty={badgeShowEmpty} />
    </div>
  );
};

export default NavItem;
