import { useCallback } from 'react';

import useModal from 'hooks/common/useModal';
import { ParentProfileFormValues } from 'interfaces/parent';

interface PasswordConfirmationModalProps {
  profileValues: ParentProfileFormValues;
  onError: (errorMessage: string) => void;
}

const usePasswordConfirmationModal = () => {
  const { showModal, hideModal } = useModal();

  const showPasswordConfirmationModal = useCallback(
    ({ profileValues, onError }: PasswordConfirmationModalProps) => {
      showModal('PasswordConfirmationModal', { profileValues, onError });
    },
    [showModal],
  );

  return {
    showPasswordConfirmationModal,
    hideModal,
  };
};

export default usePasswordConfirmationModal;
