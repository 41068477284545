import React from 'react';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';

import EmailConfirmation from 'components/organisms/mypage/EmailConfirmation';
import SingleCardTemplate from 'components/templates/SingleCardTemplate';

interface Props {
  email: string;
  isFetching: boolean;
  onResend: () => void;
  onGoBack: () => void;
}

const EmailConfirmationPage: React.FC<Props> = ({
  email,
  isFetching,
  onResend,
  onGoBack,
}) => {
  const intl = useIntl();

  return !email ? (
    <Navigate replace to="/registration" />
  ) : (
    <SingleCardTemplate
      title={intl.formatMessage({
        id: 'registration.emailRegistration.title',
      })}
    >
      <EmailConfirmation
        email={email}
        isFetching={isFetching}
        onResend={onResend}
        onCancel={onGoBack}
      />
    </SingleCardTemplate>
  );
};

export default EmailConfirmationPage;
