class AccessTokenStorage {
  private accessToken: string | null;

  constructor() {
    this.accessToken = null;
  }

  public get(): string | null {
    return this.accessToken;
  }

  public set(newAccessToken: string): void {
    this.accessToken = newAccessToken;
  }

  public clear(): void {
    this.accessToken = null;
  }
}

// Singleton
export const accessTokenStorage = new AccessTokenStorage();
