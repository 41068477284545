import React from 'react';

import { isDetailPanelHeader } from 'components/modules/detailpage/DetailPanelHeader';

import CSSModule from './detailPanel.module.scss';

export interface DetailPanelProps {
  children: React.ReactNode;
}

const DetailPanel: React.VFC<DetailPanelProps> = ({ children }) => {
  const bodyChild: React.ReactNode[] = [];
  let headerChild: React.ReactNode = undefined;
  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) {
      return undefined;
    }

    isDetailPanelHeader(child.props)
      ? (headerChild = child)
      : bodyChild.push(child);
  });
  return (
    <div className={CSSModule.DetailPanel}>
      {/* header */}
      {headerChild}
      {/* body */}
      <div className={CSSModule.DetailPanel__body}>{bodyChild}</div>
    </div>
  );
};

export default DetailPanel;
