import React from 'react';

import CSSModule from './List.module.scss';

export interface ListProps {
  children: React.ReactNode;
}

const List: React.FC<ListProps> = ({ children }) => (
  <ul className={CSSModule.List}>{children}</ul>
);

export default List;
