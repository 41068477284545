import React from 'react';
import { Helmet } from 'react-helmet-async';

import ForbiddenError from 'components/pages/errors/ForbiddenError';
import InternalServerError from 'components/pages/errors/InternalServerError';
import NotFoundError from 'components/pages/errors/NotFoundError';
import SomethingWentWrongError from 'components/pages/errors/SomethingWentWrongError';
import SingleColumnTemplate from 'components/templates/SingleColumnTemplate';
interface Props {
  type?: 'forbidden' | 'not_found' | 'internal_server_error';
  description?: string;
}

const ErrorPage: React.FC<Props> = ({ type, description }) => (
  <SingleColumnTemplate hideSubmenu>
    <>
      <Helmet title="" />
      {type === 'forbidden' && <ForbiddenError description={description} />}
      {type === 'not_found' && <NotFoundError description={description} />}
      {type === 'internal_server_error' && (
        <InternalServerError description={description} />
      )}
      {!type && <SomethingWentWrongError description={description} />}
    </>
  </SingleColumnTemplate>
);

export default ErrorPage;
