import { isEmpty, toNumber } from 'lodash-es';
import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

import Select from 'components/modules/forms/Select';
import PageTitle from 'components/modules/headers/PageTitle';
import AbsencesIndexFooter from 'components/pages/absences/index/AbsencesIndexFooter';
import AbsencesIndexList from 'components/pages/absences/index/AbsencesIndexList';
import AbsencesEmpty from 'components/pages/absences/parts/AbsencesEmpty';
import AbsencesRestricted from 'components/pages/absences/parts/AbsencesRestricted';
import { ContactType, Reason } from 'components/pages/absences/types';
import InternalServerError from 'components/pages/errors/InternalServerError';
import ErrorPage from 'components/pages/errors/common/ErrorPage';
import SingleColumnTemplate from 'components/templates/SingleColumnTemplate';
import UiStackTemplate from 'components/templates/UiStackTemplate';
import useFetch from 'hooks/common/useFetch';
import useQueryParams from 'hooks/common/useQueryParams';
import { AppContext } from 'providers/ContextProvider';

interface LatestLog {
  id: number;
  contact_type: ContactType;
  reason: Reason;
  detail_comment: string;
  go_to_school_at: string;
  leave_school_at: string;
  status: string;
  is_created_teacher: boolean;
}
interface Absence {
  id: number;
  date: string;
  organization_membership_id: string;
  latest_logs: LatestLog[];
}
export interface AbsencesIndexPayload {
  absences: Absence[];
  pagination: {
    current_page: number;
    limit_value: number;
    total_count: number;
  };
  organization_management?: {
    absence_time_limit?: string;
  };
}

type IndexState =
  | 'ok'
  | 'forbidden'
  | 'restricted'
  | 'empty'
  | 'notFound'
  | 'serverError'
  | null;

export interface SelectedStudent {
  name: string;
  value: string;
}

const WrapperStyled = styled.div({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const AbsencesIndexMainWrapperStyled = styled.div(({ theme }) => ({
  flexGrow: 1,
  padding: `0 ${theme.spacing.l}`,
}));

const AbsencesIndexMainInnerStyled = styled.div(({ theme }) => ({
  maxWidth: 780,

  [theme.media.pc]: {
    margin: `40px auto ${theme.spacing.xxxl}`,
  },
  [theme.media.sp]: {
    margin: `${theme.spacing.l} auto 25px`,
  },
}));

const StudentSelectorStyled = styled.div(({ theme }) => ({
  [theme.media.pc]: {
    marginBottom: theme.spacing.xxl,
  },
  [theme.media.sp]: {
    marginBottom: theme.spacing.xl,
  },
}));

const SelectLabelStyled = styled.span(({ theme }) => ({
  color: theme.globalColor.gray500,
  fontSize: theme.fontSize.default,
  fontWeight: theme.fontWeight.light,
  lineHeight: theme.lineHeight.default,
  marginRight: theme.spacing.m,
}));

const SelectWrapperStyled = styled.div(() => ({
  display: 'inline-block',
  width: 240,
}));

const AbsencesIndexPage: React.FC = () => {
  const [indexState, setIndexState] = React.useState<IndexState>(null);
  const [selectedStudent, setSelectedStudent] =
    React.useState<SelectedStudent | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { organizationMembershipId } = useParams();
  const { currentUser } = React.useContext(AppContext);
  const organizationMemberships = currentUser?.organization_memberships || [];
  const students: SelectedStudent[] = organizationMemberships.map(
    ({ name, id }) => ({
      name,
      value: id,
    }),
  );

  const defaultStudent = students[0];
  const query = useQueryParams();
  const pageParam = query.get('page');
  const page = pageParam || '1';

  const {
    fetch: fetchAbsences,
    isFetching,
    status,
    data: payload,
  } = useFetch<AbsencesIndexPayload>(
    'GET',
    '/api/school_communication/absences',
  );

  const handleFetchAbsences = ({
    page,
    organizationMembershipId,
  }: {
    page: number;
    organizationMembershipId?: string;
  }) =>
    void fetchAbsences({
      body: {
        page,
        organization_membership_id: organizationMembershipId,
      },
    });

  const handleOnChange = (val: string | null) => {
    let param = '';
    if (pageParam && organizationMembershipId === val) {
      param = `/?page=${page}`;
    }

    if (val) {
      const path = `/absences/member/${val}${param}`;
      const { pathname, search } = location;

      if (path !== `${pathname}${search}`) {
        void navigate(path);
      }
      setSelectedStudent(students.find((obj) => obj.value === val) || null);
    }
  };

  React.useEffect(() => {
    const validStudent = students.find(
      (obj) => obj.value === organizationMembershipId,
    );

    if (organizationMembershipId && !validStudent) {
      return;
    }

    if (validStudent) {
      setSelectedStudent(validStudent);
    } else {
      setSelectedStudent(defaultStudent);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setIndexState(null);

    if (selectedStudent) {
      if (selectedStudent.value !== organizationMembershipId) {
        setSelectedStudent(
          students.find((obj) => obj.value === organizationMembershipId) ||
            null,
        );
        return;
      }

      handleFetchAbsences({
        page: parseInt(page),
        organizationMembershipId: selectedStudent.value,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, selectedStudent]);

  React.useEffect(() => {
    setIndexState(null);
    const statusCode = toNumber(status);
    if (!isFetching) {
      // 存在しないページ番号のパラメータが要求された時
      const totalCount = payload?.pagination.total_count || 0;
      if (status === 200 && totalCount > 0 && isEmpty(payload?.absences)) {
        return setIndexState('notFound');
      }

      if (status === 200 && payload?.pagination.total_count === 0) {
        return setIndexState('empty');
      }

      if (statusCode === 200) {
        return setIndexState('ok');
      }

      if (statusCode === 403 || statusCode === 423) {
        return setIndexState('restricted');
      }

      if (statusCode >= 400 && statusCode < 500) {
        return setIndexState('notFound');
      }

      if (statusCode >= 500 && statusCode < 600) {
        return setIndexState('serverError');
      }

      const validStudent = students.find(
        (obj) => obj.value === organizationMembershipId,
      );

      if (organizationMembershipId && !validStudent) {
        return setIndexState('notFound');
      }

      return setIndexState(null);
    }
  }, [
    status,
    isFetching,
    selectedStudent,
    payload?.absences,
    payload?.pagination.total_count,
    students,
    organizationMembershipId,
  ]);

  React.useEffect(() => {
    if (!organizationMembershipId && defaultStudent) {
      navigate(`/absences/member/${defaultStudent?.value}`);
      setSelectedStudent(defaultStudent);
    }
  }, [defaultStudent, navigate, organizationMembershipId]);

  const renderContentSwitch: (
    state: IndexState,
    payload: AbsencesIndexPayload | null,
  ) => React.ReactNode = (state: IndexState) => {
    if (state === 'ok') {
      if (payload && selectedStudent) {
        return (
          <AbsencesIndexList
            organizationMembershipId={selectedStudent.value}
            payload={payload}
          />
        );
      }

      return <AbsencesEmpty />;
    }

    if (state === 'serverError') {
      return <InternalServerError />;
    }

    if (state === 'empty') {
      return <AbsencesEmpty />;
    }

    if (state === 'restricted') {
      return <AbsencesRestricted />;
    }

    return null;
  };

  return indexState === 'notFound' ? (
    <ErrorPage type="not_found" />
  ) : indexState === 'forbidden' ? (
    <ErrorPage type="forbidden" />
  ) : (
    <SingleColumnTemplate>
      <WrapperStyled>
        {indexState !== 'serverError' && <PageTitle title="欠席連絡" />}
        <AbsencesIndexMainWrapperStyled>
          <AbsencesIndexMainInnerStyled>
            {indexState !== 'serverError' && !isEmpty(students) && (
              <StudentSelectorStyled data-testid="student_selector">
                <SelectLabelStyled>生徒：</SelectLabelStyled>
                <SelectWrapperStyled>
                  <Select
                    options={students}
                    value={selectedStudent?.value}
                    onChange={handleOnChange}
                  />
                </SelectWrapperStyled>
              </StudentSelectorStyled>
            )}
            <UiStackTemplate isLoading={isFetching}>
              {renderContentSwitch(indexState, payload)}
            </UiStackTemplate>
          </AbsencesIndexMainInnerStyled>
        </AbsencesIndexMainWrapperStyled>
        {(indexState === 'ok' || indexState === 'empty') &&
          isFetching === false && (
            <AbsencesIndexFooter
              selectedStudent={selectedStudent}
              organizationManagement={payload?.organization_management}
            />
          )}
      </WrapperStyled>
    </SingleColumnTemplate>
  );
};

export default AbsencesIndexPage;
