import { useCallback } from 'react';

const useInputChangeValue = () => {
  // inputのonChangeを発火させるためのあれこれ
  // SEE: https://stackoverflow.com/a/46012210
  const changeValue = useCallback(
    (input: HTMLInputElement, value: string | null | undefined) => {
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value',
      )?.set;
      nativeInputValueSetter?.call(input, value);

      const event = new Event('input', { bubbles: true });
      input.dispatchEvent(event);
    },
    [],
  );

  return {
    changeValue,
  };
};

export default useInputChangeValue;
