import React from 'react';

import CSSModule from './Nav.module.scss';

interface OwnProps {
  children?: React.ReactNode;
}

const Nav: React.FC<OwnProps> = ({ children }) => (
  <div className={CSSModule.Nav}>{children}</div>
);

export default Nav;
