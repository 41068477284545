import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import ResetPasswordForm from 'components/organisms/password/ResetPasswordForm';
import TokenVerificationError from 'components/organisms/password/TokenVerificationError';
import SingleCardTemplate from 'components/templates/SingleCardTemplate';
import UiStackTemplate from 'components/templates/UiStackTemplate';
import { pageTitles } from 'constants/pageTitles';
import useFetch from 'hooks/common/useFetch';

import CSSModule from './ResetPasswordPage.module.scss';

interface Props {
  setCompleted: (completed: boolean) => void;
}

interface VerifyResult {
  email: string;
}

const ResetPasswordPage: React.FC<Props> = ({ setCompleted }) => {
  const location = useLocation();
  const { token } = queryString.parse(location.search);
  const [isVerificationError, setIsVerificationError] =
    useState<boolean>(false);

  const {
    fetch: verifyToken,
    error: verificationError,
    status,
    data,
  } = useFetch<VerifyResult>(
    'GET',
    '/api/school/parents/password_change_tokens/verify',
  );

  useEffect(() => {
    if (token) {
      void verifyToken({
        headers: {
          'X-Token-Type': 'password_change_token',
          Authorization: `Bearer ${token as string}`,
        },
      });
    }
  }, [verifyToken, token]);

  useEffect(() => {
    setIsVerificationError(!token || !!verificationError);
  }, [token, verificationError]);

  const isLoading = !!token && !status;

  return (
    <SingleCardTemplate title={pageTitles.passwordReset}>
      <div className={CSSModule.ResetPasswordPage}>
        <UiStackTemplate
          isLoading={isLoading}
          isError={isVerificationError}
          errorView={<TokenVerificationError />}
        >
          <div>
            <div className={CSSModule.ResetPasswordPage__Description}>
              <FormattedMessage id="resetPasswordPage.description" />
            </div>

            <ResetPasswordForm
              token={token}
              email={data?.email}
              setCompleted={setCompleted}
              setIsVerificationError={setIsVerificationError}
            />
          </div>
        </UiStackTemplate>
      </div>
    </SingleCardTemplate>
  );
};

export default ResetPasswordPage;
