import { sortBy } from 'lodash';
import React from 'react';

import AnnouncementFileInfo from 'components/molecules/announcements/AnnouncementFileInfo';
import AnnouncementFileThumbnail from 'components/molecules/announcements/AnnouncementFileThumbnail';
import FileInfoList from 'components/organisms/common/FileInfoList';
import FileThumbnailList from 'components/organisms/common/FileThumbnailList';
import { AnnouncementFile, IMAGE_FILE_TYPES } from 'interfaces/announcement';

import FileInfo from '../../molecules/common/FileInfo';

import CSSModule from './AnnouncementAttachments.module.scss';

interface Props {
  files: AnnouncementFile[];
}

const AnnouncementAttachments: React.FC<Props> = ({ files }) => {
  const imageFile = sortBy(
    files.filter((file) => IMAGE_FILE_TYPES.includes(file.content_type)),
    'id',
  );
  const nonImageFiles = sortBy(
    files.filter((file) => !IMAGE_FILE_TYPES.includes(file.content_type)),
    'id',
  );

  return (
    <div className={CSSModule.AnnouncementAttachments}>
      <FileThumbnailList>
        {imageFile.map((announcement_file) => (
          <React.Fragment key={announcement_file.id}>
            {!announcement_file.file_deleted ? (
              <AnnouncementFileThumbnail
                announcement_file={announcement_file}
              />
            ) : (
              <FileInfo name={announcement_file.original_file_name} isDeleted />
            )}
          </React.Fragment>
        ))}
      </FileThumbnailList>
      <FileInfoList>
        {nonImageFiles.map((announcement_file) => (
          <React.Fragment key={announcement_file.id}>
            {!announcement_file.file_deleted ? (
              <AnnouncementFileInfo announcement_file={announcement_file} />
            ) : (
              <FileInfo name={announcement_file.original_file_name} isDeleted />
            )}
          </React.Fragment>
        ))}
      </FileInfoList>
    </div>
  );
};

export default AnnouncementAttachments;
