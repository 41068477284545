import classNames from 'classnames';
import React from 'react';

import CSSModule from './FileThumbnail.module.scss';

interface Props {
  className?: string;
  src: string | null;
  alt?: string;
  onClick?: () => void;
}

const FileThumbnail: React.FC<Props> = ({ src, alt, className, onClick }) => {
  if (!src) {
    return null;
  }

  return (
    <div
      className={classNames(CSSModule.FileThumbnail, className, {
        [CSSModule.FileThumbnail__OnClick]: onClick,
      })}
      onClick={onClick}
    >
      <img src={src} alt={alt} />
    </div>
  );
};

export default FileThumbnail;
