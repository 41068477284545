/* eslint-disable @typescript-eslint/no-misused-promises */
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';

import StudentModal from 'components/organisms/common/StudentModal';
import useAuthentication from 'hooks/common/useAuthentication';
import useFetch from 'hooks/common/useFetch';
import { ModalInnerProps } from 'hooks/common/useModal';
import useToast from 'hooks/store/useToast';
import { ParentRegistrationFormValues } from 'interfaces/parent';
import { Student } from 'interfaces/student';
import ConsentTrailParameterFactory from 'utils/ConsentTrailParameterFactory';

import { useDarklaunchV2VariationWithoutIdentifier } from '../../../../hooks/useDarklaunchV2';

interface Props extends ModalInnerProps {
  student: Student;
  values: ParentRegistrationFormValues;
}

export interface CreateParentResult {
  access_token: string;
}

interface CreateParentError {
  message: string;
}

const StudentConfirmationModal: React.FC<Props> = ({
  isOpen,
  toggle,
  student,
  values,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = queryString.parse(location.search);
  const { login, loginV2 } = useAuthentication();
  const { showToast } = useToast();

  const { variation: isEnableSchoolCommParentsAuthV2 } =
    useDarklaunchV2VariationWithoutIdentifier(
      'enable-school-comm-parents-auth-v2',
    );

  const { fetch, isFetching, status, error, data } = useFetch<
    CreateParentResult,
    CreateParentError
  >(
    'POST',
    isEnableSchoolCommParentsAuthV2
      ? '/api/school/parents_v2'
      : '/api/school/parents',
  );

  const serverErrors = error && error.message.split(/(?:\r\n|\r|\n)/gim);

  const registerParent = () =>
    fetch({
      body: {
        parent: values,
        email_registration_token: token,
        ...ConsentTrailParameterFactory.build(),
      },
      headers: {
        Authorization: `Bearer ${token as string}`,
        'X-Token-Type': 'email_registration_token',
      },
    });

  const onCancel = () => {
    const search = queryString.stringify({ token });
    navigate(`/registration/profile/error?${search}`);
    toggle();
  };

  useEffect(() => {
    if (status === 201 && data) {
      if (isEnableSchoolCommParentsAuthV2) {
        loginV2(data.access_token);
      } else {
        login(data.access_token);
      }
      showToast(
        intl.formatMessage({
          id: 'registration.studentConfirmationModal.completed',
        }),
      );
      navigate('/mypage/profile');
      toggle();
    }
    // navigateを入れると2回発火するため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, login, showToast, toggle, status, data]);

  return (
    <StudentModal
      isFetching={isFetching}
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={registerParent}
      serverErrors={serverErrors}
      student={student}
      toggle={toggle}
    />
  );
};

export default StudentConfirmationModal;
