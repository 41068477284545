import React from 'react';

import Image from 'assets/errors/404.png';
import ErrorContent from 'components/pages/errors/common/ErrorContent';

interface Props {
  description?: string;
}

const NotFoundError: React.FC<Props> = ({ description }) => (
  <ErrorContent
    image={Image}
    title="Oops! Page not found"
    subtitle="お探しのページが見つかりません"
    description={
      description ||
      '申し訳ありませんがお探しのページが見つかりませんでした。 URLをお確かめください。'
    }
  />
);

export default NotFoundError;
