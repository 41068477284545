import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, To, useLocation, Location } from 'react-router-dom';

import BackgroundImage from 'assets/login/background-image.jpg';
import { ReactComponent as ForParentsLogo } from 'assets/logo/for-parents.svg';
import InformationHeader from 'components/organisms/common/InformationHeader';
import LoginForm from 'components/organisms/login/LoginForm';
import Footer from 'components/templates/Footer';
import { pageTitles } from 'constants/pageTitles';
import useAuthentication, {
  AccessTokenResponse,
} from 'hooks/common/useAuthentication';
import useFetch from 'hooks/common/useFetch';
import useHomePath from 'hooks/common/useHomePath';
import { useDarklaunchV2VariationWithoutIdentifier } from 'hooks/useDarklaunchV2';
import ConsentTrailParameterFactory from 'utils/ConsentTrailParameterFactory';

import CSSModule from './LoginPage.module.scss';

export interface LocationState {
  redirectTo?: To;
}

interface FormValues {
  email_or_code: string;
  password: string;
  remember_me: boolean;
}

const LoginPage: React.FC = () => {
  const location = useLocation() as Location<LocationState>;
  const { login, loginV2, isLoggedIn } = useAuthentication();
  const homePath = useHomePath();
  const [remember, setRemember] = useState(false);

  const { variation: isEnableSchoolCommParentsAuthV2 } =
    useDarklaunchV2VariationWithoutIdentifier(
      'enable-school-comm-parents-auth-v2',
    );

  const { fetch, isFetching, error, data } = useFetch<AccessTokenResponse>(
    'POST',
    isEnableSchoolCommParentsAuthV2
      ? '/api/school/auth/token'
      : '/api/school/parents/access_tokens',
  );

  const onSubmit = (values: FormValues) => {
    setRemember(values.remember_me);
    try {
      void fetch({
        body: { ...values, ...ConsentTrailParameterFactory.build() },
      });
    } catch (e) {
      console.error({ message: 'Login Submit', error: e });
    }
  };

  useEffect(() => {
    if (data) {
      if (isEnableSchoolCommParentsAuthV2) {
        loginV2(data.access_token);
      } else {
        login(data.access_token, { remember });
      }
    }
  }, [login, data, remember, isEnableSchoolCommParentsAuthV2, loginV2]);

  if (isLoggedIn) {
    const redirectTo = location.state?.redirectTo || homePath;
    return <Navigate replace to={redirectTo} />;
  }

  return (
    <div
      className={CSSModule.LoginPage}
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <Helmet>
        <title>{pageTitles.login}</title>
      </Helmet>
      <InformationHeader />
      <div className={CSSModule.LoginPage__Inner}>
        <div className={CSSModule.LoginPage__Body}>
          <div className={CSSModule.LoginPage__BodyInner}>
            <div className={CSSModule.LoginPage__LogoWrapper}>
              <ForParentsLogo className={CSSModule.LoginPage__Logo} />
            </div>
            <div className={CSSModule.LoginPage__Form}>
              <LoginForm
                isFetching={isFetching}
                serverError={error?.message || null}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        </div>
        <Footer className={CSSModule.LoginPage__Footer} />
      </div>
    </div>
  );
};

export default LoginPage;
