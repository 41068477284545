import React from 'react';
import { styled, css } from 'styled-components';

export type Status = 'optional' | 'required' | 'ok';

interface Props {
  status: Status;
}

const textByStatus = {
  optional: '任意',
  required: '必須',
  ok: 'OK',
};

const WrapperStyled = styled.span(
  ({ theme }) => css`
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 24px;
    border-radius: ${theme.borderRadius.default};
    color: ${theme.globalColor.white};
    font-size: ${theme.fontSize.s};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.default};
    text-align: center;

    &[data-status='optional'] {
      background-color: ${theme.globalColor.gray100};
      color: ${theme.globalColor.gray500};
    }

    &[data-status='required'] {
      background-color: ${theme.globalColor.pink700};
    }

    &[data-status='ok'] {
      background-color: ${theme.globalColor.ultraBlue600};
    }
  `,
);

const StatusBadge: React.FC<Props> = ({ status }) => (
  <WrapperStyled role="status" data-status={status}>
    {textByStatus[status]}
  </WrapperStyled>
);

export default StatusBadge;
