import Cookies from 'cookie_js';
import { xor, isEmpty } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import InformationBar from 'components/modules/alerts/InformationBar';
import useFetch from 'hooks/common/useFetch';

export interface NoticeFromManagement {
  notice_from_management_ids: string[];
}

const InformationHeader: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [isReload, setReload] = useState(true);
  const [isVisible, setVisible] = useState(false);
  const cookieKey = 'parents_web_notice_from_management';
  const cookieExpiresDay = 365 * 10;
  const redirectPath = '/notices';
  const permitPaths = [
    '/mypage',
    '/login',
    '/announcements',
    '/absences',
    redirectPath,
  ];

  const permit = () =>
    permitPaths.some((path) => location.pathname.startsWith(path));

  const { fetch, isFetching, status, data, error } =
    useFetch<NoticeFromManagement>(
      'GET',
      '/api/school/important_notice_from_management',
    );
  const currentRedirectPath = location.pathname === redirectPath;
  const shouldFetch = !isFetching && !error && isReload && permit();

  const handleClick = () => {
    navigate(redirectPath);
    setVisible(false);
  };

  const handleClose = (noticeFromManagement: NoticeFromManagement) => {
    readed(noticeFromManagement);
    setVisible(false);
  };

  const readed = (noticeFromManagement: NoticeFromManagement) => {
    const noticeFromManagementIds =
      noticeFromManagement.notice_from_management_ids.join();
    Cookies.set(cookieKey, noticeFromManagementIds, {
      path: '/',
      domain: window.location.host,
      expires: cookieExpiresDay,
    });
  };

  const handleNotice = (noticeFromManagement: NoticeFromManagement) => {
    const noticeIds = noticeFromManagement.notice_from_management_ids.join();
    if (isEmpty(noticeIds)) return;

    const cookieIds = Cookies.get(cookieKey)?.split(',');
    const xorIds: string[] = xor(cookieIds, noticeIds.split(','));
    if (isEmpty(xorIds)) return;

    const ids = noticeIds.split(',').filter((id) => xorIds.includes(id));

    if (isEmpty(ids)) return;
    currentRedirectPath ? readed(noticeFromManagement) : setVisible(true);
  };

  useEffect(() => {
    if (shouldFetch) {
      try {
        void fetch();
      } catch (e) {
        console.error({ message: 'NoticeFromManagement', error: e });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status === 200 && data) handleNotice(data);
    if (status) setReload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, status]);

  return isVisible ? (
    <InformationBar
      title={intl.formatMessage({
        id: 'informationBar.important',
      })}
      onClick={handleClick}
      onClose={() => data && handleClose(data)}
    />
  ) : null;
};

export default InformationHeader;
