import React from 'react';
import { useIntl } from 'react-intl';

import PageTitle from 'components/modules/headers/PageTitle';

const MyPageProfileEditPageTitle: React.FC = () => {
  const intl = useIntl();

  return (
    <PageTitle
      title={intl.formatMessage({ id: 'mypage.profileEditPage.title' })}
      rightLinkLabel={intl.formatMessage({ id: 'forms.button.cancel' })}
      rightLinkUrl="/mypage/profile"
      rightLinkColor="gray"
    />
  );
};

export default MyPageProfileEditPageTitle;
