import { format, parseISO } from 'date-fns';
import { isBoolean } from 'lodash-es';
import React from 'react';
import { useParams } from 'react-router-dom';
import { styled, css } from 'styled-components';

import Jumbotron from 'components/modules/box/Jumbotron';
import Panel from 'components/modules/box/Panel';
import Paper from 'components/modules/box/Paper';
import Button from 'components/modules/button/Button';
import PageTitle from 'components/modules/headers/PageTitle';
import AbsencesConfirmPage from 'components/pages/absences/AbsencesConfirmPage';
import { AbsenceDetailPayload } from 'components/pages/absences/AbsencesDetailPage';
import { contactTypeNames } from 'components/pages/absences/constants';
import AbsencesFormInputsOnOrAfterReason from 'components/pages/absences/form/AbsencesFormInputsOnOrAfterReason';
import AbsencesFormStaticInput from 'components/pages/absences/form/AbsencesFormStaticInput';
import {
  convertToSubmitValuesWithOmId,
  validationSchema,
} from 'components/pages/absences/form/constants';
import {
  FieldStyled,
  FormGroupStyled,
} from 'components/pages/absences/form/styled';
import AbsencesFooter from 'components/pages/absences/parts/AbsencesFooter';
import {
  ContactType,
  FormValues,
  SubmitValues,
} from 'components/pages/absences/types';
import ErrorPage from 'components/pages/errors/common/ErrorPage';
import SingleColumnTemplate from 'components/templates/SingleColumnTemplate';
import UiStackTemplate from 'components/templates/UiStackTemplate';
import useFetch from 'hooks/common/useFetch';
import useModal from 'hooks/common/useModal';
import useReactHookForm from 'hooks/common/useReactHookForm';
import useCurrentUser from 'hooks/store/useCurrentUser';

const ContentsStyled = styled.div(
  ({ theme }) => css`
    ${theme.media.pc} {
      margin-top: ${theme.spacing.xxxl};
    }
  `,
);

const NoteTextStyled = styled.div(
  ({ theme }) => css`
    color: ${theme.globalColor.gray700};
  `,
);

const ButtonStyled = styled(Button)`
  display: block;
  margin: 0 auto;
`;

const ButtonsStyled = styled.div(
  ({ theme }) => css`
    ${ButtonStyled}:not(:last-of-type) {
      ${theme.media.pc} {
        margin-bottom: ${theme.spacing.m};
      }

      ${theme.media.sp} {
        margin-bottom: ${theme.spacing.l};
      }
    }
  `,
);

const AbsencesEditPage: React.FC = () => {
  const [isConfirming, setIsConfirming] = React.useState<boolean>(false);
  const [formValues, setFormValues] = React.useState<SubmitValues | null>(null);

  const { absenceId = '', contactType = '' } = useParams();

  const { fetch, isFetching, status, data, error } =
    useFetch<AbsenceDetailPayload>(
      'GET',
      `/api/school_communication/absences/${absenceId}/${contactType}`,
    );

  const [currentUser] = useCurrentUser();

  const student = (currentUser?.organization_memberships || []).find(
    ({ id }) => id === data?.absence.organization_membership_id,
  );

  const {
    registerWithInnerRef,
    formState: { validFields, errorMessages, isValid },
    handleSubmit,
  } = useReactHookForm<FormValues>({
    validationSchema: validationSchema,
    defaultValues: {
      'absence.date': data?.absence.date,
      contact_type: data?.absence_log.contact_type,
      reason: data?.absence_log.reason,
      go_to_school_at: data?.absence_log.go_to_school_at || '',
      leave_school_at: data?.absence_log.leave_school_at || '',
      disease_name: data?.absence_log.disease_name || '',
      symptom: data?.absence_log.symptom || '',
      body_temperature: data?.absence_log.body_temperature || '',
      doctors_instruction: isBoolean(data?.absence_log.doctors_instruction)
        ? String(Number(data?.absence_log.doctors_instruction))
        : '',
      relationship: data?.absence_log.relationship || '',
      transportation: data?.absence_log.transportation || '',
      detail_comment: data?.absence_log.detail_comment,
    },
  });

  const { showModal } = useModal();

  const onCancel = React.useCallback(() => {
    showModal('AbsencesCancelFormModal', {
      url: `/absences/${absenceId}/type/${contactType}`,
    });
  }, [showModal, absenceId, contactType]);

  const onSubmit = React.useCallback(
    (values: FormValues) => {
      const submitValues = convertToSubmitValuesWithOmId(
        values,
        data?.absence.organization_membership_id as string,
      );

      setFormValues(submitValues);
      setIsConfirming(true);
    },
    [data?.absence.organization_membership_id],
  );

  React.useEffect(() => {
    void fetch();
  }, [fetch]);

  if (isConfirming && formValues)
    return (
      <AbsencesConfirmPage
        organizationMembershipId={
          data?.absence.organization_membership_id as string
        }
        values={formValues}
        onBack={() => {
          setIsConfirming(false);
        }}
        onCancel={onCancel}
        submitStatus="updated"
      />
    );

  if (status !== 200 && error) {
    if (Number(status) >= 500 && Number(status) <= 599) {
      return <ErrorPage type="internal_server_error" />;
    }

    if (status === 404) {
      return <ErrorPage type="not_found" />;
    }

    return <ErrorPage type="forbidden" />;
  }

  if (!student) return null;

  return (
    <SingleColumnTemplate>
      <UiStackTemplate isLoading={isFetching}>
        <PageTitle
          title="欠席連絡の編集"
          rightLinkLabel="キャンセル"
          onRightLinkClick={onCancel}
          rightLinkColor="gray"
        />
        <ContentsStyled>
          {/* SEE: https://github.com/react-hook-form/react-hook-form/discussions/8020 */}
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Jumbotron flexHeight>
              <Paper>
                <Panel title="欠席連絡詳細">
                  <FormGroupStyled>
                    <FieldStyled $width="max">
                      <NoteTextStyled>
                        生徒名、日付、内容は変更できません。変更したい場合はこちらの欠席連絡を取り消し、再登録してください。
                      </NoteTextStyled>
                    </FieldStyled>
                  </FormGroupStyled>

                  <AbsencesFormStaticInput
                    label="生徒名"
                    value={student.name}
                  />

                  <AbsencesFormStaticInput
                    label="日付"
                    value={
                      data?.absence.date &&
                      format(parseISO(data.absence.date), 'yyyy年M月d日')
                    }
                  />

                  <AbsencesFormStaticInput
                    label="内容"
                    value={contactTypeNames[contactType as ContactType]}
                  />

                  <AbsencesFormInputsOnOrAfterReason
                    registerWithInnerRef={registerWithInnerRef}
                    validFields={validFields}
                    contactType={contactType}
                    errorMessages={errorMessages}
                  />
                </Panel>
              </Paper>
              <AbsencesFooter>
                <ButtonsStyled>
                  <ButtonStyled
                    color="primary"
                    size="l"
                    type="submit"
                    disabled={!isValid}
                  >
                    次へ
                  </ButtonStyled>
                  <ButtonStyled color="link" size="l" onClick={onCancel}>
                    キャンセル
                  </ButtonStyled>
                </ButtonsStyled>
              </AbsencesFooter>
            </Jumbotron>
          </form>
        </ContentsStyled>
      </UiStackTemplate>
    </SingleColumnTemplate>
  );
};

export default AbsencesEditPage;
