import { addDays, getDate, getYear, parse, setDate, setYear } from 'date-fns';
import { find } from 'lodash-es';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { styled, css } from 'styled-components';

import Jumbotron from 'components/modules/box/Jumbotron';
import Panel from 'components/modules/box/Panel';
import Paper from 'components/modules/box/Paper';
import Button from 'components/modules/button/Button';
import DatePicker from 'components/modules/forms/DatePicker';
import ErrorList from 'components/modules/forms/ErrorList';
import LabelWithStatus from 'components/modules/forms/LabelWithStatus';
import Select from 'components/modules/forms/Select';
import PageTitle from 'components/modules/headers/PageTitle';
import AbsencesConfirmPage from 'components/pages/absences/AbsencesConfirmPage';
import { contactTypeOptions } from 'components/pages/absences/constants';
import AbsencesFormInputsOnOrAfterReason from 'components/pages/absences/form/AbsencesFormInputsOnOrAfterReason';
import AbsencesFormStaticInput from 'components/pages/absences/form/AbsencesFormStaticInput';
import {
  convertToSubmitValuesWithOmId,
  validationSchema,
} from 'components/pages/absences/form/constants';
import {
  FieldStyled,
  FormGroupStyled,
} from 'components/pages/absences/form/styled';
import AbsencesFooter from 'components/pages/absences/parts/AbsencesFooter';
import {
  FormValues,
  SubmitValues,
  Value,
} from 'components/pages/absences/types';
import ErrorPage from 'components/pages/errors/common/ErrorPage';
import SingleColumnTemplate from 'components/templates/SingleColumnTemplate';
import useModal from 'hooks/common/useModal';
import useReactHookForm from 'hooks/common/useReactHookForm';
import useCurrentUser from 'hooks/store/useCurrentUser';

interface TimeLimitState {
  timeLimit?: string | null;
}

const ContentsStyled = styled.div(
  ({ theme }) => css`
    ${theme.media.pc} {
      margin-top: ${theme.spacing.xxxl};
    }
  `,
);

const ButtonStyled = styled(Button)`
  display: block;
  margin: 0 auto;
`;

const ButtonsStyled = styled.div(
  ({ theme }) => css`
    ${ButtonStyled}:not(:last-of-type) {
      ${theme.media.pc} {
        margin-bottom: ${theme.spacing.m};
      }

      ${theme.media.sp} {
        margin-bottom: ${theme.spacing.l};
      }
    }
  `,
);

const AbsencesCreatePage: React.FC = () => {
  const [contactType, setContactType] = React.useState<Value>(null);
  const [isConfirming, setIsConfirming] = React.useState<boolean>(false);
  const [formValues, setFormValues] = React.useState<SubmitValues | null>(null);
  const { organizationMembershipId = '' } = useParams();
  const [currentUser] = useCurrentUser();
  const { showModal } = useModal();
  const location = useLocation();
  const state = location.state as TimeLimitState;

  const {
    handleSubmit,
    registerWithInnerRef,
    formState: { validFields, errorMessages, isValid },
  } = useReactHookForm<FormValues>({
    validationSchema,
    defaultValues: {
      'absence.date': '',
      contact_type: '',
      reason: '',
      go_to_school_at: '',
      leave_school_at: '',
      disease_name: '',
      symptom: '',
      body_temperature: '',
      doctors_instruction: '',
      relationship: '',
      transportation: '',
      detail_comment: '',
    },
  });

  const organizationMembership = find(currentUser?.organization_memberships, [
    'id',
    organizationMembershipId,
  ]);

  const setTimeLimit = () => {
    if (state?.timeLimit) {
      return state.timeLimit;
    }

    if (
      organizationMembership &&
      organizationMembership?.organization_management &&
      organizationMembership?.organization_management?.absence_time_limit
    ) {
      return organizationMembership.organization_management.absence_time_limit;
    }

    return '';
  };

  const studentTimeLimit = setTimeLimit();

  const now = new Date();

  const timeLimit = studentTimeLimit
    ? parse(studentTimeLimit, 'HH:mm', now)
    : now;

  const minDate = now <= timeLimit ? now : addDays(now, 1);
  const nextYear = setYear(now, getYear(now) + 1);
  const maxDate = setDate(nextYear, getDate(nextYear) - 1);

  const student = (currentUser?.organization_memberships || []).find(
    ({ id }) => id === organizationMembershipId,
  );

  const onSubmit = React.useCallback(
    (values: FormValues) => {
      const submitValues = convertToSubmitValuesWithOmId(
        values,
        organizationMembershipId,
      );
      setFormValues(submitValues);
      setIsConfirming(true);
    },
    [organizationMembershipId],
  );

  const onCancel = React.useCallback(() => {
    showModal('AbsencesCancelFormModal', {
      url: `/absences/member/${organizationMembershipId || ''}`,
    });
  }, [showModal, organizationMembershipId]);

  const onBackInConfirm = React.useCallback(() => {
    setIsConfirming(false);
  }, []);

  if (!student) return <ErrorPage type="not_found" />;
  if (isConfirming && formValues)
    return (
      <AbsencesConfirmPage
        organizationMembershipId={organizationMembershipId}
        values={formValues}
        onBack={onBackInConfirm}
        onCancel={onCancel}
        submitStatus="created"
      />
    );

  return (
    <SingleColumnTemplate>
      <PageTitle
        title="欠席連絡の入力"
        rightLinkLabel="キャンセル"
        rightLinkUrl="#"
        onRightLinkClick={(ev) => {
          ev.preventDefault();
          onCancel();
        }}
        rightLinkColor="gray"
      />

      <ContentsStyled>
        {/* SEE: https://github.com/react-hook-form/react-hook-form/discussions/8020 */}
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Jumbotron>
            <Paper>
              <Panel title="欠席連絡詳細">
                <AbsencesFormStaticInput label="生徒名" value={student.name} />
                <FormGroupStyled>
                  <LabelWithStatus required valid={validFields['absence.date']}>
                    日付
                  </LabelWithStatus>
                  <FieldStyled>
                    <DatePicker
                      minDate={minDate}
                      maxDate={maxDate}
                      inputProps={registerWithInnerRef('absence.date')}
                    />
                    <ErrorList errors={errorMessages['absence.date']} />
                  </FieldStyled>
                </FormGroupStyled>
                <FormGroupStyled>
                  <LabelWithStatus required valid={validFields.contact_type}>
                    内容
                  </LabelWithStatus>
                  <FieldStyled>
                    <Select
                      data-testid="contact_type"
                      inputProps={registerWithInnerRef('contact_type')}
                      placeholder="選択してください"
                      options={contactTypeOptions}
                      onChange={(val) => setContactType(val)}
                    />
                    <ErrorList errors={errorMessages.contact_type} />
                  </FieldStyled>
                </FormGroupStyled>
                <AbsencesFormInputsOnOrAfterReason
                  registerWithInnerRef={registerWithInnerRef}
                  validFields={validFields}
                  contactType={contactType}
                  errorMessages={errorMessages}
                />
              </Panel>
            </Paper>
          </Jumbotron>

          <AbsencesFooter>
            <ButtonsStyled>
              <ButtonStyled
                color="primary"
                disabled={!isValid}
                type="submit"
                size="l"
              >
                <FormattedMessage id="forms.button.next" />
              </ButtonStyled>

              <ButtonStyled color="link" size="l" onClick={onCancel}>
                <FormattedMessage id="forms.button.cancel" />
              </ButtonStyled>
            </ButtonsStyled>
          </AbsencesFooter>
        </form>
      </ContentsStyled>
    </SingleColumnTemplate>
  );
};

export default AbsencesCreatePage;
