import classNames from 'classnames';
import React from 'react';

import CSSModule from './DetailPanelHeader.module.scss';

export interface DetailPanelBaseHeaderProps {
  sendAt: number;
}

export interface DetailPanelHeaderProps {
  avatar: React.ReactElement;
  contents: React.ReactNode;
  headerClassName?: string;
  avatarClassName?: string;
  contentsClassName?: string;
}

export const isDetailPanelHeader = (
  arg: unknown,
): arg is DetailPanelBaseHeaderProps =>
  typeof arg === 'object' &&
  arg !== null &&
  typeof (arg as DetailPanelBaseHeaderProps).sendAt === 'number';

export const DetailPanelHeader: React.FC<DetailPanelHeaderProps> = ({
  avatar,
  contents,
  headerClassName,
  avatarClassName,
  contentsClassName,
}) => (
  <div className={classNames(CSSModule.DetailPanel_header, headerClassName)}>
    {/* avatar */}
    <div
      className={classNames(
        CSSModule.DetailPanel__header_avatar_container,
        avatarClassName,
      )}
    >
      {avatar}
    </div>
    {/* contents */}
    <div
      className={classNames(
        CSSModule.DetailPanel__header_contents,
        contentsClassName,
      )}
      data-dd-action-name="DetailPanel__header_contents"
    >
      {contents}
    </div>
  </div>
);
