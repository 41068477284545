import React from 'react';

import Loading from 'components/atoms/loading/Loading';
import ForbiddenError from 'components/pages/errors/ForbiddenError';
import InternalServerError from 'components/pages/errors/InternalServerError';
import NotFoundError from 'components/pages/errors/NotFoundError';

import CSSModule from './UiStackTemplate.module.scss';

interface Props {
  isLoading: boolean;
  isError?: boolean;
  errorView?: React.ReactElement;
  children?: React.ReactNode;
  status?: number | null;
}

const UiStackTemplate: React.FC<Props> = ({
  isLoading,
  isError,
  errorView,
  children,
  status,
}) => {
  if (isLoading) {
    return (
      <div className={CSSModule.UiStackTemplate}>
        <Loading />
      </div>
    );
  }

  if (isError) {
    if (errorView) return errorView;

    if (status) {
      if (status >= 500 && status <= 599) {
        return <InternalServerError />;
      }

      if (status === 403) {
        return <ForbiddenError />;
      }

      return <NotFoundError />;
    }
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default UiStackTemplate;
