import React from 'react';
import { Helmet } from 'react-helmet-async';

import WithdrawalForm from 'components/organisms/withdrawal/WithdrawalForm';
import SingleCardTemplate from 'components/templates/SingleCardTemplate';
import { pageTitles } from 'constants/pageTitles';
import useModal from 'hooks/common/useModal';

const WithdrawalPage: React.FC = () => {
  const { showModal } = useModal();

  const onClick = () => showModal('WithdrawalConfirmationModal', {});

  return (
    <SingleCardTemplate title={pageTitles.withdrawal}>
      <Helmet>
        <title>{pageTitles.withdrawal}</title>
      </Helmet>
      <WithdrawalForm onClick={onClick} />
    </SingleCardTemplate>
  );
};

export default WithdrawalPage;
