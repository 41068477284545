import { filter, isEmpty } from 'lodash-es';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation, To } from 'react-router-dom';
import { styled, css } from 'styled-components';

import Button from 'components/modules/button/Button';
import DetailBody from 'components/modules/detailpage/DetailBody';
import SingleCardTemplate from 'components/templates/SingleCardTemplate';
import { pageTitles } from 'constants/pageTitles';
import useReactQuery from 'hooks/common/useReactQuery';
import useToast from 'hooks/store/useToast';
import { AppContext } from 'providers/ContextProvider';

interface LocationState {
  redirectTo?: To;
}

const WrapperStyled = styled.div(
  ({ theme }) => css`
    ${theme.media.pc} {
      padding: ${theme.spacing.m};
    }

    ${theme.media.sp} {
      padding: ${theme.spacing.m} 0;
    }
  `,
);

const ListStyled = styled.ul(
  ({ theme }) => css`
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      margin-bottom: ${theme.size.l};

      &:last-child {
        margin-bottom: 0;
      }
    }
  `,
);

const TitleStyled = styled.h3(
  ({ theme }) => css`
    font-size: ${theme.fontSize.l};
    font-weight: ${theme.fontWeight.bold};
  `,
);

const ButtonStyled = styled(Button)(
  ({ theme }) => css`
    display: block;
    margin: 0 auto;

    ${theme.media.pc} {
      max-width: 300px;
      margin-top: ${theme.spacing.xxl};
    }

    ${theme.media.sp} {
      margin-top: ${theme.spacing.xl};
    }
  `,
);

const AgreementsPage: React.FC = () => {
  const { agreements } = React.useContext(AppContext);
  const { useMutation, useQueryClient } = useReactQuery();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const location = useLocation();

  const { mutate, isPending } = useMutation({
    url: '/api/school_communication/agreements',

    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['agreements'] });
    },
    onError: (error) => {
      showToast(error.json.message || '');
    },
  });

  const handleOnClick = React.useCallback(() => {
    mutate(JSON.stringify({ display_code: 'for_parents' }));
  }, [mutate]);

  React.useEffect(() => {
    const isAgreed = isEmpty(filter(agreements, ['agreement', false]));

    if (!isEmpty(agreements) && isAgreed) {
      const locationState = location.state as LocationState;
      const redirectTo = locationState?.redirectTo || '/announcements';
      navigate(redirectTo, { replace: true });
    }
  }, [navigate, location, agreements]);

  return (
    <SingleCardTemplate title={pageTitles.agreements}>
      <Helmet>
        <title>{pageTitles.agreements}</title>
      </Helmet>
      <WrapperStyled>
        <ListStyled>
          {agreements.map((agreement) => (
            <li key={agreement.key}>
              <TitleStyled>{agreement.title}</TitleStyled>
              <DetailBody body={agreement.body} />
            </li>
          ))}
        </ListStyled>
        <ButtonStyled
          color="primary"
          size="m"
          onClick={handleOnClick}
          disabled={isPending}
        >
          同意して次へ
        </ButtonStyled>
      </WrapperStyled>
    </SingleCardTemplate>
  );
};

export default AgreementsPage;
