import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface OwnProps {
  children?: React.ReactNode;
}

const ScrollToTop: React.FC<OwnProps> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default ScrollToTop;
