import { useQueryClient } from '@tanstack/react-query';
import { map } from 'lodash';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useParams, useLocation } from 'react-router-dom';

import { ReactComponent as AvatarTeacherIcon } from 'assets/icons/avatar-teacher.svg';
import Jumbotron from 'components/modules/box/Jumbotron';
import Paper from 'components/modules/box/Paper';
import DetailBody from 'components/modules/detailpage/DetailBody';
import DetailPanel from 'components/modules/detailpage/DetailPanel';
import DetailPanelAnnouncementHeader from 'components/modules/detailpage/DetailPanelAnnouncementHeader';
import PageTitle from 'components/modules/headers/PageTitle';
import AnnouncementAttachments from 'components/organisms/announcements/AnnouncementAttachments';
import AnnouncementDetailRestricted from 'components/organisms/announcements/AnnouncementDetailRestricted';
import AnnouncementDetailFooter, {
  LocationState,
} from 'components/pages/announcements/AnnouncementDetailFooter';
import ErrorPage from 'components/pages/errors/common/ErrorPage';
import SingleColumnTemplate from 'components/templates/SingleColumnTemplate';
import UiStackTemplate from 'components/templates/UiStackTemplate';
import { pageTitles } from 'constants/pageTitles';
import useFetch from 'hooks/common/useFetch';
import { Announcement, Recipient } from 'interfaces/announcement';
import EventTracker from 'utils/EventTracker';

import CSSModule from './AnnouncementDetailPage.module.scss';

export interface AnnouncementResult {
  announcement: Announcement;
}

const AnnouncementDetailPage: React.FC = () => {
  const intl = useIntl();
  const location = useLocation();
  const queryClient = useQueryClient();
  const locationState = location.state as LocationState;
  const backLinkUrl =
    locationState?.from === 'list'
      ? `/announcements${location.search}`
      : '/announcements';

  const { announcementId } = useParams<'announcementId'>();

  const {
    fetch: fetchAnnouncement,
    isFetching,
    status,
    data,
  } = useFetch<AnnouncementResult>(
    'GET',
    `/api/school_communication/announcements/${announcementId || ''}`,
  );

  const isNotFound = status === 404;
  const isRestricted = status === 400;
  const isSuccess = status === 200;
  const isNetworkError = status && status >= 500;
  const isLoading = !status || isFetching;

  const { announcement } = data || { announcement: {} as Announcement };
  const ccTeacherNames = announcement?.teachers?.map(
    (teacher) => teacher.display_name,
  );
  const destinationDescription = ccTeacherNames?.length
    ? intl.formatMessage(
        {
          id: 'announcements.announcementDetailPage.header.destinationDescription_cc_teacher_names',
        },
        { ccTeacherNames: ccTeacherNames?.join('、') },
      )
    : undefined;

  useEffect(() => {
    void fetchAnnouncement();
  }, [fetchAnnouncement]);

  useEffect(() => {
    if (isSuccess && data) {
      EventTracker.announcementView({
        registeredAt: announcement.sent_ts,
        organizationMembershipIds: map(
          announcement?.recipients,
          (r) => r.organization_membership_id,
        ),
      });

      void queryClient.refetchQueries({
        queryKey: ['announcements_unread_count'],
      });
    }
  }, [isSuccess, data, announcement, queryClient]);

  return isNotFound ? (
    <ErrorPage
      type="not_found"
      description={intl.formatMessage({
        id: 'announcements.errors.notFound.description',
      })}
    />
  ) : isNetworkError ? (
    <ErrorPage type="internal_server_error" />
  ) : (
    <SingleColumnTemplate>
      <Helmet>
        <title>{pageTitles.announcements}</title>
      </Helmet>
      <UiStackTemplate
        isLoading={isLoading}
        isError={isRestricted}
        errorView={<AnnouncementDetailRestricted />}
      >
        <PageTitle
          title={announcement.title}
          backLinkLabel={intl.formatMessage({
            id: 'announcements.announcementDetailPage.backToPageButton',
          })}
          backLinkUrl={backLinkUrl}
        />

        <div className={CSSModule.AnnouncementDetailPage__Card}>
          <Jumbotron flexHeight>
            <Paper>
              <DetailPanel>
                <DetailPanelAnnouncementHeader
                  avatar={
                    announcement.sender_user?.profile_image_url ? (
                      <img
                        src={announcement.sender_user?.profile_image_url}
                        alt=""
                      />
                    ) : (
                      <AvatarTeacherIcon />
                    )
                  }
                  sender={
                    announcement.sender_user?.display_name ||
                    intl.formatMessage({
                      id: 'announcements.announcementDetailPage.header.deleted_author_name',
                    })
                  }
                  sendAt={announcement.sent_ts}
                  destinations={map(announcement?.recipients, (r: Recipient) =>
                    intl.formatMessage(
                      {
                        id: 'announcements.announcementDetailPage.header.destinations_parents',
                      },
                      { destination: r.display_name },
                    ),
                  )}
                  destinationDescription={destinationDescription}
                />
                <DetailBody body={announcement.body} />
                <AnnouncementAttachments
                  files={announcement.announcement_files || []}
                />
              </DetailPanel>
            </Paper>
          </Jumbotron>
        </div>
        <AnnouncementDetailFooter />
      </UiStackTemplate>
    </SingleColumnTemplate>
  );
};

export default AnnouncementDetailPage;
