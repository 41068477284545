import React from 'react';
import { styled, css } from 'styled-components';

import DescriptionDetails from 'components/modules/lists/descriptionList/DescriptionDetails';
import DescriptionTerm from 'components/modules/lists/descriptionList/DescriptionTerm';

interface Props {
  children: [
    React.ReactElement<
      React.ComponentProps<typeof DescriptionTerm>,
      typeof DescriptionTerm
    >,
    React.ReactElement<
      React.ComponentProps<typeof DescriptionDetails>,
      typeof DescriptionDetails
    >,
  ];
  className?: string;
}

const ListItemStyled = styled.li(
  ({ theme }) => css`
    display: flex;

    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing.xl};
    }
  `,
);

const DescriptionListItem: React.FC<Props> = ({ children, className }) => (
  <ListItemStyled className={className}>{children}</ListItemStyled>
);

export default DescriptionListItem;
