/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Button from 'components/modules/button/Button';
import ErrorList from 'components/modules/forms/ErrorList';
import Field from 'components/modules/forms/Field';
import FormGroup from 'components/modules/forms/FormGroup';
import Input from 'components/modules/forms/Input';
import Label from 'components/modules/forms/Label';
import useFetch from 'hooks/common/useFetch';
import useReactHookForm from 'hooks/common/useReactHookForm';
import useToast from 'hooks/store/useToast';
import { SAPURI_EMAIL_REGEXP } from 'store/constants';

import CSSModule from './ForgotPasswordForm.module.scss';

type FormValues = {
  email: string;
  last_name: string;
  first_name: string;
};

interface ServerError {
  code?: string;
  message: string;
}

const ForgotPasswordForm: React.FC = () => {
  const intl = useIntl();
  const [formValues] = useState<FormValues>({
    email: '',
    last_name: '',
    first_name: '',
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(
        intl.formatMessage(
          { id: 'forms.errors.required' },
          {
            label: intl.formatMessage({
              id: 'forgotPage.forgotPassword.forgotPasswordForm.labels.email',
            }),
          },
        ),
      )
      .matches(SAPURI_EMAIL_REGEXP, {
        message: intl.formatMessage(
          { id: 'forms.errors.pattern' },
          {
            label: intl.formatMessage({
              id: 'forgotPage.forgotPassword.forgotPasswordForm.labels.email',
            }),
          },
        ),
        excludeEmptyString: true,
      }),
    last_name: Yup.string().required(
      intl.formatMessage(
        { id: 'forms.errors.required' },
        {
          label: intl.formatMessage({
            id: 'forgotPage.forgotPassword.forgotPasswordForm.labels.lastName',
          }),
        },
      ),
    ),
    first_name: Yup.string().required(
      intl.formatMessage(
        { id: 'forms.errors.required' },
        {
          label: intl.formatMessage({
            id: 'forgotPage.forgotPassword.forgotPasswordForm.labels.firstName',
          }),
        },
      ),
    ),
  });

  const {
    registerWithInnerRef,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid, errorMessages },
    getValues,
  } = useReactHookForm<FormValues>({
    validationSchema,
    defaultValues: formValues,
  });

  const { showToast } = useToast();
  const navigate = useNavigate();

  const {
    fetch,
    isFetching,
    error: serverError,
    status,
  } = useFetch<null, ServerError>(
    'POST',
    '/api/school/parents/password_change_tokens',
  );

  const [inputtedEmail, setInputtedEmail] = useState('');

  useEffect(() => {
    if (status === 201) {
      navigate('/forgot/password/confirmation');
      showToast(
        `${inputtedEmail} ${intl.formatMessage({
          id: 'forgotPage.forgotPassword.forgotPasswordForm.finished',
        })}`,
      );
    }
    // navigateを入れると2回発火するため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, showToast, inputtedEmail, status]);

  const onSubmit = (body: FormValues) => {
    setInputtedEmail(getValues().email);
    void fetch({ body });
  };

  const inputDisabled = isSubmitting || isFetching;
  const buttonDisabled = !isDirty || !isValid || isSubmitting || isFetching;

  const nameErrorMessages = [
    ...(errorMessages.last_name || []),
    ...(errorMessages.first_name || []),
    ...(serverError ? [serverError.message] : []),
  ];

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={CSSModule.ForgotPasswordForm}
    >
      <FormGroup>
        <Label>
          {intl.formatMessage({
            id: 'forgotPage.forgotPassword.forgotPasswordForm.labels.email',
          })}
        </Label>
        <Field>
          <Input
            {...registerWithInnerRef('email')}
            disabled={inputDisabled}
            placeholder={intl.formatMessage({
              id: 'forgotPage.forgotPassword.forgotPasswordForm.placeholders.email',
            })}
          />
          <ErrorList errors={errorMessages.email} />
        </Field>
      </FormGroup>
      <FormGroup>
        <Label>
          {intl.formatMessage({
            id: 'forgotPage.forgotPassword.forgotPasswordForm.labels.name',
          })}
        </Label>
        <Field>
          <div className={CSSModule.ForgotPasswordForm__HorizontalInput}>
            <div className={CSSModule.ForgotPasswordForm__HorizontalInputItem}>
              <Input
                {...registerWithInnerRef('last_name')}
                disabled={inputDisabled}
                placeholder={intl.formatMessage({
                  id: 'forgotPage.forgotPassword.forgotPasswordForm.placeholders.lastName',
                })}
              />
            </div>
            <div className={CSSModule.ForgotPasswordForm__HorizontalInputItem}>
              <Input
                {...registerWithInnerRef('first_name')}
                disabled={inputDisabled}
                placeholder={intl.formatMessage({
                  id: 'forgotPage.forgotPassword.forgotPasswordForm.placeholders.firstName',
                })}
              />
            </div>
          </div>
          <ErrorList errors={nameErrorMessages} />
        </Field>
      </FormGroup>
      <Button
        type="submit"
        color="primary"
        disabled={buttonDisabled}
        className={CSSModule.ForgotPasswordForm__SubmitButton}
      >
        <FormattedMessage id="forms.button.submit" />
      </Button>
    </form>
  );
};

export default ForgotPasswordForm;
