import React from 'react';
import { styled, css } from 'styled-components';

interface Props {
  flex?: boolean;
  flexItemWide?: boolean;
  children?: React.ReactNode;
}

const WrapperStyled = styled.div<{ $flex?: boolean; $flexItemWide?: boolean }>(
  ({ theme, $flex = false, $flexItemWide = false }) => css`
    display: ${$flex ? 'flex' : 'block'};
    flex-grow: ${$flexItemWide ? 1 : 0};
    background: ${theme.globalColor.white};

    ${theme.media.pc} {
      border-radius: ${theme.borderRadius.default};
      box-shadow: ${theme.paperBoxShadow.default};
    }

    ${theme.media.sp} {
      border-top: 1px solid ${theme.globalColor.gray100};
    }
  `,
);

const Paper: React.FC<Props> = ({
  children,
  flex = false,
  flexItemWide = false,
}) => (
  <WrapperStyled $flex={flex} $flexItemWide={flexItemWide}>
    {children}
  </WrapperStyled>
);

export default Paper;
