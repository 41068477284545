import React, { useCallback, useContext, useEffect, useState } from 'react';

import EditEmailModal from 'components/organisms/mypage/modals/EditEmailModal';
import PasswordConfirmationModal from 'components/organisms/mypage/modals/PasswordConfirmationModal';
import StudentModalWithParentAssociation from 'components/organisms/mypage/modals/StudentModalWithParentAssociation';
import StudentConfirmationModal from 'components/organisms/registration/modals/StudentConfirmationModal';
import WithdrawalConfirmationModal from 'components/organisms/withdrawal/modals/WithdrawalConfirmationModal';
import AbsencesCancelFormModal from 'components/pages/absences/modals/AbsencesCancelFormModal';
import AbsencesCancelModal from 'components/pages/absences/modals/AbsencesCancelModal';
import AbsencesCanceledModal from 'components/pages/absences/modals/AbsencesCanceledModal';
import AbsencesCreatedModal from 'components/pages/absences/modals/AbsencesCreatedModal';
import AbsencesEditedModal from 'components/pages/absences/modals/AbsencesEditedModal';
import AbsencesTimeExceededModal from 'components/pages/absences/modals/AbsencesTimeExceededModal';
import { AppContext } from 'providers/ContextProvider';

const modalComponents = {
  EditEmailModal,
  PasswordConfirmationModal,
  StudentConfirmationModal,
  StudentModalWithParentAssociation,
  WithdrawalConfirmationModal,
  AbsencesCancelFormModal,
  AbsencesCancelModal,
  AbsencesCreatedModal,
  AbsencesEditedModal,
  AbsencesCanceledModal,
  AbsencesTimeExceededModal,
};

export type ModalComponentsType = typeof modalComponents;

const ANIMATION_DURATION = 250;

const ModalRoot: React.FC = () => {
  const [renderModal, setRenderModal] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { isModalOpen, modalType, modalProps, setIsModalOpen } =
    useContext(AppContext);
  const toggle = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);

  useEffect(() => {
    // render null after closing animation to reset the state inside modal
    if (isModalOpen) {
      setRenderModal(isModalOpen);
    } else {
      const timer = setTimeout(
        () => setRenderModal(isModalOpen),
        ANIMATION_DURATION,
      );
      return () => clearTimeout(timer);
    }
  }, [setRenderModal, isModalOpen]);

  if (!modalType || !renderModal) {
    return null;
  }

  const Modal = modalComponents[modalType];

  return <Modal {...modalProps} isOpen={isModalOpen} toggle={toggle} />;
};

export default ModalRoot;
