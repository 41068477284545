import { startsWith } from 'lodash-es';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useToast from 'hooks/store/useToast';

import useAuthentication from './useAuthentication';

interface BuildErrorResponse {
  status?: number;
  error_code?: string;
  message?: string;
}

export interface ErrorResponse extends Error {
  status: number;
  error_code: string;
  json: {
    error_code: string | null;
    message: string | null;
  };
}

export const buildErrorResponse = ({
  status = NaN,
  error_code,
  message,
}: BuildErrorResponse) => {
  let err = new Error('Something Went Wrong') as ErrorResponse;
  if (status) {
    err = (
      !error_code && !message
        ? new Error(`HTTP status code: ${status} "Local Network Error"`)
        : message && startsWith(message, 'HTTP status code:')
          ? new Error(message)
          : new Error(
              `HTTP status code: ${status} "${
                message || 'Something Went wrong'
              }"`,
            )
    ) as ErrorResponse;
  }

  err.status = status;
  err.error_code = error_code || 'something_went_wrong';
  err.json = {
    error_code: error_code || null,
    message: message || null,
  };

  return err;
};

interface UseErrorReturn {
  unauthLogout: () => void;
  normalLogout: () => void;
}

const useError = (): UseErrorReturn => {
  const { clearUserData } = useAuthentication();
  const navigate = useNavigate();
  const { showToast } = useToast();

  const unauthLogout = useCallback(() => {
    clearUserData();
    showToast('アクセストークンの期限が過ぎたので自動でログアウトしました。');
    navigate('/login', { replace: true });
  }, [clearUserData, navigate, showToast]);

  const normalLogout = useCallback(() => {
    clearUserData();
    showToast('ログアウトしました。');
    navigate('/login', { replace: true });
  }, [clearUserData, navigate, showToast]);

  return {
    unauthLogout,
    normalLogout,
  };
};

export default useError;
