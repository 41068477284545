import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button from 'components/modules/button/Button';
import SingleCardTemplate from 'components/templates/SingleCardTemplate';
import { pageTitles } from 'constants/pageTitles';

import CSSModule from './OrganizationMembershipErrorPage.module.scss';

const OrganizationMembershipErrorPage: React.FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <SingleCardTemplate
      title={intl.formatMessage({
        id: 'mypage.organizationMembershipErrorPage.header',
      })}
    >
      <Helmet>
        <title>{pageTitles.mypageOrganizationMembershipsError}</title>
      </Helmet>
      <div className={CSSModule.OrganizationMembershipErrorPage}>
        <h3 className={CSSModule.Title}>
          <FormattedMessage id="mypage.organizationMembershipErrorPage.title" />
        </h3>
        <p className={CSSModule.Description}>
          <FormattedMessage id="mypage.organizationMembershipErrorPage.description" />
        </p>
        <Button
          onClick={() => navigate('/mypage/profile')}
          color="primary"
          className={CSSModule.BackToMyPage}
        >
          <FormattedMessage id="mypage.organizationMembershipErrorPage.backToMyPage" />
        </Button>
        <Button
          onClick={() => navigate(-1)}
          color="outline"
          className={CSSModule.BackToCodeSettingPage}
        >
          <FormattedMessage id="mypage.organizationMembershipErrorPage.backToCodeSettingPage" />
        </Button>
      </div>
    </SingleCardTemplate>
  );
};

export default OrganizationMembershipErrorPage;
