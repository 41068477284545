import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useAuthentication from 'hooks/common/useAuthentication';
import useCurrentUser from 'hooks/store/useCurrentUser';
import EventTracker from 'utils/EventTracker';

const TrackPageView: React.FC = () => {
  const { pathname, search, hash } = useLocation();
  const { isLoggedIn } = useAuthentication();
  const [currentParent] = useCurrentUser();

  useEffect(() => {
    EventTracker.updateContext({
      parentId: currentParent?.id,
      pathname,
      query: search,
      fragment: hash,
    });

    if (isLoggedIn && !currentParent) {
      return;
    }

    if (isLoggedIn && pathname === '/login') {
      return;
    }

    EventTracker.pageview();
  }, [isLoggedIn, currentParent, pathname, search, hash]);

  return null;
};

export default TrackPageView;
