import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button from 'components/modules/button/Button';
import SingleCardTemplate from 'components/templates/SingleCardTemplate';
import { pageTitles } from 'constants/pageTitles';

import CSSModule from './ProfileRegistrationErrorPage.module.scss';

const ProfileRegistrationErrorPage: React.FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <SingleCardTemplate
      title={intl.formatMessage({
        id: 'registration.profileRegistrationErrorPage.title',
      })}
    >
      <Helmet>
        <title>{pageTitles.registrationProfileError}</title>
      </Helmet>
      <div className={CSSModule.ProfileRegistrationErrorPage}>
        <h3 className={CSSModule.Title}>
          <FormattedMessage id="registration.emailRegistrationErrorPage.title" />
        </h3>
        <p className={CSSModule.Description}>
          <FormattedMessage id="registration.emailRegistrationErrorPage.description" />
        </p>
        <Button
          onClick={() => navigate(-1)}
          color="outline"
          className={CSSModule.BackToCodeSettingPage}
        >
          <FormattedMessage id="registration.emailRegistrationErrorPage.backToCodeSettingPage" />
        </Button>
      </div>
    </SingleCardTemplate>
  );
};

export default ProfileRegistrationErrorPage;
