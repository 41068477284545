import classNames from 'classnames';
import { range } from 'lodash-es';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';

import CSSModule from './pagination.module.scss';

const PAGE_LIMIT = 10;
const PAGE_BREAK_POINT = 3;

export interface PaginationProps {
  initialPage: number;
  totalPage: number;
  onPageChanged?: (page: number) => void;
}

interface Props {
  page?: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const PaginationPrev: React.FC<PaginationProps & Props> = ({
  currentPage,
  ...props
}) => (
  <div
    className={classNames(CSSModule.Pagination__Items, {
      [CSSModule.Pagination__Hide]: currentPage === 1,
    })}
  >
    <button
      className={CSSModule.Pagination__Prev}
      onClick={() =>
        handleClickPage({ currentPage, ...props, page: currentPage - 1 })
      }
    >
      <ArrowLeftIcon />
      <div className={CSSModule.Pagination__PrevLabel}>
        <FormattedMessage id="pagination.prev" />
      </div>
    </button>
  </div>
);
const PaginationNext: React.FC<PaginationProps & Props> = ({
  currentPage,
  totalPage,
  ...props
}) => (
  <div
    className={classNames(CSSModule.Pagination__Items, {
      [CSSModule.Pagination__Hide]:
        currentPage === totalPage || totalPage === 1,
    })}
  >
    <button
      className={CSSModule.Pagination__Next}
      onClick={() =>
        handleClickPage({
          currentPage,
          totalPage,
          ...props,
          page: currentPage + 1,
        })
      }
    >
      <ArrowRightIcon />
      <div className={CSSModule.Pagination__NextLabel}>
        <FormattedMessage id="pagination.next" />
      </div>
    </button>
  </div>
);

const PaginationNumbersEllipsisPatternStart: React.FC<
  PaginationProps & Props
> = ({ totalPage, ...props }) => (
  <>
    {/* start page */}
    {range(1, PAGE_BREAK_POINT + 2).map((page) => (
      <PaginationNumber
        key={page}
        {...props}
        totalPage={totalPage}
        page={page}
      />
    ))}

    {/* ellipsis page */}
    <PaginationItemEllipsis />

    {/* end page */}
    <PaginationNumber {...props} totalPage={totalPage} page={totalPage} />
  </>
);

const PaginationNumbersEllipsisPatternEnd: React.FC<
  PaginationProps & Props
> = ({ totalPage, ...props }) => (
  <>
    {/* start page */}
    <PaginationNumber {...props} totalPage={totalPage} page={1} />

    {/* ellipsis page */}
    <PaginationItemEllipsis />

    {/* end page */}
    {range(totalPage - PAGE_BREAK_POINT - 1, totalPage + 1).map((page) => (
      <PaginationNumber
        key={page}
        {...props}
        totalPage={totalPage}
        page={page}
      />
    ))}
  </>
);

const PaginationNumbersEllipsisPatternCenter: React.FC<
  PaginationProps & Props
> = ({ currentPage, totalPage, ...props }) => (
  <>
    {/* start page */}
    <PaginationNumber
      {...props}
      totalPage={totalPage}
      currentPage={currentPage}
      page={1}
    />

    {/* ellipsis page */}
    <PaginationItemEllipsis />

    {/* center page */}
    {range(currentPage - 1, currentPage + 2).map((page) => (
      <PaginationNumber
        key={page}
        {...props}
        totalPage={totalPage}
        currentPage={currentPage}
        page={page}
      />
    ))}

    {/* ellipsis page */}
    <PaginationItemEllipsis />

    {/* end page */}
    <PaginationNumber
      {...props}
      totalPage={totalPage}
      currentPage={currentPage}
      page={totalPage}
    />
  </>
);

const PaginationItemEllipsis: React.FC = () => (
  <li className={CSSModule.Pagination__Items}>
    <span className={CSSModule.Pagination__Ellipsis}>...</span>
  </li>
);

const PaginationNumber: React.FC<PaginationProps & Props> = ({
  page,
  currentPage,
  ...props
}) => (
  <li className={CSSModule.Pagination__Items}>
    <button
      className={classNames(CSSModule.Pagination__Numbers, {
        [CSSModule.Pagination__Numbers__Active]: page === currentPage,
      })}
      onClick={() => handleClickPage({ currentPage, ...props, page: page })}
    >
      {page}
    </button>
  </li>
);

const handleClickPage = ({
  page = 1,
  setCurrentPage,
  onPageChanged,
}: PaginationProps & Props) => {
  setCurrentPage(page);
  if (!onPageChanged) return;
  onPageChanged(page);
};

const PaginationCurrentGuide: React.FC<{ currentPage: number }> = ({
  currentPage,
}) => (
  <div
    className={classNames(
      CSSModule.Pagination__Items,
      CSSModule.Pagination__CurrentLabel,
    )}
  >
    <FormattedMessage
      id="pagination.currentPage"
      values={{
        page: currentPage,
      }}
    />
  </div>
);

const PaginationEllipsisContainer: React.FC<PaginationProps & Props> = ({
  totalPage,
  currentPage,
  ...props
}) =>
  currentPage < PAGE_BREAK_POINT + 1 ? (
    // ex) 1,2,3...20
    <PaginationNumbersEllipsisPatternStart
      totalPage={totalPage}
      currentPage={currentPage}
      {...props}
    />
  ) : totalPage - currentPage < PAGE_BREAK_POINT ? (
    // ex) 1...18,19,20
    <PaginationNumbersEllipsisPatternEnd
      totalPage={totalPage}
      currentPage={currentPage}
      {...props}
    />
  ) : (
    // ex) 1...8,9,10...20
    <PaginationNumbersEllipsisPatternCenter
      totalPage={totalPage}
      currentPage={currentPage}
      {...props}
    />
  );

const Pagination: React.FC<PaginationProps> = ({
  totalPage,
  initialPage: startPage,
  ...ownProps
}) => {
  const [currentPage, setCurrentPage] = useState<number>(
    startPage > totalPage ? totalPage : startPage < 0 ? 1 : startPage,
  );
  const props = {
    currentPage,
    setCurrentPage,
    ...ownProps,
  };
  if (totalPage <= 1) return null;
  return (
    <div className={CSSModule.Pagination} aria-label="Pagination Navigation">
      <nav>
        <PaginationPrev
          totalPage={totalPage}
          initialPage={startPage}
          page={1}
          {...props}
        />
        <PaginationCurrentGuide {...props} />
        <ul>
          {totalPage <= PAGE_LIMIT ? (
            range(1, totalPage + 1).map((page) => (
              <PaginationNumber
                key={page}
                totalPage={totalPage}
                initialPage={startPage}
                page={page}
                {...props}
              />
            ))
          ) : (
            <PaginationEllipsisContainer
              totalPage={totalPage}
              initialPage={startPage}
              {...props}
            />
          )}
        </ul>
        <PaginationNext
          totalPage={totalPage}
          initialPage={startPage}
          page={totalPage}
          {...props}
        />
      </nav>
    </div>
  );
};

export default Pagination;
