import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import ContextProvider from 'providers/ContextProvider';
import theme from 'store/theme';
import messages from 'translations/messages';

interface Props {
  children: React.ReactNode;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnWindowFocus: false,
      staleTime: 5000,
      networkMode: 'always',
    },
    mutations: {
      networkMode: 'always',
    },
  },
});

const Providers: React.FC<Props> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <IntlProvider locale="ja" messages={messages}>
      <HelmetProvider>
        <ContextProvider>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ContextProvider>
      </HelmetProvider>
    </IntlProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default Providers;
