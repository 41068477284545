import { parseISO } from 'date-fns';
import React from 'react';

import Panel from 'components/modules/box/Panel';
import Paper from 'components/modules/box/Paper';
import {
  contactTypeNames,
  reasonNames,
} from 'components/pages/absences/constants';
import AbsencesDetailContents from 'components/pages/absences/detail/AbsencesDetailContents';
import {
  ContactType,
  Reason,
  SubmitValues,
} from 'components/pages/absences/types';
import useCurrentUser from 'hooks/store/useCurrentUser';

interface Props {
  values: Partial<SubmitValues>;
}

const AbsencesConfirmBody: React.FC<Props> = ({
  values: {
    contact_type,
    reason,
    go_to_school_at,
    leave_school_at,
    disease_name,
    symptom,
    body_temperature,
    doctors_instruction,
    relationship,
    transportation,
    detail_comment,
    absence: { date, organization_membership_id } = {},
  },
}) => {
  const [currentUser] = useCurrentUser();
  const organizationMemberships = currentUser?.organization_memberships || [];
  const student = organizationMemberships.find(
    (obj) => obj.id === organization_membership_id,
  );

  return (
    <Paper>
      <Panel title="欠席連絡詳細">
        <AbsencesDetailContents
          studentName={student?.name}
          date={date ? parseISO(date) : null}
          contactType={contactTypeNames[contact_type as ContactType]}
          reason={reasonNames[reason as Reason]}
          goToSchoolAt={go_to_school_at}
          leaveSchoolAt={leave_school_at}
          diseaseName={disease_name}
          symptom={symptom}
          bodyTemperature={body_temperature}
          doctorsInstruction={
            doctors_instruction
              ? { '1': 'あり', '0': 'なし' }[doctors_instruction]
              : null
          }
          relationship={relationship}
          transportation={transportation}
          detailComment={detail_comment}
        />
      </Panel>
    </Paper>
  );
};

export default AbsencesConfirmBody;
