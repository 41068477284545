import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, css } from 'styled-components';

import Modal from 'components/atoms/modals/Modal';
import Button from 'components/modules/button/Button';
import useFetch from 'hooks/common/useFetch';
import useModal, { ModalInnerProps } from 'hooks/common/useModal';
import useToast from 'hooks/store/useToast';
interface Props extends ModalInnerProps {
  absenceId: string;
  contactType: string;
  organizationMembershipId: string;
}

const DescriptionStyled = styled.div(
  ({ theme }) => css`
    ${theme.media.pc} {
      margin-top: ${theme.spacing.l};
      text-align: center;
    }

    ${theme.media.sp} {
      margin-top: ${theme.spacing.m};
    }
  `,
);
const ButtonsStyled = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing.xxl};
  `,
);
const ButtonStyled = styled(Button)(
  ({ theme }) => css`
    ${theme.media.pc} {
      width: 120px;
    }

    &:first-child {
      margin-right: ${theme.spacing.m};
    }
  `,
);

const AbsencesCancelModal: React.FC<Props> = ({
  isOpen,
  toggle,
  absenceId,
  contactType,
  organizationMembershipId,
}) => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { showModal } = useModal();
  const { fetch: fetchCancel, isFetching } = useFetch(
    'PUT',
    `/api/school_communication/absences/${absenceId}/${contactType}`,
  );

  const handleOnClick = async () => {
    const res = await fetchCancel();
    const { status } = res;

    if (status === 200) {
      navigate(`/absences/member/${organizationMembershipId}`);
      toggle();
      showModal('AbsencesCanceledModal', {});
    } else {
      showToast('欠席連絡の申請が取り消しできませんでした。');
      toggle();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title={'本当に申請を取り消しますか？'}
    >
      <DescriptionStyled>
        この操作は取り消せません。本当に申請を取り消してもよろしいですか？
      </DescriptionStyled>
      <ButtonsStyled>
        <ButtonStyled size="s" color="outline" onClick={toggle}>
          キャンセル
        </ButtonStyled>
        <ButtonStyled
          size="s"
          color="danger"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleOnClick}
          disabled={isFetching}
        >
          取り消し
        </ButtonStyled>
      </ButtonsStyled>
    </Modal>
  );
};

export default AbsencesCancelModal;
