import React from 'react';
import {
  Modal as ReactStrapModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import { ModalInnerProps } from 'hooks/common/useModal';

import CSSModule from './Modal.module.scss';

interface Props extends ModalInnerProps {
  title: string;
  footer?: JSX.Element;
  className?: string;
  children?: React.ReactNode;
}

const Modal: React.FC<Props> = ({
  isOpen,
  toggle,
  title,
  footer,
  className,
  children,
}) => (
  <ReactStrapModal
    isOpen={isOpen}
    toggle={toggle}
    cssModule={CSSModule}
    className={className}
  >
    <ModalHeader cssModule={CSSModule}>{title}</ModalHeader>
    <ModalBody cssModule={CSSModule}>
      <div className={CSSModule.ModalBodyInner}>{children}</div>
    </ModalBody>
    {footer && <ModalFooter cssModule={CSSModule}>{footer}</ModalFooter>}
  </ReactStrapModal>
);

export default Modal;
