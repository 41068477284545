import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'components/modules/button/Button';
import ConsentTrailParameterFactory from 'utils/ConsentTrailParameterFactory';

import CSSModule from './ButtonWithAgreement.module.scss';

interface Props {
  disabled: boolean;
  children?: React.ReactNode;
}

const ButtonWithAgreement: React.FC<Props> = ({ disabled, children }) => (
  <div className={CSSModule.ButtonWithAgreement}>
    <p className={CSSModule.ButtonWithAgreement__Text}>
      <a
        href={ConsentTrailParameterFactory.termsUrl}
        rel="noopener noreferrer"
        target="blank"
      >
        <FormattedMessage id="forms.agreement.terms" />
      </a>
      <FormattedMessage id="forms.agreement.and" />
      <a
        href={ConsentTrailParameterFactory.privacyPolicyUrl}
        rel="noopener noreferrer"
        target="blank"
      >
        <FormattedMessage id="forms.agreement.privacy" />
      </a>
    </p>
    <Button
      color="primary"
      type="submit"
      disabled={disabled}
      className={CSSModule.ButtonWithAgreement__Button}
    >
      {children}
    </Button>
  </div>
);

export default ButtonWithAgreement;
