export const Kind = {
  IMPORTANT: 'important',
  NORMAL: 'normal',
} as const;
type Kind = (typeof Kind)[keyof typeof Kind];
export interface NoticeFromManagement {
  id: number;
  kind: Kind;
  publish_start_at: string;
  title: string;
  body: string;
}
