/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Darklaunch V2 API
 * すべて一人のユーザに対するkey(s)の値をbooleanのJSON Objectなどで取得する。 複数のユーザに対してまとめてクエリする方法はない。  なお、ここでいうユーザとは与えられたidentifierの文字列の完全一致のみで判断するものである。 identifierには名前空間があり、現在 User / Organization / Aya::Pg::Account / Parentの4種類くらいをサポ〜トする。 ※ 2023-11-14現在、Parentは未対応 AccessToken や IP や session id(?) あたりもユースケースが多いなら追加するけどいまのところそんなになさそうなので少なくともいまはない。ユースケースに依存 
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VariationsByKeysGet200ResponseInner
 */
export interface VariationsByKeysGet200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof VariationsByKeysGet200ResponseInner
     */
    key?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VariationsByKeysGet200ResponseInner
     */
    variation?: boolean;
}

/**
 * Check if a given object implements the VariationsByKeysGet200ResponseInner interface.
 */
export function instanceOfVariationsByKeysGet200ResponseInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VariationsByKeysGet200ResponseInnerFromJSON(json: any): VariationsByKeysGet200ResponseInner {
    return VariationsByKeysGet200ResponseInnerFromJSONTyped(json, false);
}

export function VariationsByKeysGet200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariationsByKeysGet200ResponseInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'variation': !exists(json, 'variation') ? undefined : json['variation'],
    };
}

export function VariationsByKeysGet200ResponseInnerToJSON(value?: VariationsByKeysGet200ResponseInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'variation': value.variation,
    };
}

