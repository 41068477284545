import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';

import Button from 'components/modules/button/Button';
import { OrganizationManegement } from 'components/pages/absences/AbsencesDetailPage';
import AbsencesFooter from 'components/pages/absences/parts/AbsencesFooter';
import AbsencesFooterContent from 'components/pages/absences/parts/AbsencesFooterContent';
import useEditableChecker from 'hooks/absences/useEditableChecker';
import useTimeExceededChecker from 'hooks/absences/useTimeExceededChecker';
import useModal from 'hooks/common/useModal';

interface Props {
  onBack?: React.MouseEventHandler<HTMLButtonElement>;
  date?: string;
  organizationMembershipId?: string;
  organizationManagement?: OrganizationManegement;
}

const ButtonStyled = styled(Button)`
  display: block;
  margin: 0 auto;
`;

const AbsencesDetailFooter: React.FC<Props> = ({
  onBack,
  date = '',
  organizationMembershipId = '',
  organizationManagement,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 639px)' });
  const { showModal } = useModal();
  const { absenceId = '', contactType = '' } = useParams();
  const { isTimeExceededWithLimit } = useTimeExceededChecker();
  const { isEditable } = useEditableChecker();

  const handleOnClickCancel = React.useCallback(() => {
    isTimeExceededWithLimit(date, organizationManagement?.absence_time_limit) &&
    date !== null
      ? showModal('AbsencesTimeExceededModal', {
          timeLimit: organizationManagement?.absence_time_limit,
          postDate: date,
        })
      : showModal('AbsencesCancelModal', {
          absenceId,
          contactType,
          organizationMembershipId,
        });
  }, [
    absenceId,
    contactType,
    date,
    isTimeExceededWithLimit,
    organizationManagement,
    organizationMembershipId,
    showModal,
  ]);

  const timeLimit = organizationManagement?.absence_time_limit;

  return (
    <AbsencesFooter>
      {isEditable(date) ? (
        timeLimit ? (
          <AbsencesFooterContent
            note={`当日の受付時間は${timeLimit}までです。`}
            description={`※当日、${timeLimit}以降は直接学校に問い合わせてください。`}
          />
        ) : null
      ) : (
        <AbsencesFooterContent
          note="過去の欠席連絡は変更や取り消しができません。"
          description="※実際の在校状況が優先されるのでご了承ください。"
        />
      )}
      {isEditable(date) && (
        <ButtonStyled
          color="outline"
          size={isMobile ? 'm' : 'l'}
          onClick={handleOnClickCancel}
          data-testid="cancelButton"
        >
          申請を取り消す
        </ButtonStyled>
      )}
      <ButtonStyled color="link" size={isMobile ? 'm' : 'l'} onClick={onBack}>
        &lt; 戻る
      </ButtonStyled>
    </AbsencesFooter>
  );
};

export default AbsencesDetailFooter;
