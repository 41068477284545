import React from 'react';

import arrowCircleBlue from 'assets/icons/arrow-circle-blue.png';
import arrowCircle from 'assets/icons/arrow-circle.png';
import Collapse from 'components/atoms/collapse/Collapse';

import CSSModule from './CollapseBox.module.scss';

interface Props {
  title: string;
  isOpen: boolean;
  onClick: () => void;
  children?: React.ReactNode;
}

const CollapseBox: React.FC<Props> = ({ title, isOpen, onClick, children }) => (
  <div className={CSSModule.CollapseBox}>
    <h3 onClick={onClick} className={CSSModule.CollapseBox__Title}>
      {isOpen ? (
        <img src={arrowCircleBlue} alt="collapse is open" />
      ) : (
        <img src={arrowCircle} alt="collapse is closed" />
      )}
      <span className={CSSModule.CollapseBox__TitleName}>{title}</span>
    </h3>
    <Collapse isOpen={isOpen}>{children}</Collapse>
  </div>
);

export default CollapseBox;
