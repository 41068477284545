import { captureException, captureMessage } from '@sentry/browser';
import { startsWith } from 'lodash-es';
import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as AbsenceIcon } from 'assets/icons/absence.svg';
import { ReactComponent as AnnouncementIcon } from 'assets/icons/announcement.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import NumberBadge from 'components/modules/badge/NumberBadge';
import Dropdown from 'components/modules/dropdown/Dropdown';
import DropdownItem from 'components/modules/dropdown/DropdownItem';
import DropdownMenu from 'components/modules/dropdown/DropdownMenu';
import DropdownToggle from 'components/modules/dropdown/DropdownToggle';
import Nav from 'components/modules/mainHeader/Nav';
import NavItem from 'components/modules/mainHeader/NavItem';
import useAuthentication from 'hooks/common/useAuthentication';
import useFetch from 'hooks/common/useFetch';
import useReactQuery from 'hooks/common/useReactQuery';
import { useDarklaunchV2VariationWithoutIdentifier } from 'hooks/useDarklaunchV2';

import CSSModule from './MainHeaderNav.module.scss';

interface Props {
  isHidden?: boolean;
  showNumberBadge?: boolean;
}

export interface UnreadNoticesCount {
  unread_count: number;
}

interface UnreadAnnouncementsCount {
  unread_count: number;
}

const MainHeaderNav: React.FC<Props> = ({
  isHidden = false,
  showNumberBadge = true,
}) => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const { clearUserData, isLoggedIn } = useAuthentication();
  const { useQuery } = useReactQuery();

  const { variation: isEnableSchoolCommParentsAuthV2 } =
    useDarklaunchV2VariationWithoutIdentifier(
      'enable-school-comm-parents-auth-v2',
    );

  const { fetch, isFetching, status } = useFetch(
    'DELETE',
    isEnableSchoolCommParentsAuthV2
      ? '/api/school/auth/token'
      : '/api/school/parents/access_tokens',
  );

  const deleteAccessToken = useCallback(() => {
    void fetch();
  }, [fetch]);

  const { data, error, errorUpdatedAt } = useQuery<UnreadNoticesCount>({
    queryKey: ['unread_count'],
    url: '/api/school/notice_from_managements/unread_count',
    enabled: isLoggedIn,
    staleTime: 0,
  });

  useEffect(() => {
    if (error && errorUpdatedAt) {
      captureMessage(`Failed to fetch unreadNoticesCount: ${error.error_code}`);
      captureException(error || 'unreadNoticesCount: something wrong');
    }
  }, [error, errorUpdatedAt]);

  const unreadCount = data?.unread_count;

  const {
    data: announceUnreadData,
    error: announceUnreadError,
    errorUpdatedAt: annouceUnreadErrorAt,
  } = useQuery<UnreadAnnouncementsCount>({
    queryKey: ['announcements_unread_count'],
    url: '/api/school_communication/announcements/unread_count',
    enabled: isLoggedIn,
    staleTime: 0,
  });

  useEffect(() => {
    if (announceUnreadError && annouceUnreadErrorAt) {
      captureMessage(
        `Failed to fetch announcementsUnreadCount: ${announceUnreadError.error_code}`,
      );
      captureException(
        announceUnreadError || 'announcementsUnreadCount: something wrong',
      );
    }
  }, [announceUnreadError, annouceUnreadErrorAt]);

  const isAnnounceUnread = !!announceUnreadData?.unread_count;

  useEffect(() => {
    if (isEnableSchoolCommParentsAuthV2 ? status === 204 : status === 200) {
      clearUserData();
      navigate('/login');
    }
    // navigateを入れると2回発火するため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearUserData, status]);

  if (isHidden || !isLoggedIn) return null;

  return (
    <Nav>
      <NavItem
        icon={<AnnouncementIcon />}
        label={intl.formatMessage({ id: 'header.nav.announcements' })}
        url="/announcements"
        active={startsWith(location.pathname, '/announcements')}
        badgeShowEmpty={isAnnounceUnread}
      />
      <NavItem
        icon={<AbsenceIcon />}
        label={intl.formatMessage({ id: 'header.nav.absences' })}
        url="/absences"
        active={startsWith(location.pathname, '/absences')}
      />
      <Dropdown>
        <DropdownToggle>
          <NavItem
            icon={<MenuIcon />}
            label={intl.formatMessage({ id: 'header.nav.menu' })}
            active={startsWith(location.pathname, '/mypage/profile')}
          />
        </DropdownToggle>
        {!!unreadCount && showNumberBadge && (
          <div
            className={CSSModule.NavItem__NumberBadge}
            data-testid="navitem_number_badge"
          >
            <NumberBadge showEmpty />
          </div>
        )}
        <DropdownMenu className={CSSModule.MainHeaderNav__DropDownMenu}>
          <DropdownItem url="/notices">
            <div className={CSSModule.DropDownItem__Notices}>
              <div>運営からのお知らせ</div>
              {!!unreadCount && showNumberBadge && (
                <div data-testid="dropdown_notices_number_badge">
                  <NumberBadge showEmpty />
                </div>
              )}
            </div>
          </DropdownItem>
          <DropdownItem url="/mypage/profile">アカウント設定</DropdownItem>
          <DropdownItem url="/contact">お問い合わせ</DropdownItem>
          <DropdownItem onClick={deleteAccessToken} disabled={isFetching}>
            ログアウト
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Nav>
  );
};

export default MainHeaderNav;
