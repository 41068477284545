import React from 'react';
import { styled } from 'styled-components';

interface Props {
  title?: string;
  $strongHeader?: boolean;
  $centerBody?: boolean;
  children?: React.ReactNode;
}

const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PanelHeaderStyled = styled.div<Partial<Props>>(
  ({ theme, $strongHeader = false }) => ({
    '&&&': {
      backgroundColor: 'transparent',

      [theme.media.sp]: {
        paddingTop: theme.spacing.m,
        background: theme.globalColor.gray50,
      },

      '> div': {
        display: 'flex',
        alignItems: 'center',
        minHeight: '56px',
        padding: `0 ${theme.spacing.xxl}`,
        borderBottom: `1px solid ${theme.globalColor.gray100}`,
        color: theme.globalColor.gray700,

        [theme.media.sp]: {
          minHeight: '36px',
          padding: `0 ${theme.spacing.l}`,
          background: theme.globalColor.gray50,
        },

        '> h2': {
          margin: 0,
          padding: 0,
          fontSize: theme.fontSize.l,
          fontWeight: theme.fontWeight.bold,
          lineHeight: theme.lineHeight.default,
          wordBreak: 'break-all',
          whiteSpace: 'pre-wrap',

          [theme.media.sp]: {
            fontSize: theme.fontSize.s,
          },
        },
      },

      ...($strongHeader
        ? {
            [theme.media.pc]: {
              backgroundColor: theme.globalColor.gray100,

              '> div': {
                padding: 0,
                justifyContent: 'center',
              },
            },
          }
        : {}),
    },
  }),
);

const PanelBodyStyled = styled.div<Partial<Props>>(
  ({ theme, $centerBody = false }) => ({
    '&&&': {
      padding: theme.spacing.xxl,

      [theme.media.sp]: {
        padding: theme.spacing.l,
      },

      ...($centerBody
        ? {
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            color: theme.globalColor.gray700,
            padding: `0 ${theme.spacing.l}`,
          }
        : {}),
    },
  }),
);

const Panel: React.FC<Props> = ({
  title,
  children,
  $strongHeader,
  $centerBody,
}) => (
  <WrapperStyled>
    {title && (
      <PanelHeaderStyled $strongHeader={$strongHeader}>
        <div>
          <h2>{title}</h2>
        </div>
      </PanelHeaderStyled>
    )}
    <PanelBodyStyled $centerBody={$centerBody}>{children}</PanelBodyStyled>
  </WrapperStyled>
);

export default Panel;
