class Browser {
  public static reload() {
    window.location.reload();
  }

  public static replace(url: string) {
    window.location.replace(url);
  }
}

export default Browser;
