import bytes from 'bytes';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as FileErrorIcon } from 'assets/icons/file_error.svg';
import FileTypeIcon from 'components/atoms/common/FileTypeIcon';
import Loading from 'components/atoms/loading/Loading';

import CSSModule from './FileInfo.module.scss';

interface Props {
  name: string;
  size?: number;
  type?: string;
  onClick?: () => void;
  isLoading?: boolean;
  isDeleted?: boolean;
}

const FileInfo: React.FC<Props> = ({
  name,
  size,
  type,
  onClick,
  isLoading,
  isDeleted,
}) => (
  <div
    className={classNames(CSSModule.FileInfo, {
      [CSSModule.FileInfo__OnClick]: onClick,
    })}
    onClick={onClick}
    data-testid="FileInfo"
    data-dd-action-name="FileInfo"
  >
    <div
      className={classNames(CSSModule.FileInfo__FileType, {
        [CSSModule.FileInfo__FileType_Deleted]: isDeleted,
      })}
    >
      {isLoading ? (
        <div className={CSSModule.FileInfo__Loading}>
          <Loading className={CSSModule.FileInfo__LoadingIcon} />
        </div>
      ) : isDeleted ? (
        <FileErrorIcon />
      ) : (
        type && <FileTypeIcon type={type} />
      )}
    </div>
    <div className={CSSModule.FileInfo__Body}>
      {!isDeleted ? (
        <>
          <div
            className={CSSModule.FileInfo__FileName}
            data-dd-action-name="FileInfo__FileName"
          >
            {name}
          </div>
          {size && (
            <div className={CSSModule.FileInfo__FileSize}>
              {bytes(size, {
                unitSeparator: ' ',
              })}
            </div>
          )}
        </>
      ) : (
        <div className={CSSModule.FileInfo__FileDeletedText}>
          <FormattedMessage id="announcements.announcementDetailPage.deletedFile" />
        </div>
      )}
    </div>
  </div>
);

export default FileInfo;
