import React from 'react';
import { styled, css } from 'styled-components';

interface Props {
  children: React.ReactNode;
  className?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

export const WrapperStyled = styled.label(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: ${theme.fontSize.l};
    line-height: ${theme.lineHeight.default};
  `,
);

export const TitleStyled = styled.div(
  ({ theme }) => css`
    width: 100%;
    color: ${theme.globalColor.gray700};
    font-size: ${theme.fontSize.default};
    font-weight: ${theme.fontWeight.bold};

    ${theme.media.pc} {
      font-size: ${theme.fontSize.l};
    }
  `,
);

const PrefixStyled = styled.div(
  ({ theme }) => css`
    margin-right: ${theme.spacing.m};
    line-height: 1;
  `,
);

const SuffixStyled = styled.div(
  ({ theme }) => css`
    margin-left: ${theme.spacing.m};
    line-height: 1;
  `,
);

const Label: React.FC<Props> = ({ className, children, prefix, suffix }) => (
  <WrapperStyled className={className}>
    {prefix && <PrefixStyled>{prefix}</PrefixStyled>}
    <TitleStyled>{children}</TitleStyled>
    {suffix && <SuffixStyled>{suffix}</SuffixStyled>}
  </WrapperStyled>
);

export default Label;
