/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import StudentModal from 'components/organisms/common/StudentModal';
import useFetch from 'hooks/common/useFetch';
import { ModalInnerProps } from 'hooks/common/useModal';
import useCurrentUser from 'hooks/store/useCurrentUser';
import useToast from 'hooks/store/useToast';
import { Student } from 'interfaces/student';

interface Props extends ModalInnerProps {
  student: Student;
}

const StudentModalWithParentAssociation: React.FC<Props> = ({
  isOpen,
  toggle,
  student,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [, setCurrentUser] = useCurrentUser();
  const { fetch, isFetching, status, error } = useFetch(
    'POST',
    '/api/school_communication/parent_organization_memberships',
  );

  const serverErrors = error && [error.message];
  const associateParent = () =>
    fetch({
      body: { organization_membership: { code: student.code } },
    });
  const onCancel = () => {
    navigate('/mypage/organization_memberships/new/error');
    toggle();
  };

  useEffect(() => {
    if (status === 201) {
      setCurrentUser(
        (currentUser) =>
          currentUser && {
            ...currentUser,
            organization_memberships: [
              ...(currentUser.organization_memberships || []),
              student,
            ],
          },
      );
      showToast(
        intl.formatMessage({
          id: 'mypage.organizationMembershipRegistration.completed',
        }),
      );
      navigate('/mypage/profile');
      toggle();
    }
    // navigateを入れると2回発火するため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, showToast, toggle, status, setCurrentUser, student]);

  return (
    <StudentModal
      isFetching={isFetching}
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={associateParent}
      serverErrors={serverErrors}
      student={student}
      toggle={toggle}
    />
  );
};

export default StudentModalWithParentAssociation;
