import json from './ja.json';

interface Messages {
  [key: string]: string;
}

interface NestedMessages {
  [key: string]: string | NestedMessages;
}

const flattenMessages = (
  nestedMessages: NestedMessages,
  prefix = '',
): Messages =>
  Object.keys(nestedMessages).reduce((messages, key) => {
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});

export default flattenMessages(json);
