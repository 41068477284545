import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';

import useToast from 'hooks/store/useToast';
import { AppContext } from 'providers/ContextProvider';

import CSSModule from './Toast.module.scss';

interface RenderToastProps {
  setIsPopIn: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFinished: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
  hideToast: () => void;
  isFinished: boolean;
  status: string;
}

const ENTER_TIMEOUT = 1000;
const EXIT_TIMEOUT = 1000;
const AUTO_HIDE_DURATION = 4000;
const HIDE_TIMEOUT = 1000;

const RenderToast: React.FC<RenderToastProps> = ({
  status,
  setIsPopIn,
  setIsFinished,
  hideToast,
  message,
  isFinished,
}) => {
  const classes = classNames(CSSModule.Toast, {
    [CSSModule.isEntering]: status === 'entering',
    [CSSModule.isEntered]: status === 'entered',
    [CSSModule.isExiting]: status === 'exiting',
    [CSSModule.isExited]: status === 'exited' && isFinished,
  });

  const hundleClickToHide = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsPopIn(false);
    window.setTimeout(() => {
      hideToast();
      setIsFinished(true);
    }, HIDE_TIMEOUT);
  };

  return (
    <div role="alert" aria-label="toast" className={classes}>
      <a
        href="#"
        className={CSSModule.Toast__Message}
        onClick={hundleClickToHide}
      >
        {message}
      </a>
    </div>
  );
};

const Toast: React.FC = () => {
  const { toastMessage } = useContext(AppContext);
  const { hideToast } = useToast();
  const [isPopIn, setIsPopIn] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(true);

  useEffect(() => {
    setIsFinished(false);

    let inPopTimer = NaN;
    let hideTimer = NaN;

    if (toastMessage) {
      setIsPopIn(true);

      inPopTimer = window.setTimeout(() => {
        setIsPopIn(false);

        hideTimer = window.setTimeout(() => {
          hideToast();
          setIsFinished(true);
        }, HIDE_TIMEOUT);
      }, AUTO_HIDE_DURATION);
    }

    return () => {
      window.clearTimeout(inPopTimer);
      window.clearTimeout(hideTimer);
    };
  }, [hideToast, toastMessage]);

  return (
    <Transition
      in={isPopIn}
      timeout={{ enter: ENTER_TIMEOUT, exit: EXIT_TIMEOUT }}
    >
      {(status: string) => (
        <RenderToast
          status={status}
          setIsPopIn={setIsPopIn}
          setIsFinished={setIsFinished}
          hideToast={hideToast}
          message={toastMessage}
          isFinished={isFinished}
        />
      )}
    </Transition>
  );
};

export default Toast;
