import React from 'react';

import FileInfo from 'components/molecules/common/FileInfo';
import useDownloadBlob from 'hooks/common/useDownloadBlob';
import useFetch from 'hooks/common/useFetch';
import { AnnouncementFile } from 'interfaces/announcement';

interface Props {
  announcement_file: AnnouncementFile;
}

const AnnouncementFileInfo: React.FC<Props> = ({
  announcement_file: { id, original_file_name, content_type, content_length },
}) => {
  const { fetch: fetchAnnouncementFileOriginal, isFetching } = useFetch<Blob>(
    'GET',
    `/api/school_communication/announcement_files/${id}/original`,
    { responseType: 'blob' },
  );

  const download = useDownloadBlob();

  const onClick = () => {
    void (async () => {
      const { data } = await fetchAnnouncementFileOriginal();
      if (data.size > 0) {
        download(data, { download: original_file_name, type: content_type });
      }
    })();
  };

  return (
    <FileInfo
      key={id}
      name={original_file_name}
      type={content_type}
      size={content_length}
      onClick={onClick}
      isLoading={isFetching}
    />
  );
};

export default AnnouncementFileInfo;
