const TERMS_SET_ID = 'stb-ts-4009';

class ConsentTrailParameterFactory {
  public static readonly termsUrl =
    'https://cdn.p.recruit.co.jp/terms/stb-t-1005/index.html';
  public static readonly privacyPolicyUrl =
    'https://cdn.p.recruit.co.jp/terms/cmn-t-1001/index.html';

  public static build() {
    return {
      terms_set_id: TERMS_SET_ID,
    };
  }
}

export default ConsentTrailParameterFactory;
