import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { styled, css } from 'styled-components';

import CollapseBox from 'components/atoms/collapse/CollapseBox';
import Button from 'components/modules/button/Button';
import Contact from 'components/organisms/contact/Contact';
import ForgotPasswordForm from 'components/organisms/forgot/ForgotPasswordForm';
import SingleCardTemplate from 'components/templates/SingleCardTemplate';
import { pageTitles } from 'constants/pageTitles';

const WrapperStyled = styled.div(
  ({ theme }) => css`
    ${theme.media.pc} {
      padding: ${theme.spacing.m} 0;
    }

    ${theme.media.sp} {
      padding: ${theme.spacing.l} ${theme.spacing.m};
    }
  `,
);

const DescriptionStyled = styled.p(
  ({ theme }) => css`
    margin: 0;
    color: ${theme.globalColor.gray700};
    font-size: ${theme.fontSize.default};
    line-height: ${theme.lineHeight.default};

    ${theme.media.pc} {
      margin-bottom: ${theme.spacing.xxl};
      text-align: center;
    }

    ${theme.media.sp} {
      margin-bottom: ${theme.spacing.xl};
      text-align: left;
    }
  `,
);

const CollapseBoxesStyled = styled.div(
  ({ theme }) => css`
    a {
      text-decoration: none;
    }

    ${theme.media.pc} {
      margin: 0 100px ${theme.spacing.m};
    }
  `,
);

const CollapseDescriptionStyled = styled.p(
  ({ theme }) => css`
    color: ${theme.globalColor.gray700};
    font-size: ${theme.fontSize.default};
    line-height: ${theme.lineHeight.default};
  `,
);

const CollapseForgotMailAddressRemarkStyled = styled.p(
  ({ theme }) => css`
    color: ${theme.globalColor.gray700};
    font-size: ${theme.fontSize.s};
  `,
);

const ButtonsStyled = styled.div`
  text-align: center;
`;

const ButtonStyled = styled(Button)(
  ({ theme }) => css`
    ${theme.media.pc} {
      width: ${theme.button.width.m};
    }

    ${theme.media.sp} {
      width: ${theme.button.widthSp.s};
    }
  `,
);

const ForgotPage: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const openPassword = () => {
    if (pathname === '/forgot/password') {
      navigate('/forgot');
    } else {
      navigate('/forgot/password');
    }
  };

  const openEmail = () => {
    if (pathname === '/forgot/email') {
      navigate('/forgot');
    } else {
      navigate('/forgot/email');
    }
  };

  const openOther = () => {
    if (pathname === '/forgot/other') {
      navigate('/forgot');
    } else {
      navigate('/forgot/other');
    }
  };

  return (
    <SingleCardTemplate title={pageTitles.forgot}>
      <Helmet>
        <title>{pageTitles.forgot}</title>
      </Helmet>
      <WrapperStyled>
        <DescriptionStyled>
          <FormattedMessage id="forgotPage.title" />
        </DescriptionStyled>

        <CollapseBoxesStyled>
          <CollapseBox
            title={intl.formatMessage({
              id: 'forgotPage.forgotPassword.title',
            })}
            isOpen={pathname === '/forgot/password'}
            onClick={openPassword}
          >
            <CollapseDescriptionStyled>
              <FormattedMessage id="forgotPage.forgotPassword.description" />
            </CollapseDescriptionStyled>
            <ForgotPasswordForm />
            <hr />
          </CollapseBox>
          <CollapseBox
            title={intl.formatMessage({
              id: 'forgotPage.forgotMailAddress.title',
            })}
            isOpen={pathname === '/forgot/email'}
            onClick={openEmail}
          >
            <CollapseDescriptionStyled>
              <FormattedMessage id="forgotPage.forgotMailAddress.description" />
            </CollapseDescriptionStyled>
            <CollapseForgotMailAddressRemarkStyled>
              <FormattedMessage id="forgotPage.forgotMailAddress.remark" />
            </CollapseForgotMailAddressRemarkStyled>
            <hr />
          </CollapseBox>
          <CollapseBox
            title={intl.formatMessage({
              id: 'forgotPage.forgotOther.title',
            })}
            isOpen={pathname === '/forgot/other'}
            onClick={openOther}
          >
            <Contact />
            <hr />
          </CollapseBox>
        </CollapseBoxesStyled>
        <ButtonsStyled>
          <ButtonStyled tag={Link} to="/login" color="outline">
            <FormattedMessage id="forgotPage.backToLoginPage" />
          </ButtonStyled>
        </ButtonsStyled>
      </WrapperStyled>
    </SingleCardTemplate>
  );
};

export default ForgotPage;
