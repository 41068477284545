import React from 'react';

import { ReactComponent as AppLogo } from 'assets/logo/for-parents.svg';
import LoadingCircle from 'components/atoms/loading/Loading';

import CSSModule from './LoadingScreen.module.scss';

const LoadingScreen: React.FC = () => (
  <div className={CSSModule.LoadingScreen}>
    <span className={CSSModule.LoadingScreen__Inner}>
      <AppLogo className={CSSModule.LoadingScreen__Logo} />
      <LoadingCircle
        className={CSSModule.LoadingScreen__CircleContainer}
        svgClassName={CSSModule.LoadingScreen__Circle}
      />
    </span>
  </div>
);

export default LoadingScreen;
