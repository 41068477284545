import React from 'react';
import { styled, css } from 'styled-components';

import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  innerRef?: React.Ref<HTMLInputElement>;
}

const WrapperStyled = styled.label<{ $disabled?: boolean }>(
  ({ theme, $disabled = false }) => css`
    display: inline-flex;
    position: relative;
    align-items: center;
    height: ${theme.icon.size.s};
    margin: 0;
    padding: 0;
    cursor: ${$disabled ? 'default' : 'pointer'};
  `,
);

const InputStyled = styled.input`
  display: none;
`;

const IconStyled = styled(TickIcon)(
  ({ theme }) => css`
    position: relative;
    z-index: 0;
    width: ${theme.icon.size.s};
    height: ${theme.icon.size.s};
    padding: ${theme.spacing.s};
    border: 1px solid ${theme.form.input.borderColor.default};
    border-radius: ${theme.borderRadius.default};
    background-color: ${theme.globalColor.white};
    fill: transparent;

    ${InputStyled}:checked + & {
      border: none;
      background-color: ${theme.globalColor.skyBlue600};
      fill: ${theme.globalColor.white};
    }

    ${InputStyled}:disabled + & {
      ${theme.form.input.styles.disabled}
    }

    ${InputStyled}:disabled:checked + & {
      fill: ${theme.form.input.color.disabled};
    }
  `,
);

const TextStyled = styled.span(
  ({ theme }) => css`
    position: relative;
    z-index: 0;
    padding-left: ${theme.spacing.m};
    color: ${theme.globalColor.gray700};
    font-weight: ${theme.fontWeight.bold};
    line-height: 1;
  `,
);

const Checkbox: React.FC<Props> = ({
  children,
  disabled,
  innerRef,
  ...props
}) => (
  <WrapperStyled $disabled={disabled}>
    <InputStyled
      type="checkbox"
      disabled={disabled}
      ref={innerRef}
      {...props}
    />
    <IconStyled />
    <TextStyled>{children}</TextStyled>
  </WrapperStyled>
);

export default Checkbox;
