import { isNumber } from 'lodash-es';
import React from 'react';

import CSSModule from './NumberBadge.module.scss';

interface Props {
  count?: number;
  showEmpty?: boolean;
}

const NumberBadge: React.FC<Props> = ({ count, showEmpty = false }) => {
  if (!showEmpty && !isNumber(count)) return null;

  return (
    <div className={CSSModule.NumberBadge} data-testid="numberBadge">
      {isNumber(count) && count > 99 ? '99+' : count}
    </div>
  );
};

export default NumberBadge;
