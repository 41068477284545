import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Modal from 'components/atoms/modals/Modal';
import Button from 'components/modules/button/Button';
import ErrorList from 'components/modules/forms/ErrorList';
import useAuthentication from 'hooks/common/useAuthentication';
import useFetch from 'hooks/common/useFetch';
import { ModalInnerProps } from 'hooks/common/useModal';
import useToast from 'hooks/store/useToast';

import CSSModule from './WithdrawalConfirmationModal.module.scss';

const WithdrawalConfirmationModal: React.FC<ModalInnerProps> = ({
  isOpen,
  toggle,
}) => {
  const intl = useIntl();
  const { clearUserData } = useAuthentication();
  const navigate = useNavigate();
  const {
    fetch: deleteParent,
    isFetching: isDeleting,
    error: deleteParentError,
    status: deleteParentStatus,
  } = useFetch('DELETE', '/api/school/parents/me');

  const { showToast } = useToast();
  useEffect(() => {
    if (deleteParentStatus === 200) {
      toggle();
      clearUserData();
      showToast('退会手続きが完了しました。');
      navigate('/login');
    }
  }, [toggle, deleteParentStatus, showToast, clearUserData, navigate]);

  const onSubmit = () => {
    void deleteParent();
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'withdrawal.withdrawalConfirmationModal.title',
      })}
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className={CSSModule.WithdrawalConfirmationModal}>
        <p className={CSSModule.WithdrawalConfirmationModal__Text}>
          <FormattedMessage id="withdrawal.withdrawalConfirmationModal.description" />
        </p>
        {deleteParentError && (
          <ErrorList errors={[deleteParentError.message]} />
        )}
        <div className={CSSModule.WithdrawalConfirmationModal__Buttons}>
          <div className={CSSModule.WithdrawalConfirmationModal__ButtonItem}>
            <Button
              size="s"
              color="outline"
              onClick={toggle}
              className={CSSModule.WithdrawalConfirmationModal__Button}
            >
              <FormattedMessage id="forms.button.cancel" />
            </Button>
          </div>
          <div className={CSSModule.WithdrawalConfirmationModal__ButtonItem}>
            <Button
              size="s"
              color="danger"
              disabled={isDeleting}
              onClick={onSubmit}
              className={CSSModule.WithdrawalConfirmationModal__Button}
            >
              <FormattedMessage id="forms.button.withdrawal" />
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WithdrawalConfirmationModal;
