import { useCallback, useContext } from 'react';

import { ModalComponentsType } from 'components/organisms/common/ModalRoot';
import { AppContext } from 'providers/ContextProvider';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ModalOuterProps<Component extends React.ComponentType<any>> = Omit<
  React.ComponentProps<Component>,
  keyof ModalInnerProps
>;

export interface ModalInnerProps {
  isOpen: boolean;
  toggle: () => void;
}

export type ModalProps<TModalName extends keyof ModalComponentsType> =
  ModalOuterProps<ModalComponentsType[TModalName]>;

const useModal = () => {
  const { setIsModalOpen, setModalType, setModalProps } =
    useContext(AppContext);

  const showModal = <TModalName extends keyof ModalComponentsType>(
    modalType: TModalName,
    props: ModalProps<TModalName>,
  ) => {
    setModalProps(props);
    setModalType(modalType);
    setIsModalOpen(true);
  };

  const hideModal = () => setIsModalOpen(false);

  return {
    showModal: useCallback(showModal, [
      setModalProps,
      setModalType,
      setIsModalOpen,
    ]),
    hideModal: useCallback(hideModal, [setIsModalOpen]),
  };
};

export default useModal;
