import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import Panel from 'components/modules/box/Panel';
import Paper from 'components/modules/box/Paper';
import MyPageProfilePageTitle from 'components/organisms/mypage/MyPageProfilePageTitle';
import ParentProfile from 'components/organisms/mypage/ParentProfile';
import MyPageTemplate from 'components/templates/mypage/MyPageTemplate';
import { pageTitles } from 'constants/pageTitles';
import useCurrentUser from 'hooks/store/useCurrentUser';

const ParentProfilePage: React.FC = () => {
  const intl = useIntl();
  const [currentParent] = useCurrentUser();

  return (
    currentParent && (
      <MyPageTemplate
        pageTitle={
          <MyPageProfilePageTitle
            parentName={`${currentParent.last_name} ${currentParent.first_name}`}
          />
        }
      >
        <Helmet>
          <title>{pageTitles.mypage}</title>
        </Helmet>
        <Paper>
          <Panel title={intl.formatMessage({ id: 'mypage.profilePage.title' })}>
            <ParentProfile parent={currentParent} />
          </Panel>
        </Paper>
      </MyPageTemplate>
    )
  );
};

export default ParentProfilePage;
