import classNames from 'classnames';
import React from 'react';

import CSSModule from './FileThumbnailList.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const FileThumbnailList: React.FC<Props> = ({ children, className }) => (
  <div className={classNames(CSSModule.FileThumbnailList, className)}>
    {children}
  </div>
);

export default FileThumbnailList;
