import { Student } from 'interfaces/student';

export interface Parent {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  first_name_kana: string;
  last_name_kana: string;
  created_ts: number;
}

export interface ParentWithStudents extends Parent {
  organization_memberships: Student[];
}

export const NAME_MAX_LENGTH = 20;
export const NAME_KANA_MAX_LENGTH = 30;
export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 72;
export const PASSWORD_REGEXP = /^(?=.*?[a-z])(?=.*?\d)[!-~]+$/i;
export const HIRAGANA_REGEXP = /^[ぁ-ん\u2014\u2015\u30fc]+$/;
export const ORGANIZATION_MEMBERSHIP_CODE_REGEXP = /^\d{10}$/;
export const ZENKAKU_REGEXP = /^[^\u0020-\u007E\uFF61-\uFF9F]+$/;

export interface ParentRegistrationFormValues {
  password: string;
  password_confirmation: string;
  first_name: string;
  last_name: string;
  first_name_kana: string;
  last_name_kana: string;
  code: string;
}

export interface ParentProfileFormValues {
  first_name: string;
  last_name: string;
  first_name_kana: string;
  last_name_kana: string;
  password: string;
  password_confirmation: string;
}

export interface PasswordConfirmFormValues {
  current_password: string;
}
