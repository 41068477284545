export const IMAGE_FILE_TYPES = ['image/jpeg', 'image/png'];

export interface AnnouncementFile {
  id: number;
  original_file_name: string;
  download_file_name: string;
  content_length: number;
  content_type: string;
  file_deleted: boolean;
}

export interface Recipient {
  id: number;
  organization_membership_id: string;
  display_name: string;
}

export interface Teacher {
  display_name: string;
}

export interface Announcement {
  id: number;
  title: string;
  body: string;
  sent_ts: number;
  sender_user?: {
    id: string;
    display_name: string;
    profile_image_url: string | null;
  } | null;
  announcement_files: AnnouncementFile[];
  recipients: Recipient[] | [];
  teachers: Teacher[] | [];
  read: boolean;
}
