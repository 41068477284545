import React from 'react';
import { useIntl } from 'react-intl';
import { styled, css } from 'styled-components';

import { ReactComponent as AvatarIcon } from 'assets/icons/avatar.svg';
import PageTitle, {
  WrapperStyled as PageTitleWrapper,
  InnerStyled as PageTitleInner,
  TitleStyled as PageTitleTitle,
  LeftStyled as PageTitleLeft,
  RightStyled as PageTitleRight,
  RightLinkStyled as PageTitleRightLink,
} from 'components/modules/headers/PageTitle';

interface Props {
  parentName: string;
}

const TitleStyled = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarStyled = styled(AvatarIcon)(
  ({ theme }) => css`
    width: ${theme.icon.size.m};
    height: ${theme.icon.size.m};
    margin-right: ${theme.spacing.l};
  `,
);

const WrapperStyled = styled.div(
  ({ theme }) => css`
    ${theme.media.sp} {
      ${PageTitleWrapper} {
        background: ${theme.globalColor.ultraBlue600};
      }

      ${PageTitleInner} {
        position: relative;
        justify-content: center;
        height: 144px;
      }

      ${PageTitleTitle} {
        color: ${theme.globalColor.white};
        font-size: ${theme.fontSize.default};
      }

      ${PageTitleLeft} {
        display: block;
        flex-grow: initial;
      }

      ${PageTitleRight} {
        display: block;
        position: absolute;
        top: ${theme.spacing.l};
        right: ${theme.spacing.l};
      }

      ${PageTitleRightLink} {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 40px;
        padding: 0;
        border: 1px solid ${theme.globalColor.white};
        border-radius: ${theme.borderRadius.default};
        color: ${theme.globalColor.white};
        font-size: ${theme.fontSize.s};

        &,
        &:hover {
          text-decoration: none;
        }
      }

      ${TitleStyled} {
        display: block;
        text-align: center;
      }

      ${AvatarStyled} {
        width: ${theme.icon.size.l};
        height: ${theme.icon.size.l};
        margin: ${theme.spacing.m};
      }
    }
  `,
);

const MyPageProfilePageTitle: React.FC<Props> = ({ parentName }) => {
  const intl = useIntl();
  return (
    <WrapperStyled>
      <PageTitle
        title={
          <TitleStyled>
            <AvatarStyled />
            <div>{parentName}</div>
          </TitleStyled>
        }
        rightLinkLabel={intl.formatMessage({ id: 'forms.button.edit' })}
        rightLinkUrl="/mypage/profile/edit"
      />
    </WrapperStyled>
  );
};

export default MyPageProfilePageTitle;
