import classNames from 'classnames';
import dayjs from 'dayjs';
import { take } from 'lodash-es';
import React, { ReactElement, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  DetailPanelHeader,
  DetailPanelBaseHeaderProps,
} from 'components/modules/detailpage/DetailPanelHeader';

import CSSModule from './DetailPanelAnnouncementHeader.module.scss';

export interface DetailPanelAnnouncementHeaderProps
  extends DetailPanelBaseHeaderProps {
  avatar: ReactElement;
  sender: string;
  destinations?: string[];
  destinationDescription?: string;
  destinationLimit?: number;
}

const DetailPanelAnnouncementHeader: React.FC<
  DetailPanelAnnouncementHeaderProps
> = ({
  avatar,
  sender,
  sendAt,
  destinations = [],
  destinationDescription,
  destinationLimit = 2,
}) => {
  const intl = useIntl();
  const [closeDestination, setCloseDestination] = useState(true);
  const others = destinations.length - destinationLimit;
  const destinationList =
    others > 0 && closeDestination
      ? take(destinations, destinationLimit)
      : destinations;
  return (
    <DetailPanelHeader
      headerClassName={classNames({
        [CSSModule.DetailPanel__header__with_destination]:
          destinationList.length > 0,
      })}
      avatarClassName={classNames({
        [CSSModule.DetailPanel__header_avatar_container_wtih_destination]:
          destinationList.length > 0,
      })}
      contentsClassName={classNames({
        [CSSModule.DetailPanel__header_contents_with_destination]:
          destinationList.length > 0,
      })}
      avatar={avatar}
      contents={
        <>
          {/* sender */}
          <div className={CSSModule.DetailPanel__sender}>
            <span>
              <FormattedMessage id="detailPanel.sender" />
            </span>
            <span>{sender}</span>
          </div>
          {/* sendAt */}
          <div>
            <FormattedMessage
              id="detailPanel.sendAt"
              values={{
                sendAt: dayjs.unix(sendAt).format(
                  intl.formatMessage({
                    id: 'detailPanel.sendAtFormat',
                  }),
                ),
              }}
            />
          </div>
          {/* destinations */}
          {destinationList.length > 0 && (
            <div
              className={classNames(
                [CSSModule.DetailPanel__destination],
                [CSSModule.DetailPanel__destination_close],
              )}
            >
              {/* title */}
              <span className={CSSModule.DetailPanel__destination_title}>
                <FormattedMessage id="detailPanel.destination" />
              </span>
              {/* members */}
              <div>
                <div
                  className={
                    CSSModule.DetailPanel__destination_parent_receivers
                  }
                  style={{ display: closeDestination ? 'inline' : 'block' }}
                >
                  {destinationList.map((destination) => destination).join('、')}
                </div>
                {/* close or open */}
                {(others > 0 || destinationDescription) &&
                  (closeDestination ? (
                    <button
                      className={CSSModule.DetailPanel__destination_open}
                      onClick={() => setCloseDestination(false)}
                    >
                      {intl.formatMessage({
                        id: 'detailPanel.destinationOpen',
                      })}
                    </button>
                  ) : (
                    <>
                      {/* descption */}
                      {destinationDescription && (
                        <span
                          className={
                            CSSModule.DetailPanel__destination_description
                          }
                        >
                          {destinationDescription}
                        </span>
                      )}
                      {/* other */}
                      <button
                        className={CSSModule.DetailPanel__destination_close}
                        onClick={() => setCloseDestination(true)}
                      >
                        <FormattedMessage id="detailPanel.destinationClose" />
                      </button>
                    </>
                  ))}
              </div>
            </div>
          )}
        </>
      }
    />
  );
};

export default DetailPanelAnnouncementHeader;
