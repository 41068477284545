/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { Route, Routes, useNavigate } from 'react-router-dom';

import ErrorPage from 'components/pages/errors/common/ErrorPage';
import EmailConfirmationPage from 'components/pages/registration/EmailConfirmationPage';
import EmailRegistrationPage from 'components/pages/registration/EmailRegistrationPage';
import { pageTitles } from 'constants/pageTitles';
import useFetch from 'hooks/common/useFetch';
import useToast from 'hooks/store/useToast';
import ConsentTrailParameterFactory from 'utils/ConsentTrailParameterFactory';

export interface FormValues {
  email: string;
}

export interface CreateEmailRegistrationTokenResult {
  consent_id: string;
}

export interface ServerError {
  code?: string;
  message: string;
}

const EmailRegistration: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState<FormValues>({ email: '' });
  const [isConfirm, setConfirm] = useState(false);
  const { showToast } = useToast();

  const {
    fetch,
    reset,
    isFetching,
    status,
    error: serverError,
    data: result,
  } = useFetch<CreateEmailRegistrationTokenResult, ServerError>(
    'POST',
    '/api/school/parents/email_registration_tokens',
  );

  const onSubmit = (formValues: FormValues) => {
    setFormValues(formValues);
    void fetch({
      body: { ...formValues, ...ConsentTrailParameterFactory.build() },
    });
  };

  const onGoBack = () => {
    reset();
    navigate(-1);
    setConfirm(false);
  };

  const resendEmail = async () => {
    await fetch({
      body: { ...formValues, consent_id: result?.consent_id },
    });
    showToast(
      intl.formatMessage(
        { id: 'registration.emailRegistration.resentEmail' },
        { email: formValues.email },
      ),
    );
  };

  const handleToEmailConfirmation = useCallback(() => {
    if (!isConfirm) {
      navigate('/registration/email_confirmation');
      setConfirm(true);
    }
  }, [isConfirm, navigate]);

  useEffect(() => {
    if (status === 201) {
      showToast(
        intl.formatMessage(
          { id: 'registration.emailRegistration.sentEmail' },
          { email: formValues.email },
        ),
      );
      handleToEmailConfirmation();
    }
  }, [intl, handleToEmailConfirmation, showToast, formValues, status]);

  return (
    <>
      <Helmet>
        <title>{pageTitles.registration}</title>
      </Helmet>
      <Routes>
        <Route
          path=""
          element={
            <EmailRegistrationPage
              formValues={formValues}
              isFetching={isFetching}
              serverError={serverError?.message || null}
              errorCode={serverError?.code}
              onSubmit={onSubmit}
            />
          }
        />
        <Route
          path="email_confirmation"
          element={
            <EmailConfirmationPage
              email={formValues.email}
              isFetching={isFetching}
              onResend={resendEmail}
              onGoBack={onGoBack}
            />
          }
        />
        <Route path="*" element={<ErrorPage type="not_found" />} />
      </Routes>
    </>
  );
};

export default EmailRegistration;
