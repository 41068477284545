export const APP_TITLE = 'スタディサプリ for PARENTS';

export const pageTitles = {
  contact: 'お問い合わせ',
  forgot: 'ログインできない理由はなんですか？',
  forgotPasswordConfirmation: 'パスワードの再設定',
  login: 'ログイン',
  mypage: 'マイページ',
  mypageOrganizationMembershipsNew: '団体会員コードの入力',
  mypageOrganizationMembershipsError: '生徒情報が一致しない場合',
  passwordReset: 'パスワードの再設定',
  registration: 'メールアドレスの設定',
  registrationProfile: 'アカウントの設定',
  registrationProfileError: '生徒情報が一致しない場合',
  withdrawal: '退会について',
  agreements: '注意事項',
  notices: '運営からのお知らせ',
  absences: '欠席連絡',
  announcements: '先生からのお知らせ',
  emailChangeTokens: 'メールアドレスの再設定',
} as const;
