import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import CSSModule from './Dropdown.module.scss';

interface Props {
  className?: string;
  active?: boolean;
  disabled?: boolean;
  url?: string;
  onClick?: (ev: React.MouseEvent<HTMLAnchorElement>) => void;
  children?: React.ReactNode;
}

const DropdownItem: React.FC<Props> = ({
  className,
  children,
  active = false,
  disabled = false,
  url = '#',
  onClick,
}) => (
  <Link
    to={url}
    onClick={onClick}
    tabIndex={-1}
    className={classNames(
      CSSModule.DropdownItem,
      {
        [CSSModule.DropdownItem__Active]: active,
        [CSSModule.DropdownItem__Disabled]: disabled,
      },
      className,
    )}
  >
    {children}
  </Link>
);
export default DropdownItem;
