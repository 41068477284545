import { BrowserOptions, replayIntegration, init } from '@sentry/react';

export default class Sentry {
  public static setup() {
    const environment = process.env.REACT_APP_SITE_ENV || '';
    const release = process.env.REACT_APP_BUILD_VERSION || '1.0.0';

    // SEE:
    //   https://raven-js.readthedocs.io/en/stable/tips.html#decluttering-Sentry
    //   https://gist.github.com/Chocksy/e9b2cdd4afc2aadc7989762c4b8b495a
    const ignoreErrors = [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
      // Avast extension error
      '_avast_submit',
      // See https://github.com/getsentry/sentry-javascript/issues/3040
      'window.webkit.messageHandlers',
      'Local Network Error',
      "undefined is not an object (evaluating 'document.querySelectorAll('input",
    ];

    // Can't find variable Filters
    const cantFindVariableNames = [
      'username',
      'email',
      'username_or_email',
      'password',
    ];
    const shouldFilterCFVError = (error: Error): boolean =>
      !!error.message &&
      typeof error.message === 'string' &&
      error.message.includes("Can't find variable") &&
      cantFindVariableNames.some((variableName) =>
        error.message.includes(variableName),
      );

    const beforeSend: BrowserOptions['beforeSend'] = (event, hint) => {
      const error = hint?.originalException as Error | undefined;
      if (error && shouldFilterCFVError(error)) {
        return null;
      }

      return event;
    };

    const integrations = [
      replayIntegration(
        environment === 'production'
          ? {
              maskAllText: true,
              maskAllInputs: true,
              blockAllMedia: true,
              unmask: [],
            }
          : {
              maskAllText: false,
              maskAllInputs: false,
              blockAllMedia: false,
            },
      ),
    ];

    const option: BrowserOptions = {
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment,
      release,
      ignoreErrors,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
      integrations,
      beforeSend,
    };

    if (environment) {
      init(option);
    }
  }
}
