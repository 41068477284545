import { useCallback, useContext } from 'react';

import { AppContext } from 'providers/ContextProvider';

type UseToastType = () => {
  showToast: (message: string) => void;
  hideToast: () => void;
};

const useToast: UseToastType = () => {
  const { setToastMessage } = useContext(AppContext);

  const hideToast = () => setToastMessage('');

  return {
    showToast: setToastMessage,
    hideToast: useCallback(hideToast, [setToastMessage]),
  };
};

export default useToast;
