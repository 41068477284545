import { offset } from '@floating-ui/react';
import { format, getYear, getMonth, parseISO } from 'date-fns';
import { ja } from 'date-fns/locale';
import { isDate } from 'lodash-es';
import { math } from 'polished';
import React from 'react';
import ReactDatePicker, {
  DatePickerProps,
  registerLocale,
} from 'react-datepicker';
import { styled, css } from 'styled-components';

import { ReactComponent as ArrowIcon } from 'assets/icons/cal_arrow.svg';
import DatePickerInput from 'components/modules/forms/DatePickerInput';
import useInputChangeValue from 'hooks/common/useInputChangeValue';
import theme from 'store/theme';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  innerRef?: React.Ref<HTMLInputElement>;
}

interface Props {
  valueFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  inputProps?: InputProps;
}

const DatePickerStyleld = styled.div`
  ${({ theme }) => css`
    & .react-datepicker__aria-live {
      display: none;
    }

    & .react-datepicker-popper {
      z-index: 1;
      left: 0;
    }

    & .react-datepicker {
      width: 324px;
      padding: ${math(`${theme.spacing.l} - 4`)};
      border-radius: ${theme.borderRadius.default};
      background: ${theme.globalColor.white};
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

      & .react-datepicker__day,
      & .react-datepicker__day-name {
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${theme.spacing.xxl};
        height: ${theme.spacing.xxl};
        margin: ${theme.spacing.s};
        border-radius: 50%;
        color: ${theme.globalColor.gray700};
      }

      & .react-datepicker__header {
        & > div {
          display: flex;
          justify-content: space-between;

          button {
            border: none;
            background-color: transparent;
            color: ${theme.globalColor.gray500};
          }
        }

        & .react-datepicker__day-names {
          .react-datepicker__day-name {
            color: ${theme.globalColor.gray500};
            font-weight: ${theme.fontWeight.bold};
          }
        }
      }

      & .react-datepicker__week {
        display: flex;
        justify-content: space-between;

        & .react-datepicker__day {
          cursor: pointer;
          &:hover {
            background: ${theme.globalColor.gray100};
          }
        }

        & .react-datepicker__day--today {
          &:not(.react-datepicker__day--selected) {
            background: ${theme.globalColor.skyBlue100};
          }
        }

        & .react-datepicker__day--selected {
          background: ${theme.globalColor.skyBlue600};
          color: ${theme.globalColor.white};
          &:hover {
            background: ${theme.globalColor.skyBlue600};
          }
        }

        & .react-datepicker__day--disabled {
          color: ${theme.globalColor.gray400};
          cursor: default;
          &:not(.react-datepicker__day--today) {
            &:hover {
              background: transparent;
            }
          }
        }

        & .react-datepicker__day--outside-month {
          color: transparent;
          pointer-events: none;
          user-select: none;

          &.react-datepicker__day--selected {
            background: transparent;
            color: transparent;
          }

          &.react-datepicker__day--today {
            background: transparent;
            color: transparent;
          }
        }
      }
    }
  `}
`;

const ButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
  cursor: pointer;
  &:disabled {
    cursor: default;
    svg {
      & path {
        fill: transparent;
      }
    }
  }
`;

const ArrowBackStyleld = styled(ArrowIcon)`
  ${({ theme }) => css`
    & path {
      fill: ${theme.globalColor.gray500};
    }
  `}
`;

const ArrowForwordStyleld = styled(ArrowIcon)`
  ${({ theme }) => css`
    transform: rotate(180deg);
    & path {
      fill: ${theme.globalColor.gray500};
    }
  `}
`;

const MonthStyled = styled.div(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  span: {
    color: theme.globalColor.gray700,
  },
}));

const CustomHeader: DatePickerProps['renderCustomHeader'] = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <div>
    <ButtonStyled
      type="button"
      onClick={decreaseMonth}
      disabled={prevMonthButtonDisabled}
    >
      <ArrowBackStyleld />
    </ButtonStyled>
    <MonthStyled>
      <span>{getYear(date)}年</span>
      <span>{getMonth(date) + 1}月</span>
    </MonthStyled>
    <ButtonStyled
      type="button"
      onClick={increaseMonth}
      disabled={nextMonthButtonDisabled}
    >
      <ArrowForwordStyleld />
    </ButtonStyled>
  </div>
);

// input hiddenでは各種eventが発火しないため
const HiddenInputStyled = styled.input`
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  appearance: none;
  border: none;
  background: transparent;
  color: transparent;
`;

const DatePicker: React.FC<Props> = ({
  valueFormat = 'yyyy-MM-dd',
  minDate = new Date(),
  maxDate,
  inputProps: { innerRef, onChange = () => void 0, ...inputProps } = {
    innerRef: undefined,
  },
}) => {
  const inputRef = React.useRef<HTMLInputElement>();
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [selectedDate, setSelectedDate] = React.useState<string | null>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  registerLocale('ja', ja);

  const { changeValue } = useInputChangeValue();

  const setDatePickerDate = React.useCallback(
    (date: Date | null) => {
      if (isDate(date) && inputRef.current) {
        setStartDate(date);
        const formatted = format(date, valueFormat);
        setSelectedDate(formatted);
        changeValue(inputRef.current, formatted);
      }
    },
    [changeValue, valueFormat],
  );

  // inputのvalueに値がある場合(componentの切り替えやback時)にDatePickerの表示側にも値をsetする
  React.useEffect(() => {
    if (inputRef.current?.value)
      setDatePickerDate(parseISO(inputRef.current.value));
  }, [inputRef.current?.value, setDatePickerDate]);

  return (
    <DatePickerStyleld data-testid="datepicker">
      <HiddenInputStyled
        defaultValue={selectedDate || ''}
        {...inputProps}
        onChange={onChange} // onChangeが渡されていない場合のwarning抑制のためデフォルトのcallbackを指定しておく
        type="text"
        ref={(node) => {
          inputRef.current = node || undefined;
          if (typeof innerRef === 'function') {
            innerRef(node);
          } else if (innerRef) {
            (
              innerRef as React.MutableRefObject<HTMLInputElement | null>
            ).current = node;
          }
        }}
        data-testid="datepicker-value-input"
      />
      <ReactDatePicker
        locale="ja"
        renderCustomHeader={CustomHeader}
        dateFormat="yyyy年M月d日"
        selected={startDate}
        showPopperArrow={false}
        popperPlacement="bottom-end"
        popperModifiers={[
          offset({
            mainAxis: (-1 * parseInt(theme.spacing.s)) / 2,
          }),
        ]}
        customInput={
          <DatePickerInput
            isOpen={isOpen}
            data-testid="datepicker-custom-input"
          />
        }
        customInputRef="innerRef"
        minDate={minDate}
        maxDate={maxDate}
        onSelect={(date) => setDatePickerDate(date)}
        onChange={(date) => setDatePickerDate(date)}
        onCalendarOpen={() => {
          setIsOpen(true);
        }}
        onCalendarClose={() => {
          setIsOpen(false);
          // RHF validation をトリガーさせるため
          inputRef.current?.focus();
          inputRef.current?.blur();
        }}
        placeholderText="日付を選択してください"
      />
    </DatePickerStyleld>
  );
};

export default DatePicker;
