import React from 'react';
import { css, styled } from 'styled-components';

interface Props {
  children?: React.ReactNode;
}

const WrapperStyled = styled.div(
  ({ theme }) => css`
    flex-shrink: 0;
    font-weight: ${theme.fontWeight.bold};

    ${theme.media.pc} {
      width: 160px;
    }
    ${theme.media.sp} {
      width: 102px;
    }
  `,
);

const DescriptionTerm: React.FC<Props> = ({ children }) => (
  <WrapperStyled>{children}</WrapperStyled>
);

export default DescriptionTerm;
