import React from 'react';
import { styled, css } from 'styled-components';

import { WrapperStyled as FormGroupWrapper } from 'components/modules/forms/FormGroup';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const WrapperStyled = styled.div(
  ({ theme }) => css`
    ${FormGroupWrapper} > &:not(:first-child) {
      margin-top: ${theme.spacing.m};
    }
  `,
);

const Field: React.FC<Props> = ({ className, children }) => (
  <WrapperStyled className={className}>{children}</WrapperStyled>
);

export default Field;
