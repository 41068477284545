import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { useNavigate, Link } from 'react-router-dom';

import BackToPageButton from 'components/atoms/buttons/BackToPageButton';
import Contact from 'components/organisms/contact/Contact';
import SingleCardTemplate from 'components/templates/SingleCardTemplate';
import { pageTitles } from 'constants/pageTitles';
import useCurrentUser from 'hooks/store/useCurrentUser';

import CSSModule from './ContactPage.module.scss';

const ContactPage: React.FC = () => {
  const navigate = useNavigate();
  const [currentParent] = useCurrentUser();

  const isShowLinkToWithdrawal =
    currentParent?.organization_memberships.length === 0;
  const goBack = () => navigate('/mypage/profile');

  return (
    <SingleCardTemplate title={pageTitles.contact}>
      <Helmet>
        <title>{pageTitles.contact}</title>
      </Helmet>
      <div className={CSSModule.ContactPage}>
        <Contact />
        {isShowLinkToWithdrawal && (
          <p className={CSSModule.ContactPage__Withdrawal}>
            <Link to="/withdrawal">{pageTitles.withdrawal}</Link>
          </p>
        )}
        <BackToPageButton
          onClick={goBack}
          className={CSSModule.ContactPage__BackToPageButton}
        >
          <FormattedMessage id="contact.backToPageButton" />
        </BackToPageButton>
      </div>
    </SingleCardTemplate>
  );
};

export default ContactPage;
