import { EventParams, CustomParams } from 'firebase/analytics';
import { pickBy } from 'lodash-es';
import queryString from 'query-string';
import snakeCaseKeys from 'snakecase-keys';

import { tracker } from 'utils/firebase';

type EventName = 'parents_web_pageviews' | 'parents_web_announcement_view';

interface EventTrackingContext {
  parentId?: number;
  pathname: string;
  query: string;
  fragment: string;
}

class EventTracker {
  private static trackingContext?: EventTrackingContext;

  public static pageview() {
    this.track('parents_web_pageviews', this.pickUtmParams());
  }

  public static announcementView(eventParams: CustomParams) {
    this.track('parents_web_announcement_view', snakeCaseKeys(eventParams));
  }

  public static updateContext(context?: EventTrackingContext) {
    this.trackingContext = context;
  }

  private static track(eventName: EventName, eventParams?: CustomParams) {
    tracker.trackEvent(this.trackingContext?.parentId?.toString(), eventName, {
      ...eventParams,
      ...this.buildCommonParameters(),
    });
  }

  private static buildCommonParameters(): EventParams {
    return {
      build_id: process.env.REACT_APP_BUILD_VERSION,
      pathname: this.trackingContext?.pathname,
      query_string: this.trackingContext?.query,
      fragment: this.trackingContext?.fragment,
      parent_id: this.trackingContext?.parentId,
    };
  }

  private static pickUtmParams(): CustomParams {
    const queryParams = queryString.parse(this.trackingContext?.query || '');
    return pickBy(queryParams || {}, (v, k) => k.startsWith('utm_'));
  }
}

export default EventTracker;
