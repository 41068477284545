import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import BackToPageButton from 'components/atoms/buttons/BackToPageButton';
import Jumbotron from 'components/modules/box/Jumbotron';
import Paper from 'components/modules/box/Paper';
import DetailBody from 'components/modules/detailpage/DetailBody';
import DetailPanel from 'components/modules/detailpage/DetailPanel';
import DetailPanelNoticeHeader from 'components/modules/detailpage/DetailPanelNoticeHeader';
import PageTitle from 'components/modules/headers/PageTitle';
import ErrorPage from 'components/pages/errors/common/ErrorPage';
import SingleColumnTemplate from 'components/templates/SingleColumnTemplate';
import UiStackTemplate from 'components/templates/UiStackTemplate';
import { pageTitles } from 'constants/pageTitles';
import useFetch from 'hooks/common/useFetch';
import { NoticeFromManagement } from 'interfaces/notice';

import CSSModule from './NoticeShowPage.module.scss';

const NoticeListPage: React.FC = () => {
  const intl = useIntl();
  const { noticeId } = useParams<'noticeId'>();

  const { fetch, isFetching, status, data } = useFetch<NoticeFromManagement>(
    'GET',
    `/api/school/notice_from_managements/${noticeId || ''}`,
  );

  const isNotFound = status === 404;
  const isNetworkError = status && status >= 500;
  const isLoading = !status || isFetching;

  useEffect(() => {
    void fetch();
  }, [fetch]);

  const notice = data || ({ kind: 'normal' } as NoticeFromManagement);

  return isNotFound ? (
    <ErrorPage
      type="not_found"
      description={intl.formatMessage({
        id: 'notice.errors.notFound.description',
      })}
    />
  ) : isNetworkError ? (
    <ErrorPage type="internal_server_error" />
  ) : (
    <SingleColumnTemplate>
      <Helmet>
        <title>{pageTitles.notices}</title>
      </Helmet>
      <UiStackTemplate isLoading={isLoading}>
        <PageTitle
          title={notice.title}
          backLinkLabel={intl.formatMessage({ id: 'notice.show.toTop' })}
          backLinkUrl="/notices"
        />

        <div className={CSSModule.NoticeShowPage__Card}>
          <Jumbotron>
            <Paper>
              <DetailPanel>
                <DetailPanelNoticeHeader
                  kind={notice.kind}
                  sendAt={dayjs(notice.publish_start_at).unix()}
                />
                <DetailBody body={notice.body} />
              </DetailPanel>
            </Paper>
          </Jumbotron>
        </div>
        <BackToPageButton
          to="/notices"
          className={CSSModule.NoticeShowPage__BackToPage}
        >
          <FormattedMessage id="notice.show.backToTop" />
        </BackToPageButton>
      </UiStackTemplate>
    </SingleColumnTemplate>
  );
};

export default NoticeListPage;
