import React from 'react';
import { styled, css } from 'styled-components';

interface Props {
  children?: React.ReactNode;
}

const WrapperStyled = styled.div(
  ({ theme }) => css`
    margin-left: ${theme.spacing.l};
    word-break: break-all;
  `,
);

const DescriptionDetails: React.FC<Props> = ({ children }) => (
  <WrapperStyled data-dd-action-name="DescriptionDetails">
    {children}
  </WrapperStyled>
);

export default DescriptionDetails;
