import React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled, css } from 'styled-components';

import Button from 'components/modules/button/Button';
import UiStackTemplate from 'components/templates/UiStackTemplate';

interface Props {
  email: string;
  isFetching: boolean;
  onResend: () => void;
  onCancel: () => void;
}

const WrapperStyled = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    ${theme.media.pc} {
      min-height: 336px;
    }

    ${theme.media.sp} {
      min-height: 392px;
    }
  `,
);

const InnerStyled = styled.div`
  width: 100%;
`;

const TitleStyled = styled.h2(
  ({ theme }) => css`
    margin: 0 auto;
    color: ${theme.globalColor.gray700};
    font-weight: bold;
    line-height: ${theme.lineHeight.default};
    text-align: center;

    ${theme.media.pc} {
      font-size: ${theme.fontSize.xl};
    }

    ${theme.media.sp} {
      font-size: ${theme.fontSize.l};
    }
  `,
);

const DescriptionStyled = styled.p(
  ({ theme }) => css`
    width: 100%;
    margin: ${theme.spacing.l} auto;
    color: ${theme.globalColor.gray700};
    font-size: ${theme.fontSize.default};
    line-height: ${theme.lineHeight.default};
    text-align: center;
    white-space: pre-wrap;
  `,
);

const EmailStyled = styled.span(
  ({ theme }) => css`
    color: ${theme.globalColor.gray700};
    font-weight: bold;
  `,
);

const RemarkStyled = styled.p(
  ({ theme }) => css`
    width: 100%;
    margin: ${theme.spacing.l} auto;
    color: ${theme.globalColor.gray500};
    font-size: ${theme.fontSize.s};
    line-height: ${theme.lineHeight.xl};
    text-align: center;

    ${theme.media.pc} {
      white-space: normal;
    }

    ${theme.media.sp} {
      white-space: pre-wrap;
    }
  `,
);

const ButtonsStyled = styled.div(
  ({ theme }) => css`
    > :not(:first-child) {
      margin-top: ${theme.spacing.m};
    }
  `,
);

const ButtonStyled = styled(Button)`
  display: block;
  width: 300px;
  margin: 0 auto;
`;

const EmailConfirmation: React.FC<Props> = ({
  email,
  isFetching,
  onResend,
  onCancel,
}) => (
  <WrapperStyled>
    <UiStackTemplate isLoading={isFetching}>
      <InnerStyled>
        <TitleStyled>
          <FormattedMessage id="registration.emailConfirmationPage.title" />
        </TitleStyled>
        <DescriptionStyled>
          <EmailStyled>{email}</EmailStyled>
          <FormattedMessage id="registration.emailConfirmationPage.description" />
        </DescriptionStyled>
        <RemarkStyled>
          <FormattedMessage id="registration.emailConfirmationPage.remark1" />
          <br />
          <FormattedMessage id="registration.emailConfirmationPage.remark2" />
        </RemarkStyled>
        <RemarkStyled>
          <FormattedMessage id="registration.emailConfirmationPage.remark3" />
          <br />
          <FormattedMessage id="registration.emailConfirmationPage.remark4" />
          <br />
          <FormattedMessage id="registration.emailConfirmationPage.remark5" />
        </RemarkStyled>
        <ButtonsStyled>
          <ButtonStyled
            color="primary"
            onClick={onResend}
            disabled={isFetching}
          >
            <FormattedMessage id="registration.emailConfirmationPage.resendButton" />
          </ButtonStyled>
          <ButtonStyled color="link" onClick={onCancel}>
            <FormattedMessage id="registration.emailConfirmationPage.backToPageButton" />
          </ButtonStyled>
        </ButtonsStyled>
      </InnerStyled>
    </UiStackTemplate>
  </WrapperStyled>
);

export default EmailConfirmation;
