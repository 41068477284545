import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import NumberBadge from 'components/modules/badge/NumberBadge';

import CSSModule from './ListItem.module.scss';
/**
 * 未読・既読を管理しない用途の場合にdefaultを利用します
 */
interface ListItemProps {
  icon: React.ReactNode;
  title: React.ReactNode;
  /** 未読・既読を管理しない用途の場合にdefaultを利用します */
  style?: 'default' | 'unread' | 'read';
  time: string;
  description: string;
  url?: string;
  onClick?: () => void;
}
const ListItem: React.FC<ListItemProps> = ({
  icon,
  title,
  style = 'default',
  time,
  description,
  url,
  onClick,
}) => {
  const listItemClasses = classNames(CSSModule.ListItem, {
    [CSSModule.ListItem__White]: style === 'default' || style === 'unread',
    [CSSModule.ListItem__Gray]: style === 'read',
  });
  const listItemTitleClasses = classNames(CSSModule.ListItem__Title, {
    [CSSModule.ListItem__TitleBold]: style === 'unread',
    [CSSModule.ListItem__TitleNormal]: style === 'default' || style === 'read',
  });

  return (
    <li className={listItemClasses}>
      <Link
        to={url ?? '#'}
        onClick={onClick}
        state={{ from: 'list' }}
        className={CSSModule.ListItem__Link}
      >
        <div className={CSSModule.ListItem__IconGroup}>
          <div className={CSSModule.ListItem__Icon}>{icon}</div>
          <div className={CSSModule.ListItem__NumberBadge}>
            {style === 'unread' && <NumberBadge showEmpty />}
          </div>
        </div>
        <div className={CSSModule.ListItem__Right}>
          <div className={CSSModule.ListItem__Top}>
            <span className={listItemTitleClasses}>{title}</span>
          </div>
          <div className={CSSModule.ListItem__Bottom}>
            <span className={CSSModule.ListItem__Time}>{time}</span>
            <span className={CSSModule.ListItem__Description}>
              {description}
            </span>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default ListItem;
