import React from 'react';

import Image from 'assets/errors/500.png';
import Button from 'components/modules/button/Button';
import ErrorContent from 'components/pages/errors/common/ErrorContent';
import useAuthentication from 'hooks/common/useAuthentication';
import useError from 'hooks/common/useError';
import Browser from 'utils/Browser';

interface Props {
  description?: string;
}

const InternalServerError: React.FC<Props> = ({ description }) => {
  const { isLoggedIn } = useAuthentication();
  const { normalLogout } = useError();

  const signOut = () => {
    normalLogout();
  };

  let buttons = [
    <Button key="refresh" color="primary" onClick={() => Browser.reload()}>
      ページを再度読み込む
    </Button>,
  ];

  if (isLoggedIn) {
    buttons = [
      ...buttons,
      <Button key="logout" color="outline" onClick={signOut}>
        ログアウト
      </Button>,
    ];
  }

  return (
    <ErrorContent
      image={Image}
      title="Internal Server Error"
      subtitle="サーバーへ接続できませんでした"
      description={
        description ||
        '申し訳ありませんがサーバーへの接続中にエラーが発生しました。時間をおいて再度お試しください。'
      }
      buttons={buttons}
    />
  );
};

export default InternalServerError;
