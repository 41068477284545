import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl, FormattedMessage } from 'react-intl';
import { useParams, Link } from 'react-router-dom';

import Button from 'components/modules/button/Button';
import TokenVerificationResult from 'components/organisms/registration/TokenVerificationResult';
import SingleCardTemplate from 'components/templates/SingleCardTemplate';
import UiStackTemplate from 'components/templates/UiStackTemplate';
import { pageTitles } from 'constants/pageTitles';
import useAuthentication from 'hooks/common/useAuthentication';
import useFetch from 'hooks/common/useFetch';
import useCurrentUser from 'hooks/store/useCurrentUser';

import CSSModule from './EmailChangeCompletePage.module.scss';

export interface VerificationResult {
  email: string;
}

export interface VerificationError {
  code?: 'token-expired';
  message: string;
}

const EmailChangeCompletePage: React.FC = () => {
  const intl = useIntl();
  const { token } = useParams<'token'>();
  const { isLoggedIn } = useAuthentication();
  const [, setCurrentParent] = useCurrentUser();

  const { fetch, error, data } = useFetch<
    VerificationResult,
    VerificationError
  >('POST', '/api/school/parents/email_change_tokens/verify');

  useEffect(() => {
    void fetch({ body: { token } });
  }, [fetch, token]);

  useEffect(() => {
    if (data) {
      setCurrentParent(
        (currentParent) =>
          currentParent && {
            ...currentParent,
            email: data.email,
          },
      );
    }
  }, [setCurrentParent, data]);

  if (error) {
    const errorType = error.code === 'token-expired' ? 'expired' : 'invalid';

    return (
      <SingleCardTemplate
        title={intl.formatMessage({
          id: 'mypage.emailChangeCompletePage.headerTitle',
        })}
      >
        <TokenVerificationResult
          isFetching={false}
          errorType={errorType}
          linkUrl="/mypage/profile/edit"
        />
      </SingleCardTemplate>
    );
  }

  return (
    <SingleCardTemplate title={pageTitles.emailChangeTokens}>
      <Helmet>
        <title>{pageTitles.emailChangeTokens}</title>
      </Helmet>
      <div className={CSSModule.EmailChangeCompletePage}>
        <UiStackTemplate isLoading={!data}>
          <div className={CSSModule.EmailChangeCompletePage__Inner}>
            <h2 className={CSSModule.EmailChangeCompletePage__Title}>
              <FormattedMessage id="mypage.emailChangeCompletePage.title" />
            </h2>
            <div className={CSSModule.EmailChangeCompletePage__Description}>
              {isLoggedIn ? (
                <FormattedMessage id="mypage.emailChangeCompletePage.descriptionToMypage" />
              ) : (
                <FormattedMessage id="mypage.emailChangeCompletePage.descriptionToLoginPage" />
              )}
            </div>
            <div className={CSSModule.EmailChangeCompletePage__ButtonArea}>
              <Button
                color="primary"
                tag={Link}
                to="/mypage/profile"
                className={CSSModule.EmailChangeCompletePage__RaisedButton}
              >
                {isLoggedIn ? (
                  <FormattedMessage id="mypage.emailChangeCompletePage.toMypage" />
                ) : (
                  <FormattedMessage id="mypage.emailChangeCompletePage.toLoginPage" />
                )}
              </Button>
            </div>
          </div>
        </UiStackTemplate>
      </div>
    </SingleCardTemplate>
  );
};

export default EmailChangeCompletePage;
