import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from 'components/modules/button/Button';

import CSSModule from './TokenVerificationError.module.scss';

const TokenVerificationError: React.FC = () => (
  <div className={CSSModule.TokenVerificationError}>
    <div className={CSSModule.TokenVerificationError__Inner}>
      <h3 className={CSSModule.TokenVerificationError__Title}>
        <FormattedMessage id="resetPasswordPage.tokenVerificationError.title" />
      </h3>

      <div className={CSSModule.TokenVerificationError__Description}>
        <FormattedMessage id="resetPasswordPage.tokenVerificationError.description" />
      </div>

      <Button
        tag={Link}
        to="/forgot"
        color="outline"
        className={CSSModule.TokenVerificationError__BackToForgot}
      >
        <FormattedMessage id="resetPasswordPage.tokenVerificationError.backToForgot" />
      </Button>
    </div>
  </div>
);

export default TokenVerificationError;
