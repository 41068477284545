import dayjs from 'dayjs';
import { findIndex, isEmpty as _isEmpty } from 'lodash-es';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useSearchParams, useLocation } from 'react-router-dom';

import { ReactComponent as AvatarTeacherIcon } from 'assets/icons/avatar-teacher.svg';
import Jumbotron from 'components/modules/box/Jumbotron';
import JumbotronPanel from 'components/modules/box/JumbotronPanel';
import Paper from 'components/modules/box/Paper';
import Filter from 'components/modules/filter/Filter';
import PageTitle from 'components/modules/headers/PageTitle';
import List from 'components/modules/lists/List';
import ListItem from 'components/modules/lists/ListItem';
import Pagination from 'components/modules/lists/Pagination';
import SingleColumnTemplate from 'components/templates/SingleColumnTemplate';
import UiStackTemplate from 'components/templates/UiStackTemplate';
import { pageTitles } from 'constants/pageTitles';
import useFetch from 'hooks/common/useFetch';
import useCurrentUser from 'hooks/store/useCurrentUser';
import { Announcement } from 'interfaces/announcement';
import { ParentWithStudents } from 'interfaces/parent';

import CSSModule from './AnnouncementsIndexPage.module.scss';

export type AnnouncementForIndex = Omit<
  Announcement,
  'announcement_files' | 'recipients'
>;

export interface AnnouncementsResult {
  announcements: AnnouncementForIndex[];
  pagination: {
    current_page: number;
    per_page: number;
    total_count: number;
  };
}

const AnnouncementsIndexPage: React.FC = () => {
  const intl = useIntl();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentUser] = useCurrentUser();
  const { organization_memberships: students } =
    currentUser as ParentWithStudents;

  const filters = [
    intl.formatMessage({
      id: 'announcements.announcementIndexPage.filter.all',
    }),
  ].concat(students.map(({ name }) => name));

  const page = searchParams.get('page') ?? 1;
  const studentId = searchParams.get('student_id') ?? '';

  const currentFilterIndex = studentId
    ? findIndex(students, (s) => s.id === studentId) + 1
    : 0;

  const {
    fetch: fetchAnnouncements,
    isFetching,
    status,
    data,
  } = useFetch<AnnouncementsResult>(
    'POST',
    '/api/school_communication/announcements',
  );

  // pagination
  const handleOnPageChanged = (page: number) => {
    setSearchParams({ page: page.toString(), student_id: studentId });
  };

  const handleFilterChange = ({ index }: { index: number }) => {
    const studentId = students[index - 1]?.['id'] ?? '';
    setSearchParams({ student_id: studentId });
  };

  useEffect(() => {
    void fetchAnnouncements({
      body: {
        page,
        organization_membership_ids: studentId ? [studentId] : null,
      },
    });
  }, [fetchAnnouncements, page, studentId]);

  const { announcements, pagination } = data || {
    announcements: [] as AnnouncementForIndex[],
  };

  const isLoading = !status || isFetching;
  const isError = !!(status && (status >= 500 || status === 404));
  const isRestricted = !!(status && status === 400);
  const isEmpty = _isEmpty(announcements);

  return (
    <SingleColumnTemplate>
      <Helmet>
        <title>{pageTitles.announcements}</title>
      </Helmet>
      <UiStackTemplate isLoading={isLoading} isError={isError} status={status}>
        <PageTitle
          title={intl.formatMessage({
            id: 'announcements.announcementIndexPage.title',
          })}
        />

        <div className={CSSModule.AnnouncementsIndexPage__Contents}>
          <Jumbotron>
            <div
              className={CSSModule.AnnouncementsIndexPage__Filter}
              aria-label="Filter announcements by student"
              data-testid="announcements_filter"
            >
              <Filter
                items={filters}
                onChange={handleFilterChange}
                initialIndex={currentFilterIndex}
              />
            </div>
            {isRestricted ? (
              <JumbotronPanel
                title={intl.formatMessage({
                  id: 'announcements.announcementIndexPage.restricted.title',
                })}
                description={intl.formatMessage({
                  id: 'announcements.announcementIndexPage.restricted.description',
                })}
              />
            ) : isEmpty ? (
              <JumbotronPanel
                title={intl.formatMessage({
                  id: 'announcements.announcementIndexPage.empty.title',
                })}
                description={intl.formatMessage({
                  id: 'announcements.announcementIndexPage.empty.description',
                })}
              />
            ) : (
              <>
                <Paper>
                  <List>
                    {announcements.map(
                      ({ id, title, sent_ts, sender_user, read }) =>
                        id && ( // undefinedがわたっている可能性 https://studysapuri.sentry.io/issues/4956121444/?project=5430472
                          <ListItem
                            key={id}
                            icon={
                              sender_user?.profile_image_url ? (
                                <img src={sender_user.profile_image_url} />
                              ) : (
                                <AvatarTeacherIcon />
                              )
                            }
                            title={title}
                            time={dayjs.unix(sent_ts).format(
                              intl.formatMessage({
                                id: 'announcements.announcementIndexPage.announcements.list_item.sent_at_format',
                              }),
                            )}
                            description={
                              sender_user?.display_name ??
                              intl.formatMessage({
                                id: 'announcements.announcementIndexPage.announcements.list_item.deleted_author_name',
                              })
                            }
                            url={`/announcements/${id}${location.search}`}
                            style={read ? 'read' : 'unread'}
                          />
                        ),
                    )}
                  </List>
                </Paper>
                {pagination && (
                  <div
                    className={CSSModule.AnnouncementsIndexPage__Pagination}
                    data-testid="announcements_pagination"
                  >
                    <Pagination
                      initialPage={pagination.current_page}
                      totalPage={Math.ceil(
                        pagination.total_count / pagination.per_page,
                      )}
                      onPageChanged={(page) => handleOnPageChanged(page)}
                    />
                  </div>
                )}
              </>
            )}
          </Jumbotron>
        </div>
      </UiStackTemplate>
    </SingleColumnTemplate>
  );
};

export default AnnouncementsIndexPage;
