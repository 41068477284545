import { isToday, parseISO, isFuture } from 'date-fns';
import { useCallback } from 'react';

const useEditableChecker = () => {
  const isEditable = useCallback((date?: string) => {
    if (!date) return false;
    const parsedDate = parseISO(date);

    return isFuture(parsedDate) || isToday(parsedDate);
  }, []);

  return {
    isEditable,
  };
};

export default useEditableChecker;
