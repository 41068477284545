import React from 'react';
import { styled, css } from 'styled-components';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  innerRef?: React.Ref<HTMLInputElement>;
  isStatic?: boolean;
}

export const InputStyled = styled.input<{ $static?: boolean }>(
  ({ theme, $static }) => css`
    ${theme.form.input.styles.default}
    height: ${theme.form.input.height.default};
    padding-top: 0;
    padding-bottom: 0;
    white-space: nowrap;

    &[type='password']::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      width: 0;
      margin: 0;
    }

    &[type='number']::-webkit-outer-spin-button,
    &[type='number']::-webkit-inner-spin-button {
      appearance: none;
    }

    &::placeholder {
      ${theme.form.input.styles.placeholder}
    }

    &:not(:placeholder-shown) {
      ${theme.form.input.styles.fill}
    }

    &:hover {
      ${theme.form.input.styles.hover}
    }

    &:focus {
      ${theme.form.input.styles.focus}
    }

    &:disabled {
      ${theme.form.input.styles.disabled}
    }

    &&& {
      ${$static ? theme.form.input.styles.static : ''}
    }
  `,
);

const Input: React.FC<Props> = ({ innerRef, isStatic, ...attrs }) => (
  <InputStyled ref={innerRef} $static={isStatic} {...attrs} />
);

export default Input;
