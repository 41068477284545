import { styled, css } from 'styled-components';

import Field from 'components/modules/forms/Field';
import FormGroup from 'components/modules/forms/FormGroup';

export const FormGroupStyled = styled(FormGroup)<{ $visible?: boolean }>(
  ({ $visible = true }) => css`
    display: ${$visible ? 'block' : 'none'};
  `,
);

export const FieldStyled = styled(Field)<{ $width?: 'max' | 'normal' }>(
  ({ theme, $width = 'normal' }) => css`
    ${theme.media.pc} {
      max-width: ${{ normal: theme.form.input.width.normal, max: '100%' }[
        $width
      ]};
    }
  `,
);
