import { isNil } from 'lodash-es';
import React from 'react';
import { css, styled } from 'styled-components';

interface Props {
  gradeLabel?: string;
  classNameLabel?: string;
  attendanceNumberLabel?: number;
  className?: string;
}

const LabelStyled = styled.label`
  margin: 0;
  cursor: inherit;
`;

const GradeStyled = styled.span(
  ({ theme }) => css`
    margin: 0 ${theme.spacing.s};
  `,
);

const ClassStyled = styled.span(
  ({ theme }) => css`
    margin-right: ${theme.spacing.s};
  `,
);

const GradeClassNumberLabel: React.FC<Props> = ({
  gradeLabel,
  classNameLabel,
  attendanceNumberLabel,
  className,
}) => (
  <LabelStyled className={className}>
    {gradeLabel &&
      (classNameLabel || !isNil(attendanceNumberLabel) ? (
        <span>
          {gradeLabel}
          <GradeStyled>-</GradeStyled>
        </span>
      ) : (
        <span>{gradeLabel}</span>
      ))}
    {classNameLabel &&
      (!isNil(attendanceNumberLabel) ? (
        <ClassStyled>{classNameLabel}組</ClassStyled>
      ) : (
        <span>{classNameLabel}組</span>
      ))}
    {!isNil(attendanceNumberLabel) ? (
      <span>{attendanceNumberLabel}番</span>
    ) : null}
  </LabelStyled>
);

export default GradeClassNumberLabel;
