import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext } from 'react';

import useCurrentUser from 'hooks/store/useCurrentUser';
import { AppContext } from 'providers/ContextProvider';
import { accessTokenStorage } from 'utils/AccessTokenStorage';
import CookieStorage from 'utils/CookieStorage';

import { buildErrorResponse } from './useError';

export type AccessTokenResponse = {
  access_token: string;
};

interface LoginOption {
  remember?: boolean;
}

const refreshAccessTokenReq = async (): Promise<
  AccessTokenResponse['access_token']
> => {
  const res = await fetch('/api/school/auth/token/refresh', {
    method: 'POST',
    headers: {
      'content-type': 'application/json;charset=UTF-8',
    },
    credentials: 'same-origin',
  }).catch(() => {
    throw buildErrorResponse({
      status: 500,
    });
  });

  if (!res.ok) {
    throw buildErrorResponse({
      status: res.status,
    });
  }
  const resBody = (await res.json()) as AccessTokenResponse;
  return resBody.access_token;
};

class RefreshAccessTokenPromiseCache {
  private cache: Promise<void> | null;

  constructor() {
    this.cache = null;
  }

  public get(): Promise<void> | null {
    return this.cache;
  }

  public set(promise: Promise<void>): void {
    this.cache = promise;
  }

  public clear(): void {
    this.cache = null;
  }
}

// Singleton
export const refreshAccessTokenPromiseCache =
  new RefreshAccessTokenPromiseCache();

interface UseAuthenticationReturn {
  /**
   * @deprecated loginV2 を使用してください
   */
  login: (token: string, options?: LoginOption) => void;
  loginV2: (token: string) => void;
  refreshAccessToken: () => Promise<void>;
  clearUserData: () => void;
  isLoggedIn: boolean;
}

const useAuthentication = (): UseAuthenticationReturn => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AppContext);
  const [, setCurrentUser] = useCurrentUser();
  const queryClient = useQueryClient();

  // TODO: リフレッシュトークン方式に移行した後は不要となる
  const login = useCallback(
    (token: string, options: LoginOption = {}) => {
      accessTokenStorage.set(token);
      CookieStorage.setAccessToken(token, options);
      setIsLoggedIn(true);
    },
    [setIsLoggedIn],
  );

  const loginV2 = useCallback(
    (token: string) => {
      accessTokenStorage.set(token);
      setIsLoggedIn(true);
    },
    [setIsLoggedIn],
  );

  const refreshAccessToken = useCallback<() => Promise<void>>(() => {
    if (refreshAccessTokenPromiseCache.get() === null) {
      refreshAccessTokenPromiseCache.set(
        refreshAccessTokenReq()
          .then((newToken) => {
            loginV2(newToken);
          })
          .finally(() => {
            refreshAccessTokenPromiseCache.clear();
          }),
      );
    }
    return refreshAccessTokenPromiseCache.get() as Promise<void>;
  }, [loginV2]);

  const clearUserData = useCallback(() => {
    setIsLoggedIn(false);
    accessTokenStorage.clear();
    setCurrentUser(null);
    CookieStorage.deleteAccessToken(); // TODO: リフレッシュトークン方式に移行した後は不要となる
    queryClient.clear();
  }, [queryClient, setCurrentUser, setIsLoggedIn]);

  return {
    login,
    loginV2,
    refreshAccessToken,
    clearUserData,
    isLoggedIn,
  };
};

export default useAuthentication;
