import {
  datadogRum,
  RumActionEventDomainContext,
  RumInitConfiguration,
} from '@datadog/browser-rum';

export const datadogRumInit: () => void = () => {
  const applicationId =
    process.env.REACT_APP_DATADOG_APPLICATION_ID ||
    'REACT_APP_DATADOG_APPLICATION_ID';
  const clientToken =
    process.env.REACT_APP_DATADOG_CLIENT_TOKEN ||
    'REACT_APP_DATADOG_CLIENT_TOKEN';
  const env = process.env.REACT_APP_SITE_ENV || 'local';
  const version = process.env.REACT_APP_BUILD_VERSION || '1.0.0';

  const options: RumInitConfiguration = {
    site: 'datadoghq.com',
    service: 'parents-web',
    applicationId,
    clientToken,
    env,
    version,
    sessionSampleRate: 25,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask',
    beforeSend: (event, context) => {
      if (event.type === 'action' && event.action && event.action.target) {
        const targetName = (context as RumActionEventDomainContext).events
          ?.map((event) => (event.target as Element).className)
          .find((className) => !!className);
        event.action.target.name = targetName || 'Some Elements';
      }
      return true;
    },
  };

  if (env !== 'production') return;
  datadogRum.init(options);
};
