import { noop } from 'lodash-es';
import React, { createContext, useState } from 'react';

import { ModalComponentsType } from 'components/organisms/common/ModalRoot';
import { Agreement } from 'interfaces/agreement';
import { SetState } from 'interfaces/common';
import { ParentWithStudents } from 'interfaces/parent';

export interface ContextValues {
  isLoggedIn: boolean;
  setIsLoggedIn: SetState<boolean>;
  currentUser: ParentWithStudents | null;
  setCurrentUser: SetState<ParentWithStudents | null>;
  toastMessage: string;
  setToastMessage: SetState<string>;
  isModalOpen: boolean;
  setIsModalOpen: SetState<boolean>;
  modalType: keyof ModalComponentsType | null;
  setModalType: SetState<keyof ModalComponentsType | null>;
  modalProps: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  setModalProps: SetState<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  agreements: Agreement[];
  setAgreements: SetState<Agreement[]>;
}

export const defaultValues: ContextValues = {
  isLoggedIn: false,
  setIsLoggedIn: noop,
  currentUser: null,
  setCurrentUser: noop,
  toastMessage: '',
  setToastMessage: noop,
  isModalOpen: false,
  setIsModalOpen: noop,
  modalType: null,
  setModalType: noop,
  modalProps: null,
  setModalProps: noop,
  agreements: [],
  setAgreements: noop,
};

interface OwnProps {
  customValues?: Partial<ContextValues>;
  children?: React.ReactNode;
}

export const AppContext = createContext(defaultValues);

const ContextProvider: React.FC<OwnProps> = ({ children, customValues }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(defaultValues.isLoggedIn);
  const [currentUser, setCurrentUser] = useState(defaultValues.currentUser);
  const [toastMessage, setToastMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(defaultValues.isModalOpen);
  const [modalType, setModalType] = useState(defaultValues.modalType);
  const [modalProps, setModalProps] = useState(defaultValues.modalProps); // eslint-disable-line @typescript-eslint/no-unsafe-assignment
  const [agreements, setAgreements] = useState(defaultValues.agreements);

  const values: ContextValues = {
    isLoggedIn,
    setIsLoggedIn,
    currentUser,
    setCurrentUser,
    toastMessage,
    setToastMessage,
    isModalOpen,
    setIsModalOpen,
    modalType,
    setModalType,
    modalProps, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
    setModalProps,
    agreements,
    setAgreements,
    ...customValues,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export default ContextProvider;
