import React from 'react';
import { styled } from 'styled-components';

interface Props {
  label?: string;
}

export const LabelStyled = styled.div<{
  $label: string;
}>(({ theme, $label }) => ({
  height: theme.size.xl,
  width: theme.size.xxxl,
  color:
    $label === '欠席' ? theme.globalColor.white : theme.globalColor.pinkRed800,
  fontWeight: theme.fontWeight.semiBold,
  fontSize: theme.fontSize.s,
  background:
    $label === '欠席'
      ? theme.globalColor.pinkRed800
      : theme.globalColor.pinkRed100,
  border: `1px solid ${theme.globalColor.pinkRed800}`,
  lineHeight: theme.lineHeight.default,
  borderRadius: 2,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const AbsenceLabel: React.FC<Props> = ({ label = '欠席' }) => (
  <LabelStyled $label={label} data-testid="absenceLabel">
    {label}
  </LabelStyled>
);
export default AbsenceLabel;
