import Mime from 'mime';
import React from 'react';

import { ReactComponent as DocIcon } from 'assets/icons/filetypes/doc.svg';
import { ReactComponent as DocxIcon } from 'assets/icons/filetypes/docx.svg';
import { ReactComponent as PdfIcon } from 'assets/icons/filetypes/pdf.svg';
import { ReactComponent as PptIcon } from 'assets/icons/filetypes/ppt.svg';
import { ReactComponent as PptxIcon } from 'assets/icons/filetypes/pptx.svg';
import { ReactComponent as XlsIcon } from 'assets/icons/filetypes/xls.svg';
import { ReactComponent as XlsxIcon } from 'assets/icons/filetypes/xlsx.svg';

const ICONS: Record<string, React.FC> = {
  pdf: PdfIcon,
  doc: DocIcon,
  docx: DocxIcon,
  ppt: PptIcon,
  pptx: PptxIcon,
  xls: XlsIcon,
  xlsx: XlsxIcon,
};

interface Props extends React.SVGProps<SVGSVGElement> {
  type: string;
}

const FileTypeIcon: React.FC<Props> = ({ type, ...props }) => {
  const Icon = ICONS[Mime.getExtension(type) as string];

  return <Icon {...props} />;
};

export default FileTypeIcon;
