import { parse, parseISO, isSameDay } from 'date-fns';
import { useCallback } from 'react';

const useTimeExceededChecker = () => {
  const isTimeExceeded = useCallback((date?: string) => {
    const now = new Date();
    const passedDate = date ? parseISO(date) : now;
    const timeLimit = parse('08:30', 'HH:mm', now);

    if (isSameDay(passedDate, timeLimit)) return now > timeLimit;
    return passedDate < timeLimit;
  }, []);

  const isTimeExceededWithLimit = useCallback(
    (date?: string, limit?: string | null) => {
      const now = new Date();
      const passedDate = date ? parseISO(date) : now;
      const timeLimit = limit ? parse(limit, 'HH:mm', now) : now;

      if (isSameDay(passedDate, timeLimit)) return now > timeLimit;
      return passedDate < timeLimit;
    },
    [],
  );

  return {
    isTimeExceeded,
    isTimeExceededWithLimit,
  };
};

export default useTimeExceededChecker;
